/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { callAPI } from '../helper/api';
import { ToastContext } from '../context/ToastContext';
import { toastType } from '../constants';

// API hook
export default function useFetch() {
  // third party
  const { t } = useTranslation();

  // state
  const [loading, setLoading] = React.useState(false);

  // context
  const { setToast } = React.useContext(ToastContext);

  /**
   *
   * @param {function} param.apiCallback callback function of api (callAPI with argument)
   * @param {string} param.message message for the specific action
   * @returns
   */
  const fetch = async ({ url, request, message = '' }) => {
    setLoading(true);

    try {
      const response = await callAPI(url, request);

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t(message),
        type: toastType.success,
        open: true
      });

      return true;
    } catch (error) {
      setToast({
        message: error.message,
        type: toastType.error,
        open: true
      });

      return false;
    }
  };

  return {
    // func
    fetch,

    loading
  };
}
