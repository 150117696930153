import React from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../../components/common/Title';
import { manageUserSchema } from '../../helper/validation';
import Form from './Form';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import withAdminManage from '../../components/hoc/withAdminManage';
import useUserData from './useUserData';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../helper/admin/page';
import useUserInputData from './useUserInputData';
import { adminType } from '../../constants';

function ManageUsers({
  t,
  location,
  type,

  // recoil
  filterList,
  setFilterList,
  user,

  // func
  onDelete,
  onEdit,
  onSave,
  onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit
}) {
  const {
    inheritedData,
    getDeleteReq,
    getEditReq,
    getCreateReq,
    handleCustomerChange
  } = useUserData({
    data: location?.state?.data,
    user,
    filterList,
    setFilterList
  });

  const inputProps = useUserInputData(user);

  const formik = useFormik({
    initialValues: { ...inheritedData },
    validationSchema: manageUserSchema(),

    onSubmit: (values) => {
      return isEdit
        ? onEdit(getEditReq(values, location?.state?.data.permission_level))
        : onSave(getCreateReq(values));
    }
  });

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      onEdit: formik.handleSubmit,
      onSave: formik.handleSubmit,
      onDelete: () => onDelete(getDeleteReq(location?.state?.data.username)),
      onClose
    }
  });

  return (
    <>
      <ContentHeading
        title={t('menuName.users')}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            {...formik}
            isEdit={isEdit}
            filterList={filterList}
            inputPropData={inputProps}
            handleCustomerChange={handleCustomerChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

// hoc will manage type of value
export default withAdminManage(adminType.user)(ManageUsers);
