export default function usePermission({ user, type, inheritedData = {} }) {
  const { isSuperRikerAdmin, isRiker, isPicardAdmin, isSuperAdmin } =
    user?.getUserPermission();

  const allUser = isRiker || isPicardAdmin;

  const obj = {
    partner: {
      hasCreate: isSuperRikerAdmin,
      hasEdit: isRiker,
      hasDelete: isSuperRikerAdmin
    },
    customer: {
      hasCreate: isRiker,
      hasEdit: allUser,
      hasDelete: isRiker
    },
    user: {
      hasCreate: allUser,
      hasEdit: allUser,
      hasDelete: allUser
    },

    // since separated api created
    device: {
      hasCreate: isSuperRikerAdmin,
      hasEdit: allUser,
      hasDelete: isSuperAdmin
    },
    csuvItem: {
      hasCreate: isSuperRikerAdmin,
      hasEdit: allUser,
      hasDelete: isSuperAdmin
    }
  };

  return { ...obj?.[type], isEdit: Object.keys(inheritedData).length > 0 };
}
