import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FaCircle } from 'react-icons/fa';

import NoDataFound from '../../components/common/NoDataFound';
import ChartDateRange from './ChartDateRange';
import Legend from './Legend';
import {
  renderNoFoundMessage,
  renderSubInformationList
} from '../../helper/roi/roi';

import ChartContainer from '../../components/Charts/ChartContainer';
import CompareCostPerSanitization from '../../components/pages/ROICalculator/CompareCostPerSanitization';
import EstimatedSaving from '../../components/pages/ROICalculator/EstimatedSaving';
import LayoutROIResult from '../../layout/LayoutROIResult';
import SubInformation from '../../components/pages/ROICalculator/SubInformation';
import useDateRange from '../../hooks/useDateRange';

import { roiEstSavingStyles } from '../../styles/ROICalculator';
import imgStar from '../../assets/images/ico_star.svg';

export default function CalculatedResult({
  calculated,
  roiCalculator,
  submitted
}) {
  const styles = roiEstSavingStyles();
  const noFoundMessage = renderNoFoundMessage({
    submitted,
    ...roiCalculator,
    ...calculated
  });

  const dateRangeObj = useDateRange({
    data: calculated?.csuvCleaning?.yearsPercent,
    startDate: roiCalculator?.startDate,
    calculated,
    roiCalculator
  });

  if (noFoundMessage)
    return (
      <Grid item md={12} xs={12}>
        <NoDataFound message={noFoundMessage} />
      </Grid>
    );

  return (
    <LayoutROIResult>
      {/* Left */}
      <LayoutROIResult.Left>
        {calculated.csuvCleaning.oneYearCostSavings > 0 && (
          <LayoutROIResult.LeftTop>
            <EstimatedSaving
              title={
                <div className={styles.oneYearSavingTitleText}>
                  3 Year Cost Savings
                </div>
              }
              value={calculated?.csuvCleaning?.oneYearCostSavings}
            />
          </LayoutROIResult.LeftTop>
        )}

        <CompareCostPerSanitization data={dateRangeObj?.compareChartData} />

        {/* sub information  */}
        <LayoutROIResult.LeftBottom>
          <SubInformation list={renderSubInformationList(calculated)} />
        </LayoutROIResult.LeftBottom>
      </LayoutROIResult.Left>

      {/* Right */}
      <LayoutROIResult.Right>
        <div>
          <div className={styles.paybackContainer}>
            <img src={imgStar} className={styles.paybackIcon} /> End of Payback
            Period
          </div>

          <div className={styles.paybackContainer}>
            <span className={styles.savingDollars}></span>
            Savings on Dollars
          </div>

          <div className={styles.paybackContainer}>
            <FaCircle fill="#f7a35c" className={styles.month} size="0.8em" />
            <FaCircle fill="#7CB5EC" className={styles.month} size="0.8em" />
            <FaCircle fill="#90ed7d" className={styles.month} size="0.8em" />
            Month
          </div>
        </div>

        <ChartContainer chartOptions={dateRangeObj?.chartData} />

        {/* legend */}
        <Legend legendList={dateRangeObj?.legend} />

        <ChartDateRange
          handleDateClick={dateRangeObj?.handleDateClick}
          selectedYear={dateRangeObj?.selectedYear}
        />
      </LayoutROIResult.Right>
    </LayoutROIResult>
  );
}
