import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  maintenanceContainer: {
    height: '100%',
    position: 'absolute'
  },
  maintenanceItem: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: 20
    }
  },
  itemWrapper: {
    width: '100%'
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '20rem'
    }
  }
}));
