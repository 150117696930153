import { getUnixTime, add } from 'date-fns';
import { conformToMask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { callAPI } from '../api';
import { getFormattedDataList } from '../chart';

import imgStar from '../../assets/images/ico_star.svg';
import { analyticsType, roi } from '../../constants';

const amountMask = createNumberMask({
  allowDecimal: true,
  decimalLimit: 2,
  decimalSymbol: '.',
  prefix: '',
  thousandsSeparatorSymbol: ','
});

const numberMask = createNumberMask({
  allowDecimal: false,
  prefix: '',
  thousandsSeparatorSymbol: ','
});

export const renderMask = (type) => {
  switch (type) {
    case 'number':
      return numberMask;

    case 'amount':
      return amountMask;

    default:
      return amountMask;
  }
};

/**
 *
 * @param {object} obj
 * @param {number} obj.val value to mask
 * @param {function} mask function to mask val
 * @param {any} obj.type type to mask
 * @returns {string} formatted string value depending on type
 */
export const renderMaskedText = ({ val, mask, type }) => {
  if (!val || !mask) return '';

  const negative = val < 0 ? '-' : ''; // regex does not support negative number option, so I added it manually
  const maskedValue = conformToMask(val, mask, { guide: false }).conformedValue;

  switch (type) {
    case 'number':
      return maskedValue;

    default:
      return `${negative}${maskedValue}`;
  }
};

export const getTotalUsage = ({ data }) => {
  let sum = 0;

  if (!data) return sum;

  getFormattedDataList({
    data,
    activity: 'scan_cycle_total'
  }).forEach((arr) => {
    sum += arr[1];
  });

  return sum;
};

export const setRequest = async (request) => {
  const response = await callAPI(`/analytics`, {
    ...request,
    type: analyticsType.usage
  });

  return response.data.data;
};

export const renderNoFoundMessage = ({ submitted, totalCSUVCleanCycle }) => {
  if (submitted) {
    if (!totalCSUVCleanCycle) return 'No previous data found';
    return '';
  }

  return 'Please select options';
};

export const renderSubInformationList = (obj) => {
  if (!obj?.csuvCleaning?.oneYearCostSavings) return [];

  return [
    {
      title: `Average Daily Uses`,
      value: obj?.csuvCleaning?.avgDailyUseTotal,
      tooltip: 'pages.savings.tooltip.roi.averageDailyUse',
      testID: 'result-avgDailyUseTotal'
    },
    {
      title: `Devices Sanitized Daily`,
      value: obj?.csuvCleaning?.deviceSanitizedDaily,
      tooltip: 'pages.savings.tooltip.roi.devicesSanitizedDaily',
      testID: 'result-deviceSanitizedDaily'
    },
    {
      title: `Pay back period`,
      value: obj?.csuvCleaning?.paybackPeriod,
      unit: 'month(s)',
      tooltip: 'pages.savings.tooltip.roi.payback',
      testID: 'result-paybackPeriod'
    },
    {
      title: `Environmental Impact`,
      value: obj?.csuvCleaning?.environmentImpact,
      unit: 'lbs',
      tooltip: 'pages.savings.tooltip.roi.envImpact',
      testID: 'result-environmentImpact'
    }
  ];
};

export function currencyToFloat(val) {
  if (typeof val === 'string' && val.includes(',')) {
    return parseFloat(val.replace(',', ''));
  }

  return parseFloat(val);
}

function getMonthPercent(years) {
  const percentList = [];

  years.forEach((yr, index) => {
    if (!percentList.length) {
      percentList.push(yr[1] / roi.years.y1.months);
    } else {
      percentList.push((yr[1] - years[index - 1][1]) / roi.years.y1.months);
    }
  });

  return percentList;
}

export function setDataByMonth({ list, duration }) {
  if (!list) return [];

  const [yr1, yr2, yr3, yr4, yr5] = getMonthPercent(list);
  let num = 0;
  const collected = [];

  // if 5yr
  for (let index = 0; index < roi.years.y5.months; index += 1) {
    const m = index + 1;
    const unix = getUnixTime(add(new Date(), { months: m }));

    if (index < roi.years.y1.months) {
      num += yr1;
    } else if (index < roi.years.y2.months) {
      num += yr2;
    } else if (index < roi.years.y3.months) {
      num += yr3;
    } else if (index < roi.years.y4.months) {
      num += yr4;
    } else {
      num += yr5;
    }

    collected.push([unix, num]);
  }

  return collected.splice(0, duration);
}

export function setPeriodByValue(val) {
  const { y1, y3, y5 } = roi.years;
  switch (val) {
    case y1.id:
      return y1.months;

    case y3.id:
      return y3.months;

    default:
      return y5.months;
  }
}

export function setZoneBySelectedYr({ yr }) {
  // value - percent value
  // color - color
  const { y1, y2, y3 } = roi.years;

  switch (yr) {
    // '1y':
    case y1.id:
      return [
        {
          color: '#f7a35c',
          name: '1 Year'
        }
      ];

    // '3y':
    case y3.id:
      return [
        {
          value: y1.months, // 11 equals 12 months
          color: '#f7a35c',
          name: '1 Year'
        },
        {
          value: y2.months,
          color: '#7cb5ec',
          name: '2 Years'
        },
        {
          color: '#90ed7d',
          name: '3 Years'
        }
      ];
    //  5 years
    default:
      return [
        {
          value: y1.months, // 11 equals 12 months
          color: '#f7a35c',
          name: '1 Year'
        },
        {
          value: y3.months,
          color: '#7cb5ec',
          name: '3 Years'
        },
        {
          color: '#90ed7d',
          name: '5 Years'
        }
      ];
  }
}

export const setXAxisPositionByYr = (yr) => {
  const { y1, y2, y3 } = roi.years;

  switch (yr) {
    case y1.id:
      return [11];

    case y3.id:
      return [y1.months, y2.months, 34];

    default:
      return [y1.months, y3.months, 58];
  }
};

export const getFormattedXAxisCategories = (list, startDate) => {
  return list.map((unix) => {
    const currentDate = new Date(unix[0] * 1000);

    return `Year ${currentDate.getFullYear() - startDate.getFullYear()}`;
  });
};

export const getCustomerChartName = (customerName) => {
  if (!customerName) return '';

  return ` - ${customerName}`;
};

export const getChartXData = (list, payback) => {
  const result = list.map((d, index) =>
    index === Math.round(payback)
      ? {
          y: d[1],
          marker: {
            symbol: `url(${imgStar})`,
            enabled: true,
            width: 35,
            height: 40
          }
        }
      : d[1]
  );

  return result;
};

export const getNameByList = (filterList, ids, filterName) => {
  if (!Array.isArray(ids)) return '';

  return filterList?.[`${filterName}s`]
    .filter((item) => ids.includes(item.id))
    .map((id) => `${id?.name}${id?.location ? ` ${id?.location}` : ''}`);
};

export const getCustomerUidByEntityType = (filterOptions) => {
  const id = filterOptions?.id;

  if (!Array.isArray(id)) return id;

  return filterOptions?.parent;
};

// if device selected, return array length
export const getDeviceLengthByEntity = async (filterOptions) => {
  const name = filterOptions?.name;

  if (name === 'device') return filterOptions?.id.length;

  const devices = await callAPI('/devices', {
    id: filterOptions.id,
    name
  });

  return devices?.data?.length;
};
