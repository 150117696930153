import { atom, selector } from 'recoil';
import { userState } from '../state';
import { dashboard } from '../key';
import { sortByObjectName } from '../../helper/filterOptions';
import { filterState } from './filter';

export const latestActivityState = atom({
  key: dashboard.latestActivityState,
  default: []
});

const defaultDashboard = {
  filterOptions: {},
  tableQuery: []
};

const defaultDashboardSelector = selector({
  key: 'defaultDashboardSelector',
  get: ({ get }) => {
    const user = get(userState);
    const filter = get(filterState);

    const { isSuperRikerAdmin, isRikerUser } = user.getUserPermission();

    let name = '';
    let id = '';

    if (isSuperRikerAdmin) {
      const initList = [...filter.customers].sort((prev, next) => {
        return sortByObjectName(prev, next, 'name');
      });

      name = 'customer';
      id = initList[0].id;
    } else if (isRikerUser) {
      name = 'customer';
      const initList = [...filter.customers].sort((prev, next) => {
        return sortByObjectName(prev, next, 'name');
      });

      id = initList[0].id;
    } else {
      name = 'customer';
      id = user?.customer_uid;
    }

    return {
      ...defaultDashboard,
      filterOptions: { ...defaultDashboard.filterOptions, name, id }
    };
  }
});

export const dashboardState = atom({
  key: dashboard.dashboardState,
  default: defaultDashboardSelector
});

export const dashboardAlertState = atom({
  key: dashboard.dashboardAlertState,
  default: {}
});
