import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

// Global style media query - without menu
// [theme.breakpoints.down('xs')]: {
// },
// [theme.breakpoints.up('sm')]: {
// },
// [theme.breakpoints.up('lg')]: {
// }

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('lg')]: {
      height: '100%'
    },
    display: 'flex',
    minHeight: '100%'
  },
  bottomMenu: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 40,
    paddingLeft: 35,
    paddingRight: 35,
    width: '100%',
    marginTop: 120
  },
  contentContainer: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 220,
      width: '100%'
    }
  },
  side: {
    // minHeight: 750,
    width: 220,
    position: 'sticky',
    top: 0
  },
  sideContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      top: 0,
      flex: '1 0 auto',
      display: 'flex',
      outline: 0,
      height: '100vh',
      zIndex: 1200,
      position: 'fixed',
      overflowY: 'auto',
      flexDirection: 'column',
      backgroundColor: color.white
    }
  }
}));
