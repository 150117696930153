import { useHistory } from 'react-router-dom';

export default function useURLParam(location) {
  const history = useHistory();

  const getSerialByLocationState = () => {
    const serial = location.state?.serial;

    if (!/^cs[\d][\d]-[\d][\d][\d][\d][\d]$/gim.test(serial)) {
      // if serial not does not meet requirement
      history.goBack();

      return '';
    }

    return serial;
  };

  return getSerialByLocationState();
}
