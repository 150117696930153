import { selectorFamily } from 'recoil';
import { sub } from 'date-fns';
import { getAnalyticsDefaultDateRange } from '../../helper/date';
import { filterState } from '../atom/filter';
import { userState } from '../state';

import { dataOptions, filterType, analyticsType } from '../../constants';

export const getDefaultAnalytics = selectorFamily({
  key: 'getDefaultAnalytics',
  get:
    (type) =>
    ({ get }) => {
      const filter = get(filterState);
      const user = get(userState);

      let dateRange = dataOptions.week;

      const id = filter?.devices.map((dv) => dv.id);
      const name = filterType.device;
      let parent = filter?.devices?.[0]?.parent_customer;
      let date = {
        startDate: new Date(),
        endDate: new Date()
      };

      if (!parent) {
        parent = user?.customer_uid;
      }

      switch (type) {
        case analyticsType.average:
          dateRange = dataOptions.month;
          date = getAnalyticsDefaultDateRange('months');
          break;

        case analyticsType.compare:
          dateRange = dataOptions.customDays;
          date = {
            startDate: sub(new Date(), { days: 1 }),
            endDate: new Date()
          };
          break;

        case analyticsType.usage:
          dateRange = dataOptions.customDays;
          date = {
            startDate: sub(new Date(), { weeks: 1 }),
            endDate: new Date()
          };
          break;

        case analyticsType.trend:
          dateRange = dataOptions.customDays;
          date = {
            startDate: sub(new Date(), { days: 9 }),
            endDate: new Date()
          };
          break;

        default:
          break;
      }

      console.log({ date });

      return {
        filterOptions: {
          type,
          id,
          name,
          parent,
          ...date
        },
        dateRange
      };
    }
});
