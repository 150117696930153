import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import radioStyles, { white } from '../../styles/Input/Radio';
import { color } from '../../constants';

/**
 *
 * @param {*} radioGroupProps.value
 * @param {*} radioGroupProps.name
 * @param {*} radioGroupProps.row
 * @param {*} radioGroupProps.onChange
 * @param {{id: string, name:string}[]} list
 * @param {string} color
 * @returns
 */

export default function CSUVRadio({
  color: inputColor,
  list = [],
  radioGroupProps,
  radioProps = {},
  value
}) {
  const renderStyles = () => {
    switch (inputColor) {
      case color.white:
        return white();
      default:
        return radioStyles();
    }
  };

  const { t } = useTranslation();
  const classes = renderStyles();

  return (
    <FormControl component="fieldset" className={classes.container}>
      <RadioGroup defaultValue={value} {...radioGroupProps}>
        {list.map((radio) => {
          return (
            <FormControlLabel
              checked={radioGroupProps.value === radio?.id}
              control={<Radio {...radioProps} />}
              key={radio?.id}
              label={t(radio?.name)}
              value={radio?.id}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
