import { FaCircle } from 'react-icons/fa';

import { legendListStyles } from '../../styles/ROICalculator';

export default function Legend({ legendList }) {
  const classes = legendListStyles();

  return (
    <div className={classes.legendContainer}>
      {legendList?.map((item) => (
        <div key={item.name}>
          <FaCircle
            fill={item.color}
            size="0.5em"
            className={classes.legendColor}
          />
          {item.name}
        </div>
      ))}
    </div>
  );
}
