import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useRecoilValue } from 'recoil';

import Card from '../../components/common/Card';
import { sanitizationSelector } from '../../recoil/selector/analytics';
import { useDateRange } from '../../components/pages/Analytics/hooks/useChartDateChange';

import { averageSanitizationInfoCardStyle } from '../../styles/Analytics';
import { buttonStyle } from '../../constants';

const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

export default function SanitizationInfoCard({
  number,
  title,
  filterOptions,
  changedDateByData
}) {
  const { t } = useTranslation();
  const classes = averageSanitizationInfoCardStyle();
  const range = useRecoilValue(sanitizationSelector(filterOptions));

  // if chart date is not same as filter option
  const selectedRange = useDateRange(changedDateByData, range);

  return (
    <Grid item {...columns} className={classes.container}>
      <Card style={buttonStyle.filledGreen}>
        <div className={classes.textContainer}>
          <div>
            <span className={classes.title}>{t(title)}</span>
            <br />
            <span className={classes.dateRange}>
              {t(selectedRange)}
              <br />
            </span>
          </div>
          <div className={classes.numberContainer}>
            <span className={classes.number}>
              {Number.isNaN(Math.round(number)) ? 0 : Math.round(number)}
            </span>
          </div>
        </div>
      </Card>
    </Grid>
  );
}
