import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  languageIconContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
