import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => {
  return {
    filterButtonContainer: {
      [theme.breakpoints.down('sm')]: {
        '& button': {
          width: '100%'
        }
      },
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'flex-end',
        flexDirection: 'row-reverse'
      },
      [theme.breakpoints.up('lg')]: {},
      '& button': {
        width: '100%'
      },
      display: 'flex'
    },
    filterContainer: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column'
    }
  };
});
