/* eslint-disable no-restricted-syntax */
import { format, compareAsc } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function setByHour({ map, list }) {
  list.forEach((cycle) => {
    const cycleEntries = Object.entries(cycle)[0];
    const hour = cycleEntries[0];
    const value = cycleEntries[1];

    if (!map.get(hour)) {
      map.set(`${hour}`, value);
    } else {
      const prev = map.get(hour);
      map.set(`${hour}`, value + prev);
    }
  });

  return map;
}

const hourConverter = (h) => {
  if (h === '24') return '00';
  if (h.length === 1) return `0${h}`;

  return h;
};

export function createChartList({ map, date, tz }) {
  const list = [];

  for (const [key, value] of map) {
    const hour = `${parseInt(key)}`;
    const dt = `${date}T${hourConverter(hour)}:00:00.000Z`;
    const convertedDate = utcToZonedTime(dt, tz);
    const name = format(convertedDate, 'h aaa');

    list.push({
      y: value,
      name,
      labelLink: 1,
      zoneHour: hour,
      zoneDate: convertedDate.getHours()
    });
  }

  return list;
}

export const sortByHours = (list) => {
  return list.sort((prv, nxt) => {
    return compareAsc(prv.zoneDate, nxt.zoneDate);
  });
};
