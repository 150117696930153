import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { utcToZonedTime } from 'date-fns-tz';

// components
import { formatDate } from '../../helper/date';
import { getPageData } from '../../helper/page'; // This state is for selectedDate, each Analytics page will have this selectedDate to print custom range option
import { validateSixMonth } from '../../helper/validation';
import Button from '../Button/Button';
import CustomDatePicker from '../Input/CustomDatePicker';
import ErrorMessage from './ErrorMessage';

// styles
import customDateRangeStyles from '../../styles/common/CustomDateRange';
import { dateformat } from '../../constants';

const initDate = {
  startDate: '',
  endDate: ''
};

export default function CustomDateRange({ onClose, timezone, scheduled }) {
  const { state: pageState } = getPageData(window.location.pathname);
  const [state, setState] = useRecoilState(pageState);
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [errorMessage, setErrorMessage] = useState('');
  const dateClasses = customDateRangeStyles();
  const today = new Date();
  const { t } = useTranslation();
  const { filterOptions } = state;

  const onClick = () => {
    const dateRangeStr = `${formatDate(
      selectedDate.startDate,
      dateformat.noTime
    )} - ${formatDate(selectedDate.endDate, dateformat.noTime)}`;
    const isDateValid = validateSixMonth(selectedDate);

    if (!isDateValid) {
      setErrorMessage(t('errorMessage.rangeOver6Months'));
      return;
    }

    setState({
      ...state,
      values: { ...state.values, selectedDate: dateRangeStr },
      filterOptions: {
        ...state.filterOptions,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate
      }
    });
    onClose();
  };

  useEffect(() => {
    setSelectedDate({
      startDate: filterOptions.startDate,
      endDate: filterOptions.endDate
    });

    // When the date range is not selected, set date from recoil value
    const dateRangeStr = `${formatDate(
      filterOptions.startDate,
      dateformat.noTime
    )} - ${formatDate(filterOptions.endDate, dateformat.noTime)}`;

    setState({
      ...state,
      values: { ...state.values, selectedDate: dateRangeStr }
    });
  }, []);

  const onChange = (date, key) => {
    setErrorMessage('');
    setSelectedDate({ ...selectedDate, [key]: date });
  };

  return (
    <>
      <Grid container spacing={2}>
        {!scheduled ? (
          <>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={dateClasses.datePickerContainer}
            >
              {/* Start date picker */}
              <CustomDatePicker
                selected={selectedDate.startDate}
                onChange={(date) => onChange(date, 'startDate')}
                selectsStart
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
                label={t('label.startDate')}
                maxDate={today}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={dateClasses.datePickerContainer}
            >
              {/* End date picker */}
              <CustomDatePicker
                selected={selectedDate.endDate}
                onChange={(date) => onChange(date, 'endDate')}
                selectsEnd
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
                label={t('label.endDate')}
                maxDate={today}
              />
            </Grid>
          </>
        ) : (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={dateClasses.datePickerContainer}
          >
            <CustomDatePicker
              showTimeSelect={true}
              selected={filterOptions.endDate}
              onChange={(date) =>
                setSelectedDate({
                  ...selectedDate,
                  endDate: utcToZonedTime(date, timezone)
                })
              }
              selectsEnd
              minDate={today}
              label={t('common.customDateTime')}
            />
          </Grid>
        )}

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            description={t('label.updateDateRange')}
            onClick={onClick}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      </Grid>
    </>
  );
}
