import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import ViewMoreButton from '../../components/Button/ViewMoreButton';
import { analyticsBoxButtonStyles } from '../../styles/Analytics';
import { track } from '../../helper/segment';
import { trends, averages, comparisons, useStatistics } from '../../paths';

export default function AnalyticsBoxButton({
  title,
  onMouseOver,
  isSelected,
  link
}) {
  const { t } = useTranslation();
  const classes = analyticsBoxButtonStyles();
  const history = useHistory();

  const onLinkClick = () => {
    history.push(link);
    let navigateTo = '';

    switch (link) {
      case averages:
        navigateTo = 'Averages';
        break;

      case comparisons:
        navigateTo = 'Comparisons';
        break;

      case useStatistics:
        navigateTo = 'Usages';
        break;

      case trends:
        navigateTo = 'Trends';
        break;

      default:
        navigateTo = '';
        break;
    }

    track(`Analytics Overview - ${navigateTo} is clicked`);
  };

  return (
    <Grid item lg={12} md={6} sm={6} xs={6} onClick={onLinkClick}>
      <div
        className={`${classes.container} ${isSelected && 'is-selected'}`}
        onMouseOver={onMouseOver}
      >
        <span className={`${classes.title} title`}>{t(title)}</span>
        <span>
          {link && <ViewMoreButton link={link} pageName="Analytics Overview" />}
        </span>
      </div>
    </Grid>
  );
}
