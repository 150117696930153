import Grid from '@material-ui/core/Grid';

import Card from '../components/common/Card';
import { roiEstSavingStyles } from '../styles/ROICalculator';

function LayoutROIResult({ children }) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}

function Left({ children }) {
  return (
    <Grid item md={5} xs={12}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
}

function LeftTop({ children }) {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
}

function LeftBottom({ children }) {
  const styles = roiEstSavingStyles();

  return (
    <Grid item xs={12}>
      <Card cardStyle={{ height: '100%' }}>
        <Grid container className={styles.container}>
          {children}
        </Grid>
      </Card>
    </Grid>
  );
}

function Right({ children }) {
  return (
    <Grid item md={7} xs={12}>
      <Card>{children}</Card>
    </Grid>
  );
}

LayoutROIResult.Left = Left;
LayoutROIResult.LeftTop = LeftTop;
LayoutROIResult.LeftBottom = LeftBottom;
LayoutROIResult.Right = Right;

export default LayoutROIResult;
