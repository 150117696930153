import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';

import { renderMask } from '../../helper/roi/roi';
import textFieldStyles from '../../styles/Input/TextField';

function CSUVMaskedInput(props) {
  const { mask, inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={renderMask(mask)}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default function TextFieldWithIcon(props) {
  const textFieldClasses = textFieldStyles();

  return (
    <TextField
      className={props.className} // additional class
      label={props.label}
      onChange={(ev) => props.onChange(ev)}
      value={props.value}
      variant="outlined"
      // props for input, label
      InputProps={{
        inputComponent: CSUVMaskedInput,
        startAdornment: (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        classes: {
          root: textFieldClasses.outlineRoot,
          focused: textFieldClasses.focused,
          notchedOutline: textFieldClasses.notchedOutline
        }
      }}
      inputProps={{
        'data-testid': props?.testID,
        mask: props?.maskType,
        name: props.name,
        id: `id-${props?.testID}`
      }}
      InputLabelProps={{
        classes: {
          root: textFieldClasses.root,
          focused: textFieldClasses.focused
        }
      }}
    />
  );
}
