import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

// components
import { ContentHeading } from '../../components/common/Title';
import { setTableColumns, getFormattedList } from '../../helper/tableOptions';
import { trendState } from '../../recoil/atom/trend';
import { userState } from '../../recoil/state';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import FilterChartContainer from '../../components/Charts/FilterChartContainer';
import Loading from '../../components/common/Loading';
import TableContainer from '../../components/common/Table/TableContainer';
import useAnalytics from '../../hooks/useAnalytics';
import { useChartDateChange } from '../../components/pages/Analytics/hooks/useChartDateChange';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';

// etc
import { analyticsType } from '../../constants';

function Trends() {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const trendRecoil = useRecoilValue(trendState);

  // analytics react hook
  const { resData, loading } = useAnalytics(analyticsType.trend);

  // 2022-08-15 chart data's date is not same because of loading latest data
  const changed = useChartDateChange(resData.chart, {
    startDate: trendRecoil.filterOptions.startDate,
    endDate: trendRecoil.filterOptions.endDate
  });

  const modifiedList = getFormattedList(resData?.table);
  const columns =
    modifiedList?.length > 0 &&
    setTableColumns(Object.keys(modifiedList[0]), user, t);

  // table options
  const tableProps = {
    columns,
    rows: modifiedList,
    hasDownload: true,
    hasSearch: true,
    tableName: t('menuName.trends')
  };

  // filter chart props
  const filterChartProps = {
    changed,
    listOptions: { customer: true, group: true },
    chartData: resData.chart,
    loading,
    type: analyticsType.trend
  };

  useSegment();

  return (
    <>
      <Loading isOpen={loading} />

      <ContentHeading
        title={t('menuName.trends')}
        description={t('pages.analytics.trendDescription')}
      />

      <FilterChartContainer {...filterChartProps} />

      {/* Section Table */}
      {!loading ? (
        <TableContainer tableProps={tableProps} />
      ) : (
        <CardContentLoader />
      )}
    </>
  );
}

export default withContent(Trends);
