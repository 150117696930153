import { forwardRef } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import { HiOutlineSave, HiOutlineCheck, HiOutlineTrash } from 'react-icons/hi';
import { BiFirstPage, BiLastPage } from 'react-icons/bi';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { MdClose, MdFilterList } from 'react-icons/md';
import { RiEdit2Line } from 'react-icons/ri';

import { color } from '../constants';

export default function useTableIcons() {
  return {
    // Can be used later so I would like to keep it.
    // Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    Check: forwardRef((props, ref) => <HiOutlineCheck {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <MdClose {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => (
      <RiEdit2Line
        {...props}
        ref={ref}
        size="0.8em"
        style={{ fill: color.darkBlue }}
      />
    )),
    Delete: forwardRef((props, ref) => (
      <HiOutlineTrash
        {...props}
        ref={ref}
        size="0.8em"
        style={{ stroke: color.darkRed }}
      />
    )),
    Filter: forwardRef((props, ref) => (
      <MdFilterList {...props} ref={ref.current} />
    )),
    FirstPage: forwardRef((props, ref) => <BiFirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <BiLastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <GrFormNext {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <GrFormPrevious {...props} ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
      <AiOutlineArrowDown {...props} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <HiOutlineSave {...props} ref={ref} />),
    ResetSearch: () => <></>,
    Search: forwardRef((props, ref) => <FaSearch {...props} ref={ref} />)
  };
}
