import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Route as RRDRoute,
  Redirect
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// pages
import ManageUsers from './pages/ManageUsers';
// import SystemMaintenance from './pages/SystemMaintenance';
import Users from './pages/Users';
import AlertDetails from './pages/AlertDetails';
import Analytics from './pages/Analytics';
import Averages from './pages/Averages';
import Comparisons from './pages/Comparisons';
import Contact from './pages/Contact';
import Customers from './pages/Customers';
import Dashboard from './pages/Dashboard';
import DeviceDetail from './pages/DeviceDetail';
import ManageDevice from './pages/ManageDevice';
import ManageMultipleDevice from './pages/ManageDevice/Multiple';
import Devices from './pages/Devices';
import ForgotPassword from './pages/ForgotPassword';
import GroupDetail from './pages/GroupDetail';
import Groups from './pages/Groups';
import KnowledgeBase from './pages/KnowledgeBase';
import Maintenance from './pages/Maintenance';
import ManageCustomer from './pages/ManageCustomer';
import ManageGroups from './pages/ManageGroups';
import ManageNotification from './pages/ManageNotification';
import ManagePartner from './pages/ManagePartner';
import ManageReport from './pages/ManageReport';
import MobileSupport from './pages/Support/Mobile';
import NoMatch from './pages/NoMatch';
import Notifications from './pages/Notifications';
import Partners from './pages/Partners';
import Reports from './pages/Reports';
import ResetPassword from './pages/ResetPassword';
import ROICalculator from './pages/ROICalculator';
import ScanRecords from './pages/ScanRecords';
import ScheduledReport from './pages/ScheduledReport';
import Setting from './pages/Setting';
import SignIn from './pages/SignIn';
import Support from './pages/Support';
import Trends from './pages/Trends';
import Unsubscribe from './pages/Unsubscribe';
import UseStatistics from './pages/UseStatistics';
import Estimator from './pages/Estimator';

// components
import Toast from './components/common/Toast';
import withAuth from './components/hoc/withAuth';
import useUserSession from './hooks/useUserSession';
import { ToastProvider } from './context/ToastContext';

// paths
import { userState } from './recoil/state';
import { languageCode } from './constants';
import paths from './paths';

const {
  addAlert,
  addDevice,
  addGroup,
  adminManageCustomer,
  adminManageGroup,
  adminManagePartners,
  adminManageUser,
  analytics,
  averages,
  comparisons,
  contact,
  customers,
  dashboard,
  deviceManage,
  devices,
  editAlert,
  editGroup,
  expansion,
  forgotPassword,
  groups,
  home,
  knowledgeBase,
  maintenance,
  manageReport,
  multipleDeviceManage,
  noMatch,
  notifications,
  notificationsManage,
  partners,
  reports,
  resetPassword,
  roiCalculator,
  scanRecords,
  scheduled,
  setting,
  signIn,
  support,
  supportMobile,
  trends,
  unsubscribe,
  users,
  useStatistics,
  viewDevice
} = paths;

function App() {
  const { isLoading } = useUserSession();
  const user = useRecoilValue(userState);

  useEffect(() => {
    Cookies.remove();

    if (
      localStorage.getItem('lang') === 'null' ||
      !localStorage.getItem('lang')
    ) {
      localStorage.setItem('lang', languageCode.en);
    }
  }, []);

  if (isLoading) {
    return (
      <ToastProvider>
        <Router>
          <Switch>
            <PrivateRoute
              exact={true}
              path={home}
              component={Dashboard}
              isAuthed={user.isAuthed}
            />
            {/* Detail pages */}
            <PrivateRoute
              path={addAlert}
              component={AlertDetails}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={addGroup}
              component={GroupDetail}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={addDevice}
              component={DeviceDetail}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={editAlert}
              component={AlertDetails}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={editGroup}
              component={GroupDetail}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={`${viewDevice}/:id`}
              component={DeviceDetail}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={multipleDeviceManage}
              component={ManageMultipleDevice}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={deviceManage}
              component={ManageDevice}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={notificationsManage}
              component={ManageNotification}
              isAuthed={user.isAuthed}
            />

            {/* Admin Manage */}
            <PrivateRoute
              path={adminManageGroup}
              component={ManageGroups}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={adminManageUser}
              component={ManageUsers}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={adminManagePartners}
              component={ManagePartner}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={adminManageCustomer}
              component={ManageCustomer}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={manageReport}
              component={ManageReport}
              isAuthed={user.isAuthed}
            />

            {/* Page main */}
            <PrivateRoute
              path={analytics}
              component={Analytics}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={trends}
              component={Trends}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={averages}
              component={Averages}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={comparisons}
              component={Comparisons}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={useStatistics}
              component={UseStatistics}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={support}
              component={Support}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={scheduled}
              component={ScheduledReport}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={dashboard}
              component={Dashboard}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={devices}
              component={Devices}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={groups}
              component={Groups}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={reports}
              component={Reports}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={roiCalculator}
              component={ROICalculator}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={expansion}
              component={Estimator}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={setting}
              component={Setting}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={knowledgeBase}
              component={KnowledgeBase}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={contact}
              component={Contact}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={maintenance}
              component={Maintenance}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={users}
              component={Users}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={customers}
              component={Customers}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={partners}
              component={Partners}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={notifications}
              component={Notifications}
              isAuthed={user.isAuthed}
            />
            <PrivateRoute
              path={scanRecords}
              component={ScanRecords}
              isAuthed={user.isAuthed}
            />

            {/* Not authenticated */}
            <Route path={supportMobile} component={MobileSupport} />
            <Route path={signIn} component={SignIn} />
            <Route path={forgotPassword} component={ForgotPassword} />
            <Route path={resetPassword} component={ResetPassword} />
            <Route path={unsubscribe} component={Unsubscribe} />
            <Route path={noMatch} component={NoMatch} />
          </Switch>
        </Router>

        {/* Routing does not matter */}
        <Toast />
      </ToastProvider>
    );
  }

  return <></>;
}

function Route(props) {
  const { exact, path, component: Component } = props;

  return (
    <RRDRoute exact={exact} path={path}>
      <Component />
    </RRDRoute>
  );
}

function PrivateRoute({ component, isAuthed }) {
  const Component = withAuth(component);

  return (
    <RRDRoute
      render={(props) =>
        isAuthed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: signIn,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default App;

Route.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired
};
