import {
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  //
  getWeekOfMonth,
  isSameDay,
  isAfter,
  isBefore,
  //
  sub,
  format,
  differenceInDays,
  addDays
} from 'date-fns';
import { getUtcDate } from '../date';

const dayMapping = [
  [isSunday, 'sun', 'pages.analytics.days.sun'],
  [isMonday, 'mon', 'pages.analytics.days.mon'],
  [isTuesday, 'tue', 'pages.analytics.days.tue'],
  [isWednesday, 'wed'],
  [isThursday, 'thu'],
  [isFriday, 'fri'],
  [isSaturday, 'sat']
];

const getDay = (date) => {
  let day = '';
  dayMapping.forEach(([fn, key]) => {
    if (fn(date)) {
      day = key;
    }
  });

  return day;
};

export const getWeekRange = ({ date, start, end }) => {
  return (
    isSameDay(date, start) ||
    isSameDay(date, end) ||
    (isAfter(date, start) && isBefore(date, end))
  );
};

export function getThisWeek() {
  const endDate = new Date();
  const startDate = sub(endDate, { weeks: 1 });

  return {
    endDate: format(endDate, 'yyyy-MM-dd'),
    startDate: format(startDate, 'yyyy-MM-dd')
  };
}

export function getHasThisWeek(thisWeek, selectedDate) {
  const thisWeekStartDate = new Date(thisWeek.startDate);
  const reqStartDate = new Date(selectedDate.startDate);
  const reqEndDate = new Date(selectedDate.endDate);

  return reqStartDate <= thisWeekStartDate && reqEndDate >= thisWeekStartDate;
}

export const filterThisWeekDate = (data, { start, end }) => {
  const current = [];

  if (!data || !Array.isArray(data)) return [];

  const list = data
    .map((item) => {
      const date = new Date(item.sk);

      if (getWeekRange({ date, start, end })) {
        current.push({ ...item, sk: date });
        return null;
      }

      return item;
    })
    .filter((item) => item);

  return {
    others: list.filter((item) => item),
    current
  };
};

export const getDayWeek = (data, tz) => {
  const map = new Map();

  if (!data || !Array.isArray(data)) return map;

  data.forEach((item) => {
    const date = getUtcDate({ date: item.sk, timezone: tz });
    const numWeek = getWeekOfMonth(date);
    const day = getDay(date);

    const mapKey = `${day}-${numWeek}`;

    if (map.has(mapKey)) {
      const prev = map.get(mapKey);

      map.set(mapKey, prev + item.scan_cycle_total);
    } else {
      map.set(mapKey, item.scan_cycle_total);
    }
  });

  return map;
};

export const getNumWeeks = (start, end) => {
  let startDate = addDays(new Date(`${start}T00:00:00.000Z`), 1);
  const endDate = addDays(new Date(`${end}T00:00:00.000Z`), 1);

  const map = new Map();

  do {
    const day = getDay(startDate);

    if (map.has(day)) {
      const num = map.get(day);

      map.set(day, num + 1);
    } else {
      map.set(day, 1);
    }

    startDate = addDays(startDate, 1);
  } while (startDate <= endDate);

  return map;
};

export const totalByDays = (map, numWeeksObj, isCurrent) => {
  const totalMap = {
    sun: { total: 0, length: 0 },
    mon: { total: 0, length: 0 },
    tue: { total: 0, length: 0 },
    wed: { total: 0, length: 0 },
    thu: { total: 0, length: 0 },
    fri: { total: 0, length: 0 },
    sat: { total: 0, length: 0 }
  };

  // get total
  [...map].forEach(([key, value]) => {
    const day = key.split('-')[0];
    const regex = new RegExp(`^${day}`, 'g');

    if (regex.test(day)) {
      if (isCurrent) {
        totalMap[day].length += 1;

        totalMap[day].total =
          (totalMap[day].total + value) / totalMap[day].length;

        return;
      }

      totalMap[day].total += value;
      totalMap[day].length = numWeeksObj.get(day);
    }
  });

  return totalMap;
};

export const getDiffDays = ({ startDate, endDate }) => {
  return differenceInDays(new Date(endDate), new Date(startDate));
};

const setPercentByThisWeek = (hasThisWeek, thisWeekTotal, divided) => {
  if (hasThisWeek) {
    return (thisWeekTotal - divided) / divided;
  }

  return divided / divided;
};

const setAvgByDayByThisWeek = (hasThisWeek, total, num) => {
  if (hasThisWeek) {
    return total.other / num.other;
  }

  return total.other / num.other;
};

export function getPercent(totalOtherWeeks, totalCurrentWeeks, hasThisWeek) {
  return new Promise((resolve) => {
    try {
      let totalAvg = 0;
      const barChartData = [];
      const currentWeekData = [];

      const otherWeekObjToList = Object.entries(totalOtherWeeks);

      otherWeekObjToList.forEach(([key, value], index) => {
        const otherWeekTotal = value.total;
        const numOtherWeek = value.length;

        const thisWeekTotal = totalCurrentWeeks[key].total; // e.g (m-[(M1+M2+M3)/3])/[(M1+M2+M3)/3] <- m is current week
        const numThisWeek = totalCurrentWeeks[key].length;

        const divided = otherWeekTotal / numOtherWeek; // e.g (M1+M2+M3)/3
        const percent = setPercentByThisWeek(
          hasThisWeek,
          thisWeekTotal,
          divided
        ); // e.g (m-[(M1+M2+M3)/3])/[(M1+M2+M3)/3]

        // current week and rest week is separated so
        const avgByDay = setAvgByDayByThisWeek(
          hasThisWeek,
          {
            other: otherWeekTotal,
            thisWeek: thisWeekTotal
          },
          {
            other: numOtherWeek,
            thisWeek: numThisWeek
          }
        );

        totalAvg += avgByDay;

        barChartData.push([
          index,
          {
            total: avgByDay,
            numOfMerged: 1,
            day: `pages.analytics.days.${key}`
          }
        ]);

        currentWeekData.push({
          day: `pages.analytics.days.${key}`,
          percent,
          current: thisWeekTotal
        });
      });

      resolve({
        totalAvg: totalAvg / otherWeekObjToList.length, // top right card number
        barChartData, // bar chart data
        currentWeekData // this week element value
      });
    } catch (error) {
      console.log('error: ', error);
    }
  });
}
