import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  buttonMenuItem: {
    minWidth: 200,
    fontSize: '1em',
    color: color.darkBlue,
    fontFamily: fontFamily.regular
  }
});
