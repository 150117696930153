import { selector } from 'recoil';
import { filterState } from '../atom/filter';
import { userState } from '../state';
import { filterType } from '../../constants';

import { sortByObjectName } from '../../helper/filterOptions';

export const manageAdminSelector = selector({
  key: 'manageAdminSelector',
  get: ({ get }) => {
    const filter = get(filterState);
    const user = get(userState);
    const listObj = {};
    const { isRiker } = user.getUserPermission();

    if (isRiker) {
      Object.entries(filter).forEach(([key, values]) => {
        const parent = key === filterType.customers ? 'parent_partner' : '';

        listObj[key] = values.map((val) => ({
          value: val.id,
          text: val.name,
          parent: val[parent]
        }));
      });

      listObj.customers = listObj?.customers.sort((prev, next) =>
        sortByObjectName(prev, next, 'text')
      );
    }

    return listObj;
  }
});
