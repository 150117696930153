import { compareDesc } from 'date-fns';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import MaterialTable from 'material-table';

import { userState } from '../../../recoil/state';
import { getPageData } from '../../../helper/page';
import { color, responseKey } from '../../../constants';
import { containerStyles } from '../../../styles/common/Table';
import { formatDate, getUtcDate } from '../../../helper/date';
import TBFilterInput from './TBFilterInput';
// import TBFilterSelect from './TBFilterSelect';
import {
  getCustomizedColumns,
  tableLocalization,
  setDateKey,
  statusToInteger
} from '../../../helper/table';
import useTableIcons from '../../../hooks/useTableIcons';
import { devices, useStatistics, trends } from '../../../paths';

export default function TableContainer({
  actions,
  columns,
  hasDelete,
  hasDownload,
  hasEdit,
  hasPage: propHasPage,
  hasSearch,
  hasSelect,
  hasShadow,
  onDelete,
  onRowClick,
  onSelect,
  pageSize,
  rows,
  selectedData,
  tableName,
  type,

  ...others
}) {
  const tableRef = React.useRef();
  const user = useRecoilValue(userState);
  const { state } = getPageData(window.location.pathname);
  const pageState = useRecoilValue(state);
  const hasToolbar = hasSearch || hasDownload;
  const hasPage = propHasPage || rows?.length > 100;
  const tableContainerClasses = containerStyles();
  const icons = useTableIcons();
  const filteringOption = 'hasFilter' in others ? others.hasFilter : true;
  const localizationObject = tableLocalization();

  // Date default sort
  const dateKey = setDateKey(rows.length && rows?.[0]);
  const initRows = [...rows].sort((prev, next) => {
    if (window.location.pathname === devices) {
      return statusToInteger(prev.status) - statusToInteger(next.status);
    }

    return compareDesc(new Date(prev[dateKey]), new Date(next[dateKey]));
  });

  // column render
  let addedRenderColumns = getCustomizedColumns({
    rows,
    columns,
    user,
    pageState
  });

  addedRenderColumns = addedRenderColumns.map((col, index) => {
    const storedValue = pageState.tableQuery?.map((it) => {
      return { field: it.field, filterValue: it.tableData.filterValue };
    });

    if (col?.lookup) {
      return col;
      // {
      //   ...col,

      //   filterComponent: (props) => {
      //     return null;
      //     // (
      //     //   <TBFilterSelect
      //     //     {...props}
      //     //     lookup={col.lookup}
      //     //     rowId={props.columnDef?.tableData.columnOrder}
      //     //     queryData={tableRef.current?.dataManager.columns}
      //     //     storedValue={storedValue?.[index]?.filterValue || ''}
      //     //   />
      //     // );
      //   }
      // };
    }

    return {
      ...col,
      filterComponent: (props) => {
        return (
          <TBFilterInput
            {...props}
            rowId={props.columnDef?.tableData.columnOrder}
            queryData={tableRef.current?.dataManager.columns}
            storedValue={storedValue?.[index]?.filterValue || ''}
          />
        );
      }
    };
  });

  const { timezone } = user;

  const onClick = (event, data) => {
    if (onRowClick) onRowClick(data);
  };

  const onRowUpdate = (newData, oldData) => {
    console.log(newData, oldData);
    // update event
  };

  const onRowDelete = (oldData) => {
    if (onDelete) {
      return new Promise((resolve, reject) => {
        try {
          const response = onDelete(oldData);

          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    }

    return null;
  };

  const onSelectionChange = (selectedRow) => {
    if (onSelect) {
      onSelect(selectedRow);
    }
  };

  const renderDate = (row, key) => {
    const modifiedRow = { ...row };
    const hasActivityDate = [useStatistics, trends].includes(
      window.location.pathname
    );
    try {
      if (
        Object.keys(row).includes(key) &&
        hasActivityDate &&
        modifiedRow[key]
      ) {
        modifiedRow[key] = formatDate(
          getUtcDate({ date: new Date(modifiedRow[key]), timezone }),
          'yyyy-MM-dd H:mm'
        );
      }

      if (Object.keys(row).includes(key) && !hasActivityDate) {
        modifiedRow[key] = formatDate(
          getUtcDate({ date: new Date(modifiedRow[key]), timezone }),
          'noTime'
        );
      }
    } catch (error) {
      console.log(error);
    }

    return modifiedRow;
  };

  const renderRows = () => {
    return initRows.map((row) => {
      let isInclude = false;
      let modifiedRow = { ...row };

      if (selectedData && type === 'device') {
        isInclude = selectedData.some((item) => item === row.csuv_serial);
      }

      if (selectedData && type === 'user') {
        isInclude = selectedData.some((item) => item === row.username);
      }

      // 2021-9-9 had problem of rendering group created_at date
      // if (Object.keys(row).includes(responseKey.createdAt)) {
      //   modifiedRow = renderDate(row, responseKey.createdAt);
      // }

      // if (Object.keys(row).includes(responseKey.date)) {
      // modifiedRow = renderDate(row, responseKey.date);  2021-08-10 analytics usage trend have error
      // modifiedRow = renderDate(row, responseKey.date); 2021-08-10 analytics usage trend have error
      // }

      if (Object.keys(row).includes(responseKey.joinDate)) {
        modifiedRow = renderDate(row, responseKey.joinDate);
      }

      if (
        Object.keys(row).includes(responseKey.csuvAssetId) &&
        row[responseKey.csuvAssetId].match(/null/gi)
      ) {
        modifiedRow[responseKey.csuvAssetId] = ' ';
      }

      return {
        ...modifiedRow,
        tableData: {
          ...(selectedData?.length ? { checked: isInclude } : {})
        }
      };
    });
  };

  // override actions
  const overrideEditable = {
    ...(hasEdit ? { onRowUpdate } : {}),
    ...(hasDelete ? { onRowDelete } : {})
  };

  useEffect(() => {
    const rowNodeList = document.querySelectorAll('.MuiTableRow-hover');

    // if row click function exists, row will have hover effect
    if (onRowClick) {
      // to control if row does not have onclick function
      for (let index = 0; index < rowNodeList.length; index += 1) {
        const rowNode = rowNodeList[index];
        rowNode.classList.add('row-hover');
      }
    } else {
      // to control if row does not have onclick function
      for (let index = 0; index < rowNodeList.length; index += 1) {
        const rowNode = rowNodeList[index];
        rowNode.style.cursor = 'default';
      }
    }
  }, []);

  const additionalProps = {
    ...(actions ? { actions } : {})
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      title=""
      icons={icons}
      style={!hasShadow && { boxShadow: 'none' }}
      className={tableContainerClasses.container}
      columns={addedRenderColumns}
      localization={localizationObject}
      data={renderRows()} // table data cannot be created for some reason.
      onRowClick={onClick}
      onSelectionChange={onSelectionChange}
      editable={overrideEditable}
      options={{
        exportButton: hasDownload,
        headerStyle: {
          position: 'sticky',
          top: 0,
          maxBodyHeight: '650px',
          backgroundColor: color.lightGrey
        },
        maxBodyHeight: 670,
        pageSize: pageSize || 100,
        paging: hasPage,
        showTitle: false,
        toolbar: hasToolbar,
        filtering: filteringOption,
        search: false,
        sorting: true,
        selection: hasSelect,
        actionsColumnIndex: -1,
        ...(selectedData?.length && type === 'device'
          ? {
              selectionProps: () => {
                return {
                  color: 'primary'
                };
              }
            }
          : {}),
        ...(hasDownload && {
          exportFileName: `${tableName} ${formatDate(new Date())}`
        })
      }}
      {...additionalProps}
    />
  );
}
