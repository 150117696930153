import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { analyticsType } from '../../constants';
import { analyticsDetailStyles } from '../../styles/Analytics';
import img1 from '../../assets/images/img_analytics1.svg';
import img2 from '../../assets/images/img_analytics2.svg';
import img3 from '../../assets/images/img_analytics3.svg';
import img4 from '../../assets/images/img_analytics4.svg';
import img5 from '../../assets/images/img_analytics5.svg';

export default function Detail({ currentItem }) {
  const { t } = useTranslation();
  const { trend, usage, compare } = analyticsType;
  const classes = analyticsDetailStyles();
  let description = '';
  let title = '';
  let image = img1;

  switch (currentItem) {
    case compare:
      image = img2;
      title = 'menuName.comparisons';
      description = 'pages.analytics.comparisonDescription';
      break;

    case trend:
      image = img3;
      title = 'menuName.trends';
      description = 'pages.analytics.trendDescription';
      break;

    case usage:
      image = img4;
      title = 'menuName.useStatistics';
      description = 'pages.analytics.useStatsDescription';
      break;

    case 'report':
      image = img5;
      title = 'menuName.reports';
      description = 'pages.analytics.reportDescription';
      break;

    default:
      image = img1;
      title = 'menuName.averages';
      description = 'pages.analytics.averageDescription';
      break;
  }

  return (
    <Grid
      item
      lg={9}
      md={12}
      sm={12}
      xs={12}
      container
      className={classes.container}
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.detailContainer}
      >
        <div className={classes.title}>{t(title)}</div>
        <div className={classes.description}>{t(description)}</div>
        <div className={classes.buttonContainer}>
          {/* <Button description={t(buttonDescription)} onClick={() => history.push(link)} />
          ddd */}
        </div>
        <div>
          <img src={image} className={classes.image} />
        </div>
        <div className={classes.decoBackground}></div>
      </Grid>
    </Grid>
  );
}
