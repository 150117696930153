import React from 'react';
import { GrMoney } from 'react-icons/gr';

import Card from '../../common/Card';
import Tooltip from '../../common/Tooltip';
import { renderMaskedText, renderMask } from '../../../helper/roi/roi';

import { buttonStyle } from '../../../constants';
import { roiEstSavingStyles } from '../../../styles/ROICalculator';

export default function EstimatedSaving({ title, value }) {
  const classes = roiEstSavingStyles();

  return (
    <Card style={buttonStyle.filledDarkBlue} cardStyle={{ height: '100%' }}>
      <div className={classes.oneYearSavingContainer}>
        {/* title */}
        <div className={classes.oneYearSavingTitle}>
          <div>
            <GrMoney className={classes.icon} size="2em" />
          </div>
          <div className={classes.oneYearSavingTitleContainer}>{title}</div>

          <div>
            <Tooltip message="pages.savings.tooltip.roi.costPer3yr" />
          </div>
        </div>

        {/* currency */}
        <div className={classes.savingPriceContainer}>
          <div data-testid="currency-type" className={classes.currencyType}>
            USD
          </div>
          <div
            className={classes.oneYearSavingAmount}
            data-testid="saving-value"
          >
            $ {renderMaskedText({ val: value, mask: renderMask('amount') })}
          </div>
        </div>
      </div>
    </Card>
  );
}
