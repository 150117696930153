/* eslint-disable radix */
import { compareDesc, toDate as changeDate, sub } from 'date-fns';
import { FiMoreHorizontal } from 'react-icons/fi';
import i18n from 'i18next';
import MUIIconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { downloadReport } from './api';

import ArrayContentsRender from '../components/common/Table/ArrayContentsRender';
import Chip from '../components/common/Chip';

import { getLabelByValue } from './label';
import { getUtcDate, formatDate } from './date';
import {
  notifications,
  partners,
  customers,
  groups,
  reports,
  scanRecords
} from '../paths';
import {
  responseKey,
  cognitoGroup,
  deviceStatus,
  dateformat
} from '../constants';
import ballastStatusObject from '../ballastStatus';
import imgPDF from '../assets/images/ico_pdfdownload.svg';
import imgCSV from '../assets/images/ico_excel.svg';

const {
  activity,
  alertCategory,
  averageUses,
  ballastStatus,
  contactName,
  createdAt,
  csuvHealth,
  csuvSerial,
  customerAddress,
  customerName,
  customerUid,
  date,
  endDate,
  firstName,
  groupName,
  groupUid,
  groupUids,
  joinDate,
  lastLogin,
  lastName,
  locationName,
  loginCount,
  partnerName,
  partnerUid,
  permission,
  pk,
  recipients,
  reportLink,
  reportStatus,
  reportType,
  scanCycle,
  scanCycleState,
  serial,
  startDate,
  status,
  timestamp,
  toDate
} = responseKey;

/**
 * @description return formatted text
 * @param {string} lowerCasedText
 * @return {Object} refined formatted text
 */
function setUserFormatText(lowerCasedText) {
  switch (lowerCasedText) {
    case cognitoGroup.picardUser.toLowerCase():
      return 'Customer User';

    case cognitoGroup.picardAdmin.toLowerCase():
      return 'Customer Admin';

    case cognitoGroup.rikerUser.toLowerCase():
      return 'CleanSlate Partner';

    case cognitoGroup.rikerAdmin.toLowerCase():
      return 'CleanSlate Admin';

    default:
      return '';
  }
}

function renderKey(key) {
  switch (key) {
    case 'push':
      return i18n.t('pages.notifications.sendPushNotification');

    case 'phone':
      return i18n.t('pages.notifications.sendSms');

    default:
      return i18n.t('pages.notifications.sendEmail');
  }
}

function getLookupOptions(rows, key) {
  const lookupObject = {};
  let uniqueCycle = new Set(
    rows.map((item) => {
      return item?.[key];
    })
  );

  uniqueCycle = [...uniqueCycle]?.filter((item) => item);

  for (let index = 0; index < uniqueCycle.length; index += 1) {
    const item = uniqueCycle[index];
    lookupObject[item] = i18n.t(getLabelByValue(item)?.label);
  }

  return lookupObject;
}

/**
 * @description return sorted by status
 * @param {string} targetStatus status name (error|warning|normal)
 * @return {Number} sorting integer value
 */
export function statusToInteger(targetStatus) {
  switch (targetStatus.toLowerCase()) {
    case 'error':
      return -1;

    case 'warning':
      return 0;

    default:
      return 1;
  }
}

/**
 * @description column default render options
 * @param {Object} data
 * @param {Object} data.columns columns inherited from parent component
 * @param {Object} data.t translation function
 * @param {Object} data.rows rows inherited from parent component
 * @return {Object} customized column object -> this will render rows by this column object
 */
export function getCustomizedColumns({ columns, rows, user, pageState }) {
  const timezone = user?.tz;

  return columns.map((column) => {
    let columnObject = column;

    // hide column if it is uid
    const shouldHideColumn = [
      partnerUid,
      customerUid,
      pk,
      loginCount,
      lastLogin,
      groupUid,
      groupUids
    ].includes(column.field);
    const isGroupUid = column.field === groupUid;

    if (column.field === status) {
      columnObject = {
        ...columnObject,

        customSort: (prev, next) => {
          return statusToInteger(prev.status) - statusToInteger(next.status);
        },

        render: (rowData) => {
          return <Chip value={rowData[column.field]} />;
        }
      };
    }

    if (column.field === reportStatus) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          return <Chip value={rowData[column.field]} />;
        }
      };
    }

    if (column.field === reportType) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          let currentReportType = rowData[column.field];

          if (rowData[reportType] === 'device')
            currentReportType = i18n.t('menuName.analytics');

          return (
            <span style={{ textTransform: 'capitalize' }}>
              {currentReportType}
            </span>
          );
        }
      };
    }

    if (column.field === 'deployed_image') {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (rowData.deployed_image instanceof File) {
            return (
              <img
                src={URL.createObjectURL(rowData.deployed_image)}
                style={{ width: '100%' }}
              />
            );
          }

          return '';
        }
      };
    }

    if (
      ['model_type', 'sanitizer_mount_type', 'size_mount_type'].includes(
        column.field
      )
    ) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          return rowData[column.field] ? (
            <span>{i18n.t(`label.${rowData[column.field]}`)}</span>
          ) : (
            ''
          );
        }
      };
    }

    if (['signage'].includes(column.field)) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (
            Array.isArray(rowData[column.field]) &&
            rowData[column.field].length
          ) {
            console.log('-----');
            return (
              <ul>
                {rowData[column.field].map((val) => (
                  <li>{i18n.t(`label.${val}`)}</li>
                ))}
              </ul>
            );
          }

          return rowData[column.field] ? (
            <span>{i18n.t(`label.${rowData[column.field]}`)}</span>
          ) : (
            ''
          );
        }
      };
    }

    if ([activity, averageUses].includes(column.field)) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (rowData.activity) return <Chip value={rowData[column.field]} />;

          // Rendering average uses, data must be parsed data
          if (parseFloat(rowData?.[averageUses]) > 0 && rowData?.[averageUses])
            return Math.round(parseFloat(rowData[averageUses]));
          if (parseFloat(rowData?.[averageUses]) === 0) return 0;

          return rowData;
        }
      };
    }

    // Here is formatting text
    if ([permission].includes(column.field)) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (rowData?.[permission]) {
            const lowerCased = rowData?.[permission].toLowerCase();
            return setUserFormatText(lowerCased);
          }

          return rowData;
        }
      };
    }

    if ([startDate, endDate].includes(column.field)) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          return rowData[column.field];
        }
      };
    }

    // Timestamp and date will be used for sorting data by default
    if ([timestamp, date, createdAt, joinDate].includes(column.field)) {
      columnObject = {
        ...columnObject,
        customSort: (prev, next) => {
          return compareDesc(
            new Date(prev[column.field]),
            new Date(next[column.field])
          );
        },
        render: (rowData) => {
          if (timestamp === column.field) {
            return formatDate(
              getUtcDate({ date: new Date(rowData[column.field]), timezone })
            );
          }

          if (date === column.field && rowData[column.field]) {
            return formatDate(
              getUtcDate({ date: new Date(rowData[column.field]), timezone })
            );
          }

          if (!rowData[column.field]) {
            return '';
          }

          if (createdAt === column.field || joinDate === column.field) {
            return formatDate(
              getUtcDate({ date: new Date(rowData[column.field]), timezone }),
              dateformat.noTime
            );
          }

          return rowData;
        }
      };
    }

    if (column.field === reportLink) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (rowData?.report_status !== 'completed') {
            return null;
          }

          const { isPicardAdmin } = user?.getUserPermission();

          const reqCustomerUid = isPicardAdmin
            ? user?.customer_uid
            : pageState?.filterOptions?.id;

          return (
            <span
              onClick={() =>
                downloadReport({
                  customerUid: reqCustomerUid,
                  reportType: rowData.report_type,
                  reportBucket: rowData?.report_bucket
                })
              }
              style={{ cursor: 'pointer' }}
            >
              <img
                className="report-link-icon"
                src={rowData?.report_type !== 'scanrecord' ? imgPDF : imgCSV}
              />
            </span>
          );
        }
      };
    }

    // Scan cycle to render lookup object (checkbox option)
    if (column.field === scanCycleState) {
      const lookupObject = getLookupOptions(rows, scanCycleState);

      columnObject = {
        ...columnObject,
        render: (rowData) => <Chip value={rowData[column.field]} />,
        lookup: lookupObject
      };
    }

    if (column.field === scanCycle) {
      const lookupObject = getLookupOptions(rows, scanCycle);

      columnObject = {
        ...columnObject,
        render: (rowData) => <Chip value={rowData[column.field]} />,
        lookup: lookupObject
      };
    }

    if (column.field === alertCategory) {
      const lookupObject = getLookupOptions(rows, alertCategory);

      columnObject = {
        ...columnObject,
        render: (rowData) => <Chip value={rowData[column.field]} />,
        lookup: lookupObject
      };
    }

    if (column.field === ballastStatus) {
      const lookupObject = getLookupOptions(rows, ballastStatus);

      columnObject = {
        ...columnObject,
        render: (rowData) => {
          const changedBallast =
            ballastStatusObject[`${rowData[column.field]}`];
          const message = changedBallast
            ? `errorMessage.${ballastStatusObject[`${rowData[column.field]}`]}`
            : 'N/A';
          return <span>{i18n.t(message)}</span>;
        },
        lookup: lookupObject
      };
    }

    if (column.field === toDate) {
      columnObject = {
        ...column,
        ...columnObject,
        render: (rowData) => {
          const splitDate = rowData[toDate].split('-');
          const aDayBefore = sub(
            changeDate(
              new Date(
                parseInt(splitDate[0]),
                parseInt(splitDate[1]) - 1,
                parseInt(splitDate[2])
              )
            ),
            { days: 1 }
          );

          try {
            return formatDate(aDayBefore, 'Y-MM-dd');
          } catch (error) {
            return rowData[toDate];
          }
        }
      };
    }

    if (column.field === csuvHealth) {
      columnObject = {
        ...column,
        ...columnObject,
        render: (rowData) => {
          const message = /bad/gi.test(rowData[column.field])
            ? deviceStatus.error
            : rowData[column.field];
          return <Chip value={message} />;
        }
      };
    }

    if (column.field === 'action') {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          return renderKey(rowData.action);
        }
      };
    }

    if (
      [
        partnerName,
        groupName,
        customerName,
        locationName,
        serial,
        csuvSerial,
        contactName,
        customerAddress,
        'username',
        firstName,
        lastName
      ].includes(column.field)
    ) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          return <span className="fs-mask">{rowData[column.field]}</span>;
        }
      };
    }

    // render array
    if ([recipients, 'deviceList', 'userList'].includes(column.field)) {
      columnObject = {
        ...columnObject,
        render: (rowData) => {
          if (Array.isArray(rowData[column.field])) {
            return <ArrayContentsRender data={rowData[column.field]} />;
          }

          return rowData;
        }
      };
    }

    // Group must have lookup for setting default filter
    if (isGroupUid) {
      const initGroupLookup = {};
      const groupLookup = new Set(rows.map((item) => item?.[groupUid]));

      for (let index = 0; index < groupLookup.length; index += 1) {
        const item = groupLookup[index];
        initGroupLookup[item] = item;
      }

      columnObject = {
        ...columnObject,
        lookup: initGroupLookup
      };
    }

    // hide column if they are uid column
    // The uid is for future api call
    if (shouldHideColumn) {
      columnObject = {
        ...columnObject,
        hidden: true
      };
    }

    return columnObject;
  });
}

/**
 * @description return array object with fill in N/A if the object key has null value
 * @param {Array} list response data list
 * @return {Array} refined array object
 */
export function refineTableObjectIfNull(list) {
  return list?.map((item) => {
    const initItem = { ...item };
    const objectArray = Object.keys(item);

    for (let index = 0; index < objectArray.length; index += 1) {
      const key = objectArray[index];

      /**
       * 2022-09-08 exception for deployment info data
       * data must be false, or true so created exception
       */
      const deploymentInfoException = ![
        'sanitizer_mount_type',
        'signage'
      ].includes(key);

      if (
        (!initItem[key] && deploymentInfoException) ||
        initItem[key] === 'NULL' ||
        initItem[key] === 'null'
      ) {
        initItem[key] = '';
      }
    }

    return initItem;
  });
}

/**
 * @description return table's custom message object
 * translation json available table object
 * @return {Object} custom message object
 */
export function tableLocalization() {
  let deleteMessage = '';

  switch (window.location.pathname) {
    case notifications:
      deleteMessage = 'table.message.deleteNotification';
      break;

    case reports:
      deleteMessage = 'table.message.deleteReport';
      break;

    case partners:
      deleteMessage = 'table.message.deletePartner';
      break;

    case customers:
      deleteMessage = 'table.message.deleteCustomer';
      break;

    case groups:
      deleteMessage = 'table.message.deleteGroup';
      break;

    case scanRecords:
      deleteMessage = 'table.message.deleteScanRecordReport';
      break;

    default:
      deleteMessage = 'table.message.deleteItem';
      break;
  }

  const languageObject = {
    header: {
      actions: ''
    },
    body: {
      editRow: {
        deleteText: i18n.t(deleteMessage)
      }
    }
  };

  return languageObject;
}

/**
 * @description To set row data view icon
 * @param onRowClick on row click function
 * @return {Object} view object table option, it will be pushed to column object
 */
export function getViewMoreOption(onRowClick) {
  return {
    field: 'view',
    title: '',
    render: (rowData) => (
      <Tooltip title={i18n.t('common.moreDetails')}>
        <MUIIconButton onClick={() => onRowClick(rowData)}>
          <FiMoreHorizontal />
        </MUIIconButton>
      </Tooltip>
    ),
    filtering: false,
    width: 50,
    cellStyle: {
      width: 50,
      maxWidth: 50,
      minWidth: 50,
      textAlign: 'right'
    },
    headerStyle: {
      width: 50,
      maxWidth: 50,
      minWidth: 50
    }
  };
}

export function setDateKey(dataObject) {
  if (dataObject?.[timestamp]) {
    return timestamp;
  }

  if (dataObject?.[createdAt]) {
    return createdAt;
  }

  if (dataObject?.[joinDate]) {
    return joinDate;
  }

  return date;
}
