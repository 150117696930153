import { makeStyles } from '@material-ui/styles';
import { color, fontFamily, status } from '../../constants';

export default makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontFamily: fontFamily.bold
  },
  subTitle: {
    color: color.lightBlue,
    display: 'flex',
    fontFamily: fontFamily.semiBold,
    fontSize: 15,
    margin: '0 0 20px 0',
    position: 'relative',
    justifyContent: 'space-between',
    width: '100%',

    '&::after': {
      backgroundColor: color.darkBlue,
      bottom: -4,
      content: '""',
      height: 2,
      left: 0,
      position: 'absolute',
      width: 30
    }
  },
  description: {
    color: color.fontGrey,
    width: '100%'
  },
  breadcrumbs: {
    fontSize: '.8rem',
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    '& a, & span': {
      color: color.lightBlue
    }
  },
  separator: {
    fill: color.darkBlue
  },
  home: {
    fill: color.lightBlue
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBreadcrumbs-li': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginLeft: 10
    }
  }
});

function getStatusColor(styles) {
  const { red, yellow } = status;
  switch (styles) {
    case red:
      return color.red;

    case yellow:
      return color.yellow;

    default:
      return color.green;
  }
}

export const statusText = (styles) => {
  const statusColor = getStatusColor(styles);

  return makeStyles({
    status: {
      display: 'flex',
      alignItems: 'center',
      border: `solid 1px ${statusColor}`,
      borderRadius: 20,
      color: statusColor,
      fontSize: 14,
      marginLeft: 20,
      padding: '5px 10px',
      textTransform: 'uppercase',
      marginTop: -15
    }
  });
};

export const titleWithButton = makeStyles({
  titleWithButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  }
});

export const formHeaderStyles = makeStyles({
  formHeader: {
    color: color.fontGrey,
    fontSize: '0.85em'
  }
});
