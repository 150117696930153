import { useHistory } from 'react-router-dom';
import { pageObj } from '../../../../helper/admin/page';

export default function useAdminRoute(type) {
  // third party
  const history = useHistory();
  const { editURL = '', saveURL, closeURL } = pageObj?.[type];

  // Routing - go to create page
  const onRowSave = () => {
    history.push({
      pathname: saveURL,
      state: { data: {} }
    });
  };

  // Routing - go to edit page
  const onRowClick = (rowData) => {
    history.push({
      pathname: editURL || saveURL,
      state: { data: rowData }
    });
  };

  const onClose = () => {
    history.push(closeURL);
  };

  return {
    // for list
    onRowSave,
    onRowClick,

    // for manage
    onClose
  };
}
