import React from 'react';
import {
  getThisWeek,
  getDayWeek,
  getHasThisWeek,
  filterThisWeekDate,
  totalByDays,
  getPercent,
  getNumWeeks
} from '../../helper/analytics/averages';

/**
 *
 * @param {{ data: Array, tz: string }} selectedData user selected data
 * @param {string} startDate  2022-10-10 start date
 * @param {string} endDate  2022-10-10 end date
 * @param {string} dateRange  day, week, month or customRange -> refer dataOptions constants
 * @param {array|string} id  selected id from filter
 * @param {string} customerUid  customerUid
 * @returns {data: array} if user select over 30 days, 7 days of list data, one day or 7 days -> return empty array
 */
export default function useDayCompare({
  selectedData,
  startDate,
  endDate,
  recoilValue,
  setCompareUsage
}) {
  const [data, setData] = React.useState([]); // this week percentage
  const [selectedWeekData, setSelectedWeekData] = React.useState([]); // bar chart data
  const [loading, setLoading] = React.useState(true);
  const [selectedWeekAverage, setSelectedWeekAverage] = React.useState(0); // top right average card
  const [showDateError, setShowDateError] = React.useState(false);
  const [weekData, setWeekData] = React.useState({
    thisWeek: {},
    otherWeeks: {}
  });

  const main = async () => {
    const thisWeek = getThisWeek();

    const hasCurrentWeek = getHasThisWeek(
      { startDate: thisWeek.startDate, endDate: thisWeek.endDate },
      {
        startDate,
        endDate
      }
    );

    const { others, current } = filterThisWeekDate(selectedData.data, {
      start: new Date(thisWeek.startDate),
      end: new Date(thisWeek.endDate)
    });

    const numDayInMonth = getNumWeeks(startDate, endDate); // this value is num weeks between startDate and endDate

    const otherData = getDayWeek(others, selectedData.tz);
    const currentData = getDayWeek(current, selectedData.tz);

    const totalOtherWeeks = totalByDays(otherData, numDayInMonth);
    const totalCurrentWeeks = totalByDays(currentData, numDayInMonth, true);

    const { totalAvg, barChartData, currentWeekData } = await getPercent(
      totalOtherWeeks,
      totalCurrentWeeks,
      hasCurrentWeek
    );

    if (Number.isNaN(totalAvg)) {
      setCompareUsage(false); // modal error
      setData([]);
      setWeekData({
        otherWeeks: {},
        thisWeek: {}
      });
      return;
    }

    setWeekData({
      otherWeeks: totalOtherWeeks,
      thisWeek: totalCurrentWeeks
    });

    setSelectedWeekAverage(totalAvg);
    setSelectedWeekData(barChartData);

    // if current data not available should initialize values
    if (!hasCurrentWeek) {
      setCompareUsage(false); // modal error
      setData([]); // clear data -> percentage and this week element will not show
    } else {
      setData(currentWeekData);
    }

    setLoading(false);
  };

  const handleThisWeekUsage = (value, cb) => {
    if (!data.length && value) {
      setShowDateError(true);
      return;
    }

    // if all current week not available -> will show error
    if (data.every((item) => !item.current)) {
      setShowDateError(true);
      return;
    }

    cb(value);
  };

  React.useEffect(() => {
    setLoading(true);

    main();
    return () => {
      setSelectedWeekData([]);
      setData([]);
    };
  }, [startDate, endDate, recoilValue.filterOptions.id, selectedData.data]);

  return {
    data,
    selectedWeekData,
    dayLoading: loading,
    selectedWeekAverage,

    handleThisWeekUsage,
    setShowDateError,
    showDateError,
    weekData
  };
}
