import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

// components
import { comparisonState } from '../../recoil/atom/comparison';
import { ContentHeading } from '../../components/common/Title';
import { setTableColumns } from '../../helper/tableOptions';
import { userState } from '../../recoil/state';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import FilterChartContainer from '../../components/Charts/FilterChartContainer';
import Loading from '../../components/common/Loading';
import TableContainer from '../../components/common/Table/TableContainer';
import useAnalytics from '../../hooks/useAnalytics';
import { useChartDateChange } from '../../components/pages/Analytics/hooks/useChartDateChange';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';

// etc
import { analyticsType } from '../../constants';

function Comparisons() {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const comparisonRecoil = useRecoilValue(comparisonState);

  // analytics react hook
  const { resData, loading } = useAnalytics(analyticsType.compare);

  // 2022-08-15 chart data's date is not same because of loading latest data
  const changed = useChartDateChange(resData.chart, {
    startDate: comparisonRecoil.filterOptions.startDate,
    endDate: comparisonRecoil.filterOptions.endDate
  });

  const columns =
    resData?.table?.length > 0 &&
    setTableColumns(Object.keys(resData?.table[0]), user, t);

  // table props
  const tableProps = {
    columns,
    rows: resData?.table,
    hasDownload: true,
    hasSearch: true,
    tableName: t('menuName.comparisons')
  };

  // filter chart props
  const filterChartProps = {
    changed,
    listOptions: { customer: true, group: true },
    chartData: resData.chart,
    loading,
    type: analyticsType.compare
  };

  useSegment();

  return (
    <>
      <Loading isOpen={loading} />

      <ContentHeading
        title={t('menuName.comparisons')}
        description={t('pages.analytics.comparisonDescription')}
      />

      <FilterChartContainer {...filterChartProps} />

      {/* Section Table */}
      {!loading ? (
        <TableContainer tableProps={tableProps} />
      ) : (
        <CardContentLoader />
      )}
    </>
  );
}

export default withContent(Comparisons);
