import { IoChevronDownOutline } from 'react-icons/io5';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import accordionStyles from '../../../styles/common/Accordion';

export default function Header({ title }) {
  const classes = accordionStyles();
  return (
    <AccordionSummary
      expandIcon={<IoChevronDownOutline />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Typography className={classes.header}>{title}</Typography>
    </AccordionSummary>
  );
}
