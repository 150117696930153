import axios from 'axios';
import i18n from 'i18next';
import { api } from '../config';
import { tokens, apiActionTypes } from '../constants';

/**
 * helper function to set API header
 * @return {Object} header object
 */
export function getHeaderOptions() {
  const idToken = localStorage.getItem(tokens.idToken);

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`
  };
}

/**
 * helper function to use axios API.
 * @param {string} url - api url
 * @param {object} data - option data object to request
 * @param {string} method - specific method type of request
 * @param {object} headers - specific headers of request
 * @return {object} api response
 */
export async function callAPI(
  url,
  data,
  method = 'post',
  headers = getHeaderOptions()
) {
  const controller = new AbortController();

  const response = await axios({
    method: method || 'post',
    url: `${api}${url}`,
    data,
    headers,
    signal: controller.signal
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

  controller.abort();

  return response;
}

export async function downloadReport({
  customerUid,
  reportBucket,
  reportType
}) {
  try {
    const request = {
      type: apiActionTypes.DOWNLOAD,
      customerUid,
      reportBucket,
      reportType
    };

    // returning download link
    const response = await callAPI('/report-management', request);

    if (response.hasError) {
      throw new Error();
    }

    window.open(response?.data, '_blank');
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(i18n.t('pages.reports.cannotOpenPdf'));
  }

  return null;
}

export const getFilterByOption = async (request) => {
  const response = await callAPI(`/filters`, request)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error('errorMessage.somethingWentWrong');
    });

  return response;
};
