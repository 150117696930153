import Grid from '@material-ui/core/Grid';
import Button from '../../components/Button/Button';

import { dateSelectStyles } from '../../styles/ROICalculator';
import { buttonStyle, roi } from '../../constants';

const btns = [
  { description: '1 Year', val: roi.years.y1.id },
  { description: '3 Years', val: roi.years.y3.id },
  { description: '5 Years', val: roi.years.y5.id }
];

export default function ChartDateRange({ handleDateClick, selectedYear }) {
  const classes = dateSelectStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      {btns.map((btn) => {
        return (
          <ButtonContainer
            key={btn.val}
            {...btn}
            handleDateClick={handleDateClick}
            selectedYear={selectedYear}
          />
        );
      })}
    </Grid>
  );
}

function ButtonContainer({ description, val, handleDateClick, selectedYear }) {
  const btnStyle =
    val === selectedYear
      ? buttonStyle.filledDarkBlue
      : buttonStyle.filledLightBlue;

  return (
    <Grid item md={4} xs={12}>
      <Button
        style={btnStyle}
        description={description}
        fullWidth={true}
        data-testid={`btn-${val}`}
        onClick={() => handleDateClick(val)}
      />
    </Grid>
  );
}
