import NoDataFound from '../NoDataFound';
import CardContentLoader from '../../Loader/CardContentLoader';
import Table from './index';

export default function TableContainer({ tableProps, loading }) {
  if (loading) return <CardContentLoader />;

  return Array.isArray(tableProps?.rows) &&
    tableProps?.rows?.length &&
    tableProps?.columns?.length ? (
    <Table {...tableProps} />
  ) : (
    <NoDataFound />
  );
}
