import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useRecoilValue } from 'recoil';

// components
import { ContentHeading } from '../../components/common/Title';
import { getPageTitle } from '../../helper/admin/page';
import { userAdminState } from '../../recoil/atom/admin';
import AdminWithList from '../../components/pages/Admin/AdminWithList';
import Card from '../../components/common/Card';
import Filter from '../../components/Filter/Filter';
import IconButton from '../../components/Button/IconButton';
import useUserData from '../ManageUsers/useUserData';
import withAdminManage from '../../components/hoc/withAdminManage';

import { buttonStyle, adminType, apiActionTypes, icons } from '../../constants';

function Users({
  // func
  onDelete,
  ...props
}) {
  const userFilterRecoil = useRecoilValue(userAdminState);
  const { getDeleteReq } = useUserData();

  const onRowDelete = (values) => {
    onDelete(getDeleteReq(values.username));
  };

  const filterOptions = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 6, md: 6, sm: 6, xs: 12 },
    hasTitle: false,
    largeHorizontal: true,
    noApply: true
  };

  React.useEffect(() => {
    if (userFilterRecoil.filterOptions?.id) {
      props.getData({
        customerUid: userFilterRecoil.filterOptions.id,
        type: apiActionTypes.FETCH
      });
    }
  }, [userFilterRecoil.filterOptions?.id]);

  const pageColumn = [
    'first_name',
    'last_name',
    'username',
    'permission_level',
    'persona_id',
    'login_count',
    'tz',
    'last_login',
    'join_date',
    'phone'
    // 'is_deleted'
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContentHeading
          title={props.t(getPageTitle(adminType.user))}
          leftButton={
            props.hasCreate ? (
              <IconButton icon={icons.addNew} onClick={props.onRowSave} />
            ) : null
          }
        />
      </Grid>

      {props.user?.getUserPermission()?.isRiker ? (
        <Grid item xs={12}>
          <Card style={buttonStyle.filledBlue}>
            <Filter
              {...filterOptions}
              listOptions={{ customer: true, group: false }}
            />
          </Card>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <AdminWithList
          {...props}
          type={adminType.user}
          onRowDelete={onRowDelete}
          pageColumn={pageColumn}
        />
      </Grid>
    </Grid>
  );
}

export default withAdminManage('user')(Users);
