import { getColumns, getColumnByKey } from '../../../../helper/admin/table';

export default function useTable({
  data,
  user,

  // table func
  onRowClick,
  onRowDelete,

  // permission
  hasDelete,

  // utils
  tableName,
  pageColumn,
  actions
}) {
  return {
    columns: Array.isArray(pageColumn)
      ? getColumnByKey(pageColumn, onRowClick)
      : getColumns({ list: data, user, onRowClick }),
    hasFilter: true,
    hasDelete,
    hasDownload: true,
    rows: data,
    tableName,
    onRowClick,
    onDelete: onRowDelete,
    actions
  };
}
