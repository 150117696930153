import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      scrollSnapAlign: 'start',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 3, 2),
      outline: 'none',
      borderRadius: 10,
      position: 'relative'
    },
    modalCloseContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 10,
      zIndex: 1
    },
    modalCloseIcon: {
      cursor: 'pointer'
    },
    modalTitle: {
      color: color.lightBlue
    },
    modalMessage: {
      color: color.darkBlue
    }
  };
});

export const modalProgressBarStyles = makeStyles({
  barColorPrimary: {
    backgroundColor: `${color.darkBlue} !important`
  },
  bar: {
    backgroundColor: `${color.backgroundGrey} !important`
  }
});
