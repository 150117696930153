import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { filterState } from '../../recoil/atom/filter';
import { getChartObject } from '../../helper/analytics';
import Card from '../common/Card';
import CardContentLoader from '../Loader/CardContentLoader';
import ChartContainer from './ChartContainer';

import Filter from '../Filter/Filter';
import WeekUsage from './WeekUsage';
import NoDataFound from '../common/NoDataFound';
import chartViewStyles from '../../styles/Layout/ChartView'; // chartDateMessageStyles

import { noDataMessageStyles as averageNoMessageStyles } from '../../styles/Analytics';
import { buttonStyle, analyticsType, emailAddress } from '../../constants';

export default function FilterChartContainer({
  changed,
  chartData,
  filterProps,
  listOptions,
  loading,
  type,
  weekUsage,
  dateProps
}) {
  const { t } = useTranslation();
  const chartViewClasses = chartViewStyles();
  const filterObj = useRecoilValue(filterState);

  const renderChartContainer = () => {
    let options = {};
    if (loading) {
      return <CardContentLoader />;
    }

    // response data from useAnalytics
    if (chartData?.data) {
      const isUsage = type === analyticsType.usage;
      const isComparison = type === analyticsType.compare;
      const isAverage = type === analyticsType.average;

      // if data is null contain
      if (!chartData?.data.length) {
        return isAverage ? <AverageNoDataMessage /> : <NoDataFound />;
      }

      // create chart option
      options = getChartObject({
        type,
        data: chartData?.data,
        tz: chartData?.tz,
        ...(isAverage && {
          showValues: filterProps?.switch.value,
          currentWeek: weekUsage
        }),
        ...(isComparison && {
          filterList: filterObj
        }),
        ...(isUsage && {
          showTrendLine: filterProps?.switch.value
        })
      });

      return (
        <Card>
          {/* if usage, will show trend line option */}
          <ChartContainer chartOptions={options} type={type} />
        </Card>
      );
    }

    return null;
  };

  return (
    <Grid
      container
      spacing={2}
      className={chartViewClasses.chartViewChartContainer}
    >
      <Grid item lg={3} xs={12}>
        <Card header={t('common.searchOptions')} style={buttonStyle.filledBlue}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Filter
                dateProps={dateProps}
                column={{ md: 12, xs: 6 }}
                hasTitle={false}
                listOptions={listOptions}
                changed={changed}
              />
            </Grid>
            {filterProps?.switch?.element ? filterProps?.switch?.element : null}
          </Grid>
        </Card>
      </Grid>

      <Grid item lg={9} xs={12}>
        <Grid container spacing={1}>
          {filterProps?.switch?.value[1] ? (
            <Grid item xs={12}>
              <WeekUsage
                data={weekUsage}
                columns={{ xs: 12 }}
                debug={chartData}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {renderChartContainer()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function AverageNoDataMessage() {
  const classes = averageNoMessageStyles();

  return (
    <NoDataFound
      message={
        <Trans
          i18nKey="pages.analytics.noAverageMessage"
          components={{
            a: (
              <a
                href={`mailto:${emailAddress.trialEmail}`}
                className={classes.link}
                target="_blank"
              />
            ),
            br: <br />
          }}
          values={{ email: emailAddress.trialEmail }}
        />
      }
    />
  );
}
