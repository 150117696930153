import React from 'react';
import Grid from '@material-ui/core/Grid';
import { GrPowerReset } from 'react-icons/gr';

import { FormErrorMessage } from '../../components/common/ErrorMessage';
import { getInputProps } from '../../helper/admin/page';
import { sortList } from '../../helper/filterOptions';
import Card from '../../components/common/Card';
import { FormHeader } from '../../components/common/Title';

import Radio from '../../components/Input/Radio';
import Checkbox from '../../components/Input/Checkbox';
import UploadDeployedImage from './UploadDeployedImage';
import MultipleSelectWithChip from '../../components/Input/MultipleSelectWithChip';
import TextField from '../../components/Input/TextField';

export default function Form({
  errors,
  filterList,
  inputPropData,
  values,
  isEdit,

  // func
  comparePrevCustomer,
  setFieldValue,
  handleChange,
  handleCustomerChange,
  handleRemoveSizeMountType,

  // 2022-09-06 additional props
  acceptTypes,
  handleFileChange
}) {
  const inputProps = getInputProps({
    inputProps: inputPropData,
    errors,
    values
  });

  const groupColumn = inputProps.customer_uid.visible
    ? { md: 6, xs: 12 }
    : { xs: 12 };

  return (
    <Grid container spacing={2}>
      {/* entity */}
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2}>
            {inputProps.customer_uid.visible ? (
              <Grid item md={6} xs={12}>
                <MultipleSelectWithChip
                  {...inputProps.customer_uid}
                  list={sortList(filterList?.customers, 'name')}
                  handleChange={(props, children) => {
                    return handleCustomerChange({
                      customerUid: props.target.value,
                      cb: setFieldValue,
                      partnerUid: children.props.parent_partner
                    });
                  }}
                />

                <FormErrorMessage
                  message={
                    comparePrevCustomer(inputProps.customer_uid.value)
                      ? 'Customer changed, this device will be reset'
                      : ''
                  }
                />
              </Grid>
            ) : null}

            <Grid item {...groupColumn}>
              <MultipleSelectWithChip
                {...inputProps.group_uid}
                list={sortList(filterList?.groups, 'name')}
                handleChange={(props) =>
                  setFieldValue('group_uid', props.target.value)
                }
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* Device information */}
      <Grid item xs={12}>
        <Card header={'Device Information'}>
          <Grid container spacing={2}>
            <TextField
              {...inputProps.serial}
              disabled={isEdit}
              visible={inputProps.serial.visible || isEdit}
              onChange={handleChange}
            />
            <TextField {...inputProps.location_name} onChange={handleChange} />
            <TextField {...inputProps.csuv_asset_id} onChange={handleChange} />
          </Grid>
        </Card>
      </Grid>

      {/* Deployment information */}
      <Grid item md={6} xs={12}>
        <Card header="Deployment Information" cardStyle={{ height: '100%' }}>
          <MultipleSelectWithChip
            {...inputProps.model_type}
            handleChange={(props) =>
              setFieldValue('model_type', props.target.value)
            }
          />
          <MultipleSelectWithChip
            {...inputProps.sanitizer_mount_type}
            handleChange={(props) =>
              setFieldValue('sanitizer_mount_type', props.target.value)
            }
          />

          {/* Signage group */}
          <FormHeader title={'Signage'} />

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Radio
                radioProps={{
                  onClick: (event) => {
                    handleRemoveSizeMountType(
                      event.target.value,
                      inputProps.size_mount_type.value,
                      setFieldValue
                    );
                  }
                }}
                radioGroupProps={{
                  row: false,
                  'aria-label': 'size_mount_type',
                  name: 'size_mount_type',
                  value: inputProps.size_mount_type.value,
                  onChange: (props) => {
                    setFieldValue('size_mount_type', props.target.value);
                  }
                }}
                list={inputProps.size_mount_type.list}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Checkbox
                checkboxGroupProps={{ row: false }}
                list={inputProps.signage.list}
                onChange={(val) => {
                  setFieldValue('signage', val);
                }}
                value={inputProps.signage.value}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card
          header="Deployed device photo"
          button={{
            icon: <GrPowerReset />,
            size: 'small',
            description: 'Reset',
            onClick: () => setFieldValue('deployed_image', null)
          }}
        >
          <UploadDeployedImage
            name="deployed_image"
            url={inputProps.deployed_image.value}
            acceptTypes={acceptTypes}
            onChange={(value) => {
              return handleFileChange(value, setFieldValue);
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
