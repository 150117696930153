import { cognitoGroup, personaType } from '../../constants';

// permission item
const picardUser = {
  id: cognitoGroup.picardUser,
  name: 'pages.users.picardUser'
};

const picardAdmin = {
  id: cognitoGroup.picardAdmin,
  name: 'pages.users.picardAdmin'
};

const rikerUser = { id: cognitoGroup.rikerUser, name: 'pages.users.rikerUser' };

const rikerAdmin = {
  id: cognitoGroup.rikerAdmin,
  name: 'pages.users.rikerAdmin'
};

const getPermissionListByRole = ({
  isRikerUser,
  isSuperAdmin,
  isRikerAdmin,
  isPicardAdmin
}) => {
  if (isSuperAdmin) return [picardUser, picardAdmin, rikerUser, rikerAdmin];
  if (isRikerAdmin) return [picardUser, picardAdmin, rikerUser];
  if (isRikerUser) return [picardUser, picardAdmin];
  if (isPicardAdmin) return [picardUser];

  return [];
};

export default function useUserInputData(user) {
  const { isRiker } = user?.getUserPermission();

  const inputProps = {
    first_name: {
      label: 'label.firstName',
      xs: 6
    },
    last_name: {
      label: 'label.lastName',
      xs: 6
    },
    contact_phone: {
      label: 'label.phoneNumber',
      xs: 6
    },
    username: {
      label: 'label.email',
      xs: 6
    },
    persona_id: {
      label: 'label.userPersona',
      multiple: false,
      list: [
        {
          id: personaType.actionableReporting,
          name: 'pages.users.actionableReporting'
        },
        {
          id: personaType.fleetManagement,
          name: 'pages.users.fleetManagement'
        },
        {
          id: personaType.both,
          name: 'pages.users.both'
        }
      ]
    },
    permission_level: {
      label: 'label.permission',
      xs: 6,
      list: getPermissionListByRole(user?.getUserPermission())
    },
    customer_uid: {
      label: 'label.assignedCustomer',
      multiple: false,
      visible: isRiker
    },
    group_uids: {
      label: 'label.assignedGroups',
      multiple: true
    },
    tz: {
      label: 'common.timezone',
      req: 'timezone',
      xs: 12
    }
  };

  return inputProps;
}
