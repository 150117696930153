import timezoneList from '../../timezoneList';

export default function useCustomerInputData() {
  const inputProps = {
    partner_name: {
      label: 'label.partnerName'
    },
    partner_address: {
      label: 'pages.device.address'
    },
    tz: {
      label: 'common.timezone',
      list: timezoneList
    }
  };

  return inputProps;
}
