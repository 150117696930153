import React from 'react';
// import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import useSelect from '../../hooks/useSelect';
import { averages } from '../../paths';
import { filterType } from '../../constants';
import selectStyles, { groupSelectStyles } from '../../styles/Input/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function SearchSelect({ type }) {
  const groupClasses = groupSelectStyles();
  const { t } = useTranslation();
  const selectClasses = selectStyles();
  const { onChange, item, options, renderValue } = useSelect({
    type
  });

  const isMultiple = [filterType.groups, filterType.devices].includes(type);

  //  design
  const defaultHeight = options?.length * 4.5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 400,
        right: 0,
        minWidth: defaultHeight
      },
      anchorreference: 'anchorEl',
      anchororigin: { vertical: 'top', horizontal: 'right' }
    },
    getContentAnchorEl: () => null
  };

  return (
    <FormControl className={selectClasses.formContainer}>
      {/* Label is for customer */}
      {!isMultiple || [averages].includes(window.location.pathname) ? (
        <InputLabel
          classes={{
            root: selectClasses.root
          }}
          id="demo-mutiple-name-label"
          shrink={true}
          data-cy="filterLabel"
        >
          {type}
        </InputLabel>
      ) : null}

      <Select
        classes={{
          root: selectClasses.root
        }}
        data-testid={`select-${type}`}
        renderValue={renderValue}
        multiple={isMultiple}
        displayEmpty
        value={item}
        onChange={onChange}
        // onClose={onClose}
        id="demo-mutiple-nme"
        labelId={`label-value-${type}`}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': t('label.withoutLabel') }}
        input={
          <InputBase
            data-testid={`input-${type}`}
            className="fs-mask"
            classes={{ root: selectClasses.root }}
          />
        }
      >
        {/* all control */}
        {isMultiple && options?.length ? (
          <MenuItem value={'all'} data-testid="select-menu-btn">
            <Checkbox checked={options.every((opt) => item.includes(opt.id))} />

            <ListItemText
              className={`fs-mask ${groupClasses.menuItem}`}
              primary={t('common.all')}
            />
          </MenuItem>
        ) : null}

        {options.map((opt) => {
          return (
            <MenuItem
              key={opt.id}
              value={opt?.id}
              data-testid="select-menu-btn"
            >
              {isMultiple ? (
                <MultipleItem
                  checked={item.indexOf(opt?.id) > -1}
                  opt={opt}
                  type={type}
                />
              ) : (
                <EntityItem opt={opt} />
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function MultipleItem({ checked, opt, type }) {
  const groupClasses = groupSelectStyles();
  const { t } = useTranslation();

  return (
    <>
      <Checkbox checked={checked} />

      <ListItemText
        className={`fs-mask ${groupClasses.menuItem}`}
        primary={
          ['all', 'PARTNER'].includes(opt?.id) || type === filterType.groups
            ? t(opt?.name) // all or groups select
            : `${opt?.name} - ${opt?.location}`
        }
      />
    </>
  );
}

function EntityItem({ opt }) {
  const groupClasses = groupSelectStyles();
  const { t } = useTranslation();

  return (
    <ListItemText
      className={`fs-mask ${groupClasses.menuItem}`}
      primary={t(opt?.name)}
    />
  );
}

export default React.memo(SearchSelect);
