import * as yup from 'yup';
import i18n from 'i18next';
import { differenceInMonths, differenceInDays } from 'date-fns';
import timezoneList from '../timezoneList';
import languageList from '../languageList';
import { regex } from '../constants';

const setConditionObj = (shouldAddObj, obj) => {
  return shouldAddObj ? obj : {};
};

export default (type, value) => {
  if (!value) {
    return false;
  }

  return value.match(regex[type]);
};

export const forgotPasswordSchema = yup.object({
  username: yup
    .string()
    .matches(regex.email, 'email does not meet requirement')
    .required('errorMessage.required')
});

// validation for partners
export const managePartnerSchema = (isEdit) =>
  yup.object({
    partner_name: yup.string().required('errorMessage.required'),
    partner_address: yup.string(),
    tz: yup
      .string()
      .oneOf(timezoneList, 'Please add valid timezone')
      .required('errorMessage.required'),

    ...setConditionObj(isEdit, {
      assigned_customers: yup
        .array()
        .min(1, 'errorMessage.invalidAssignedCustomerLength')
        .required('errorMessage.required')
    })

    // primaryContact: yup.string(),
    // phoneNumber: yup.string(),
    // email: yup.string().email()
  });

// validation for customer
export const manageCustomerSchema = () =>
  yup.object({
    customer_name: yup.string().required('errorMessage.required'),
    partner_uid: yup.string().required('errorMessage.required'),
    customer_address: yup.string(),
    tier_type: yup.string().required('errorMessage.selectTier'),
    tier_status: yup.string().required('errorMessage.selectTierStatus'),
    connectivity: yup.string().required('errorMessage.required'),
    tz: yup
      .string()
      .oneOf(timezoneList, 'Please add valid timezone')
      .required('errorMessage.required')
  });

// validation for group
export const manageGroupSchema = yup.object({
  partnerUid: yup.string(),
  customerUid: yup.string().required('label.pleaseSelectCustomer'),
  groupName: yup.string().required('errorMessage.group')
});

// validation for user
export const manageUserSchema = () =>
  yup.object({
    first_name: yup.string().required('errorMessage.required'),
    last_name: yup.string().required('errorMessage.required'),
    contact_phone: yup.string(),
    username: yup
      .string()
      .matches(regex.email, 'email does not meet requirement')
      .required('errorMessage.required'),
    persona_id: yup.string(
      'Persona should be one of Actionable Reporting, Fleet Management or Both'
    ),
    permission_level: yup.string().required(),
    customer_uid: yup.string().required('errorMessage.required'),
    group_uids: yup
      .array()
      .min(1, 'errorMessage.required')
      .required('errorMessage.required'),
    tz: yup
      .string()
      .oneOf(timezoneList, 'Please add valid timezone')
      .required('errorMessage.required')
  });

// validation for user
export const manageNotificationSchema = yup.object({
  customerUid: yup.string().required(i18n.t('errorMessage.customer')),
  event: yup.string().required()
});

export const manageReportSchema = yup.object({
  reportName: yup.string().required(),
  customerUid: yup.string().required()
});

// validation for user info
export const userInfoSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  phoneNumber: yup.string()
});

// validation for password
export const accountPasswordSchema = yup.object({
  currentPassword: yup.string().matches(regex.password).required(),
  newPassword: yup.string().matches(regex.password).required(),
  confirmNewPassword: yup.string().matches(regex.password).required()
});

const deviceSchema = {
  serial: yup
    .string()
    .matches(regex.csuvSerial, 'Serial number must be CSXX-XXXXX')
    .required('errorMessage.device'),
  location_name: yup.string().required('errorMessage.required')
};

export const singleDeviceSchema = yup.object({
  ...deviceSchema
});

export const manageMultipleDeviceSchema = yup.object({
  devices: yup.array().min(1, 'Please add one or more devices').required(),
  partner_uid: yup.string().required('errorMessage.required'),
  customer_uid: yup.string().required('errorMessage.required'),
  group_uid: yup.string().required('errorMessage.required')
});

export const manageDeviceSchema = yup.object({
  ...deviceSchema,
  partner_uid: yup.string().required('errorMessage.required'),
  customer_uid: yup.string().required('errorMessage.required'),
  group_uid: yup.string().required('errorMessage.required')
});

export const roiCalculatorSchema = yup.object({
  costLabor: yup.string(),
  costPerUnit: yup.string(),
  endDate: yup.date(),
  startDate: yup.date()
});

export const manageDeploymentSchema = yup.object({
  image: yup.string(),
  model_type: yup.string(),
  sanitizer_mount_type: yup.string(),
  size_mount_type: yup.string(),
  signage: yup.array()
});

/**
 * @description validation for timezone
 * @param {string} value timezone value from input field
 * @return {string} timezone validation
 */
export function timezoneValidation(value) {
  const selectedItem = timezoneList.find((item) => value === item);

  // selected timezone must be listed or value is empty
  return selectedItem?.length > 0 || value?.length === 0;
}

/**
 * @description validation for language
 * @param {string} value language value from input field
 * @return {string} language validation
 */
export function languageValidation(value) {
  const selectedItem = languageList.find((item) => value === item);

  // selected timezone must be listed or value is empty
  return selectedItem?.length > 0 || value?.length === 0;
}

/**
 * @description refine text value as valid data
 * @param {string} value eg. email
 * @return {string} refined text value
 */
export function refineTextValue(value) {
  if (value)
    return value.trim().toLowerCase().replace(regex.spaceBetweenText, '');
  return '';
}

/**
 * @description confirm password validation
 * @param {string} password new password
 * @param {string} confirmPassword new confirm password
 * @return {Boolean} return boolean value of password and confirm password match
 */
export function confirmPasswordValidation(password, confirmPassword) {
  return password !== confirmPassword || !password || !confirmPassword;
}

export function validateSixMonth(selectedDate) {
  const isWithin6Month = differenceInMonths(
    selectedDate.startDate,
    selectedDate.endDate
  );

  if (isWithin6Month < -6) {
    return false;
  }

  return true;
}

// returns error message if it is false
export function validateRoiCalculator(values) {
  // date validation
  // end date cannot be greater than today
  if (values.endDate > new Date()) {
    return 'errorMessage.endDateNoGreaterThanToday';
  }

  // start date should not be less than end date
  if (differenceInDays(values.endDate, values.startDate) < 0) {
    return 'errorMessage.startDateNoGreaterThanEndDate';
  }

  // date range cannot be less than 6 months
  if (differenceInMonths(values.endDate, values.startDate) > 6) {
    return 'errorMessage.rangeOver6Months';
  }

  // cost per unit validation
  if (parseFloat(values.costPerUnit) < 1) {
    return 'errorMessage.quotePerUnitNoLessThan0';
  }

  return '';
}

export const findDuplicatedSerial = (deviceList, serial) => {
  if (!Array.isArray) return false;
  if (!deviceList.length) return false;
  if (!serial) return false;

  return deviceList.some((d) => new RegExp(d.serial, 'gi').test(serial));
};
