import React from 'react';

import { compareAsc } from 'date-fns';
import { formatDate } from '../../../../helper/date';

/**
 * Business logic hook
 *
 * if chart date changed, it returns chart date and isChanged true
 * if chart date and recoilDate are same, chart date
 
 * @param {object} chartData
 * @param {object} recoilState filterOption's date
 * @param {Date} recoilState.startDate
 * @param {Date} recoilState.endDate
 */
export function useChartDateChange(chartData, recoilDate) {
  const [changed, setChanged] = React.useState(false);
  const [changedDate, setChangedDate] = React.useState({
    startDate: null,
    endDate: null
  });

  /**
   *
   * @param {*} chartDate
   * @param {*} recoilDate
   * @returns return
   */
  const compareDateValue = (chartDate, r_date) => {
    return !chartDate.includes(r_date);
  };

  const getSortedDate = () => {
    const map = new Map();

    chartData?.data?.forEach((item) => {
      if (!map.has(item.sk)) {
        map.set(item.sk, item.sk.split('T')[0]);
      }
    });

    const result = [...map]
      .sort((p, n) => {
        return compareAsc(new Date(p[1]), new Date(n[1]));
      })
      .map((item) => item[1]);

    return {
      chartStartDate: result[0],
      chartEndDate: result[result.length - 1]
    };
  };

  React.useEffect(() => {
    if (chartData?.data && chartData.data.length) {
      const { chartStartDate, chartEndDate } = getSortedDate();
      const { startDate: r_startDate, endDate: r_endDate } = recoilDate;

      const recoilStartDate = formatDate(r_startDate, 'noTime');
      const recoilEndDate = formatDate(r_endDate, 'noTime');

      const s_changed = compareDateValue(chartStartDate, recoilStartDate);
      const e_changed = compareDateValue(chartEndDate, recoilEndDate);

      if (s_changed || e_changed) {
        setChanged(true);

        setChangedDate({
          startDate: chartStartDate,
          endDate: chartEndDate
        });
      } else {
        setChanged(false);

        setChangedDate({
          startDate: recoilStartDate,
          endDate: recoilEndDate
        });
      }
    }
  }, [chartData?.data]);

  return { isChanged: changed, changedDate };
}

/**
 *
 * @param {*} changedDateByData the data is from useChartDateChange
   @param {Boolean} changedDateByData.isChanged the data is from useChartDateChange
 * @param {string} range string value of the static date 
 * @returns string value of date (startDate - endDate), date range or static date range (7days etc..)
 */
export function useDateRange(changedDateByData, range) {
  const { isChanged, changedDate } = changedDateByData;

  if (isChanged) return `${changedDate.startDate} - ${changedDate.endDate}`;

  return range;
}
