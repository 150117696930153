import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import MUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import modalStyles from '../../styles/common/Modal';

export default function Modal({ isOpen, onClose, title, message, children }) {
  const classes = modalStyles();
  const { t } = useTranslation();

  const modalOptions = {
    'aria-labelledby': 'transition-modal-title',
    'aria-describedby': 'transition-modal-description',
    className: classes.modal,
    open: isOpen,
    onClose,

    BackdropComponent: Backdrop,
    BackdropProps: {
      timeout: 500
    }
  };

  return (
    <div>
      <MUIModal {...modalOptions} closeAfterTransition>
        <Fade in={isOpen}>
          <div className={classes.paper}>
            {onClose && (
              <div className={classes.modalCloseContainer}>
                <AiOutlineCloseCircle
                  className={classes.modalCloseIcon}
                  size={20}
                  onClick={onClose}
                />
              </div>
            )}
            <h2 className={classes.modalTitle} id="transition-modal-title">
              {t(title)}
            </h2>
            {children || (
              <p
                className={classes.modalMessage}
                id="transition-modal-description"
              >
                {message}
              </p>
            )}
          </div>
        </Fade>
      </MUIModal>
    </div>
  );
}
