import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles({
  root: {
    width: '100%',
    '&.Mui-focused': {
      color: color.darkBlue
    }
  },
  focused: {},
  outlineRoot: {
    '&$focused $notchedOutline': {
      border: `solid 2px ${color.lightBlue}`
    }
  },
  notchedOutline: {},
  container: {
    position: 'relative'
  }
});

export const filterRowInputStyles = makeStyles({
  root: {
    width: '100%',
    '&.Mui-focused': {
      color: color.darkBlue
    }
  },
  focused: {},
  outlineRoot: {
    '&$focused $notchedOutline': {
      border: `solid 2px ${color.lightBlue}`
    }
  },
  notchedOutline: {},
  container: {
    position: 'relative'
  }
});

export const roiTextFieldStyle = makeStyles({
  container: {
    width: '100%'
  }
});

export const multipleSelectWithChipStyles = makeStyles({
  container: {
    minWidth: 120,
    position: 'relative',
    width: '100%'
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
});
