import React from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components
import { ContentHeading } from '../../components/common/Title';
import { deviceState } from '../../recoil/atom/device';
import { getPageTitle } from '../../helper/admin/page';
import { setMergeDeployedInfoData } from '../../helper/device/deploymentInformation';
import AdminWithList from '../../components/pages/Admin/AdminWithList';
import Card from '../../components/common/Card';
import Filter from '../../components/Filter/Filter';
import IconButton from '../../components/Button/IconButton';
import useDeviceData from '../ManageDevice/useDeviceData';
import useSegment from '../../hooks/useSegment';
import withAdminManage from '../../components/hoc/withAdminManage';

// etc
import { viewDevice, deviceManage } from '../../paths';
import { buttonStyle, adminType, icons } from '../../constants';

function Devices({
  // func
  // onDelete,
  ...props
}) {
  const history = useHistory();
  const deviceRecoil = useRecoilValue(deviceState);

  const { getFetchReq, getDeleteReq } = useDeviceData({
    filterList: props.filterList
  });

  React.useEffect(() => {
    props.getData(getFetchReq(deviceRecoil.filterOptions?.id));
  }, [deviceRecoil.filterOptions?.id]);

  useSegment();

  // overwrite - on row click for device
  const onRowClick = (data) => {
    history.push({
      pathname: `${viewDevice}/${data?.serial}`,
      state: {
        serial: data?.serial,
        data,
        deviceList: []
      }
    });
  };

  const onRowEdit = async (data) => {
    try {
      history.push({
        pathname: deviceManage,
        state: {
          data: await setMergeDeployedInfoData(data)
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onRowDelete = (data) => {
    props.onDelete(getDeleteReq(data.serial));
  };

  const filterOptions = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 4, md: 6, sm: 6, xs: 6 },
    hasTitle: false,
    largeHorizontal: true,
    listOptions: { customer: true, group: false }
  };

  const pageColumn = [
    'customer_name',
    'group_name',
    'serial',
    'location_name',
    'total_uses',
    'status',
    'bulb_change_date',
    'csuv_asset_id',
    'view'
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContentHeading
          title={props.t(getPageTitle(adminType.device))}
          leftButton={
            props.hasCreate ? (
              <IconButton icon={icons.addNew} onClick={props.onRowSave} />
            ) : null
          }
        />
      </Grid>

      {props.user?.getUserPermission()?.isRiker ? (
        <Grid item xs={12}>
          <Card style={buttonStyle.filledBlue}>
            <Filter {...filterOptions} />
          </Card>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <AdminWithList
          {...props}
          type={adminType.user}
          pageColumn={pageColumn}
          onRowClick={onRowClick}
          onRowDelete={onRowDelete}
          actions={[
            {
              icon: () => <MdModeEditOutline size={'0.7em'} />,
              onClick: (event, rowData) => {
                onRowEdit(rowData);
              }
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default withAdminManage(adminType.device)(Devices);
