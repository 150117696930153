import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { track } from '../../helper/segment';
import { supportButtonStyles } from '../../styles/Support';
import { trends, averages, comparisons, useStatistics } from '../../paths';

export default function ViewMoreButton({ link, pageName }) {
  const classes = supportButtonStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const onLinkClick = () => {
    history.push(link);
    let navigateTo = '';

    switch (link) {
      case averages:
        navigateTo = 'Averages';
        break;

      case comparisons:
        navigateTo = 'Comparisons';
        break;

      case useStatistics:
        navigateTo = 'Usages';
        break;

      case trends:
        navigateTo = 'Trends';
        break;

      default:
        navigateTo = '';
        break;
    }

    track(`${pageName} - ${navigateTo} is clicked`);
  };

  return (
    <span
      className={`${classes.supportViewButton} view-more-container`}
      onClick={onLinkClick}
    >
      <span className="view-more-text">{t('label.view')}</span>{' '}
      <BsArrowRight className={`${classes.supportArrow} view-more`} size={20} />
    </span>
  );
}
