import Grid from '@material-ui/core/Grid';
import statusCardStyles from '../../styles/common/StatusCard';

export default function StatusCard({ number, date, columnOptions }) {
  const column = columnOptions || { md: 4, xs: 4 };
  const statusCardClasses = statusCardStyles();

  return (
    <Grid className={statusCardClasses.container} item {...column}>
      <div className={statusCardClasses.cardNumberBox}>{number}</div>
      <div className={statusCardClasses.date}>{date}</div>
    </Grid>
  );
}
