import { useRef } from 'react';
import MUIAccordion from '@material-ui/core/Accordion';
import accordionStyles from '../../../styles/common/Accordion';

export default function Container({ expanded, onChange, id, children }) {
  const classes = accordionStyles();
  const cardRef = useRef();

  return (
    <MUIAccordion
      ref={cardRef}
      className={classes.container}
      expanded={expanded === id}
      onChange={onChange(id)}
    >
      {children}
    </MUIAccordion>
  );
}
