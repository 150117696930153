import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles({
  selectContainer: {
    width: '100%',
    backgroundColor: color.white
  },
  label: {
    backgroundColor: color.white,
    paddingRight: 10,
    paddingLeft: 5,
    '&$focused': {
      color: color.darkBlue
    }
  },
  errorMessage: {
    color: color.red
  },
  focused: {},
  selectRoot: {
    '&:focus': {
      backgroundColor: color.white
    }
  },
  container: {
    position: 'relative'
  }
});

export const timezoneSelectStyle = makeStyles({
  root: {
    '& .MuiFormLabel-root.MuiInputLabel-shrink.Mui-focused': {
      color: color.darkBlue
    }
  },
  inputRoot: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: color.lightBlue
    }
  }
});
