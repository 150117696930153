import { makeStyles } from '@material-ui/styles';

import { color, fontFamily } from '../constants';

export default makeStyles((theme) => ({
  handIcon: {
    width: 100
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingLeft: 0,

      '& button:first-child': {
        marginBottom: 10
      }
    },
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  lastActivityContainer: {
    marginTop: 20
  },
  imageContainer: {
    paddingRight: 30,
    textAlign: 'right'
  }
}));

export const fleetHealthItemStyle = makeStyles({
  fleetItemContainer: {
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  fleetItemStatusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    marginRight: 20
  },
  description: {
    fontSize: 12,
    color: color.white,
    fontFamily: fontFamily.semiBold
  },
  number: {
    fontSize: 80,
    fontFamily: fontFamily.bold,
    color: color.white
  },
  more: {
    position: 'absolute',
    bottom: 10,
    right: 10,

    '& span': {
      color: color.white,
      fontSize: 12,
      fontFamily: fontFamily.semiBold
    }
  },
  moreText: {
    paddingRight: 5
  }
});

export const alertDetailStyle = makeStyles({
  errorHeader: {
    backgroundColor: color.red,

    '& th': { color: color.white, fontFamily: fontFamily.semiBold }
  },
  warningHeader: {
    backgroundColor: color.mustardYellow,
    '& th': { color: color.darkBlue, fontFamily: fontFamily.semiBold }
  },
  row: {
    cursor: 'pointer',

    '&.warning': {
      '&:hover': {
        backgroundColor: 'rgba(255, 221, 85, 0.2) !important'
      }
    },

    '&.error': {
      '&:hover': {
        backgroundColor: 'rgba(234, 66, 56, 0.2) !important'
      }
    }
  }
});

export const alertStyles = makeStyles({
  alertButton: {
    minWidth: 0,
    width: 40,

    '&:hover, &:focus, &:active, &:focus-visible': {
      backgroundColor: 'initial'
    },
    '& .MuiTouchRipple-root': {
      borderRadius: 40,
      paddingLeft: 10,
      width: 40,
      height: 40,
      marginTop: 5,
      left: -6,
      top: 0
    }
  },
  alertMenuItem: {
    display: 'block'
  },
  alertDevice: {
    fontSize: 13,
    display: 'block',
    width: '100%',
    fontFamily: fontFamily.bold
  },
  alertSerial: {
    fontSize: 10,
    display: 'block',
    width: '100%',
    color: color.darkBlue,
    fontFamily: fontFamily.semiBold
  },
  alertDetail: {
    fontSize: 13,
    display: 'block',
    width: '100%',
    color: color.fontGrey
  },
  alertMenuContainer: {
    marginTop: 35
  }
});
