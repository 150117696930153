import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components
import { ContentHeading } from '../../components/common/Title';
import { setTableColumns } from '../../helper/tableOptions';
import { userState } from '../../recoil/state';
import { dateRange } from '../../helper/filterOptions';
import { averageState } from '../../recoil/atom/average';

import Modal from '../../components/common/Modal';
import { useChartDateChange } from '../../components/pages/Analytics/hooks/useChartDateChange';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import FilterChartContainer from '../../components/Charts/FilterChartContainer';
import Loading from '../../components/common/Loading';
import SanitizationInfoCard from './SanitizationInfoCard';
import Switch from '../../components/Input/Switch';
import TableContainer from '../../components/common/Table/TableContainer';
import useAnalytics from '../../hooks/useAnalytics';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';

// etc
import { analyticsType, dataOptions } from '../../constants';
import { averageSanitizationInfoCardStyle } from '../../styles/Analytics';
import useDayCompare from './useDayCompare';

function Averages() {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const classes = averageSanitizationInfoCardStyle();
  const averageRecoil = useRecoilValue(averageState);

  // filter switch values
  const [showValues, setShowValues] = React.useState(false);
  const [compareUsages, setCompareUsage] = React.useState(false);

  // analytics hook
  const { resData, loading } = useAnalytics(analyticsType.average);

  // 2022-08-15 chart data's date is not same because of loading latest data
  const changed = useChartDateChange(resData.chart, {
    startDate: averageRecoil.filterOptions.startDate,
    endDate: averageRecoil.filterOptions.endDate
  });

  const {
    data,
    selectedWeekData,
    dayLoading,
    selectedWeekAverage,
    handleThisWeekUsage,
    showDateError,
    setShowDateError,
    weekData
  } = useDayCompare({
    ...changed.changedDate,
    selectedData: resData.chart,
    recoilValue: averageRecoil,
    analyticsLoading: loading,
    setCompareUsage
  });

  const columns =
    resData?.table?.length > 0 &&
    setTableColumns(Object.keys(resData?.table[0]), user, t);

  // table props
  const tableProps = {
    columns,
    rows: resData?.table,
    hasDownload: true,
    hasSearch: true,
    tableName: t('menuName.averages')
  };

  // filter chart props
  const filterChartProps = {
    changed,
    chartData: { data: selectedWeekData, tz: resData.chart.tz },
    loading: loading && dayLoading,
    type: analyticsType.average,
    listOptions: { customer: true, group: true, device: true },
    weekUsage: data,

    dateProps: {
      data: [dateRange[2], dateRange[3]],
      defaultValue: dataOptions.month
    },
    filterProps: {
      switch: {
        value: [showValues, compareUsages],
        element: (
          <Grid item xs={12}>
            <div>
              <Switch
                checkHandler={[showValues, setShowValues]}
                hasSwitch={true}
                label="Show values"
                labelPlacement="end"
                size="small"
              />
            </div>
            <div>
              <Switch
                checkHandler={[
                  compareUsages,
                  (value) => handleThisWeekUsage(value, setCompareUsage)
                ]}
                hasSwitch={true}
                label="Compare to this weeks usages"
                labelPlacement="end"
                size="small"
              />
            </div>
          </Grid>
        )
      }
    }
  };

  useSegment();

  return (
    <>
      <Modal
        isOpen={showDateError}
        onClose={() => setShowDateError(false)}
        title="Cannot display this week"
        message="You cannot compare this weeks usages"
      />
      <Loading isOpen={loading} />

      <Grid container spacing={2}>
        <Grid item lg={9} xs={12}>
          <ContentHeading
            title={t('menuName.averages')}
            description={t('pages.analytics.averageDescription')}
          />
        </Grid>
        <Grid item lg={3} xs={12} className={classes.titleContainer}>
          <Grid container spacing={1}>
            <SanitizationInfoCard
              title="pages.analytics.averageDaily"
              number={selectedWeekAverage}
              changedDateByData={changed}
              filterOptions={averageRecoil.filterOptions}
            />
          </Grid>
        </Grid>
      </Grid>

      <FilterChartContainer {...filterChartProps} />

      {process.env.REACT_APP_STAGE === 'dev' ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {Object.entries(weekData.otherWeeks).map((item) => {
              return (
                <div>
                  <b>other weeks - {item[0]}: </b>
                  <span>
                    num of usage: {item[1].total} / How many week ?{' '}
                    {item[1].length}
                  </span>
                </div>
              );
            })}

            {Object.entries(weekData.thisWeek).map((item) => {
              return (
                <div>
                  <b>This weeks - {item[0]}: </b>
                  <span>
                    {item[1].total} / How many week ? {item[1].length}
                  </span>
                </div>
              );
            })}
          </Grid>
        </Grid>
      ) : null}

      {/* Section Table */}
      {!loading ? (
        <TableContainer tableProps={tableProps} />
      ) : (
        <CardContentLoader />
      )}
    </>
  );
}

export default withContent(Averages);
