import MUIChip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

import chipStyles from '../../styles/common/Chip';
import { getLabelByValue } from '../../helper/label';
import { deviceStatus, dataOptions } from '../../constants';
import ballastStatusObject from '../../ballastStatus';

export default function Chip({ value }) {
  const { t } = useTranslation();
  const chipClasses = chipStyles();
  let modifiedValue = value;

  if (Number.isInteger(value)) {
    modifiedValue = ballastStatusObject[`${value}`];
  }

  const renderChip = () => {
    let classes = {};

    switch (modifiedValue?.toLowerCase()) {
      case dataOptions.all:
        classes = chipClasses.orangeChip;
        break;

      case dataOptions.completed:
      case dataOptions.success:
        classes = chipClasses.blueChip;
        break;

      case dataOptions.good:
      case deviceStatus.normal:
        classes = chipClasses.greenChip;
        break;

      case dataOptions.inProgress:
      case dataOptions.stopped:
      case deviceStatus.warning:
        classes = chipClasses.yellowChip;
        break;

      case dataOptions.failed:
      case deviceStatus.error:
        classes = chipClasses.redChip;
        break;

      default:
        break;
    }

    return { classes };
  };

  const { classes } = renderChip();
  const { label } = getLabelByValue(value);

  return <MUIChip size="small" label={t(label)} className={classes} />;
}
