import Grid from '@material-ui/core/Grid';

import { getInputProps, getListByDependency } from '../../helper/admin/page';
import { sortList } from '../../helper/filterOptions';
import Card from '../../components/common/Card';
import AutocompleteSelect from '../../components/Input/AutocompleteSelect';
import MultipleSelectWithChip from '../../components/Input/MultipleSelectWithChip';
import TextField from '../../components/Input/TextField';
import timezoneList from '../../timezoneList';

import { cognitoGroup } from '../../constants';

export default function Form({
  errors,
  filterList,
  inputPropData,
  values,
  isEdit,

  // func
  setFieldValue,
  handleChange,
  handleCustomerChange
}) {
  const inputProps = getInputProps({
    inputProps: inputPropData,
    errors,
    values
  });

  return (
    <Card>
      <Grid container spacing={2}>
        <TextField {...inputProps.first_name} onChange={handleChange} />
        <TextField {...inputProps.last_name} onChange={handleChange} />
        <TextField {...inputProps.contact_phone} onChange={handleChange} />
        <TextField
          {...inputProps.username}
          disabled={isEdit}
          onChange={handleChange}
        />

        <Grid item xs={6}>
          <MultipleSelectWithChip
            {...inputProps.persona_id}
            handleChange={(props) =>
              setFieldValue('persona_id', props.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelectWithChip
            {...inputProps.permission_level}
            handleChange={(props) => {
              setFieldValue('permission_level', props.target.value);

              if (props.target.value === cognitoGroup.rikerAdmin) {
                setFieldValue('group_uids', [
                  'g-22222222-2222-2222-2222-222222222222'
                ]);
                handleCustomerChange({
                  customerUid: 'c-22222222-2222-2222-2222-222222222222',
                  cb: setFieldValue,
                  partnerUid: 'p-22222222-2222-2222-2222-222222222222'
                });
              }

              return null;
            }}
          />
        </Grid>

        {inputProps.permission_level.value !== cognitoGroup.rikerAdmin ? (
          <>
            <Grid item xs={12}>
              <MultipleSelectWithChip
                {...inputProps.customer_uid}
                list={sortList(filterList?.customers, 'name')}
                handleChange={(props, children) => {
                  return handleCustomerChange({
                    customerUid: props.target.value,
                    cb: setFieldValue,
                    partnerUid: children.props.parent_partner
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleSelectWithChip
                {...inputProps.group_uids}
                noListMessage={'Please select customer'}
                list={getListByDependency(
                  inputProps.customer_uid.value,
                  sortList(filterList?.groups, 'name')
                )}
                allProps={{
                  hasAll: true,
                  handleClick: (groupValues) => {
                    setFieldValue('group_uids', groupValues);
                  }
                }}
                handleChange={(props) =>
                  setFieldValue('group_uids', props.target.value)
                }
              />
            </Grid>
          </>
        ) : null}

        <AutocompleteSelect
          {...inputProps.tz}
          list={timezoneList}
          onChange={(value) => setFieldValue('tz', value)}
        />
      </Grid>
    </Card>
  );
}
