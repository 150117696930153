import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  logo: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 200
  }
});
