import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

import Button from '../Button/Button';
import Card from './Card';
import noDataFoundStyles from '../../styles/Layout/NoDataFound';
import { userState } from '../../recoil/state';

import {
  adminManageCustomer,
  adminManageGroup,
  adminManagePartners,
  customers,
  devices,
  // expansion,
  groups,
  manageReport,
  notifications,
  notificationsManage,
  partners,
  reports,
  roiCalculator,
  scanRecords
} from '../../paths';

export default function noDataFound({ message, fullHeight }) {
  const classes = noDataFoundStyles();
  const history = useHistory();
  const user = useRecoilValue(userState);
  const isRiker = user?.isAuthed ? user?.getUserPermission()?.isRiker : false;
  const { t } = useTranslation();

  const renderDataName = () => {
    let customText = '';
    let customLink = '';
    const customTranslate = 'errorMessage.noCustomDataFound';
    let isButton = true;
    let noDataFoundMessage = 'errorMessage.noDataFound';

    switch (window.location.pathname) {
      case roiCalculator:
        customLink = '';
        customText = '';
        isButton = false;
        noDataFoundMessage = 'Please select options';
        break;

      case scanRecords:
        customText = 'scan records report';
        customLink = { pathname: manageReport, state: 'scanRecords' };

        if (isRiker) {
          isButton = false;
          noDataFoundMessage = 'errorMessage.pleaseSelectRikerOption';
        }
        break;

      case reports:
        customText = 'report';
        customLink = manageReport;

        if (isRiker) {
          isButton = false;
          noDataFoundMessage = 'errorMessage.pleaseSelectRikerOption';
        }
        break;

      // should add other page
      case notifications:
        customText = 'notification';
        customLink = notificationsManage;

        if (isRiker) {
          isButton = false;
          noDataFoundMessage = 'errorMessage.pleaseSelectRikerOption';
        }
        break;

      case partners:
        customText = 'partner';
        customLink = adminManagePartners;
        break;

      case customers:
        customText = 'customer';
        customLink = adminManageCustomer;
        break;

      case devices:
        if (isRiker) {
          isButton = false;
          noDataFoundMessage = 'errorMessage.pleaseSelectRikerOption';
        }
        break;

      case groups:
        customText = 'group';
        customLink = adminManageGroup;

        if (isRiker) {
          isButton = false;
          noDataFoundMessage = 'errorMessage.pleaseSelectRikerOption';
        }
        break;

      default:
        customText = '';
        customLink = '';
        isButton = false;
        break;
    }

    return {
      isButton,
      link: customLink,
      noDataFoundMessage,
      text: customLink ? t(customTranslate, { data: customText }) : customText // render different button's text
    };
  };

  const { text, link, isButton, noDataFoundMessage } = renderDataName();
  const noDataMessage = message || noDataFoundMessage;

  const cardStyle = {
    ...(fullHeight ? { height: '100%' } : {})
  };

  return (
    <Card cardStyle={cardStyle}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.noDataFoundGridContainer}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.noDataFoundContainer}
        >
          {isButton ? (
            <Button
              description={text}
              fullWidth={false}
              onClick={() => history.push(link)}
              isInitialLabel={true}
            />
          ) : (
            <span data-testid="no-found-message">
              {typeof noDataMessage === 'string' ? t(noDataMessage) : message}
            </span>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
