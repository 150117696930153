import { atom, selector } from 'recoil';
import { userState } from '../state';
import { device } from '../key';

const defaultDevice = { filterOptions: {} };

const defaultDeviceSelector = selector({
  key: 'defaultDeviceSelector',
  get: ({ get }) => {
    const user = get(userState);
    const { isSuperRikerAdmin, isRikerUser } = user.getUserPermission();

    let name = '';
    let id = '';

    if (isSuperRikerAdmin) {
      name = 'partner';
    } else if (isRikerUser) {
      name = 'partner';
    } else {
      name = 'customer';
      id = user.customer_uid;
    }

    return {
      ...defaultDevice,
      filterOptions: { ...defaultDevice.filterOptions, name, id }
    };
  }
});

export const deviceState = atom({
  key: device.deviceState,
  default: defaultDeviceSelector
});

export const viewDeviceState = atom({
  key: device.viewDeviceState,
  default: defaultDeviceSelector
});
