import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px'
      // minHeight: 450
    },
    alignItems: 'center',
    backgroundColor: color.white,
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100vh',
    justifyContent: 'center',
    outline: 0,
    overflowY: 'auto',
    position: 'fixed',
    top: 0,
    zIndex: 1200
  },
  formContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '200px 0 100px 0',
      top: 0
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%'
    }
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 200
  }
}));
