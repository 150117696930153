import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { filterState } from '../recoil/atom/filter';
import { filterType } from '../constants';
import { getPageData } from '../helper/page';
import {
  initEntityValues,
  renderFilterType,
  setMultipleValue,
  updateFilter,
  setGroupLabelValue,
  setEntityLabelValue
} from '../components/Filter/helper';
import {
  averages,
  comparisons,
  trends,
  useStatistics,
  dashboard,
  home,
  devices as devicePath
} from '../paths';

function useSelect({ type }) {
  const { t } = useTranslation();
  const [hasDefault, setHasDefault] = React.useState(false);
  const [item, setItem] = useState([]);
  const [filter, setFilter] = useRecoilState(filterState);
  const { state: pageState } = getPageData(window.location.pathname);
  const [state, setState] = useRecoilState(pageState);

  const [selectedDevices, setSelectedDevices] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  const data = filter?.[type] || [];
  const { devices, groups, customers } = filterType;

  const handleGroupChange = (event, child) => {
    try {
      const selectedValue = child.props.value; // string, only one item
      const eventValue = event.target.value; // array, selected all value

      const { arr, func } =
        type === 'devices'
          ? { func: setSelectedDevices, arr: selectedDevices }
          : { func: setSelectedGroups, arr: selectedGroups };

      if (selectedValue === 'all') {
        if (arr.length === data.length) {
          func([]);
          return;
        }

        func(data.map((val) => val.id));
      }

      const value = setMultipleValue({
        selectedValue,
        eventValue,
        data
      });

      // if value is empty, do not change filter options
      if (!value?.length) {
        func([]);
        return;
      }

      const requestIDs = value?.filter(
        (val) => val !== 'all' && val !== 'PARTNER'
      );
      const parent = filter?.devices?.[0]?.parent_customer;

      const { name } = renderFilterType({ type });
      func(value);

      setState({
        ...state,
        filterOptions: {
          ...state.filterOptions,
          name,
          id: requestIDs,
          parent,
          customerUid: parent
        }
      });

      return;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerChange = async (_, child) => {
    try {
      const selectedValue = child.props.value; // string, only one item
      const { name } = renderFilterType({ type });

      const value = selectedValue;
      const id = selectedValue;
      const res = await updateFilter({ name, value });

      setFilter({ ...filter, ...res });
      setState({
        ...state,
        filterOptions: {
          ...state.filterOptions,
          name: filterType.customer,
          id,
          customerUid: id
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderGroupValue = (selected) => {
    const { name, id } = state.filterOptions;

    const renderedValue = setGroupLabelValue({
      data,
      id,
      name,
      selected,
      type
    });

    return t(renderedValue);
  };

  const renderEntityValue = (value) => {
    const { id, parent, name } = state.filterOptions;

    if (value === 'all') return t('errorMessage.pleaseSelectOptions');

    if (Array.isArray(value) && !value?.length) {
      return t(
        setEntityLabelValue({
          data,
          id,
          name,
          parent
        })
      );
    }

    return data?.find((dt) => dt.id === value)?.name;
  };

  const initializeGroupItem = () => {
    const { id } = state.filterOptions;

    const itemFunc =
      type === 'devices' ? setSelectedDevices : setSelectedGroups;

    if (Array.isArray(id)) {
      itemFunc(id);
    } else if (/^c-/g.test(id) && data.length) {
      itemFunc(data?.map((val) => val.id) || []);
    }
  };

  const initializeEntityItem = () => {
    const { id, parent } = state.filterOptions;

    if (id && /^c-/g.test(id)) {
      setItem(id);
      return null;
    }
    if (data) {
      const values = initEntityValues({ id, parent });
      setItem(values);

      return null;
    }

    return null;
  };

  const getObjByType = () => {
    switch (type) {
      case devices:
        return {
          onChange: handleGroupChange,
          renderValue: renderGroupValue,
          initializeItem: initializeGroupItem,
          item: selectedDevices,
          options: data
        };

      case groups:
        return {
          onChange: handleGroupChange,
          renderValue: renderGroupValue,
          initializeItem: initializeGroupItem,
          item: selectedGroups,
          options: data
        };

      case customers:
        return {
          onChange: handleCustomerChange,
          initializeItem: initializeEntityItem,
          renderValue: renderEntityValue,
          item,
          options: data
        };

      default:
        return {};
    }
  };

  React.useEffect(() => {
    if (
      [
        averages,
        trends,
        comparisons,
        useStatistics,
        home,
        dashboard,
        devicePath
      ].includes(window.location.pathname) &&
      !hasDefault
    ) {
      //
      setHasDefault(true);
    }
  }, [window.location.pathname]);

  React.useEffect(() => {
    const { initializeItem } = getObjByType();

    initializeItem();
  }, [state.filterOptions, data]);

  // console.log('----', getObjByType());

  return getObjByType();
}

export default useSelect;
