import { selectorFamily } from 'recoil';
import { formatDate } from '../../helper/date';
import { getPageData } from '../../helper/page';
import { dateRange } from '../../helper/filterOptions';
import { dataOptions, dateformat } from '../../constants';

export const sanitizationSelector = selectorFamily({
  key: 'sanitizationSelector',
  get:
    (option) =>
    ({ get }) => {
      const { state } = getPageData(window.location.pathname);
      const pageState = get(state);
      const selectedRange = pageState.dateRange;

      let range = '';

      if (selectedRange === dataOptions.customDays) {
        const { noTime } = dateformat;
        const startDate = formatDate(option?.startDate, noTime);
        const endDate = formatDate(option?.endDate, noTime);

        range = `${startDate} - ${endDate}`;
      } else {
        const obj = dateRange.find((opt) => opt.id === selectedRange);
        range = obj?.name;
      }

      return range;
    }
});
