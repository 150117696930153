import { convertFloat } from './csuvCalculator';

// (Quote per unit - CleanSlate cost)*number of units.
export const getTotalEarning = ({ quotePerUnit, numOfDevice, costCSUV }) => {
  return (quotePerUnit - costCSUV) * numOfDevice;
};

export const getSavingsYearly = ({ csuv, wipe }) => {
  return wipe.map((w, index) => {
    return [`${index + 1} year`, convertFloat(w - csuv[index], 0)];
  });
};
