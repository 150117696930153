import { subDays } from 'date-fns';
import { useTranslation, Trans } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

import { userState } from '../../recoil/state';
import CustomDatePicker from '../../components/Input/CustomDatePicker';
import Card from '../../components/common/Card';

import customDateRangeStyles from '../../styles/common/CustomDateRange';

export default function AnalyticsDateRange({ formik, isScanRecord }) {
  const dateClasses = customDateRangeStyles();
  const user = useRecoilValue(userState);

  const { t } = useTranslation();
  const today = subDays(utcToZonedTime(new Date(), user.timezone), 1);

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Card
        header={
          <Trans
            i18nKey="pages.reports.dateRangeTitle" // optional -> fallbacks to defaults if not provided
            values={{ title: isScanRecord ? 'scan records' : 'report' }}
          />
        }
        cardStyle={{ height: 'auto' }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={dateClasses.datePickerContainer}
          >
            <CustomDatePicker
              endDate={formik.values.endDate}
              label={t('label.startDate')}
              maxDate={today}
              onChange={(date) => formik.setFieldValue('startDate', date)}
              selected={formik.values.startDate}
              selectsStart
              startDate={formik.values.startDate}
            />
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={dateClasses.datePickerContainer}
          >
            <CustomDatePicker
              endDate={formik.values.endDate}
              label={t('label.endDate')}
              maxDate={today}
              minDate={formik.values.startDate}
              onChange={(date) => formik.setFieldValue('endDate', date)}
              selected={formik.values.endDate}
              selectsEnd
              startDate={formik.values.startDate}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
