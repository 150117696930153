import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/Input';

import selectStyles from '../../styles/Input/Select';

export default function Select({
  fullWidth,
  handleChange,
  inputBaseStyle,
  list,
  name,
  placeholder,
  value
}) {
  const selectClasses = selectStyles();
  const { t } = useTranslation();

  if (!list?.length) return null;

  const selectProps = {
    'data-testid': `select-${name}`,
    classes: { root: selectClasses.root },
    value,
    name,
    displayEmpty: !!placeholder,
    onChange: handleChange,
    style: fullWidth ? { width: '100%' } : {},
    input: <InputBase classes={inputBaseStyle} />,
    inputProps: { 'data-testid': 'select-input' }
  };

  return (
    <FormControl className={selectClasses.formContainer}>
      <MUISelect {...selectProps}>
        {placeholder ? (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        ) : null}

        {list?.map((m) => {
          return (
            <MenuItem key={m.id} value={m.id} data-testid="select-item">
              {t(m.name)}
            </MenuItem>
          );
        })}
      </MUISelect>
    </FormControl>
  );
}
