import { Trans } from 'react-i18next';
import { signInError } from '../../styles/SignIn';

export default function IEErrorMessage() {
  const errorClasses = signInError();

  return (
    <span className={errorClasses.error}>
      <Trans
        i18nKey="errorMessage.ieNotSupport"
        components={{
          br: <br />,
          a: (
            <a
              href="http://browsehappy.com/"
              className={errorClasses.browserLink}
            />
          )
        }}
      />
    </span>
  );
}
