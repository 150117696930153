import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaRegSave } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';

import {
  accList1,
  accList2,
  signageList1,
  signageList2
} from '../../helper/device/deploymentInformation';
import { manageDeploymentSchema } from '../../helper/validation';
import Card from '../../components/common/Card';
import DeploymentInfoSelectItem from '../../components/pages/Device/DeviceInfo/DeploymentInfoSelectItem';
import DeviceInfoDetail from './DeviceInfoDetail';
import useDeployData from './hook/useDeployData';
import Radio from '../../components/Input/Radio';
import Checkbox from '../../components/Input/Checkbox';

import { fileUploadStyles } from '../../styles/DeviceDetail';
import { buttonStyle, color } from '../../constants';

export default function DeploymentInformation({
  serial,
  data,
  setCurrentDevice
}) {
  if (!serial) return null;

  const { t } = useTranslation();

  // controller for deployment information
  const {
    inheritedData,
    uploadImageTypeList,

    // func
    handleSubmit,
    handleFileChange,
    handleRemoveSizeMountType
  } = useDeployData({
    serial,
    ...data
  });

  const imageInputRef = React.useRef();

  const formik = useFormik({
    initialValues: inheritedData,
    validationSchema: manageDeploymentSchema,
    onSubmit: (values, actions) => {
      const result = handleSubmit(values, actions, serial);
      if (result) {
        setCurrentDevice(values);
      }
    }
  });

  const openFile = () => {
    if (imageInputRef?.current) {
      imageInputRef.current.click();
    }
  };

  return (
    <Card
      header="pages.device.developmentInfo"
      style={buttonStyle.filledBlue}
      cardStyle={{ height: 'auto' }}
      {...(formik.dirty && {
        button: {
          description: 'label.saveChanges',
          icon: <FaRegSave size="0.8em" />,
          onClick: formik.handleSubmit,
          size: 'small',
          style: buttonStyle.lineWhite
        }
      })}
    >
      {/* photo edit */}
      <DeviceInfoDetail title="Photo">
        <DeviceInfoDetail.EditWrapper
          onEditClick={openFile}
          element={
            <FilePicture
              imageInputRef={imageInputRef}
              url={formik.values.deployed_image}
              acceptTypes={uploadImageTypeList.join(', ')}
              onChange={(value) => {
                return handleFileChange(value, formik.setFieldValue);
              }}
              name="deployed_image"
            />
          }
        />
      </DeviceInfoDetail>

      <DeviceInfoDetail title="label.modelType">
        <DeviceInfoDetail.EditWrapper
          element={
            <DeploymentInfoSelectItem
              handleChange={formik.handleChange}
              list={accList1}
              name={'model_type'}
              placeholder={t('pages.device.placeholder', {
                option: t('label.modelType')
              })}
              value={formik.values.model_type}
            />
          }
        />
      </DeviceInfoDetail>

      <DeviceInfoDetail title="label.sanitizerMountType">
        <DeviceInfoDetail.EditWrapper
          element={
            <DeploymentInfoSelectItem
              handleChange={formik.handleChange}
              list={accList2}
              name="sanitizer_mount_type"
              placeholder={t('pages.device.placeholder', {
                option: t('label.sanitizerMountType')
              })}
              value={formik.values.sanitizer_mount_type}
            />
          }
        />
      </DeviceInfoDetail>

      <DeviceInfoDetail title="label.signage">
        <DeviceInfoDetail.EditWrapper
          element={
            <Radio
              color={color.white}
              list={signageList1}
              radioProps={{
                onClick: (event) => {
                  handleRemoveSizeMountType(
                    event.target.value,
                    formik.values.size_mount_type,
                    formik.setFieldValue
                  );
                }
              }}
              radioGroupProps={{
                onChange: (props) => {
                  formik.setFieldValue('size_mount_type', props.target.value);
                },
                value: formik.values.size_mount_type,
                name: 'size_mount_type',
                row: true
              }}
            />
          }
        />
      </DeviceInfoDetail>

      <DeviceInfoDetail>
        <DeviceInfoDetail.EditWrapper
          element={
            <Checkbox
              onChange={(val) => formik.setFieldValue('signage', val)}
              list={signageList2}
              color={color.white}
              name="signage"
              value={formik.values.signage}
            />
          }
        />
      </DeviceInfoDetail>
    </Card>
  );
}

// this function is for deploy information
function FilePicture({ url, imageInputRef, name, onChange, acceptTypes }) {
  const classes = fileUploadStyles();

  const handlePhotoChange = (event) => {
    const isValid = onChange(event.target.files[0]);

    if (!isValid) {
      // eslint-disable-next-line no-param-reassign
      imageInputRef.current.value = '';
    }
  };

  return (
    <Grid item xs={12}>
      {url ? (
        <img src={URL.createObjectURL(url)} className={classes.image} />
      ) : null}

      <input
        className={classes.input}
        ref={imageInputRef}
        type="file"
        name={name}
        onChange={handlePhotoChange}
        accept={acceptTypes}
      />

      {!url ? (
        <span className={classes.noImageMessage}>Please select an image</span>
      ) : null}
    </Grid>
  );
}
