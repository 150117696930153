import i18n from 'i18next';
import {
  cognitoGroup,
  tableType,
  responseKey,
  dataOptions
} from '../constants';
import { customers } from '../paths';

const { rikerAdmin, rikerUser } = cognitoGroup;
const { scheduled, report, admin, analytics } = tableType;

// from line 6 to 346 will be removed after table change.
const partner = {
  id: 'partner',
  label: 'pages.device.partner'
};

const customer = {
  id: 'customer',
  label: 'common.customer'
};

const device = {
  id: 'device',
  label: 'common.device'
};

const address = {
  id: 'address',
  label: 'pages.device.address'
};

const primaryContact = {
  id: 'primaryContact',
  label: 'label.primaryContact'
};

const group = {
  id: 'groupName',
  label: 'table.groupName'
};

const assetId = {
  id: 'assetId',
  label: 'pages.device.assetId'
};

const users = {
  id: 'users',
  label: 'common.users'
};

const displayName = {
  id: 'displayName',
  label: 'pages.device.displayName'
};

const primaryGroup = {
  id: 'primaryGroup',
  label: 'table.primaryGroup'
};

const dateTime = {
  id: 'dateTime',
  label: 'common.dateTime'
};

const activityName = {
  id: 'activity',
  label: 'common.activity'
};

const description = {
  id: 'description',
  label: 'common.description'
};

const serialNum = {
  id: 'serial',
  label: 'pages.device.serialNumber'
};

const orderDate = {
  id: 'orderDate',
  label: 'pages.device.orderDate'
};

const changeDate = {
  id: 'changeDate',
  label: 'pages.device.changeDate'
};

const location = {
  id: 'location',
  label: 'common.location'
};

const subGroup = {
  id: 'subGroup',
  label: 'table.subGroup'
};

const subSubGroup = {
  id: 'subSubGroup',
  label: 'table.subSubGroup'
};

const fromDateDeprecated = {
  id: 'fromDate',
  label: 'table.fromDate'
};

const toDateDeprecated = {
  id: 'toDate',
  label: 'table.toDate'
};

const averageUsesDeprecated = {
  id: 'averageUses',
  label: 'table.averageUses'
};

// column id is must be same as rows json object key
const scheduledReportColumns = (user) => [
  { id: 'reportType', label: 'table.reportType' },
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  {
    id: 'schedule',
    label: 'table.schedule'
  },
  {
    id: 'recipient',
    label: 'table.recipient'
  }
];

const reportColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  primaryGroup,
  subSubGroup,
  device,
  location,
  assetId,
  dateTime,
  activityName,
  description
];

const adminGroupsColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  group,
  device
];

const adminUsersColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),

  {
    id: 'permission',
    label: 'label.permissions'
  }
];

const adminCustomerColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  address,

  primaryContact,
  device,
  users
];

const adminPartnersColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),

  address,

  primaryContact,
  device,
  users,
  ...(user === rikerAdmin || user === rikerUser ? [customer] : [])
];

const adminManageDeviceColumns = [device, location, assetId];
const adminManageGroupColumns = [group];
const adminManageCustomerColumns = [
  customer,
  address,
  {
    id: 'timezone',
    label: 'common.timezone'
  }
];

const deviceOverviewColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  device,
  displayName,
  assetId,
  primaryGroup,
  {
    id: 'users',
    label: 'table.activation'
  },
  {
    id: 'status',
    label: 'pages.device.status'
  },
  {
    id: 'totalUses',
    label: 'common.totalUses'
  }
];

const deviceRecentActivityColumns = (user) => [
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  device,
  serialNum,
  displayName,
  dateTime,
  activityName,
  description
];

const deviceBulbScheduleColumns = (user) => [
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  serialNum,
  displayName,
  orderDate,
  changeDate
];

const deviceActivityLogColumns = [dateTime, activityName, description];

const analyticsAverageColumns = (user) => [
  ...(user === rikerAdmin ? [partner] : []),
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  primaryGroup,
  subGroup,
  subSubGroup,
  device,
  displayName,
  assetId,
  fromDateDeprecated,
  toDateDeprecated,
  averageUsesDeprecated
];

const analyticsTrendColumns = (user) => [
  ...(user === rikerAdmin || user === rikerUser ? [customer] : []),
  primaryGroup,
  subGroup,
  subSubGroup,
  device,
  displayName,
  assetId,
  dateTime,
  activityName
];

export function setTableOptions(user, type) {
  switch (type) {
    case scheduled:
      return scheduledReportColumns(user);

    case report:
      return reportColumns(user);

    case admin.group:
      return adminGroupsColumns(user);

    case admin.user:
      return adminUsersColumns(user);

    case admin.customer:
      return adminCustomerColumns(user);

    case admin.partner:
      return adminPartnersColumns(user);

    case admin.manage.device:
      return adminManageDeviceColumns;

    case admin.manage.group:
      return adminManageGroupColumns;

    case admin.manage.customer:
      return adminManageCustomerColumns;

    case tableType.device.overview:
      return deviceOverviewColumns(user);

    case tableType.device.recentActivity:
      return deviceRecentActivityColumns(user);

    case tableType.device.bulbSchedule:
      return deviceBulbScheduleColumns(user);

    case tableType.device.activityLog:
      return deviceActivityLogColumns;

    case analytics.average:
      return analyticsAverageColumns(user);

    case analytics.comparison:
      return analyticsAverageColumns(user);

    case analytics.trend:
      return analyticsTrendColumns(user);

    case analytics.useStat:
      return analyticsAverageColumns(user);

    default:
      return null;
  }
}

const {
  action,
  activities,
  activity,
  alertCategory,
  averageUses,
  ballastStatus,
  bulbChangeDate,
  contactEmail,
  contactName,
  contactPhone,
  csuvAssetId,
  csuvHealth,
  csuvSerial,
  customerAddress,
  customerName,
  customerUid,
  date,
  email,
  firstName,
  fromDate,
  groupName,
  groupUid,
  lastLogin,
  lastName,
  locationName,
  loginCount,
  partnerAddress,
  partnerName,
  partnerUid,
  permission,
  phone,
  pk,
  push,
  recipients,
  reportType,
  scanCycle,
  scanCycleState,
  serial,
  status,
  timestamp,
  toDate,
  totalUses,
  tz,
  useCount,
  username
} = responseKey;

export const getTranslationByName = (key) => {
  switch (key) {
    case timestamp:
      return 'common.dateTime';

    case serial:
      return 'pages.device.serialNumber';

    case activity:
      return 'common.activity';

    case locationName:
      return 'common.location';

    case customerName:
      return 'common.customer';

    case partnerName:
      return 'pages.device.partner';

    case groupName:
      return 'table.groupName';

    case csuvAssetId:
      return 'table.assetId';

    case status:
      return 'table.status';

    case totalUses:
      return 'table.totalUses';

    case toDate:
      return 'table.toDate';

    case fromDate:
      return 'table.fromDate';

    case averageUses:
      return 'table.averageUses';

    case useCount:
      return 'table.useCount';

    case activities:
      return 'common.activity';

    case scanCycle:
      return 'table.scanCycleState';

    case csuvSerial:
      return 'pages.device.serialNumber';

    case date:
      return 'common.dateTime';

    case scanCycleState:
      return 'table.scanCycleState';

    case customerUid:
      return 'table.customerID';

    case partnerUid:
      return 'table.partnerID';

    case groupUid:
      return 'table.groupID';

    case contactName:
      return 'table.contactName';

    case contactEmail:
      return 'label.email';

    case partnerAddress:
      return 'pages.device.address';

    case customerAddress:
      return 'pages.device.address';

    case phone:
    case contactPhone:
      return 'label.phoneNumber';

    case tz:
      return 'common.timezone';

    case loginCount:
      return 'common.loginCount';

    case firstName:
      return 'label.firstName';

    case lastName:
      return 'label.lastName';

    case permission:
      return 'label.permission';

    case username:
      return 'label.email';

    case email:
      return 'label.email';

    case lastLogin:
      return 'label.lastLogin';

    case push:
      return 'common.pushNotification';

    case pk:
      return 'pk';

    case action:
      return 'label.action';

    case recipients:
      return 'label.recipients';

    case alertCategory:
      return 'label.alertCategory';

    case 'deviceList':
      return 'common.devices';

    case 'userList':
      return 'common.users';

    case csuvHealth:
      return 'label.deviceHealth';

    case ballastStatus:
      return 'label.alertDescription';

    case bulbChangeDate:
      return 'table.changeBulbsBy';

    case reportType:
      return 'table.reportType';

    case 'tier_type':
      return 'label.tier';

    case 'tier_status':
      return 'label.tierStatus';

    default:
      return 'N/A';
  }
};

/**
 * @description assign target to first index of array
 * @param {Array} keys list of column names
 * @param {string} targetKey target to add first index of array
 * @return {Array} formatted column list
 */
function setKeyFirstByName(keys, targetKey) {
  let initKeys = [...keys];
  initKeys = initKeys.filter((key) => key !== targetKey);
  initKeys.unshift(targetKey); // assign first array

  return initKeys;
}

/**
 * @description reassign key list
 * @param {Array} keys list of column names
 * @return {Array} formatted column list
 */
function getOrderedKeyArray(keys) {
  const hasPartnerName = keys.some((key) => key === partnerName);
  const hasCustomerName = keys.some((key) => key === customerName);
  const hasGroupName = keys.some((key) => key === groupName);
  const hasPermission = keys.some((key) => key === permission);
  const hasFirstName = keys.some((key) => key === firstName);
  const hasLastName = keys.some((key) => key === lastName);
  const hasDeviceLocation = keys.some((key) => key === location);
  const hasLocationName = keys.some((key) => key === locationName);

  let initKey = [...keys];

  // Order must be end to first
  if (hasLastName) initKey = setKeyFirstByName(initKey, lastName);
  if (hasFirstName) initKey = setKeyFirstByName(initKey, firstName);
  if (hasPermission) initKey = setKeyFirstByName(initKey, permission);
  if (hasDeviceLocation) initKey = setKeyFirstByName(initKey, location);
  if (hasLocationName) initKey = setKeyFirstByName(initKey, locationName);
  if (hasGroupName) initKey = setKeyFirstByName(initKey, groupName);
  if (hasCustomerName) initKey = setKeyFirstByName(initKey, customerName);
  if (hasPartnerName) initKey = setKeyFirstByName(initKey, partnerName);

  return initKey;
}

/**
 * @description return formatted column array for printing dynamically
 * @param {Array} keys list of column names
 * @param {string} user user object
 * @param {Function} t translation function
 * @return {Object} formatted column list for material table
 */
export const setTableColumns = (keys, user) => {
  const formattedKeyArray = getOrderedKeyArray(keys);
  const { getUserPermission, userGroup } = user;
  const { isRiker, isPicardUser } = getUserPermission();

  if (typeof keys === 'object' && keys.length > 0) {
    return formattedKeyArray
      .map((key) => {
        const isAdminCustomer =
          !isPicardUser && window.location.pathname === customers;

        // If the key is customer name or customer uid, hide
        if (
          !isAdminCustomer &&
          !isRiker &&
          [customerName, customerUid].includes(key)
        ) {
          return;
        }

        // If the key is partner uid, hide
        if ([partnerUid].includes(key)) {
          return;
        }

        // 2021-08-20 temp hide asset id
        if ([csuvAssetId].includes(key)) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return {
          field: key,
          title: i18n.t(getTranslationByName(key, userGroup))
        };
      })
      .filter((menu) => menu);
  }

  return [];
};

/**
 * @description Due to problem of nested array of the activities(activities: (30) arrays), re-assigning rows
 * @param {Array} tableData row data
 * @return {Object} formatted row list for material table
 */
export function getFormattedList(tableData) {
  let formattedList = [];
  const initTableData = Array.isArray(tableData) && [...tableData]; // make immutable

  for (let index = 0; index < initTableData.length; index += 1) {
    const data = initTableData[index];
    const removedActivity = { ...tableData[index] }; // create object and remove activity
    delete removedActivity[activities]; // remove activities

    if (
      Array.isArray(data[responseKey.activities]) &&
      data[responseKey.activities]?.length > 0
    ) {
      // extract data from activity and then re-assign scan cycle values
      formattedList = [
        ...formattedList,
        ...data[responseKey.activities].map((singleActivity) => {
          return {
            ...removedActivity,
            ...singleActivity,
            scan_cycle: singleActivity[responseKey.scanCycle]
              ? dataOptions.success
              : dataOptions.stopped
          };
        })
      ];
    } else {
      formattedList.push({
        ...removedActivity,
        date: '',
        scan_cycle: 'N/A'
      });
    }
  }

  return formattedList.filter((item) => item?.date || item?.csuv_serial);
}

/**
 * @description assign stop or success value for rendering chip
 * @param {Array} tableData row data
 * @return {Object} formatted row list for material table
 */
export function getChangeBooleanToString(tableData) {
  const { success, stopped } = dataOptions;
  const initTableData = [...tableData];

  return initTableData.map((data) => {
    return {
      ...data,
      scan_cycle_state: data.scan_cycle_state ? success : stopped
    };
  });
}

const getTranslatedPersona = (id) => {
  switch (id) {
    case 'AR':
      return 'pages.users.actionableReporting';

    case 'FM':
      return 'pages.users.fleetManagement';

    case 'ARFM':
      return 'pages.users.both';

    default:
      return '';
  }
};

const renderPersonaId = (rowData) => {
  if (!rowData?.persona_id) return '';
  if (!Array.isArray(rowData?.persona_id)) return rowData?.persona_id;
  return (
    <span>
      {rowData?.persona_id.map((persona) => {
        return <div key={persona}>{i18n.t(getTranslatedPersona(persona))}</div>;
      })}
    </span>
  );
};

export const columnRenderFn = {
  persona_id: renderPersonaId
};
