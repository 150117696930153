import { useTranslation } from 'react-i18next';
import Tooltip from '../common/Tooltip';

// component
import OutlineSelect from '../Input/OutlineSelect';
import { reportTypeStyles } from '../../styles/Report';

export default function ReportType({
  itemArray,
  setItem,
  selectedItem,
  toolTipMessage
}) {
  const classes = reportTypeStyles();
  const { t } = useTranslation();
  const handleChange = (value, func) => func(value);

  return (
    <div className={classes.reportTypeContainer}>
      <OutlineSelect
        label="Report type:"
        lg={12}
        md={12}
        onChange={(event) => handleChange(event.target.value, setItem)}
        sm={12}
        xs={12}
        value={selectedItem}
        menuItemArray={itemArray}
        margin="dense"
      />
      <div className={classes.tooltipContainer}>
        <Tooltip message={t(toolTipMessage)} />
      </div>
    </div>
  );
}
