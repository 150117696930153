import { cognitoGroup, dataOptions, filterType } from '../constants';
import {
  groups as groupPath,
  devices as devicePath,
  reports as reportPath,
  averages,
  comparisons,
  dashboard,
  home,
  trends,
  useStatistics
} from '../paths';

export const defaultAll = { name: 'common.all', id: 'all' };
export const defaultAllPartner = { name: 'common.all', id: 'PARTNER' };
export const hasAll = [
  averages,
  comparisons,
  dashboard,
  home,
  trends,
  useStatistics
].includes(window.location.pathname);

const activities = [
  { label: 'common.all', value: 'scan_cycle_total' },
  { label: 'common.successCycles', value: 'scan_cycle_success' },
  { label: 'common.stoppedCycles', value: 'scan_cycle_fail' }
  // { label: 'common.alerts', value: dataOptions.alerts } Hide this option for now because it does not support yet.
];

const viewBy = [
  { label: 'common.timeOfDay', value: dataOptions.timeOfDay }
  // { label: 'common.dayOfWeek', value: dataOptions.dayOfWeek } // day of week is not supported
];

const permission = [
  { label: 'common.picardMember', value: cognitoGroup.picardUser },
  { label: 'common.picardAdmin', value: cognitoGroup.picardAdmin }
];

export const dateRange = [
  { name: 'common.usesToday', id: dataOptions.day },
  { name: 'common.past7Days', id: dataOptions.week },
  { name: 'common.past30Days', id: dataOptions.month },
  {
    name: 'common.customDays',
    id: dataOptions.customDays
  }
];

/**
 * @description This function is default comparison value by user
 * @param {string} user - current user's type
 * @return {Array} Array type of select menu
 */
export function setDefaultComparisonValueByUser(user) {
  const { rikerAdmin, rikerUser, picardAdmin } = cognitoGroup;

  switch (user) {
    case rikerAdmin:
      return [{ label: 'label.partner', value: dataOptions.partner }];
    case rikerUser:
      return [{ label: 'common.customer', value: dataOptions.customer }];
    case picardAdmin:
      return [{ label: 'common.group', value: dataOptions.group }];
    default:
      return [{ label: 'common.devices', value: dataOptions.devices }];
  }
}

/**
 * @description Set user's option data to default option
 * @param {Object} defaultOptions - default option
 * @param {Array} moreData - user's type
 * @return {Object} returning additional options by user's data
 */
function setMoreOptions(defaultOptions, moreData) {
  return [
    ...(defaultOptions?.length > 0 ? defaultOptions : []),
    ...(moreData?.length > 0 ? moreData : [])
  ];
}

/**
 * @description Formatting select label friendly
 * @param {Object} data - object each partners data
 * @param {Object} data.id - it is going to be value
 * @param {Object} data.name - it is going to be label
 * @return {Array} Array type of select menu
 */
function setFilterData(data) {
  const initData = typeof data === 'object' && data.length > 0 && [...data];

  if (initData)
    return initData.map((item) => ({
      label: item.name,
      value: item.id,
      ...item
    }));

  return null;
}

/**
 * @description Sort by label name
 * @param {Object} prev argument from sort function
 * @param {Object} next argument from sort function
 * @return {Array} sorted array by name
 */
export function sortByObjectName(prev, next, key) {
  if (
    prev?.[key] &&
    next?.[key] &&
    prev?.[key].toLowerCase() < next?.[key].toLowerCase()
  ) {
    return -1;
  }
  if (
    prev?.[key] &&
    next?.[key] &&
    prev?.[key].toLowerCase() > next?.[key].toLowerCase()
  ) {
    return 1;
  }
  return 0;
}

export function sortList(list, key) {
  if (!list || !list.length || !key) return [];

  return [...list].sort((prv, next) => sortByObjectName(prv, next, key));
}

export function sortByObjectText(prev, next) {
  if (
    prev.text &&
    next.text &&
    prev.text.toLowerCase() < next.text.toLowerCase()
  ) {
    return -1;
  }
  if (
    prev.text &&
    next.text &&
    prev.text.toLowerCase() > next.text.toLowerCase()
  ) {
    return 1;
  }

  return 0;
}

/**
 * @description set select data if it is available
 * @param {Object} object - parameter
 * @param {Object} object.data - response data from api
 * @param {Object} object.defaultData - default list setup
 * @param {Object} object.refinedData - modified data
 * @return {Object} refined Object
 */
function setFilterObjByData({ data, defaultData, refinedData, type }) {
  let initData = [...data];

  try {
    if (data && type === filterType.devices) {
      initData = data.map((item) => ({
        id: item.id,
        name: `${item.id} - ${item.location}`
      }));
    }

    if (data) {
      initData = initData.sort((prev, next) =>
        sortByObjectName(prev, next, 'name')
      );
      initData = setFilterData(initData);

      // Define frontend route that does not include all value
      const noDefaultPathList = [groupPath, devicePath, reportPath];

      if (!noDefaultPathList.includes(window.location.pathname)) {
        initData = setMoreOptions(defaultData, initData);
      }
    } else {
      initData = refinedData;
    }
  } catch (error) {
    console.log(error, type, data);
  }

  return initData;
}

const getDefaultData = (key) =>
  key === 'customers' ? [] : [{ label: 'common.all', value: dataOptions.all }];

/**
 * @description This function is getting filterOptions by user type
 * @param {String} user - user object
 * @param {Object} filterData - filter response data from API.
 * @param {Object} listOptions - visibility option of select
 * @param {Object} currentFilterState - refined response data ()
 * @return {Object} Object type of select menu
 */
export function getFormattedFilterList(filterData, listOptions) {
  const listByData = {};

  Object.entries(filterData).forEach(([key, value]) => {
    const data = setFilterObjByData({
      data: value,
      defaultData: getDefaultData(key),
      type: key
    });

    listByData[key] = data;
  });

  // remove partner from filter data
  delete listByData.partners;
  const activityData = activities;

  const viewByData = viewBy;
  const permissionData = permission;
  const formattedList = {
    ...listByData,
    ...(listOptions?.activity && { activity: activityData }),
    ...(listOptions?.viewBy && { viewBy: viewByData }),
    ...(listOptions?.permission && { permission: permissionData })
  };

  return formattedList;
}

/**
 * @description Set label and placeholder by object key
 * @param {String} key - object key string value
 * @return {Object} its label and id
 */
export function renderLabelPlaceholder(key) {
  let label = '';
  let placeholder = '';

  switch (key) {
    case 'partners':
      label = 'label.partner';
      break;

    case 'customers':
      label = 'label.customer';
      break;

    case 'groups':
      label = 'label.groups';
      placeholder = 'label.pleaseSelectCustomer';
      break;

    case 'device':
      label = 'label.devices';
      placeholder = 'label.pleaseSelectCustomer';
      break;

    case 'activity':
      label = 'label.activities';
      placeholder = '';
      break;

    case 'dateRange':
      label = 'label.dateRange';
      placeholder = '';
      break;

    case 'viewBy':
      label = 'label.viewBy';
      placeholder = '';
      break;

    case 'comparison':
      label = 'label.compareBy';
      placeholder = '';
      break;

    case 'permission':
      label = 'label.permission';
      placeholder = '';
      break;

    default:
      return '';
  }

  return { label, placeholder };
}

export const getSortedFilter = (data = {}) => {
  // eslint-disable-next-line prefer-const
  let sortedData = {};

  if (!Object.keys(data).length) return data;

  Object.entries(data).forEach(([key, value]) => {
    sortedData = {
      ...sortedData,
      [key]: [...value].sort((prev, next) => {
        if (key === 'devices') return sortByObjectName(prev, next, 'location');
        return sortByObjectName(prev, next, 'name');
      })
    };
  });

  return sortedData;
};
