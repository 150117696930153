import { useEffect } from 'react';
import { callAPI } from '../helper/api';
import fullStory from '../segment';
import { getKey } from '../helper/segment';

export default function useSegment(key) {
  const segmentKey = getKey(key)?.page.toLowerCase();

  useEffect(() => {
    if (process.env?.REACT_APP_STAGE === 'prod') {
      window.analytics.page(fullStory[segmentKey]?.page);
    } else {
      console.log(fullStory[segmentKey]?.page);
    }
  }, []);

  const callSNSLog = async (data) => {
    await callAPI('/gwapi-to-sns', data);
  };

  return {
    callSNSLog
  };
}
