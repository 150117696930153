module.exports = {
  addAlert: '/alerts/addAlert/',
  addDevice: '/devices/add-device/',
  addGroup: '/groups/add-group/',
  admin: '/admin/',
  adminManageCustomer: '/customers/manage/',
  adminManageGroup: '/admin/manage-group/',
  adminManagePartners: '/distributor/manage/',
  adminManageUser: '/users/manage/',
  alerts: '/alerts/',
  analytics: '/analytics/',
  averages: '/averages/',
  cleanSlates: '/clean-slates/',
  comparisons: '/comparisons/',
  contact: '/contact-support/',
  customers: '/customers/',
  dashboard: '/dashboard/',
  dataUpload: '/data-upload/',
  deviceManage: '/devices/manage/',
  devices: '/devices/',
  editAlert: '/alerts/:id',
  editGroup: '/groups/:id',
  expansion: '/savings/expansion-estimator/',
  forgotPassword: '/forgot-password/',
  groups: '/groups/',
  home: '/',
  knowledgeBase: '/knowledge-base/',
  maintenance: '/maintenance/',
  manageReport: '/reports/manage-report',
  multipleDeviceManage: '/devices/multi-manage/',
  noMatch: '*',
  notifications: '/notifications/',
  notificationsManage: '/notifications/manage-notifications',
  partners: '/distributor/',
  reports: '/reports/',
  resetPassword: '/reset-password/',
  roiCalculator: '/savings/roi-calculator/',
  scanRecords: '/scan-records/',
  scheduled: '/scheduled/',
  scheduledManage: '/scheduled/manage/',
  setting: '/setting/',
  signIn: '/sign-in/',
  support: '/support/',
  supportAll: '/support/all/',
  supportContact: '/support/contact/',
  supportHardware: '/support/hardware/',
  supportMobile: '/support-mobile/',
  supportSoftware: '/support/software/',
  trends: '/trends/',
  unsubscribe: '/unsubscribe',
  users: '/users/',
  useStatistics: '/use-statistics/',
  viewDevice: '/view-device'
};
