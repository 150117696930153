/* eslint-disable camelcase */
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// components
import { callAPI } from '../../helper/api';
import { getViewMoreOption } from '../../helper/table';
import { setTableColumns } from '../../helper/tableOptions';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import IconButton from '../../components/Button/IconButton';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';
import LayoutOverview from '../../layout/LayoutOverview';
import { notificationState } from '../../recoil/atom/notification';
import { filterState } from '../../recoil/atom/filter';

// etc
import { notificationsManage } from '../../paths';
import { icons, apiActionTypes, responseKey, toastType } from '../../constants';

const { DELETE, FETCH } = apiActionTypes;
const { partnerUid, customerUid, alertCategory } = responseKey;

function Notifications() {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const user = useRecoilValue(userState);
  const notificationRecoil = useRecoilValue(notificationState);
  const listOptions = { customer: true };
  const filterRecoil = useRecoilValue(filterState);

  const columns =
    notificationList?.length > 0 &&
    setTableColumns(Object.keys(notificationList[0]), user, t);
  const { isPicardAdmin } = user.getUserPermission();

  // Table Row on click event
  const onRowClick = (rowData) => {
    return history.push({
      pathname: notificationsManage,
      state: { data: rowData }
    });
  };

  if (columns) {
    columns.push(getViewMoreOption(onRowClick));
  }

  // Save button on click
  const onSave = () =>
    history.push({ pathname: notificationsManage, state: 'new' });

  // Delete button on click
  const onDelete = async (rowData) => {
    try {
      let deleteReq = {
        type: DELETE,
        action: rowData.action,
        alertCategory: rowData?.[alertCategory]
      };

      if (isPicardAdmin) {
        deleteReq = {
          ...deleteReq,
          partnerUid: user?.partner_uid,
          customerUid: rowData?.[customerUid]
        };
      } else {
        deleteReq = {
          ...deleteReq,
          partnerUid: filterRecoil.customers.find(
            (dt) => dt.id === rowData.customer_uid
          ).parent_partner,
          customerUid: rowData?.[customerUid]
        };
      }

      const response = await callAPI(`/notification-management`, deleteReq);

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      const filteredList = notificationList.filter((item) => {
        const isMatch =
          rowData[partnerUid] !== item[partnerUid] ||
          rowData[customerUid] !== item[customerUid] ||
          rowData[alertCategory] !== item[alertCategory] ||
          rowData.action !== item.action;

        return isMatch;
      });

      setNotificationList(filteredList);
      setLoading(false);
      setToast({
        message: t('toastMessage.notificationDeleteSuccess'),
        type: toastType.success,
        open: true
      });
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      setLoading(false);
      return null;
    }
  };

  const getData = async () => {
    setLoading(true);

    const id = notificationRecoil?.filterOptions?.id || user?.customer_uid;

    try {
      const list = [];
      const response = await callAPI('/notification-management', {
        type: FETCH,
        customerUid: id
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      if (!Array.isArray(response?.data)) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      for (let index = 0; index < response?.data.length; index += 1) {
        const item = response?.data[index];
        const { alert_category, customer_name, customer_uid, partner_uid } =
          item;

        for (
          let secondIndex = 0;
          secondIndex < Object.entries(item).length;
          secondIndex += 1
        ) {
          const key = Object.entries(item)[secondIndex][0];
          const value = Object.entries(item)[secondIndex][1];

          if (Array.isArray(item[key]) && item[key].length > 0) {
            list.push({
              alert_category,
              customer_name,
              customer_uid,
              partner_uid,
              action: key,
              recipients: value
            });
          }
        }
      }

      setNotificationList(list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (notificationRecoil?.filterOptions?.id || isPicardAdmin) {
      getData();
    }
  }, [notificationRecoil?.filterOptions?.id]);

  useSegment();

  // table option
  const tableProps = {
    columns,
    hasFilter: true,
    hasDelete: true,
    hasDownload: true,
    rows: notificationList,
    tableName: t('menuName.notifications'),
    onRowClick,
    onDelete
  };

  const filterOptions = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 4, md: 6, sm: 6, xs: 6 },
    hasTitle: false,
    largeHorizontal: true,
    listOptions
  };

  return (
    <LayoutOverview>
      <LayoutOverview.Heading
        title={t('menuName.notifications')}
        leftButton={<IconButton icon={icons.addNew} onClick={onSave} />}
      />

      <LayoutOverview.Contents
        filterOptions={filterOptions}
        tableProps={tableProps}
        loading={loading}
      />
    </LayoutOverview>
  );
}

export default withContent(Notifications);
