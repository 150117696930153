import headerStyles from '../../styles/common/Header';
import logo from '../../assets/images/logo.svg';
import { home } from '../../paths';

export default function Logo() {
  const classes = headerStyles();

  return (
    <a className={classes.container} href={home}>
      <img className={classes.img} src={logo} />
    </a>
  );
}
