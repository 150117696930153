import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';

import languageMenuStyles from '../../styles/common/LanguageMenu';
import flagCn from '../../assets/images/flag_cn.svg';
import flagFr from '../../assets/images/flag_fr.svg';
import flagCa from '../../assets/images/flag_ca.svg';
import flagKor from '../../assets/images/flag_kr.svg';
import flagIn from '../../assets/images/flag_ind.svg';
import flagUS from '../../assets/images/flag_us.svg';
import { languageCode } from '../../constants';
import { getTemporaryVisible } from '../../helper/page';

const menuList = [
  {
    icon: flagUS,
    description: 'US',
    lang: 'en'
  },
  ...(getTemporaryVisible()
    ? [
        {
          icon: flagFr,
          description: 'FR',
          lang: 'fr'
        },
        {
          icon: flagKor,
          description: 'KR',
          lang: 'kr'
        },
        {
          icon: flagIn,
          description: 'IN',
          lang: 'in'
        },
        {
          icon: flagCn,
          description: 'CN',
          lang: 'cn'
        }
      ]
    : [])
];

export default function LanguageMenu() {
  const [isLangMenuOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(flagCa);
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();
  const classes = languageMenuStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getFlag = (currentFlag) => {
      switch (currentFlag) {
        case languageCode.en:
          return flagUS;

        case languageCode.fr:
          return flagFr;

        case languageCode.cn:
          return flagCn;

        case languageCode.in:
          return flagIn;

        case languageCode.kr:
          return flagKor;

        default:
          return flagCa;
      }
    };

    const storedLanguageCode = localStorage.getItem('lang');

    if (storedLanguageCode) {
      i18n.changeLanguage(storedLanguageCode);
      setSelectedFlag(getFlag(storedLanguageCode));
    }
  }, []);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span className={classes.languageIconContainer}>
          <img
            src={selectedFlag}
            style={{ width: 20 }}
            data-cy="languageSwitcher"
          />
        </span>
      </IconButton>

      <Menu
        open={isLangMenuOpen}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
      >
        {menuList.map((menu, index) => (
          <LanguageMenuItem
            key={index}
            {...menu}
            onMenuClose={handleClose}
            setSelectedFlag={setSelectedFlag}
          />
        ))}
      </Menu>
    </>
  );
}

const LanguageMenuItem = React.forwardRef(
  ({ icon, description, lang, onMenuClose, setSelectedFlag }, ref) => {
    const { i18n } = useTranslation();

    const onClose = (selectedLang, selectedIcon) => {
      i18n.changeLanguage(selectedLang);
      onMenuClose();
      localStorage.setItem('lang', selectedLang);
      setSelectedFlag(selectedIcon);
    };

    return (
      <MenuItem onClick={() => onClose(lang, icon)} ref={ref}>
        <ListItemIcon data-cy={`lang-${lang}`}>
          <img src={icon} style={{ width: 20 }} />
        </ListItemIcon>
        <Typography variant="inherit">{description}</Typography>
      </MenuItem>
    );
  }
);
