import Grid from '@material-ui/core/Grid';

import { findDuplicatedSerial } from '../../../helper/validation';
import { getInputProps, getListByDependency } from '../../../helper/admin/page';
import { sortList } from '../../../helper/filterOptions';
import Card from '../../../components/common/Card';
import DeviceForm from './DeviceForm';
import MultipleSelectWithChip from '../../../components/Input/MultipleSelectWithChip';
import Table from '../../../components/common/Table';
import useTable from '../../../components/pages/Admin/hooks/useTable';

export default function Form({
  errors,
  filterList,
  inputPropData,
  values,
  isEdit,

  // func
  setFieldValue,
  setErrors,
  handleCustomerChange,
  handleRemoveSizeMountType,

  // 2022-09-06 additional props
  acceptTypes,
  handleFileChange
}) {
  const inputProps = getInputProps({
    inputProps: inputPropData,
    errors,
    values
  });

  const onDeviceSubmit = (value, actions) => {
    const hasSerialNumber = findDuplicatedSerial(values.devices, value.serial);

    if (hasSerialNumber) {
      setErrors({ ...errors, devices: 'Serial number is duplicated' });
    } else {
      setFieldValue('devices', [
        ...values.devices,
        { ...value, serial: value.serial.toUpperCase() }
      ]);
      actions.resetForm({ values: { serial: '', location_name: '' } });
    }
  };

  const onRowDelete = (data) => {
    setFieldValue(
      'devices',
      values.devices.filter((d) => d.serial !== data.serial)
    );
  };

  // hook
  const tableProps = useTable({
    data: values.devices,

    // function
    onRowDelete,

    // permission
    hasDelete: true,

    // table utils
    pageColumn: [
      'serial',
      'location_name',
      'csuv_asset_id',

      'deployed_image',
      'model_type',
      'sanitizer_mount_type',
      'size_mount_type',
      'signage'
    ]
  });

  return (
    <Grid container spacing={2}>
      {/* Entity card */}
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MultipleSelectWithChip
                {...inputProps.customer_uid}
                list={sortList(filterList?.customers, 'name')}
                handleChange={(props, children) => {
                  return handleCustomerChange({
                    customerUid: props.target.value,
                    cb: setFieldValue,
                    partnerUid: children.props.parent_partner
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MultipleSelectWithChip
                {...inputProps.group_uid}
                noListMessage={'Please select customer'}
                list={getListByDependency(
                  inputProps.customer_uid.value,
                  sortList(filterList?.groups, 'name')
                )}
                handleChange={(props) =>
                  setFieldValue('group_uid', props.target.value)
                }
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DeviceForm
          acceptTypes={acceptTypes}
          errors={errors}
          filterList={filterList}
          handleFileChange={handleFileChange}
          handleRemoveSizeMountType={handleRemoveSizeMountType}
          inputPropData={inputPropData}
          isEdit={isEdit}
          onSubmit={onDeviceSubmit}
          values={values}
        />
      </Grid>

      {/* Table */}
      <Grid item xs={12}>
        <Table {...tableProps} hasFilter={false} hasDownload={false} />
      </Grid>
    </Grid>
  );
}
