import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

import menuStyles from '../../styles/common/Menu';
import { track, getKey } from '../../helper/segment';
import diamond from '../../assets/images/ico_diamond.png';

export default function MenuItem(props) {
  const { icon, text, link } = props;

  const isSelected = props.haSubMenu
    ? props.isSubMenu === window.location.pathname
    : window.location.pathname.includes(props.link);

  const history = useHistory();
  const classes = menuStyles(isSelected);
  const { t } = useTranslation();

  const renderOpenIcon = () => {
    return props.isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />;
  };

  const onClick = () => {
    if (link) track(`Navigation Pane - ${getKey(link)?.page} is clicked`);

    if (props.onClick) {
      props.onClick();
    } else {
      history.push(link);
    }
  };

  return (
    <ListItem
      classes={{ root: classes.menuItem }}
      className={`${props.isSubmenu && classes.subMenuItem}`}
      button
      onClick={onClick}
      data-cy={`menuButton${t(props.text)}`}
    >
      <ListItemIcon classes={{ root: classes.menuIcon }}>{icon}</ListItemIcon>
      <ListItemText
        classes={{
          primary: props.isSubMenu ? classes.menuSubText : classes.menuText,
          root: classes.menuTextRoot
        }}
        primary={t(text)}
      />
      {props?.isPaid && !props?.isSubmenu && (
        <span>
          <img src={diamond} className={classes.proIcon} />
        </span>
      )}
      {typeof props.isOpen === 'boolean' && renderOpenIcon()}
    </ListItem>
  );
}

MenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  isSubMenu: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired
};
