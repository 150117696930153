import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

// component
import { callAPI } from '../../helper/api';
import { forgotPasswordSchema } from '../../helper/validation';
import { getErrorMessageForUser } from '../../helper/admin/page';
import { MainHeading } from '../../components/common/Title';
import { ToastContext } from '../../context/ToastContext';
import ErrorMessage from '../../components/common/ErrorMessage';
import FloatLogo from '../../components/common/FloatLogo';
import Form from './Form';
import Modal from '../../components/common/Modal';

// etc
import { apiActionTypes, modalTime, toastType } from '../../constants';
import forgotPasswordStyles from '../../styles/ForgotPassword';
import paths from '../../paths';

export default function ForgotPassword() {
  const [isOpen, setIsOpen] = React.useState(false);

  // context
  const { setToast } = React.useContext(ToastContext);

  // third party
  const { t } = useTranslation();
  const history = useHistory();

  // styles
  const classes = forgotPasswordStyles();

  const onSubmit = async (values) => {
    try {
      const response = await callAPI('/forgot-password', {
        type: apiActionTypes.FORGOT_PASSWORD,
        username: values.username?.toLowerCase()
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setIsOpen(true);

      setTimeout(() => {
        setIsOpen(false);
        history.push(paths.signIn);
      }, modalTime + 1000);
    } catch (error) {
      setToast({
        message: getErrorMessageForUser(error.response.data) || error.message,
        type: toastType.error,
        open: true
      });
    }
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      username: ''
    },
    validationSchema: forgotPasswordSchema,
    onSubmit
  });

  return (
    <Grid
      className={classes.container}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        title={t('modal.passwordResetConfirm.title')}
        message={t('pages.forgotPassword.emailSent')}
      />

      <Grid item className={classes.formContainer} xs={12}>
        <FloatLogo />
        <MainHeading title={t('menuName.forgotPassword')} />

        <Form {...formik} />

        <ErrorMessage
          message={formik.isSubmitting ? t(formik.errors?.username) : ''}
        />
      </Grid>
    </Grid>
  );
}
