import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MUITextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '../common/Tooltip';
import TextField from './TextField';

import textFieldStyles from '../../styles/Input/TextField';
import tooltipStyles from '../../styles/common/Tooltip';
import outlineSelectStyles, {
  timezoneSelectStyle
} from '../../styles/common/OutlineSelect'; //

export default function AutocompleteSelect({
  label,
  errorMessage,
  isInvalid,
  helperText,
  onChange,
  name,
  lg,
  md,
  sm,
  xs,
  cy,
  tooltip,
  onClick,
  list,
  value: parentValue = '',
  placeholder,
  readOnly
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(parentValue);
  const classes = outlineSelectStyles();
  const textFieldClasses = textFieldStyles();
  const tooltipClasses = tooltipStyles();
  const timezoneClasses = timezoneSelectStyle();
  const muiHelperText = isInvalid ? errorMessage : helperText;

  const onSelectChange = (event, newValue) => {
    onChange(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(parentValue);
  }, []);

  if (readOnly) {
    return (
      <Grid
        item
        lg={lg}
        md={md}
        sm={sm}
        xs={xs}
        className={textFieldClasses.container}
        onClick={onClick}
      >
        <TextField
          lg={12}
          md={12}
          sm={12}
          xs={12}
          errorMessage={t('errorMessage.email')}
          isInvalid={isInvalid}
          readOnly={readOnly}
          label={label}
          value={parentValue}
        />
      </Grid>
    );
  }

  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      className={textFieldClasses.container}
      onClick={onClick}
    >
      {tooltip && (
        <div className={tooltipClasses.tooltipContainer}>
          <Tooltip message={t(tooltip)} />
        </div>
      )}
      <FormControl
        classes={{ root: classes.selectContainer }}
        variant="outlined"
        margin="normal"
        style={tooltip && { marginTop: 30 }}
      >
        <Autocomplete
          autoHighlight
          classes={timezoneClasses}
          fullWidth={true}
          inputValue={value || ''}
          defaultValue={parentValue}
          getOptionLabel={(option) => option}
          getOptionSelected={(option) => option}
          label={t(label)}
          options={list}
          variant="outlined"
          onInputChange={onSelectChange}
          renderInput={(params) => (
            <MUITextField
              {...params}
              data-cy={cy}
              error={isInvalid}
              fullWidth
              label={t(label)}
              placeholder={t(placeholder)}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off', // disable autocomplete and autofill,
                readOnly,
                'data-testid': `autoComplete-${name}`
              }}
            />
          )}
        />
        <FormHelperText error={isInvalid}>{t(muiHelperText)}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

AutocompleteSelect.propTypes = {
  label: PropTypes.string.isRequired,
  lg: PropTypes.number,
  list: PropTypes.array.isRequired,
  md: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  sm: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
