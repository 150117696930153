import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: `solid 1px ${color.lightGrey}`,
    boxShadow: `0px 2px 3px 0px ${color.shadow}`,
    display: 'flex',
    height: '65px',
    justifyContent: 'flex-end',
    // width: '100%',
    position: 'fixed',
    width: 220,
    top: 0,
    zIndex: 999
  },
  img: {
    height: 'auto',
    minWidth: '200px',
    width: 'calc(100% - 20px)'
  },
  userContainer: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      padding: '0 30px',
      right: 'auto',
      width: '100%',
      zIndex: 999
    },
    position: 'fixed',
    top: 0,
    width: 'calc(100% - 220px)',
    zIndex: 10,
    right: 0,
    paddingRight: 30,
    justifyContent: 'space-between'
  },
  userTypeText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    },
    color: color.green,
    fontSize: 20,
    fontFamily: fontFamily.semiBold,
    paddingLeft: 20
  },
  selectContainer: {
    paddingLeft: 10,
    paddingRight: 10
  },
  // should show mobile only
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  mobileHamburgerMenu: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8
  },
  mobileUserIcon: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    textTransform: 'none'
  },
  desktopUserContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    display: 'flex',
    alignItems: 'center'
  },
  mobileMenuList: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 999,
    bottom: 0,
    padding: '30px 30px 0 30px',
    top: 0,
    flex: '1 0 auto',
    display: 'flex',
    outline: 0,
    position: 'fixed',
    overflowY: 'auto',
    flexDirection: 'column'
  },
  mobileCloseBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  userButton: { cursor: 'pointer', textTransform: 'none' }
}));

export const tierStyles = makeStyles((theme) => ({
  tierContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    fontSize: 12,
    color: color.white,
    padding: 8,
    backgroundColor: color.lightBlue,
    fontFamily: fontFamily.bold,
    marginBottom: 8,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 20
  },
  tierDay: {
    color: color.darkBlue,
    paddingLeft: 0,
    fontFamily: fontFamily.bold
  }
}));
