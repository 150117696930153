import { differenceInDays } from 'date-fns';

import {
  getDailyCleaningCyclePerDay,
  getMaterialTotalCostPerDay,
  getYearsOfCost,
  getCostPerSanitizationWipe,

  // labour
  getLabourTimePerDay,
  getLabourTotalCostPerDay
} from '../helper/roi/calculator';

import {
  convertFloat,
  getBulbCostPerDevice,
  getCostPerDeviceWithCSUV,
  getCSUVAnnualCostPerYear,
  getCSUVCleaningPerDay,
  getCSUVCostPerDevice,
  getCSUVYearsOfCost,
  getEnvironmentalImpact,
  getPaybackPeriod,
  getTotalTimePerYear,
  getUnitLabourByYear,
  getYearPercent,
  getYearSavings
} from '../helper/roi/csuvCalculator';

import { getTotalEarning, getSavingsYearly } from '../helper/roi/estimator';

// wipe cost values
const avgMaterialCostByCycle = 0.1;
const cleanedPerDay = 1.75;

// csuv cost values
const bulbCost = 700;
const partCost = 100;
const avgTimeCleaningEvent = 30;
const years = 5;

export default function useCalculatedResult({
  roiCalculator,
  numOfDevice,
  costPerUnit,
  costCSUV
}) {
  const { costLabor, endDate, startDate, totalCSUVCleanCycle } = roiCalculator;

  // 1. material cost
  const dailyCleaningCycles = getDailyCleaningCyclePerDay({
    totalCSUVCleanCycle,
    cleanedCycle: cleanedPerDay,
    days: differenceInDays(endDate, startDate)
  });

  // H39
  const materialTotalCostPerDay = getMaterialTotalCostPerDay(
    dailyCleaningCycles,
    avgMaterialCostByCycle
  );

  // 2. labour cost
  const labourTimePerDay = getLabourTimePerDay(dailyCleaningCycles, 90); // H41
  const labourTotalPerDay = getLabourTotalCostPerDay(
    labourTimePerDay,
    costLabor
  );

  // 3. csuv cost
  const csuvCleaningCyclePerDay = getCSUVCleaningPerDay(
    totalCSUVCleanCycle,
    differenceInDays(endDate, startDate)
  );

  const costUnitYearly = getCSUVYearsOfCost({
    firstYear: getCSUVCostPerDevice(costPerUnit, numOfDevice),
    years,
    totalValues: 0
  });

  const bulbYearly = getCSUVYearsOfCost({
    firstYear: getBulbCostPerDevice(bulbCost, numOfDevice) / 2,
    years,
    totalValues: bulbCost * numOfDevice
  });

  const partYearly = getCSUVYearsOfCost({
    firstYear: 0,
    years,
    totalValues: partCost * numOfDevice
  });

  // 4. csuv labour cost
  const csuvTimePerYear = getTotalTimePerYear(
    csuvCleaningCyclePerDay / numOfDevice,
    avgTimeCleaningEvent,
    numOfDevice
  );

  const csuvLabourYearly = getCSUVYearsOfCost({
    firstYear: getCSUVAnnualCostPerYear(costLabor, csuvTimePerYear),
    years: 5,
    totalValues: getCSUVAnnualCostPerYear(costLabor, csuvTimePerYear)
  });

  // Excel line 35
  const { csuvTotalYearly, csuvOnlyYearly } = getUnitLabourByYear(
    costUnitYearly,
    bulbYearly,
    partYearly,
    csuvLabourYearly
  );

  const { wipeTotalYearly, wipeOnlyYearly } = getYearsOfCost(
    materialTotalCostPerDay + labourTotalPerDay,
    years
  );

  const yearPercentList = getYearPercent(
    [1, 2, 3, 4, 5],
    csuvTotalYearly,
    wipeTotalYearly,
    costUnitYearly[0]
  );

  // value should be rounded
  return {
    wipeCleaning: {
      materialDaily: convertFloat(materialTotalCostPerDay, 0),
      materialCostPerYear: wipeTotalYearly,
      costPerSanitization: getCostPerSanitizationWipe(costLabor)
    },
    csuvCleaning: {
      avgDailyUseTotal: convertFloat(csuvCleaningCyclePerDay, 0),
      deviceSanitizedDaily: convertFloat(dailyCleaningCycles, 0),
      environmentImpact: getEnvironmentalImpact(
        totalCSUVCleanCycle,
        differenceInDays(endDate, startDate)
      ),

      years: csuvTotalYearly,
      yearsPercent: yearPercentList,

      // calculation is based on 3 years
      oneYearCostSavings: getYearSavings(
        wipeTotalYearly[2],
        csuvTotalYearly[2]
      ), // 1year or 5 year ?
      oneYearCostPerDeviceWithCSUV: getCostPerDeviceWithCSUV(
        // Cost per device sanitized:
        dailyCleaningCycles,
        csuvTotalYearly[2]
      ),

      totalEarning: convertFloat(
        getTotalEarning({
          quotePerUnit: costPerUnit,
          numOfDevice,
          costCSUV
        })
      ),

      // =((csuv1yr + csuv2yr)/2)/(((wipe1yr-csuv1yr)+(wipe2yr-csuv2yr))/2)*12
      paybackPeriod: convertFloat(
        getPaybackPeriod(
          costUnitYearly[0],
          csuvOnlyYearly[1],
          wipeOnlyYearly[1]
        ),
        1
      )
    },
    savings: getSavingsYearly({ csuv: csuvTotalYearly, wipe: wipeTotalYearly })
  };
}
