import React from 'react';
import { useRecoilState } from 'recoil';
import { BiFilter } from 'react-icons/bi';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { filterRowInputStyles } from '../../../styles/Input/TextField';
import { getPageData } from '../../../helper/page';

export default function TBFilterInput({
  rowId,
  onFilterChanged,
  queryData,
  storedValue
}) {
  const { state } = getPageData(window.location.pathname);
  const [pageState, setPageState] = useRecoilState(state);
  const [filterVal, setFilterVal] = React.useState(storedValue);
  const textFieldClasses = filterRowInputStyles();

  const handleChange = (event) => {
    setFilterVal(event.target.value);
    onFilterChanged(rowId, event.target.value);
  };

  React.useEffect(() => {
    if (storedValue) {
      onFilterChanged(rowId, storedValue);
    }
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
      <Input
        startAdornment={
          <InputAdornment position="start">
            <BiFilter />
          </InputAdornment>
        }
        classes={{
          root: textFieldClasses.outlineRoot,
          focused: textFieldClasses.focused
        }}
        margin="dense"
        onBlur={() => setPageState({ ...pageState, tableQuery: queryData })} // when user on blur add table search query data
        onChange={handleChange}
        value={filterVal}
        type="search"
      />
    </div>
  );
}
