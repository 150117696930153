import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// components
import Tooltip from '../common/Tooltip';

// etc
import textFieldStyles from '../../styles/Input/TextField';
import tooltipStyles from '../../styles/common/Tooltip';

export default function TextField(
  {
    cy,
    errorMessage,
    helperText,
    disabled,
    isInvalid,
    readOnly,
    isRequired,
    label,
    lg,
    margin,
    md,
    name,
    onChange,
    onClick,
    placeholder,
    shrink,
    sm,
    tooltip,
    type,
    value = '',
    xs
  },
  props
) {
  const textFieldClasses = textFieldStyles();
  const autoComplete = props.autoComplete ? 'off' : 'new-password';
  const muiHelperText = isInvalid ? errorMessage : helperText;
  const tooltipClasses = tooltipStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      className={textFieldClasses.container}
      onClick={onClick}
    >
      {tooltip && (
        <div className={tooltipClasses.tooltipContainer}>
          <Tooltip message={t(tooltip)} />
        </div>
      )}
      <MUITextField
        {...props}
        autoComplete={autoComplete}
        disabled={disabled}
        error={isInvalid}
        fullWidth
        helperText={t(muiHelperText)}
        label={`${t(label)}${isRequired ? ' *' : ''}`}
        margin={margin || 'normal'}
        name={name}
        placeholder={t(placeholder)}
        variant="outlined"
        InputLabelProps={{
          shrink,
          classes: {
            root: textFieldClasses.root,
            focused: textFieldClasses.focused
          }
        }}
        inputProps={{ 'data-testid': `input-${name}` }}
        InputProps={{
          readOnly,
          className: 'fs-mask',
          classes: {
            root: textFieldClasses.outlineRoot,
            focused: textFieldClasses.focused,
            notchedOutline: textFieldClasses.notchedOutline
          }
        }}
        onChange={onChange}
        value={value}
        type={type}
        data-cy={cy}
      />
    </Grid>
  );
}

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  sm: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
