export const convertFloat = (num, decimal = 2) =>
  parseFloat(num).toFixed(decimal);

export const getCSUVCostPerDevice = (csuvCost, numOfDevices) => {
  return csuvCost * numOfDevices;
};

export const getBulbCostPerDevice = (bulbCost, numOfDevices) => {
  return bulbCost * numOfDevices;
};

export const getCSUVYearsOfCost = ({ firstYear, years, totalValues }) => {
  const totalCostYearlyList = [];

  for (let index = 1; index <= years; index += 1) {
    if (index === 1) {
      totalCostYearlyList.push(firstYear);
    } else {
      totalCostYearlyList.push(totalValues);
    }
  }

  return totalCostYearlyList;
};

export const getCSUVCleaningPerDay = (totalCSUVCleanCycle, days) => {
  return totalCSUVCleanCycle / days;
};

// K17*F33*C8*365)/60/60
export const getTotalTimePerYear = (
  csuvCleaningCyclePerDay,
  avgTimeCleaningEvent,
  numOfDevice
) => {
  return (
    (csuvCleaningCyclePerDay * avgTimeCleaningEvent * numOfDevice * 365) /
    60 /
    60
  );
};

export const getCSUVAnnualCostPerYear = (costLabour, totalTime) => {
  return costLabour * totalTime;
};

export const getUnitLabourByYear = (unit, bulb, part, labour) => {
  const csuvTotalYearly = [];
  const csuvOnlyYearly = [];

  unit.forEach((u, index) => {
    const prvYear = index === 0 ? 0 : csuvTotalYearly[index - 1];
    const sum = u + bulb[index] + part[index] + labour[index];
    csuvOnlyYearly.push(sum);
    csuvTotalYearly.push(sum + prvYear);
  });

  return { csuvTotalYearly, csuvOnlyYearly };
};

export const getCostPerDeviceWithCSUV = (deviceSanitizedDaily, year3CSUV) => {
  if (!deviceSanitizedDaily || !year3CSUV) return 0;

  const num = year3CSUV / (deviceSanitizedDaily * 365 * 3);

  return convertFloat(num);
};

export const getPaybackPeriod = (unit1yr, csuv2yr, wipe2yr) => {
  if (!csuv2yr || !wipe2yr) return 0;
  // =(J28)/(K42-K35)*12
  const num = (unit1yr / (wipe2yr - csuv2yr)) * 12;
  return num;
};

export const getYearPercent = (yearsList, csuvYearly, wipeYearly, unit1yr) => {
  return yearsList.map((yr) => {
    const year = yr - 1;
    const minus = wipeYearly[year] - csuvYearly[year];
    const num = minus / unit1yr;

    return [`${yr} year`, convertFloat(num) * 100];
  });
};

export const getYearSavings = (wipeTotal, csuvTotal) => {
  return convertFloat(wipeTotal - csuvTotal);
};

// =C7*(365/(C6-C5))*5*(1/453.6)
// =totalCSUVCleanCycle*(365/days)*5*(1/453.6)
export const getEnvironmentalImpact = (totalCSUVCleanCycle, days) => {
  const num = totalCSUVCleanCycle * (365 / days) * 5 * (1 / 453.6);

  return convertFloat(num);
};
