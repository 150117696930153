import { useEffect } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MUITooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { AiOutlineGroup } from 'react-icons/ai';
import { BiChip } from 'react-icons/bi';
import { useRecoilState, useRecoilValue } from 'recoil';

import { filterState } from '../../recoil/atom/filter';
import { comparisonState } from '../../recoil/atom/comparison';
import { groupToggleButtonStyles } from '../../styles/common/Button';
import { filterType, color } from '../../constants';

export default function GroupToggleButton({ entityController }) {
  const [showGroup, setShowGroup] = entityController;
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useRecoilState(comparisonState);
  const filterList = useRecoilValue(filterState);
  const classes = groupToggleButtonStyles();
  const isGroup = showGroup === filterType.groups;
  const isDevice = showGroup === filterType.devices;

  const onChange = (value) => {
    if (value) {
      setShowGroup(value);
    }
  };

  useEffect(() => {
    // only comparisons page will be implemented to show device
    // When user click device, filterOptions will set as all device list
    if (
      window.location.pathname.includes('comparisons') &&
      showGroup === filterType.groups &&
      !filterOptions?.id
    ) {
      setFilterOptions({
        ...filterOptions,
        filterOptions: {
          ...filterOptions.filterOptions,
          id: filterList?.devices.map((item) => item.id)
        }
      });
    }
  }, []);

  return (
    <ToggleButtonGroup
      size="small"
      value={showGroup}
      exclusive
      onChange={(event, value) => onChange(value)}
      className={classes.container}
    >
      <MUITooltip title={t('label.filterByDevice')} placement="top">
        <ToggleButton
          value={filterType.devices}
          className={isDevice ? classes.active : classes.noActive}
        >
          <AiOutlineGroup fill={isDevice ? color.white : color.darkBlue} />
          <span className={classes.text}>
            {showGroup === filterType.devices && t('label.filterBy')}
            {t('common.devices')}
          </span>
        </ToggleButton>
      </MUITooltip>

      <MUITooltip title={t('label.filterByGroup')} placement="top">
        <ToggleButton
          value={filterType.groups}
          className={isGroup ? classes.active : classes.noActive}
        >
          <BiChip fill={isGroup ? color.white : color.darkBlue} />
          <span className={classes.text}>
            {showGroup === filterType.groups && t('label.filterBy')}
            {t('common.groups')}
          </span>
        </ToggleButton>
      </MUITooltip>
    </ToggleButtonGroup>
  );
}
