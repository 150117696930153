// network connectivity, and data policies
export default [
  // setup
  {
    q: 'How do I create an account?',
    a: 'Reach out to your CleanSlate rep with the user’s full name and email and we will happily create an account for you. You can also email product@cleanslateuv.com! '
  },
  {
    q: 'How do I use the CleanCloud?',
    a: 'We offer comprehensive resources, including demo videos (found here) and step-by-step guides. For individual training and onboarding, contact your account manager.'
  },
  {
    q: 'What are the prerequisites to use?',
    a: `To use the app: <br/>
      1)    An account in our system <br/>
      2)    A smartphone with a compatible operating system<br/><br/>
      To use the configuration abilities:<br/>
      1)    Have Bluetooth enables and able to scan for nearby devices<br/>
      2)    Disable “low power” / “battery saver” mode<br/>
      3)    Provide the app permission to refresh in the background<br/>
      4)    Provide the app permission to use cellular data`
  },
  {
    q: 'Who can use my software?',
    a: `People with individual access within your company. Your data cannot be accessed by anyone that is not part of your company or has not been granted access. <br/>
      <br/>*The only exception to this is the CleanSlate technicians, which view usage information of the device to verify a successful connection of the CleanSlate and keep an eye on device diagnositics to ensure you’re getting the most use out of your devices!`
  },
  {
    q: 'How do I change my network?',
    a: 'Follow the mobile app’s process to connect. The process used to connect the CleanSlate allows for the WiFi network to be changed.'
  },
  {
    q: 'How much work does CleanCloud require from me?',
    a: `CleanCloud reduces your workload as the software will alert you and us about errors and maintenance. This allows us to be proactive and resolve issues quickly.<br/>
      <br/>Furthermore, we will do pre-configurations and assist with the setup process.`
  },
  {
    q: 'How reliable is your system?',
    a: `Our software is hosted with Amazon Web Services (AWS), which means we benefit from their excellent uptime standards for hosting applications and databases. We follow gated processes before deploying new software to help prevent downtime.<br/>
      <br/>In the event of unexpected outages, our team acts accordingly to restore production as quickly as possible. Our databases are protected by AWS to prevent the loss of data in this situation.`
  },
  {
    q: 'How do I disconnect?',
    a: `Disconnecting is strongly discouraged, unless it is during network configurations or maintenance activites. Disconnecting disables all software features, including proactive monitoring and dashboard alerts.<br/>
      <br/>To disconnect your CleanSlate from the network, use your mobile app to connect to the CleanSlate using Bluetooth. Next, disconnect the app from the CleanSlate. Your CleanSlate is no longer connected to the internet.`
  },

  // Data Security
  {
    q: 'Why do you need access to my network?',
    a: 'To connect the CleanSlates to the internet. Network access allows CleanSlates to send packets of non-personally-identifying data to our Amazon Web Services (AWS) Cloud IoT system. The data is stored within our database and presented to end-users in a permission-based web portal and mobile app.'
  },
  {
    q: 'Who has access to my data?',
    a: 'As the customer, you and your team members with accounts have access to your data. As well, CleanSlate UV has access to your information, which allows us to provide real-time monitoring, proactive alerts, and help you get the most out of your investment.'
  },
  {
    q: 'What do you do with my data?',
    a: 'The information captured is translated into a data packet (~10kB of data per cycle), transmitted to the Cloud and stored securely. Customers only have access to their own data, and access is protected by individual accounts. CleanSlate UV also has access to this data, but we do not sell or share our customers’ data.'
  },
  {
    q: 'What data is captured?',
    a: 'The CleanSlate does not capture personally identifying information about the user or the items within. We track hardware activities, such as how long the cycle ran and if the stop button was pressed. The CleanSlate also sends warnings and alerts, which allow us to display this in the portal and apps so they can be acted on.'
  },
  {
    q: 'How secure is your system?',
    a: 'We take data security seriously. Our software and mobile apps are powered by Amazon Web Services (AWS) products, allowing us to benefit from Amazon’s security experience. For more information, please refer to: Amazon Cognito - User Management security - Amazon Cognito & Application security - Amazon RDS.'
  },
  {
    q: 'How and where do you store my data?',
    a: 'The CleanSlate UV portal and mobile apps are powered behind the scenes by Amazon Web Services (AWS), including using their robust database and storage capabilities. The Amazon servers that are used by CleanSlate are located in the US-East region. If your organization has specific requirements for data storage & hosting, please contact us. How long do I have access to my data if we need to cancel our agreement?When your agreement ends, you and your team will no longer have access to the web portal or mobile apps. You may request a full export of your CleanSlate activity data within 30 days of your agreement termination date, which we will provide to you in a timely manner.'
  },
  {
    q: 'How long do I have access to my data if we need to cancel our agreement?',
    a: 'When your agreement ends, you and your team will no longer have access to the web portal or mobile apps. You may request a full export of your CleanSlate activity data within 30 days of your agreement termination date, which we will provide to you in a timely manner.'
  },
  {
    q: 'What happens to the data if my CleanSlate’s serial number changes (ie. During a repair)?',
    a: 'The data sent to the Cloud does not get deleted or removed if the serial number of your CleanSlate changes. We can restore the historical information against your new device serial number, and you will see this information in your portal and app.'
  },
  {
    q: 'What analytics trackers or programs are in place?',
    a: `In our software, we use different tools that help us improve your experience with it. Analytics information uses anonymous identifiers, which means we do not know the personal identity of the user.<br/>
      <br/>The products we choose for analytics are carefully evaluated for privacy, ensuring the privacy of our data, and our users as well. We currently use Fullstory and Segment for our web portal and mobile apps.`
  }
];
