import React from 'react';
import { MdCalculate } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// components
import { ContentHeading } from '../../components/common/Title';
import { getRoiFilterOptionByUser } from '../../recoil/selector/roiCalculator';
import { userState } from '../../recoil/state';
import Button from '../../components/Button/Button';
import CalculatorForm from '../ROICalculator/CalculatorForm';
import Card from '../../components/common/Card';
import EstimatorForm from './EstimatorForm';
import EstimatorResult from './EstimatorResult';
import Filter from '../../components/Filter/Filter';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import useCalculatedResult from '../../hooks/useCalculatedResult';
import useCalculator from '../../hooks/useCalculator';
import useEstimator from './useEstimator';
import withContent from '../../components/hoc/withContent';
import useSegment from '../../hooks/useSegment';
import { track } from '../../helper/segment';
import fullStory from '../../segment';

// etc
import paths from '../../paths';
import { buttonStyle } from '../../constants';

function Estimator() {
  const { t } = useTranslation();

  // user
  const user = useRecoilValue(userState);
  const { isRiker } = user.getUserPermission();
  const initFilterOption = useRecoilValue(getRoiFilterOptionByUser);
  const history = useHistory();
  const { callSNSLog } = useSegment('estimator');

  const {
    // func
    handleSubmit: calculatorSubmit,

    // val
    roiCalculator,
    submitted
    // customerName
  } = useCalculator(initFilterOption);

  // business
  const {
    handleCostPerUnit,
    handleNumUnit,
    handleReset,

    estimatorData,
    costPerUnit,
    costCSUV
  } = useEstimator({ roiCalculator });

  const roiCalculated = useCalculatedResult({
    roiCalculator: estimatorData,
    numOfDevice: estimatorData.estNumUnit,
    estNumUnit: estimatorData.estNumUnit,
    costPerUnit: estimatorData.estNewCostPerUnit
  });

  const headingOpt = {
    title: t('menuName.expansion'),
    leftButton: isRiker && (
      <IconButtonContainer>
        <Button
          description={'pages.savings.btnRoiCalculator'}
          icon={<MdCalculate />}
          onClick={() => history.push(paths.roiCalculator)}
        />
      </IconButtonContainer>
    )
  };

  const filterCompOpt = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 4, md: 6, sm: 6, xs: 6 },
    hasTitle: false,
    largeHorizontal: true
  };

  if (!isRiker) {
    history.push(paths.roiCalculator);
  }

  const handleSubmit = async (values) => {
    await calculatorSubmit(values);

    // track calculate
    track(fullStory.estimator.calculated);
  };

  React.useEffect(() => {
    callSNSLog({
      type: 'WIPES_SAVINGS',
      data: user?.email
    });
  }, []);

  return (
    <>
      <ContentHeading {...headingOpt} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isRiker && (
            <Card style={buttonStyle.filledBlue} cardStyle={{ height: '100%' }}>
              <Filter {...filterCompOpt} listOptions={{ customer: true }} />
            </Card>
          )}
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CalculatorForm
              isEstimator={true}
              onSubmit={handleSubmit}
              roiCalculator={roiCalculator}
            />
          </Card>
        </Grid>

        {/* left */}
        <Grid item md={5} xs={12}>
          <Card>
            <EstimatorForm
              costCSUV={costCSUV}
              costPerUnit={costPerUnit}
              handleReset={handleReset}
              handleCostPerUnit={handleCostPerUnit}
              handleNumUnit={handleNumUnit}
              numOfDevice={estimatorData.estNumUnit}
            />
          </Card>
        </Grid>
        <Grid item md={7} xs={12}>
          <EstimatorResult
            submitted={submitted}
            calculated={roiCalculated}
            roiCalculator={roiCalculator}
          />
        </Grid>

        {process.env?.REACT_APP_STAGE === 'dev' ? (
          <div>
            ***Test purpose <br />
            Production does not show this JSON text
            <br />
            {JSON.stringify(roiCalculator)}
          </div>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
}

export default withContent(Estimator);
