import { TiWarning } from 'react-icons/ti';
import {
  RiAlarmWarningFill,
  RiArrowDownSFill,
  RiArrowUpSFill
} from 'react-icons/ri';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { fleetHealthItemStyle } from '../../styles/Dashboard';
import { color, alertTypes } from '../../constants';

export default function FleetHealthItem({
  number,
  type,
  description,
  showController,
  showDetail
}) {
  const { t } = useTranslation();
  const fleetHealthItemClasses = fleetHealthItemStyle();
  const [showAlert, setShowAlert] = showController;

  const renderImage = () => {
    let bgColor = color.white;
    let image = null;

    switch (type) {
      case alertTypes.warning:
        bgColor = color.mustardYellow;
        image = TiWarning;
        break;

      default:
        bgColor = color.red;
        image = RiAlarmWarningFill;
        break;
    }

    return { bgColor, image };
  };

  const ArrowIcon = showDetail ? RiArrowUpSFill : RiArrowDownSFill;
  const { bgColor, image: Icon } = renderImage();

  const onClick = () => {
    let val = '';
    if (!showAlert || showAlert !== type) {
      val = type;
    } else if (showAlert === type) {
      val = '';
    }

    setShowAlert(val);
  };

  return (
    <div
      className={fleetHealthItemClasses.fleetItemContainer}
      style={{ backgroundColor: bgColor }}
      data-cy="fleetHealthItem"
    >
      <div className={fleetHealthItemClasses.fleetItemStatusContainer}>
        <Icon color={color.white} fill={color.white} size={40} />
        <div className={fleetHealthItemClasses.description}>
          {t(description)}
        </div>
      </div>
      <div className={fleetHealthItemClasses.statusContainer}>
        <div className={fleetHealthItemClasses.number}>{number}</div>
      </div>

      {number > 0 && (
        <Button
          className={fleetHealthItemClasses.more}
          onClick={onClick}
          color="inherit"
          size="small"
          data-cy="fleetItemMoreBtn"
        >
          <span className={fleetHealthItemClasses.moreText}>
            {t('common.moreDetails')}
          </span>{' '}
          <ArrowIcon size="1em" fill={color.white} />
        </Button>
      )}
    </div>
  );
}
