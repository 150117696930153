import AutocompleteSelect from '../../components/Input/AutocompleteSelect';
import Card from '../../components/common/Card';
import TextField from '../../components/Input/TextField';
import { getInputProps } from '../../helper/admin/page';

export default function Form({
  errors,
  values,
  isEdit,

  handleChange,
  setFieldValue,
  inputPropData
}) {
  const inputProps = getInputProps({
    inputProps: inputPropData,
    errors,
    values
  });

  return (
    <Card>
      <TextField {...inputProps.partner_name} onChange={handleChange} />

      <TextField {...inputProps.partner_address} onChange={handleChange} />

      <AutocompleteSelect
        {...inputProps.tz}
        isInvalid={!!errors?.tz}
        disabled={isEdit}
        onChange={(value) => setFieldValue('tz', value)}
      />
    </Card>
  );
}
