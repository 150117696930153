import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

// comp
import MenuItem from './MenuItem';

export default function MenuItemWithSubMenu(props) {
  const { item } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const history = useHistory();

  const onParentMenuClick = () => {
    if (item.link) history.push(item.link);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MenuItem onClick={onParentMenuClick} {...item} isOpen={isOpen} />

      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        data-cy={`collapseMenuContainer${t(props.item.text)}`}
      >
        <List component="div" disablePadding>
          {item.subMenuArray.map((subItem, subIndex) => (
            <MenuItem key={subIndex} {...subItem} isSubMenu={true} />
          ))}
        </List>
      </Collapse>
    </>
  );
}

MenuItemWithSubMenu.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
