import React from 'react';
import Grid from '@material-ui/core/Grid';

import SliderWithAmount from '../../components/pages/ROICalculator/SliderWithAmount';
import Button from '../../components/Button/Button';

import { renderMaskedText, renderMask } from '../../helper/roi/roi';
import { estimatorFormStyles } from '../../styles/ROICalculator';

import { roi } from '../../constants';

export default function EstimatorForm({
  handleCostPerUnit,
  handleNumUnit,
  handleReset,

  costPerUnit,
  numOfDevice
}) {
  const classes = estimatorFormStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.resetContainer}>
              <Button
                data-testid="btn-estimator-reset"
                description="Reset"
                type="button"
                onClick={handleReset}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <SliderWithAmount
              data-testid="estimator-costPerUnit"
              max={roi.quotePerUnit.max}
              min={roi.quotePerUnit.min}
              onChange={handleCostPerUnit}
              step={1}
              title="Quote per unit"
              unit="$"
              value={costPerUnit}
              marks={[
                {
                  value: roi.quotePerUnit.min,
                  label: `$ ${renderMaskedText({
                    val: `${roi.quotePerUnit.min}`,
                    mask: renderMask('amount'),
                    type: 'amount'
                  })}`
                },
                {
                  value: roi.quotePerUnit.max,
                  label: `$ ${renderMaskedText({
                    val: `${roi.quotePerUnit.max}`,
                    mask: renderMask('amount'),
                    type: 'amount'
                  })}`
                }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <SliderWithAmount
              data-testid="estimator-newNumOfDevice"
              max={roi.newUnit.max}
              min={roi.newUnit.min}
              onChange={handleNumUnit}
              step={1}
              title="Number of new units"
              value={numOfDevice}
              marks={[
                {
                  value: roi.newUnit.min,
                  label: `${renderMaskedText({
                    val: `${roi.newUnit.min}`,
                    mask: renderMask('amount'),
                    type: 'amount'
                  })}`
                },
                {
                  value: roi.newUnit.max,
                  label: `${renderMaskedText({
                    val: `${roi.newUnit.max}`,
                    mask: renderMask('amount'),
                    type: 'amount'
                  })}`
                }
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
