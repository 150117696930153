import { atom, selector } from 'recoil';
import { admin } from '../key';

import { userState } from '../state';

const getDefaultAdmin = selector({
  key: 'getDefaultAdmin',
  get: ({ get }) => {
    const user = get(userState);
    const { isPicardAdmin, isRiker, isSuperRikerAdmin } =
      user?.getUserPermission();

    let id;

    if (isPicardAdmin) {
      id = user.customer_uid;
    }

    return {
      filterOptions: { id },
      permission: {
        read: true,
        save: isPicardAdmin || isSuperRikerAdmin,
        edit: isPicardAdmin || isRiker,
        delete: isPicardAdmin || isRiker
      }
    };
  }
});

export const partnerState = atom({
  key: admin.partnerState,
  default: getDefaultAdmin
});

export const customerState = atom({
  key: admin.customerState,
  default: getDefaultAdmin
});

export const groupState = atom({
  key: admin.groupState,
  default: getDefaultAdmin
});

export const userAdminState = atom({
  key: admin.userState,
  default: getDefaultAdmin
});
