import paths from '../paths';
import { sortByObjectText } from './filterOptions';

/**
 * @description Returning start and end date depends on static date option
 * @param {Object} object - data
 * @param {string} object.value - selected value from FilterSelect
 * @param {Object} object.user - user object
 * @param {string} object.type - select type
 * @return {Object} selected value name, id, parent
 */
export function setKeyValueByUserType({ value, user, type }) {
  let name = '';
  let id = '';
  let parent = '';
  let partnerId = '';
  let customerId = '';

  const hasAllOption =
    window.location.pathname === paths.home ||
    window.location.pathname === paths.dashboard ||
    window.location.pathname === paths.devices;

  partnerId = hasAllOption ? '' : 'PARTNER';
  customerId = hasAllOption ? '' : user?.partner_uid;

  if (type === 'partners' && value === 'allPartners')
    return { name: 'allPartners', id: partnerId };
  if (type === 'customers' && value === 'allCustomers')
    return { name: 'allCustomers', id: customerId };
  if (type === 'partners') name = 'partner';

  if (type === 'customers') {
    name = 'customer';
    parent = 'parent_partner';
  }

  if (type === 'groups') {
    name = 'group';
    parent = 'parent_customer';
  }
  if (type === 'device') name = 'device';

  id = value;

  return { name, id, parent };
}

/**
 * @description helper function to set new filter list
 * @param {Object} object - data
 * @param {string} object.event - input event
 * @param {Object} object.formik - formik object
 * @param {string} object.updateFilterOptions - call function from useFilterList
 * @param {string} object.user - user information
 * @param {string} object.type - filter type
 */
export function formFilterOnChange({
  event,
  formik,
  updateFilterOptions,
  user,
  type
}) {
  const { name, id } = setKeyValueByUserType({
    value: event.target.value,
    user,
    type
  });
  updateFilterOptions(name, id);
  formik.handleChange(event);
}

/**
 * @description Returning formatted filter list by key
 * @param {Object} object - data
 * @param {string} object.filterList - returned list value from useFilterList
 * @param {Object} object.key - target key
 * @param {string} object.parentKey - parent key to filter list under that key
 * @param {string} object.parentValue - parent key's value
 * @return {Array} formatted list
 */
export function getFilterOptionByKey({
  filterList,
  key,
  parentKey,
  parentValue,
  excludeId
}) {
  let formattedList = [];

  // When parent key exist (example - customer)
  if (parentKey) {
    formattedList = filterList?.[`${key}`]?.map((item) => {
      if (item[parentKey] === parentValue) {
        return { value: item.id, text: item.name };
      }

      return { value: '', text: '' };
    });
  }

  // When parent has no selected key (example - partner | group management delete)
  else {
    formattedList = filterList?.[`${key}`]?.map((item) => {
      if (item.id === excludeId) {
        return null;
      }

      return { value: item.id, text: item.name };
    });
  }

  return formattedList
    ? formattedList
        .filter((item) => item?.value && item?.text)
        .sort(sortByObjectText)
    : [];
}
