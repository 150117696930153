import copyrightStyles from '../../styles/common/Copyright';
import { version } from '../../../package.json';

export default function Copyright() {
  const classes = copyrightStyles();

  return (
    <>
      <div className={classes.copyrightContainer}>
        Copyright © {new Date().getFullYear()}
        <br />
        CleanSlateUV
      </div>

      <div className={classes.version}>V {version}</div>
    </>
  );
}
