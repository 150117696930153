import { useState } from 'react';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import * as authLib from '../../lib/auth';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/common/ErrorMessage';
import Modal from '../../components/common/Modal';
import PasswordField from '../../components/Input/PasswordField';
import PasswordRequirement from '../../components/Input/PasswordRequirement';

import {
  accountPasswordSchema,
  confirmPasswordValidation
} from '../../helper/validation';
import { inputType } from '../../constants';

export default function PasswordPopup({ open, error }) {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [isOpen, setIsOpen] = open;
  const [errorMessage, setErrorMessage] = error;
  const [confirmMessage, setConfirmMessage] = useState('errorMessage.password');
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

  const passwordFormik = useFormik({
    validateOnMount: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: accountPasswordSchema
  });

  const { currentPassword, newPassword, confirmNewPassword } =
    passwordFormik.values;

  const onChangePassword = async (event) => {
    event.preventDefault();

    if (!passwordFormik.isValid) {
      setShowError(true);
      return null;
    }

    if (newPassword !== confirmNewPassword) {
      setShowError(true);
      setConfirmMessage('errorMessage.passwordNotMatch');

      return null;
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(() => {
        setIsOpen(false);
        setErrorMessage('');
      })
      .catch((cognitoError) => {
        setErrorMessage(t(authLib.cognitoErrorHelper(cognitoError)));
      });

    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
        setErrorMessage('');
      }}
      title={'modal.newPassword.title'}
    >
      <form onSubmit={onChangePassword}>
        <Grid container>
          <Grid item {...columns}>
            <PasswordField
              cy="newPassword"
              name="currentPassword"
              label="label.currentPassword"
              isRequired={true}
              onChange={passwordFormik.handleChange}
              isInvalid={passwordFormik?.errors.currentPassword && showError}
              value={passwordFormik.values.currentPassword}
              errorMessage={'errorMessage.password'}
            />
          </Grid>
          <Grid item {...columns}>
            <PasswordField
              cy="newPassword"
              name="newPassword"
              label="label.newPassword"
              isRequired={true}
              onChange={passwordFormik.handleChange}
              isInvalid={passwordFormik?.errors.newPassword && showError}
              value={passwordFormik.values.newPassword}
              errorMessage={'errorMessage.password'}
            />

            <PasswordRequirement />
          </Grid>
          <Grid item {...columns}>
            <PasswordField
              cy="newPassword"
              name="confirmNewPassword"
              label="label.newPasswordConfirm"
              isRequired={true}
              onChange={passwordFormik.handleChange}
              isInvalid={
                (passwordFormik?.errors.confirmNewPassword ||
                  confirmPasswordValidation(newPassword, confirmNewPassword)) &&
                showError
              }
              value={passwordFormik.values.confirmNewPassword}
              errorMessage={confirmMessage}
            />
          </Grid>
        </Grid>
        <Grid item {...columns}>
          <ErrorMessage message={t(errorMessage)} />
        </Grid>
        <Grid item {...columns}>
          <Button
            description="label.update"
            fullWidth={true}
            type={inputType.submit}
          />
        </Grid>
      </form>
    </Modal>
  );
}
