import { useRecoilValue } from 'recoil';

import { filterState } from '../../recoil/atom/filter';
import { apiActionTypes, connectivityType } from '../../constants';
import {
  getRefinedReq,
  getAssignedEntityFilter
} from '../../helper/admin/page';

export default function useCustomerData(props) {
  const data = props?.data;
  const isSuperRikerAdmin = props?.user?.getUserPermission()?.isSuperRikerAdmin;

  const filterList = useRecoilValue(filterState);
  const partnerUid = isSuperRikerAdmin
    ? getAssignedEntityFilter({
        list: filterList?.partners,
        name: 'id',
        uid: data.partner_uid
      })?.[0]
    : props?.user?.partner_uid;
  const connectivity = props?.data?.connectivity || connectivityType.wifi;

  const pairs = {
    customer_uid: 'customerUid',
    customer_name: 'customerName',
    partner_uid: 'partnerUid',
    customer_address: 'customerAddress',
    connectivity: 'connectivity',
    tz: 'timezone',
    tier_type: 'tierType',
    tier_status: 'tierStatus'
  };

  const inheritedData =
    data && Object.keys(data).length
      ? // edit
        {
          ...data,
          partner_uid: partnerUid,
          connectivity
        }
      : // create
        { partner_uid: partnerUid, connectivity };

  const getDeleteReq = (uid) => {
    return { type: apiActionTypes.DELETE, customerUid: uid };
  };

  const getEditReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    return reqData;
  };

  const getCreateReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    return reqData;
  };

  return {
    inheritedData,

    // func
    getDeleteReq,
    getEditReq,
    getCreateReq
  };
}
