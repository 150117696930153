export default [
  // general
  {
    q: 'What is CleanSlate UV?',
    a: 'CleanSlate UV is a simple, fast, and effective solution to reduce the spread of germs on mobile devices. It uses UV-C light to kill up to 99.999% of germs on cell phones, tablets, keys and other handheld items without the use of harmful chemicals. '
  },
  {
    q: 'How Does the CleanSlate Work?',
    a: "In just 20 seconds, CleanSlate inactivates harmful bacteria and viruses. It emits UV-C light onto your device which damages the pathogen's DNA/RNA, which inhibits them from functioning, and ultimately, stop spreading. Once a device in placed in a CleanSlate, the tray moves to the sanitization chamber at the back of the machine where six (6) bulbs then irradiate your device with 360 degrees of UV-C light, inactivating up to 99.999% of viruses and bacteria."
  },
  {
    q: 'What can the CleanSlate Sanitize?',
    a: `CleanSlate can sanitize numerous daily essentials such as phones, tablets, watches, ID badges, walkies-talkies, keys, cameras, credit cards, pens, pencils and much more. <br/>
      <br/>CleanSlate should ONLY be used sanitize non-critical devices and personal items with hard, non-porous surfaces. <br/>
      <br/>It should NOT be used to sanitize fabrics, food, or porous items, as we are not able to guarantee efficacy.`
  },
  {
    q: 'Why is Device Hygiene Important? ',
    a: 'Phones and tablets are like a third hand we never wash. They are constantly touched but rarely cleaned and a proven reservoir for bacteria and viruses. This undermines hand hygiene and allows for cross contamination. Sanitizing mobile devices when disinfecting your hands reduces the risk of transmitting harmful pathogens and breaks the chain of infection. '
  },
  {
    q: 'What Germs Does CleanSlate Kill?',
    a: `CleanSlate has proven efficacy against some of the most difficult to kill pathogens such as: MRSA, C. difficile and E. coli. The efficacy has been validated with EPA recommended testing protocols. <br/>
      <br/>*Recently, CleanSlate UV became the first mobile device sanitizer to make a claim, supported by test results, against SARS-CoV-2, the cause of COVID-19. The product achieved a 99.995% kill rate in just 20 seconds.`
  },
  {
    q: 'Is the CleanSlate Safe for My Device?',
    a: 'Yes. UV light is the best solution for sanitizing electronics, as it does not damage expensive touchscreens, eliminates human error, and is the fastest, most effective method available. '
  },

  // use
  {
    q: 'What is the efficacy of the CleanSlate against different types of pathogens?',
    a: 'The CleanSlate has been validated by third-party labs to have pathogenic kill rates ranging from 99.97% to more than 99.999%, depending on the pathogen. For detailed information about efficacy of CleanSlate please contact us. '
  },
  {
    q: 'Should devices be cleaned before sanitization?',
    a: 'It is highly recommended to clean/wipe devices before being places in the Clean Slate, as UV-C light cannot penetrate through dirt. Wiping is supplementary to the sanitization process and should not replace sanitization with the CleanSlate. However, we do complete efficacy testing using soil which mimics the oils typically found on these devices. '
  },
  {
    q: 'How is the user affected by repeated use of the CleanSlate?',
    a: 'Not at all. The CleanSlate’s design ensures complete enclosure of the sanitization chamber preventing the exposure of UV-C light to users. '
  },
  {
    q: 'Can children use the CleanSlate?',
    a: 'The CleanSlate is classified as lab equipment and should not be used by those under 14. '
  },
  {
    q: 'How Many Items can I Place Inside the CleanSlate at once?',
    a: `UV-C cleans by ‘line of sight’ meaning that as long as the light can “see” the items, it can kill germs on them. Therefore, you can place as many objects as you would like into the CleanSlate chamber as long as there is at least one (1) inch between the items for the light to reach all corners. Items cannot be stacked on top of each other as this will block the light. <br/>
      <br/>For example, the CleanSlate can disinfect 2-4 smartphones at once, 1 iPad or 6 Vocera communication devices.<br/>
      <br/>Tray Dimensions (Interior): L 9.5” x W 12.7” x H 4.5”`
  },
  { q: 'How long does it take?', a: '20 seconds.' }
];
