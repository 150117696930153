import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/state';

import { ContentHeading } from '../components/common/Title';
import Card from '../components/common/Card';
import CardContentLoader from '../components/Loader/CardContentLoader';
import Filter from '../components/Filter/Filter';
import TableContainer from '../components/common/Table/TableContainer';

import { buttonStyle } from '../constants';

function LayoutOverview({ children }) {
  return <>{children}</>;
}

function Contents({ filterOptions, loading, tableProps }) {
  const user = useRecoilValue(userState);
  const { isPicardAdmin } = user?.getUserPermission();

  // static
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

  return (
    <Grid container spacing={2}>
      {!isPicardAdmin && (
        <Grid item {...columns}>
          <Card style={buttonStyle.filledBlue}>
            <Filter {...filterOptions} listOptions={{ customer: true }} />
          </Card>
        </Grid>
      )}

      <Grid item {...columns}>
        {/* Section Table */}
        {!loading ? (
          <TableContainer tableProps={tableProps} />
        ) : (
          <CardContentLoader />
        )}
      </Grid>
    </Grid>
  );
}

LayoutOverview.Heading = (props) => <ContentHeading {...props} />;
LayoutOverview.Contents = Contents;

export default LayoutOverview;
