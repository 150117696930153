import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import { filterState } from '../../recoil/atom/filter';
import { userState } from '../../recoil/state';
import useAdminRoute from '../pages/Admin/hooks/useAdminRoute';
import useManage from '../pages/Admin/hooks/useManage';
import usePermission from '../pages/Admin/hooks/usePermission';
import withContent from './withContent';

const withAdminManage = (type) => (Component) => {
  return withContent((props) => {
    // third party
    const { t } = useTranslation();
    const location = useLocation();

    // recoil
    const user = useRecoilValue(userState);
    const [filterList, setFilterList] = useRecoilState(filterState);

    // admin hook
    const { data, getData, loading, onDelete, onEdit, onSave } = useManage(
      type,
      [filterList, setFilterList]
    );
    const { onClose, onRowSave, onRowClick } = useAdminRoute(type);
    const { hasDelete, hasEdit, isEdit, hasCreate } = usePermission({
      user,
      type,
      inheritedData: location?.state?.data
    });

    const manageProps = {
      t,
      location,

      // recoil
      user,
      filterList,
      setFilterList,

      // manage func
      getData,
      onDelete,
      onEdit,
      onSave,

      data,
      loading,

      // route func
      onClose,
      onRowSave,
      onRowClick,

      // permission
      hasCreate,
      hasDelete,
      hasEdit,
      isEdit
    };

    return <Component {...props} {...manageProps} />;
  });
};

export default withAdminManage;
