import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import MaskedInput from 'react-text-mask';

// components
import Tooltip from '../common/Tooltip';

// etc
import textFieldStyles from '../../styles/Input/TextField';
import tooltipStyles from '../../styles/common/Tooltip';

function CustomMaskField(props) {
  const { inputRef, type, ...other } = props;

  const renderMask = () => {
    switch (type) {
      /* 
        phone number mask that does not allow user to use string value.
        /\d/ + digit number
        /[1-9]/ not allowing number 0
      */
      default:
        return [
          /[1-9]/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ];
    }
  };

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={renderMask()}
      guide={false}
      keepCharPositions={false}
    />
  );
}

export default function MaskedTextField(
  {
    cy,
    label,
    lg,
    md,
    onChange,
    sm,
    xs,
    type,
    value,
    helperText,
    isInvalid,
    errorMessage,
    tooltip,
    onClick,
    name,
    margin,
    placeholder,
    shrink,
    readOnly
  },
  props
) {
  const textFieldClasses = textFieldStyles();
  const autoComplete = props.autoComplete ? 'off' : 'new-password';
  const muiHelperText = isInvalid ? errorMessage : helperText;
  const tooltipClasses = tooltipStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      className={textFieldClasses.container}
      onClick={onClick}
    >
      {tooltip && (
        <div className={tooltipClasses.tooltipContainer}>
          <Tooltip message={t(tooltip)} />
        </div>
      )}
      <MUITextField
        autoComplete={autoComplete}
        className="fs-mask"
        name={name}
        data-cy={cy}
        error={isInvalid}
        fullWidth
        helperText={muiHelperText}
        label={t(label)}
        margin={margin || 'normal'}
        onChange={onChange}
        placeholder={t(placeholder)}
        type={type}
        value={value}
        variant="outlined"
        InputLabelProps={{
          shrink,
          classes: {
            root: textFieldClasses.root,
            focused: textFieldClasses.focused
          }
        }}
        InputProps={{
          readOnly,
          inputComponent: CustomMaskField,
          classes: {
            root: textFieldClasses.outlineRoot,
            focused: textFieldClasses.focused,
            notchedOutline: textFieldClasses.notchedOutline
          }
        }}
      />
    </Grid>
  );
}

MaskedTextField.propTypes = {
  label: PropTypes.string.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  sm: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
