/**
 * Business logic hook. depending on user type, should return different columns
 * @param {*} type admin type. eg. customer, partner ...
 * @param {*} userGroup user group
 * @returns
 */
export default function useTableColumn(type, userGroup) {
  // will be removed
  const filterColumn = {
    customer: {
      picardAdmin: ['tier_type', 'tier_status', 'connectivity'],
      picardUser: ['tier_type', 'tier_status', 'connectivity']
    }
  };

  // default columns
  const columns = {
    customer: [
      'customer_name',
      'contact_name',
      'tier_type',
      'tier_status',
      'connectivity',
      'contact_phone',
      'contact_email',
      'customer_address',
      'tz',
      'created_at',
      'view'
    ]
  };

  /**
   * return filtered columns or default columns
   */
  const getColumnByPermission = () => {
    if (!filterColumn[type]?.[userGroup]) return columns[type];

    return columns[type].filter((column) => {
      return !filterColumn[type][userGroup].includes(column);
    });
  };

  return getColumnByPermission();
}
