import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  container: {
    boxShadow: color.cardShadow
  },
  header: {
    fontFamily: fontFamily.semiBold,
    color: color.darkBlue,
    fontSize: '1rem'
  },
  detail: {
    fontFamily: fontFamily.regular,
    color: color.dimGrey,
    fontSize: '0.9rem'
  }
});
