import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import AllOption from './AllOption';
import NoList from './NoList';

import textFieldStyles, {
  multipleSelectWithChipStyles
} from '../../../styles/Input/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function useRenderValue({ list, name }) {
  const multiSelectChipClasses = multipleSelectWithChipStyles();
  const { t } = useTranslation();

  const renderChip = (selected) => {
    if (!selected?.length) return [];

    const refinedSelect = list.filter((l) => selected?.find((s) => s === l.id));

    return (
      <div className={multiSelectChipClasses.chipContainer}>
        {refinedSelect.map((item) => {
          return (
            <Chip
              key={item.value}
              label={t(item.name)}
              data-testid={`chip-${name}`}
              className={multiSelectChipClasses.chip}
            />
          );
        })}
      </div>
    );
  };

  const renderSingleVal = (selected) => {
    return t(list.find((l) => l.id === selected)?.name);
  };

  return { renderChip, renderSingleVal };
}

export default function MultipleSelect({
  errorMessage,
  handleChange,
  disabled,
  label = '',
  list = [],
  multiple,
  readOnly,
  value = [],
  name,

  noListMessage,
  allProps,
  visible = true
}) {
  if (!visible) return null;
  const textFieldClasses = textFieldStyles();
  const { t } = useTranslation();

  // helper  hook
  const { renderChip, renderSingleVal } = useRenderValue({
    list,
    name
  });

  const renderValueFunc = (isMultiple, type) => {
    if (!isMultiple) return renderSingleVal;

    switch (type) {
      default:
        return renderChip;
    }
  };

  // local var
  const inputLabel = t(label);
  const isInvalid = !!errorMessage;
  const muiHelperText = isInvalid ? errorMessage : '';

  const InputProps = {
    classes: {
      root: textFieldClasses.outlineRoot,
      focused: textFieldClasses.focused,
      notchedOutline: textFieldClasses.notchedOutline
    },
    readOnly
  };

  const InputLabelProps = {
    classes: {
      root: textFieldClasses.root,
      focused: textFieldClasses.focused
    }
  };

  const SelectProps = {
    renderValue: renderValueFunc(multiple, ''),
    onChange: handleChange,
    multiple,
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250
        }
      }
    }
  };

  if (!list.length) {
    return (
      <NoList inputLabel={inputLabel} name={name} message={noListMessage} />
    );
  }

  return (
    <Grid item xs={12}>
      <TextField
        disabled={disabled}
        error={isInvalid}
        fullWidth={true}
        helperText={t(muiHelperText)}
        InputLabelProps={InputLabelProps}
        inputProps={{ 'data-testid': `input-${name}` }}
        InputProps={InputProps}
        label={inputLabel}
        margin="normal"
        name={`multi-select-${name}`}
        select={true}
        SelectProps={SelectProps}
        value={value}
        variant="outlined"
      >
        <AllOption
          {...allProps}
          selectedAll={list.length === value.length}
          list={list}
        />

        {list.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id} {...item}>
              {multiple ? (
                <Checkbox checked={value?.indexOf(item.id) > -1} />
              ) : null}
              <ListItemText
                primary={t(item.name)}
                data-testid={`menu-${item.name}`}
              />
            </MenuItem>
          );
        })}
      </TextField>
    </Grid>
  );
}
