import { atom, selector } from 'recoil';
import { notification } from '../key';

import { userState } from '../state';

const getDefaultNotification = selector({
  key: 'getDefaultNotification',
  get: ({ get }) => {
    const user = get(userState);
    const { isPicardAdmin } = user.getUserPermission();

    let id;

    if (isPicardAdmin) {
      id = user.customer_uid;
    }

    return { filterOptions: { id } };
  }
});

export const notificationState = atom({
  key: notification.notificationState,
  default: getDefaultNotification
});
