import React, { useEffect } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { add } from 'date-fns';

// components
import { formatDate } from '../helper/date';
import { callAPI } from '../helper/api';
import { getPageData } from '../helper/page';
import { filterState } from '../recoil/atom/filter';

// etc
import { analyticsType, dateformat } from '../constants';

export default function useAnalytics(type) {
  const { state } = getPageData(window.location.pathname);
  const pageState = useRecoilValueLoadable(state);
  const filterList = useRecoilValueLoadable(filterState)?.contents;
  const filterOptions = pageState.contents?.filterOptions;
  const [resData, setResData] = React.useState({ chart: {}, table: [] });
  const [loading, setLoading] = React.useState(true);

  const id = filterOptions?.id;

  const getData = async () => {
    try {
      setLoading(true);

      const request = {
        type,
        id,
        startDate: formatDate(filterOptions?.startDate, dateformat.noTime),
        endDate: formatDate(
          add(filterOptions?.endDate, { days: 1 }),
          dateformat.noTime
        ),
        customerUid: filterList?.devices[0]?.parent_customer // device returns parent customer uid
      };

      const chartRequest = {
        ...request,
        type: type === analyticsType.average ? analyticsType.usage : type
      };

      const apiReqList = [
        callAPI(`/analytics-table`, request),
        callAPI(`/analytics`, chartRequest)
      ];

      const result = await Promise.all(apiReqList)
        .then((res) => res)
        .catch((err) => {
          throw new Error(err.message);
        });

      const chartData = {
        data: result[1].data?.data?.filter((item) => item), // filter data if it is null
        tz: result[1].data?.tz
      };

      const tableData = result[0].data.data;

      setResData({ chart: chartData, table: tableData });
      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (filterOptions?.id) getData();
  }, [filterOptions?.id, filterOptions?.startDate, filterOptions?.endDate]);

  return {
    loading,
    resData
  };
}
