import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import Grid from '@material-ui/core/Grid';

import Card from '../common/Card';
import Tooltip from '../common/Tooltip';
import { weekUsageStyles } from '../../styles/Analytics';

function WeekUsage({ data, columns, debug }) {
  if (!data || !data.length) return null;

  const classes = weekUsageStyles();
  const { t } = useTranslation();

  if (process.env?.REACT_APP_STAGE !== 'prod') {
    console.log('debug purpose: ', { data, debug });
  }

  return (
    <Grid item {...columns}>
      <Card cardStyle={{ height: '100%' }}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} className={classes.titleContainer}>
            <span className={classes.title}>
              {t('pages.analytics.thisWeekUsage')}
            </span>
            <Tooltip message={t('pages.analytics.weekUsageTooltip')} />
          </Grid>
          {data.map((item) => {
            return <WeekUsageItem key={item.day} {...item} />;
          })}
        </Grid>
      </Card>
    </Grid>
  );
}

function WeekUsageItem({ day, percent: usagePercent, current }) {
  const classes = weekUsageStyles();
  const { t } = useTranslation();

  const renderOption = () => {
    if (Number.isNaN(usagePercent)) {
      return {
        bgChange: '',
        icon: '',
        p: '',
        c: 'N/A'
      };
    }

    const percent = parseFloat(usagePercent * 100);
    const p = `${percent?.toFixed(0)}%`;
    const refinedPercent = percent?.toFixed(1).replace(/^-/g, '');

    if (refinedPercent === '0') {
      return {
        bgChange: classes.zero,
        icon: null,
        p: `${refinedPercent}%`,
        c: current?.toFixed(1)
      };
    }

    if (percent > 0) {
      return {
        bgChange: classes.increase,
        icon: <FiArrowUpRight size={12} stroke={'white'} />,
        p,
        c: current?.toFixed(1)
      };
    }

    return {
      bgChange: classes.decrease,
      icon: <FiArrowDownRight size={12} stroke={'white'} />,
      p,
      c: current?.toFixed(1)
    };
  };

  const { bgChange, icon, p, c } = renderOption();

  return (
    <Grid item className={classes.dayPercentContainer}>
      <div className={classes.dayTitleContainer}>
        <span className={classes.day}>{t(day)}</span>
        <span className={`${classes.percentContainer} ${bgChange}`}>
          {icon}
          <span className={classes.percent}>{p}</span>
        </span>
      </div>
      <div className={classes.numCurrent}>{c}</div>
    </Grid>
  );
}

export default withErrorBoundary(WeekUsage, {
  onError(error, info) {
    console.log(error.message, info);
  }
});
