import { Auth } from 'aws-amplify';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';

import constants, {
  tokens,
  cognitoGroup,
  tierGroup,
  languageCode as langCode
} from '../constants';
import { cognito } from '../config';

/**
 * Getting user pool - not to repeat code
 * @return {CognitoUserPool}
 */
function getCurrentUserPool() {
  const userPoolObject = {
    UserPoolId: cognito.userPoolId,
    ClientId: cognito.userPoolWebClientId
  };

  return new CognitoUserPool(userPoolObject);
}

/**
 * Send Cognito forgot password verification code
 * @param {Object} userData - user's cognito data
 * @return {Object} - result of sending Email, if it fails, it returns error message
 */
function getForgotPassword(userData) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (error) => {
        reject(error);
      }
    });
  });
}

/**
 * Set user local session - Session is for store user data and user does not have to sign in again
 * @param {CognitoSession} sessionObject - returned cognito User object 1. when user sign in. 2. created by CognitoUser class
 * @param {string} email - user's email
 * @return {void} Nothing - just store user's session in LocalStorage and Cognito Session
 */
export function setUserSession(sessionObject, email) {
  const { accessToken, idToken, refreshToken } = sessionObject;
  let languageCode = localStorage.getItem('lang');

  if (!languageCode) languageCode = constants.languageCode.en;

  localStorage.setItem(tokens.accessToken, accessToken.jwtToken);
  localStorage.setItem(tokens.idToken, idToken.jwtToken);
  localStorage.setItem(tokens.refreshToken, refreshToken.token);
  localStorage.setItem('lang', languageCode);

  const localSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: idToken.jwtToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken.token }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken.jwtToken })
  });

  const localUser = new CognitoUser({
    Username: email,
    Pool: Auth.userPool,
    Storage: Auth.userPool.storage
  });

  localUser.setSignInUserSession(localSession);
}

/**
 * Reset user's password and sign in immediately
 * @param {Object} userObject - User's object including email, verificationCode, new password - destructured
 * @return {Object} if it is error, it returns error get caught component try catch, if it success, returns authenticated and cognito user
 */
export const resetPassword = async ({
  email,
  verificationCode,
  newPassword
}) => {
  const userPool = getCurrentUserPool();
  const userData = {
    Username: email,
    Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure: (error) => {
        reject(error);
      },
      onSuccess: async () => {
        const newCognitoUser = await Auth.signIn(
          email?.toLowerCase(),
          newPassword
        );
        setUserSession(newCognitoUser.signInUserSession, email);
        resolve({ isAuthenticated: true, cognitoUser: newCognitoUser });
      }
    });
  });
};

/**
 * User sign in
 * @param {Object} userObject - User's object including email, password - destructured
 * @param {Object} userObject.email - User's email
 * @param {Object} userObject.password - User's password
 * @return {Object} returns authenticated status and cognito user
 */
export const signIn = async ({ email, password }) => {
  const cognitoUser = await Auth.signIn(email?.toLowerCase(), password);

  // if the user is new user (just set user in cognito), returns challengeName 'NEW_PASSWORD_REQUIRED'
  if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
    // Session should be returned - Without Session, it returns error "custom auth lambda trigger is not configured for the user pool".
    return {
      isAuthenticated: true,
      session: cognitoUser.Session,
      newUser: true
    };
  }

  // Normal user will be here
  if ('signInUserSession' in cognitoUser) {
    setUserSession(cognitoUser.signInUserSession, email);
    return { isAuthenticated: true, cognitoUser };
  }

  return { isAuthenticated: false, cognitoUser: null };
};

/**
 * Get signed Users Session and group
 * @return {Object} - cognito user's group - will be used defining user's type
 */
export const getCurrentUser = async () => {
  const userGroup = await Auth.currentAuthenticatedUser().then((userData) => {
    return userData;
  });

  return userGroup;
};

/**
 * User sign out, user sign out and remove session and LocalStorage
 * @return {void}
 */
export const signOut = async () => {
  const userPool = getCurrentUserPool();
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser) {
    window.analytics.reset();
    Cookies.remove();
    await cognitoUser.signOut();
  }

  const languageCode = localStorage.getItem('lang');

  localStorage.clear();
  localStorage.setItem('lang', languageCode || langCode.en);
};

/**
 * Return error message from Cognito
 * @param {Object} - error object from Cognito
 * @return {string} - string message. Otherwise, it is null
 */
export const cognitoErrorHelper = (error) => {
  if ('code' in error && 'message' in error) {
    switch (error.message) {
      // Specify the message, here I used error.message because the error code is same
      case 'Password does not conform to policy: Password must have symbol characters':
        return 'errorMessage.passwordNotMeetError';

      case 'Incorrect username or password.':
        return 'errorMessage.incorrectUsernamePassword';

      case 'Attempt limit exceeded, please try after some time.':
        return 'errorMessage.attemptLimitExceeded';

      default:
        return error.message;
    }
  }

  return error.message;
};

/**
 * Send email when user forgot password
 * @param {string} - user's email
 * @return {Object} - returns Cognito sending verification code result
 */
export const forgotPassword = async (email) => {
  const userPool = getCurrentUserPool();
  const userData = {
    Username: email,
    Pool: userPool
  };

  const response = await getForgotPassword(userData);

  return response;
};

/**
 * Initialize New user's password - relevant to reset password function
 * @param {Object} - user's object including email and session token
 * @return {Object} - returns authenticated status and cognito user
 */
export const initializeNewPassword = async (user, newPassword) => {
  const email = user.email?.toLowerCase();
  const cognitoUser = await Auth.signIn(email, user.password);
  const { requiredAttributes } = cognitoUser.challengeParam;

  const result = await Auth.completeNewPassword(
    cognitoUser, // the Cognito User Object
    newPassword, // the new password
    // OPTIONAL, the required attributes
    requiredAttributes
  )
    .then((signedInCognitoUser) => {
      // at this time the user is logged in if no MFA required
      setUserSession(signedInCognitoUser?.signInUserSession, email);

      return signedInCognitoUser;
    })
    .catch((e) => {
      console.log(e);

      throw new Error(e.message);
    });

  return { isAuthenticated: true, cognitoUser: result };
};

/**
 * Return user's permission depends on its group
 * @param {string} userGroup - user's group
 * @return {Object} - permission object
 */
export function getUserPermission(userGroup) {
  const { superAdmin, rikerAdmin, rikerUser, picardAdmin, picardUser } =
    cognitoGroup;

  // common
  const isSuperAdmin = userGroup === superAdmin;
  const isRikerAdmin = userGroup === rikerAdmin;
  const isRikerUser = userGroup === rikerUser;
  const isPicardAdmin = userGroup === picardAdmin;
  const isPicardUser = userGroup === picardUser;
  const isSuperRikerAdmin = [superAdmin, rikerAdmin].includes(userGroup);
  const isRiker = [superAdmin, rikerAdmin, rikerUser].includes(userGroup);

  // admin
  const isCustomerAdmin = [superAdmin, rikerUser].includes(userGroup);
  const isGroupAdmin = [superAdmin, rikerUser, picardAdmin].includes(userGroup);

  return {
    // common
    isRiker,
    isSuperRikerAdmin,
    isSuperAdmin,
    isRikerAdmin,
    isRikerUser,
    isPicardAdmin,
    isPicardUser,

    // admin
    isCustomerAdmin,
    isGroupAdmin
  };
}

/**
 * Return user group as text
 * @param {Array} cognitoUserGroups - cognito Group array
 * @return {string} - user group string
 */
export function getUserGroup(cognitoUserGroups) {
  let userGroup;

  if (cognitoUserGroups.includes(cognitoGroup.superAdmin)) {
    userGroup = cognitoGroup.superAdmin;
  } else if (cognitoUserGroups.includes(cognitoGroup.rikerAdmin)) {
    userGroup = cognitoGroup.rikerAdmin;
  } else if (cognitoUserGroups.includes(cognitoGroup.rikerUser)) {
    userGroup = cognitoGroup.rikerUser;
  } else if (cognitoUserGroups.includes(cognitoGroup.picardAdmin)) {
    userGroup = cognitoGroup.picardAdmin;
  } else {
    userGroup = cognitoGroup.picardUser;
  }

  return userGroup;
}

/**
 * Return user tier group as text
 * @param {Array} cognitoUserGroups - cognito Group array
 * @return {string} - user tier string
 */
export function getTierGroup(cognitoTierGroups) {
  let userTierGroup;

  if (cognitoTierGroups.includes(tierGroup.standard)) {
    userTierGroup = tierGroup.standard;
  } else if (cognitoTierGroups.includes(tierGroup.premium)) {
    userTierGroup = tierGroup.premium;
  } else if (cognitoTierGroups.includes(tierGroup.enterprise)) {
    userTierGroup = tierGroup.enterprise;
  }

  return userTierGroup;
}
