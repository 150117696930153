import React, { useEffect, useState, useContext } from 'react';
import { add } from 'date-fns';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

// components
import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { getAnalyticsDefaultDateRange, formatDate } from '../../helper/date';
import { manageAdminSelector } from '../../recoil/selector/admin';
import { manageReportSchema } from '../../helper/validation';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import AnalyticsDateRange from './AnalyticsDateRange';
import Card from '../../components/common/Card';
import IconButton from '../../components/Button/IconButton';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import Modal from '../../components/common/Modal';
import OutlineSelect from '../../components/Input/OutlineSelect';
import { reportState } from '../../recoil/atom/report';
import TextField from '../../components/Input/TextField';
import withContent from '../../components/hoc/withContent';

// etc
import {
  icons,
  apiActionTypes,
  toastType,
  reportTypes,
  dateformat
} from '../../constants';
import { modalProgressBarStyles } from '../../styles/common/Modal';
import { reports, scanRecords } from '../../paths';

const { device, maintenance, scanRecord } = reportTypes;

function ManageReport() {
  const [showError, setShowError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const user = useRecoilValue(userState);
  const reportRecoil = useRecoilValue(reportState);
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };
  const history = useHistory();
  const location = useLocation();
  const inheritedData = location?.state?.data; // data from overview page
  const progressClasses = modalProgressBarStyles();
  const selectList = useRecoilValue(manageAdminSelector);
  const isScanRecord = location?.state === 'scanRecords';

  const { isRikerUser, isPicardAdmin } = user.getUserPermission();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      reportType: isScanRecord ? scanRecord : device,
      reportName: '',
      customerUid: '',
      startDate: null,
      endDate: null
    },
    validationSchema: manageReportSchema
  });

  const refinedPartnerObject = {
    ...(formik.values.reportType === device
      ? {
          startDate:
            formik.values.startDate &&
            formatDate(formik.values.startDate, 'noTime'),
          endDate:
            formik.values.endDate && formatDate(formik.values.endDate, 'noTime')
        }
      : {}),
    reportType: formik.values.reportType.trim() || null,
    reportName: formik.values.reportName.trim() || null,
    customerUid: formik.values.customerUid.trim() || null
  };

  const timer = () =>
    setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

  // Trigger when user click save button
  const onSave = async () => {
    try {
      if (
        (!isPicardAdmin && !formik?.isValid) ||
        (isPicardAdmin && formik.errors.reportName)
      ) {
        setShowError(true);
        return null;
      }

      const request = {
        ...refinedPartnerObject,
        type: apiActionTypes.CREATE,
        reportType: formik.values.reportType, // 'device' or 'maintenance'.
        reportName: formik.values.reportName,
        startDate:
          formik.values.startDate &&
          formatDate(formik.values.startDate, dateformat.noTime),
        endDate: formatDate(
          add(formik.values.endDate, { days: 1 }),
          dateformat.noTime
        )
      };

      const response = await callAPI(`/report-management`, request);

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      if (progress < 100) {
        setIsOpen(true);
        timer();
      }

      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  const onDelete = async () => {};
  const onEdit = async () => {};

  const onClose = () => {
    history.push(isScanRecord ? scanRecords : reports);
  };

  const renderReportMessage = () => {
    switch (formik.values.reportType) {
      case device:
        return 'pages.reports.helpText.device';

      default:
        return 'pages.reports.helpText.maintenance';
    }
  };

  useEffect(() => {
    const { startDate, endDate } = getAnalyticsDefaultDateRange();

    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
  }, []);

  const { values } = formik;

  // initialize customer uid when the select list available
  React.useEffect(() => {
    let customerUid = '';
    if (selectList?.customers && !values.customerUid) {
      customerUid = selectList?.customers?.[0].value;
    } else {
      customerUid = user.customer_uid;
    }

    formik.setFieldValue('customerUid', customerUid);
  }, [selectList?.customers]);

  useEffect(() => {
    // Redirect to Report overview page when the progress is 100%
    if (progress === 100) {
      clearInterval(timer);
      setIsOpen(false);
      setToast({
        message: t('toastMessage.reportSaveSuccess'),
        type: toastType.success,
        open: true
      });
      onClose();
    }
  }, [progress]);

  const onChange = (_, child) => {
    // set partner and customer uid
    const { parent = '', value = '' } = child.props;

    formik.setFieldValue('customerUid', value);
    formik.setFieldValue('partnerUid', parent);
  };

  return (
    <>
      <ContentHeading
        title={t(isScanRecord ? 'menuName.scanRecords' : 'menuName.reports')}
        leftButton={
          <IconButtonContainer>
            {!inheritedData && (
              <IconButton icon={icons.save} onClick={onSave} />
            )}
            {(reportRecoil.permission.edit || isRikerUser) && inheritedData && (
              <IconButton icon={icons.save} onClick={onEdit} />
            )}
            {reportRecoil.permission.delete && inheritedData && (
              <IconButton icon={icons.delete} onClick={onDelete} />
            )}
            <IconButton icon={icons.close} onClick={onClose} />
          </IconButtonContainer>
        }
      />

      {isOpen && progress > 0 && (
        <Modal isOpen={isOpen} title={t('pages.reports.creatingReport')}>
          <LinearProgress
            color="primary"
            classes={{
              root: progressClasses.bar,
              barColorPrimary: progressClasses.barColorPrimary
            }}
            variant="determinate"
            value={progress}
            style={{ backgroundColor: '#000' }}
          />
        </Modal>
      )}

      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card cardStyle={{ height: 'auto' }}>
            <Grid container spacing={2}>
              {!isPicardAdmin && (
                <OutlineSelect
                  {...columns}
                  cy="customerOptionField"
                  errorMessage="errorMessage.selectCustomer"
                  isInvalid={formik.errors?.customerUid && showError}
                  isRequired={true}
                  label="label.customer"
                  name="customerUid"
                  onChange={(_, child) => {
                    onChange(_, child);
                  }}
                  placeholder="errorMessage.selectCustomer"
                  value={formik.values.customerUid}
                  menuItemArray={selectList?.customers}
                />
              )}

              <TextField
                {...columns}
                errorMessage={t('errorMessage.reportName')}
                isInvalid={formik.errors?.reportName && showError}
                label="label.reportName"
                name="reportName"
                onChange={formik.handleChange}
                value={formik.values.reportName}
              />

              {!isScanRecord ? (
                <OutlineSelect
                  {...columns}
                  label={t('label.reportType')}
                  onChange={formik.handleChange}
                  value={formik.values.reportType}
                  name="reportType"
                  disabled={!!inheritedData}
                  menuItemArray={[
                    { text: 'menuName.analytics', value: device },
                    { text: 'menuName.maintenance', value: maintenance }
                  ]}
                  helperText={renderReportMessage()}
                />
              ) : null}
            </Grid>
          </Card>
        </Grid>

        {/* Date range */}
        <AnalyticsDateRange formik={formik} isScanRecord={isScanRecord} />
      </Grid>
    </>
  );
}

export default withContent(ManageReport);
