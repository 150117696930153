import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { getTierMessage } from '../../helper/date';
import { userState } from '../../recoil/state';
import Button from '../Button/Button';

import contentStyles, { proBlockStyles } from '../../styles/common/Content';
import { buttonStyle, tierGroup } from '../../constants';
import {
  averages,
  comparisons,
  trends,
  useStatistics,
  reports,
  scheduled,
  notifications
} from '../../paths';

export default function withContent(Component) {
  return (props) => {
    const user = useRecoilValue(userState);
    const { t } = useTranslation();
    const classes = contentStyles();
    const proClasses = proBlockStyles();
    const isPaidPage = [
      averages,
      comparisons,
      trends,
      useStatistics,
      reports,
      scheduled,
      notifications
    ].includes(window.location.pathname);

    // const isPermanent = user?.tier_status === tierStatus.permanent;
    const isStandard = user?.tier === tierGroup.standard;

    return (
      <>
        {isStandard && isPaidPage && (
          <div className={proClasses.proContainer}>
            <div className={proClasses.proTitleContainer}>
              <span className={proClasses.proTitle}>
                {t('common.tierContact')}
              </span>
            </div>
            <div>
              <Button
                style={buttonStyle.filledWhiteDarkBlue}
                description={t('pages.support.contact')}
                isInitialLabel={true}
                onClick={() => {
                  window.location.href = getTierMessage(user);
                }}
              />
            </div>
          </div>
        )}
        <div className={classes.container}>
          <div className={classes.componentContainer}>
            <Component {...props} />
          </div>
        </div>
      </>
    );
  };
}
