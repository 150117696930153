/* eslint-disable no-async-promise-executor */
import { getFilterByOption } from '../../../helper/api';
import { getRefinedReq } from '../../../helper/admin/page';

import { apiActionTypes } from '../../../constants';

export default function useMultipleDeviceData(props) {
  const initData = {
    devices: [],
    partner_uid: '',
    customer_uid: '',
    group_uid: '',
    assetId: ''
  };

  // request pairs
  const pairs = {
    partner_uid: 'partnerUid',
    customer_uid: 'customerUid',
    group_uid: 'groupUid',
    devices: 'devices',
    csuv_asset_id: 'assetId'
  };

  const getDeleteReq = (serial) => {
    return {
      type: apiActionTypes.DELETE,
      csuvSerial: serial
    };
  };

  const getCreateReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    return reqData;
  };

  const getGroupByCustomer = async (customerUid) => {
    const response = await getFilterByOption({
      customer: customerUid,
      customerDevices: customerUid
    });

    props.setFilterList({ ...props?.filterList, groups: response?.groups });
  };

  const handleCustomerChange = async ({ partnerUid, customerUid, cb }) => {
    await getGroupByCustomer(customerUid);

    cb('customer_uid', customerUid);
    cb('partner_uid', partnerUid);
    cb('group_uid', '');
  };

  const refineDeviceListReq = (list, fn) => {
    return new Promise(async (resolve) => {
      const promiseList = await list.map(async (device) => {
        const refined = await fn(device);
        return refined;
      });

      const refinedList = await Promise.all(promiseList);

      resolve(refinedList);
    });
  };

  return {
    inheritedData: initData,

    // func
    getDeleteReq,
    getCreateReq,

    // special user customer handler
    handleCustomerChange,
    getGroupByCustomer,

    // refine device for deployment information
    refineDeviceListReq
  };
}
