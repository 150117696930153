import React from 'react';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../../styles/common/ButtonMenu';

export default function ButtonMenu({ button, menuList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const classes = styles();

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (menu) => {
    try {
      setAnchorEl(null);

      if (menu?.callback) menu?.callback();
    } catch (error) {
      console.error('onClose ButtonMenu error: ', error);
    }
  };

  return (
    <div>
      <div onClick={onClick}>{button}</div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {menuList.map((menu, index) => {
          return (
            <MenuItem
              key={index}
              className={classes.buttonMenuItem}
              onClick={() => onClose(menu)}
            >
              {t(menu.description)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
