import React from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../../../components/common/Title';
import { manageMultipleDeviceSchema } from '../../../helper/validation';
import Form from './Form';
import IconButtonContainer from '../../../components/Button/IconButtonContainer';
import useMultipleDeviceData from './useMultipleDeviceData';
import withAdminManage from '../../../components/hoc/withAdminManage';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../../helper/admin/page';
import useDeviceInputData from './useDeviceInputData';
import useMultipleDeviceManage from '../../../components/pages/Admin/hooks/useMultipleDeviceManage';
import useDeployData from '../../DeviceDetail/hook/useDeployData';
import { adminType } from '../../../constants';

function ManageDevice({
  t,
  location,
  type,

  // recoil
  filterList,
  setFilterList,
  user,

  // func
  onDelete,
  // onSave,
  onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit
}) {
  const {
    inheritedData,
    getDeleteReq,
    getCreateReq,
    handleCustomerChange,
    getGroupByCustomer,
    getDeleteCustomerUid,
    refineDeviceListReq
  } = useMultipleDeviceData({
    data: location?.state?.data,
    user,
    filterList,
    setFilterList
  });

  const {
    inheritedData: dep_inheritedData,
    uploadImageTypeList,

    getRefinedRequest,
    handleFileChange,
    handleRemoveSizeMountType
  } = useDeployData({
    ...location?.state?.data
  });

  const { onSave } = useMultipleDeviceManage(adminType.csuvItem, [
    filterList,
    setFilterList
  ]);

  const inputProps = useDeviceInputData(user);

  const formik = useFormik({
    initialValues: { ...inheritedData, ...dep_inheritedData },
    validationSchema: manageMultipleDeviceSchema,

    onSubmit: async (values) => {
      const reqData = getCreateReq(values);

      reqData.devices = await refineDeviceListReq(
        reqData.devices,
        getRefinedRequest
      );

      onSave(reqData);
    }
  });

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      onEdit: formik.handleSubmit,
      onSave: formik.handleSubmit,
      onDelete: () =>
        onDelete(getDeleteReq(inheritedData?.serial, getDeleteCustomerUid())),
      onClose
    }
  });

  // Device does not require specific customer filter in view page. so it should load specific group filter by customer
  React.useEffect(() => {
    if (inheritedData?.customer_uid) {
      getGroupByCustomer(inheritedData?.customer_uid);
    }
  }, [inheritedData?.customer_uid]);

  return (
    <>
      <ContentHeading
        title={t('menuName.devices')}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            {...formik}
            acceptTypes={uploadImageTypeList?.join(', ')}
            filterList={filterList}
            handleCustomerChange={handleCustomerChange}
            handleRemoveSizeMountType={handleRemoveSizeMountType}
            handleFileChange={handleFileChange}
            inputPropData={inputProps}
            isEdit={isEdit}
            setErrors={formik.setErrors}
          />
        </Grid>
      </Grid>
    </>
  );
}

// hoc will manage type of value
export default withAdminManage(adminType.csuvItem)(ManageDevice);
