import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function FilePicture({
  url,
  onChange,
  name,
  acceptTypes = 'image/*'
}) {
  const ref = React.useRef(); // to control input value
  const handlePhotoChange = (event) => {
    const isValid = onChange(event.target.files[0]);

    if (!isValid) {
      ref.current.value = '';
    }
  };

  // render input if not file object
  if (!url || !(url instanceof File)) {
    return (
      <Grid item xs={12} data-testid="no-photo">
        <input
          ref={ref}
          type="file"
          name={name}
          onChange={handlePhotoChange}
          accept={acceptTypes}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <img src={URL.createObjectURL(url)} style={{ width: '100%' }} />
    </Grid>
  );
}
