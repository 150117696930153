import { getFilterByOption } from '../../helper/api';
import {
  getAllGroups,
  getRefinedReq,
  getUidByRole,
  renderPersona
} from '../../helper/admin/page';

import { apiActionTypes, personaType } from '../../constants';

export default function useUserData(props) {
  const data = props?.data;
  const user = props?.user;

  // initialize values
  const groupUids = !data?.group_uids ? [] : data?.group_uids;
  const personaId = !data?.persona_id ? '' : renderPersona(data?.persona_id);

  const initData = {
    ...data,
    persona_id: personaId,
    group_uids: groupUids,
    customer_uid: getUidByRole({
      isPicardAdmin: user?.getUserPermission().isPicardAdmin,
      uidFromUser: user?.customer_uid,
      existingUid: data?.customer_uid
    }),
    partner_uid: getUidByRole({
      isPicardAdmin: user?.getUserPermission().isPicardAdmin,
      uidFromUser: user?.partner_uid,
      existingUid: data?.partner_uid
    })
  };

  // request pairs
  const pairs = {
    customer_uid: 'customerUid',
    first_name: 'firstName',
    group_uids: 'groupUids',
    last_name: 'lastName',
    permission_level: 'permission',
    persona_id: 'personaId',
    username: 'username',
    partner_uid: 'partnerUid',
    tz: 'timezone'
  };

  const inheritedData = { ...data, ...initData };

  const refinePersona = (values) => {
    const { actionableReporting, fleetManagement, both } = personaType;
    const initValues = { ...values };

    if (!Object.keys(initValues).includes('persona_id')) {
      return values;
    }

    if (initValues.persona_id === both) {
      return {
        ...initValues,
        persona_id: [actionableReporting, fleetManagement]
      };
    }

    return { ...initValues, persona_id: [initValues.persona_id] };
  };

  const getDeleteReq = (uid) => {
    return { type: apiActionTypes.DELETE, username: uid };
  };

  const getEditReq = (values, oldPermission) => {
    // if permission not changed, remove permission
    const reqData = getRefinedReq({ pairs, data: refinePersona(values) });
    const shouldDeletePermission = values.permission_level === oldPermission; // not change permission

    if (shouldDeletePermission) {
      delete reqData.permission;
    } else {
      reqData.oldPermission = oldPermission;
    }

    return reqData;
  };

  const getCreateReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: refinePersona(values) });

    return reqData;
  };

  const handleCustomerChange = async ({ partnerUid, customerUid, cb }) => {
    const response = await getFilterByOption({
      customer: customerUid,
      customerDevices: customerUid
    });

    props.setFilterList({ ...props?.filterList, groups: response?.groups });

    cb('customer_uid', customerUid);
    cb('partner_uid', partnerUid);

    // select all group
    cb('group_uids', getAllGroups(response.groups));
  };

  return {
    inheritedData,

    // func
    getDeleteReq,
    getEditReq,
    getCreateReq,

    // special user customer handler
    handleCustomerChange
  };
}
