import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

// components
import { filterState } from '../../recoil/atom/filter';
import { userState } from '../../recoil/state';
import DateSelect from './DateSelect';
import GroupToggleButton from '../Button/GroupToggleButton';
import SearchSelect from './SearchSelect';

// etc
import {
  averages,
  comparisons,
  devices,
  groups,
  notifications,
  reports,
  scanRecords,
  trends,
  useStatistics
} from '../../paths';
import { filterType } from '../../constants';
import filterStyles from '../../styles/common/Filter';

const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

function Filter({ listOptions, changed, dateProps = {} }) {
  const showGroup = listOptions?.group;
  const [entity, setEntity] = React.useState(filterType.devices); // value will be 1. group | 2. device
  const user = useRecoilValue(userState);
  const filterRecoil = useRecoilValueLoadable(filterState).contents;
  const classes = filterStyles();
  const { isRiker } = user.getUserPermission();

  if (Object.keys(filterRecoil).length) {
    return (
      <form className={classes.filterContainer} data-testid="filter-container">
        <Grid container spacing={2}>
          {isRiker ? (
            <Grid item {...columns}>
              <SearchSelect type={filterType.customers} />
            </Grid>
          ) : null}

          {/* Average does not have device list */}
          {showGroup ? (
            <GroupDeviceComp
              hasToggle={![].includes(window.location.pathname)}
              entityController={[entity, setEntity]}
            />
          ) : null}

          {[comparisons, averages, trends, useStatistics].includes(
            window.location.pathname
          ) ? (
            <Grid item {...columns}>
              <DateSelect changedDateByData={changed} {...dateProps} />
            </Grid>
          ) : null}
        </Grid>
      </form>
    );
  }

  return null;
}

function GroupDeviceComp({ hasToggle, entityController }) {
  const entity = entityController[0];

  // page does not require group select
  const noGroupOption = [
    devices,
    groups,
    notifications,
    reports,
    scanRecords
  ].includes(window.location.pathname);

  if (noGroupOption) {
    return null;
  }

  return !hasToggle ? (
    <Grid item {...columns}>
      <SearchSelect type={filterType.groups} />
    </Grid>
  ) : (
    <>
      <Grid item {...columns}>
        <GroupToggleButton entityController={entityController} />
      </Grid>

      <Grid item {...columns}>
        <SearchSelect type={entity} />
      </Grid>
    </>
  );
}

export default React.memo(Filter);
