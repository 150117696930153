import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components

import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { filterState } from '../../recoil/atom/filter';
import { getFilterOptionByKey } from '../../helper/filter';
import { manageAdminSelector } from '../../recoil/selector/admin';
import { manageGroupSchema } from '../../helper/validation';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import Button from '../../components/Button/Button';
import Card from '../../components/common/Card';
import IconButton from '../../components/Button/IconButton';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import Modal from '../../components/common/Modal';
import OutlineSelect from '../../components/Input/OutlineSelect';
import TableContainer from '../../components/common/Table/TableContainer';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';
import GroupForm from './GroupForm';

// etc
import {
  apiActionTypes,
  responseKey,
  icons,
  filterType,
  toastType
} from '../../constants';
import groupStyles from '../../styles/Group';
import { groups } from '../../paths';
import { groupState } from '../../recoil/atom/admin';

function ManageGroups() {
  const [showError, setShowError] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterRecoil] = useRecoilState(filterState);
  const groupRecoil = useRecoilValue(groupState);
  const selectList = useRecoilValue(manageAdminSelector);

  const { groupName, customerUid, partnerUid } = responseKey;
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const user = useRecoilValue(userState);
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };
  const location = useLocation();
  const history = useHistory();
  const groupClasses = groupStyles();
  const inheritedData = location?.state?.data; // data from overview page
  const {
    isSuperAdmin,
    isRikerUser,
    isPicardAdmin,

    isRikerAdmin
  } = user.getUserPermission();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      partnerUid: '',
      customerUid: '',
      groupName: inheritedData?.[groupName] || '',
      newGroupId: ''
    },
    validationSchema: manageGroupSchema
  });
  const { values } = formik;

  const refinedGroupObject = {
    partnerUid: formik.values.partnerUid.trim() || user?.[partnerUid] || null,
    customerUid:
      formik.values.customerUid?.trim() || user?.[customerUid] || null,
    groupName: formik.values.groupName.trim() || null
  };

  const validationByUserGroup = () => {
    // Riker admin validation
    if (isSuperAdmin && !formik?.isValid) {
      setShowError(true);
      throw formik.errors;
    }

    // Riker user validation
    if (
      isRikerUser &&
      (formik.errors?.customerUid || formik.errors?.groupName)
    ) {
      setShowError(true);
      throw new Error('errorMessage.save');
    }

    // Picard admin validation
    if (isPicardAdmin && formik.errors?.groupName) {
      setShowError(true);
      throw new Error('errorMessage.group');
    }

    // Delete validation
    if (isPicardAdmin && formik.errors?.newGroupId) {
      setShowError(true);
      throw new Error('errorMessage.save');
    }

    // Empty device list check
    if (!selectedDevices?.length) {
      setShowError(true);
      throw new Error('errorMessage.groupEmptyError');
    }

    // edit must have previous devices
    if (inheritedData) {
      const hasPreviousDevice = inheritedData?.deviceList.every((device) =>
        selectedDevices.includes(device)
      );

      if (!hasPreviousDevice) {
        const notSelectedDevice = inheritedData?.deviceList.filter(
          (device) => !selectedDevices.includes(device)
        );

        setShowError(true);
        throw new Error(
          t('errorMessage.groupNotSelectedDevice', {
            devices: notSelectedDevice.join(', ')
          })
        );
      }
    }
  };

  // Trigger when user click save button
  const onSave = async () => {
    try {
      validationByUserGroup();

      const response = await callAPI(`/group-management`, {
        ...refinedGroupObject,
        type: apiActionTypes.CREATE,
        ...(selectedUsers?.length ? { groupUsers: selectedUsers } : {}),
        ...(selectedDevices?.length ? { csuvSerial: selectedDevices } : {})
      });

      if (response?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t('toastMessage.groupSaveSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(groups);
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  const onDelete = async () => {
    setIsOpen(true);
  };

  const onModalDelete = async () => {
    try {
      // Delete validation
      if (!formik.values.newGroupId) {
        setShowError(true);
        throw new Error('errorMessage.pleaseSelectOneGroup');
      }

      const response = await callAPI(`/group-management`, {
        type: apiActionTypes.DELETE,
        groupUid: inheritedData?.[responseKey.groupUid],
        newGroupUid: formik.values.newGroupId,
        customerUid: formik.values.customerUid
      });

      if (response?.status !== 200) {
        throw new Error(t('errorMessage.somethingWentWrong'));
      }

      setToast({
        message: t('toastMessage.groupDeleteSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(groups);
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  const onEdit = async () => {
    delete refinedGroupObject.partnerUid;

    try {
      validationByUserGroup();

      const response = await callAPI(`/group-management`, {
        ...refinedGroupObject,
        type: apiActionTypes.UPDATE,
        groupUid: inheritedData?.[responseKey.groupUid],
        oldCsuvSerial: inheritedData?.deviceList,
        newCsuvSerial: selectedDevices,
        oldGroupUsers: inheritedData?.userList,
        newGroupUsers: selectedUsers
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t('toastMessage.groupEditSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(groups);
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  const onClose = () => {
    history.push(groups);
  };

  const getData = async () => {
    try {
      const response = await callAPI(`/group-management`, {
        type: apiActionTypes.FETCH,
        customerUid: formik.values.customerUid
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      if (
        !Array.isArray(response?.data?.csuv_data) ||
        !Array.isArray(response?.data?.group_data) ||
        !Array.isArray(response?.data?.user_data)
      ) {
        throw new Error('errorMessage.noDataFound');
      }

      setDeviceList(response?.data?.csuv_data);
      setUserList(response?.data?.user_data);
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  useEffect(() => {
    if (inheritedData?.deviceList.length) {
      setSelectedDevices(inheritedData?.deviceList);
    }

    if (inheritedData?.userList.length) {
      setSelectedUsers(inheritedData?.userList);
    }
  }, []);

  // initialize customer uid when the select list available
  React.useEffect(() => {
    // riker set customer
    if (selectList?.customers) {
      if (!values.customerUid) {
        formik.setFieldValue(
          'customerUid',
          inheritedData?.customer_uid || selectList?.customers?.[0].value
        );
        formik.setFieldValue('partnerUid', selectList?.customers?.[0].parent);
      } else {
        const currentCustomer = selectList?.customers.find(
          (c) => c.value === inheritedData.customer_uid
        );

        formik.setFieldValue('partnerUid', currentCustomer?.parent);
      }
    }

    // picard
    else if (!values.customerUid) {
      formik.setFieldValue('customerUid', user?.customer_uid);
      formik.setFieldValue('partnerUid', user?.partner_uid);
    }
  }, [selectList?.customers]);

  useEffect(() => {
    if (formik.values.customerUid) {
      getData();
    }
  }, [formik.values.customerUid]);

  const deviceTableColumns = [
    { field: 'csuv_serial', title: t('pages.device.serialNumber') },
    { field: 'location_name', title: t('common.location') },
    { field: 'group_name', title: t('label.currentGroup') }
  ];

  const deviceTableProps = {
    columns: deviceTableColumns,
    hasPage: true,
    hasSelect: true,
    pageSize: 7,
    rows: deviceList,
    selectedData: selectedDevices,
    type: 'device',
    isEdit: !!inheritedData,
    onSelect: (vals) => {
      setSelectedDevices(
        vals.length ? vals.map((item) => item.csuv_serial) : []
      );
    }
  };

  const userTableColumns = [
    { field: 'username', title: t('label.email') },
    { field: 'first_name', title: t('label.firstName') },
    { field: 'last_name', title: t('label.lastName') },
    { field: 'group_name', title: t('label.currentGroup') }
  ];

  const userTableProps = {
    columns: userTableColumns,
    hasSelect: true,
    rows: userList,
    selectedData: selectedUsers,
    type: 'user',
    onSelect: (vals) => {
      setSelectedUsers(vals.length ? vals.map((item) => item.username) : []);
    }
  };

  useSegment();

  return (
    <>
      {/* Page title */}
      <ContentHeading
        title={t('menuName.groups')}
        description={
          <Trans
            i18nKey="pages.groups.description"
            components={{
              strong: <span className={groupClasses.groupPageDescription} />,
              br: <br />
            }}
            values={{ date: user?.tierRemainDays }}
          />
        }
        leftButton={
          <IconButtonContainer>
            {groupRecoil?.permission.save && !inheritedData && (
              <IconButton icon={icons.save} onClick={onSave} />
            )}
            {groupRecoil?.permission.edit && inheritedData && (
              <IconButton icon={icons.save} onClick={onEdit} />
            )}
            {groupRecoil?.permission.delete && inheritedData && (
              <IconButton icon={icons.delete} onClick={onDelete} />
            )}
            <IconButton icon={icons.close} onClick={onClose} />
          </IconButtonContainer>
        }
      />

      {!isRikerAdmin && isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          title={t('errorMessage.pleaseSelectOneGroup')}
        >
          <OutlineSelect
            {...columns}
            errorMessage="errorMessage.selectGroup"
            isInvalid={!formik.values?.newGroupId && showError}
            isRequired={true}
            label="label.group"
            name="newGroupId"
            onChange={formik.handleChange}
            value={formik.values.newGroupId}
            menuItemArray={getFilterOptionByKey({
              filterList: filterRecoil,
              key: filterType.groups,
              excludeId: inheritedData?.[responseKey.groupUid]
            })}
          />
          <Button
            description={t('label.confirmDelete')}
            onClick={onModalDelete}
            fullWidth={true}
          />
        </Modal>
      )}

      {/* Text field Section */}
      <Grid container spacing={2}>
        <GroupForm
          showError={showError}
          formik={formik}
          isEdit={!!inheritedData?.[groupName]}
          selectList={selectList}
          user={user}
        />

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card header={t('label.pleaseSelectDevice')}>
            <TableContainer tableProps={deviceTableProps} />
          </Card>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card header={t('label.pleaseSelectUser')}>
            <TableContainer tableProps={userTableProps} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default withContent(ManageGroups);
