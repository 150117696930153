import { atom } from 'recoil';
import { sub } from 'date-fns';

import { savings } from '../key';

export const defaultRoiCalculator = {
  filterOptions: {},
  // input
  startDate: sub(new Date(), { months: 1 }),
  endDate: new Date(),
  costPerUnit: parseFloat(1000).toFixed(2),
  costLabor: parseFloat(40).toFixed(2),

  // calculated
  totalCSUVCleanCycle: null,
  numOfDevice: 0
};

export const roiCalculatorState = atom({
  key: savings.roiCalculator,
  default: defaultRoiCalculator
});

export const estimatorState = atom({
  key: savings.estimator,
  default: {}
});
