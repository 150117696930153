import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Tooltip from './Tooltip';
import titleStyles, {
  statusText,
  titleWithButton,
  formHeaderStyles
} from '../../styles/common/Title';

export function MainHeading({ description, title, option }) {
  const classes = titleStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title} variant="h1">
          {t(title)}
        </Typography>

        <p className={classes.description}>{description}</p>
      </div>
      <div>{option}</div>
    </div>
  );
}

export function ContentHeading({
  description,
  title,
  option,
  leftButton,
  tooltip
}) {
  const { t } = useTranslation();
  const classes = titleStyles();
  const titleWithButtonClasses = titleWithButton();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid
          item
          lg={9}
          md={7}
          sm={6}
          xs={6}
          className={classes.headerContainer}
        >
          <Typography variant="h2">{title}</Typography>

          <div>{option}</div>

          {tooltip && (
            <span style={{ marginTop: -20 }}>
              <Tooltip message={t(tooltip)} />
            </span>
          )}
        </Grid>

        {leftButton && (
          <Grid
            className={titleWithButtonClasses.titleWithButtonContainer}
            item
            lg={3}
            md={5}
            sm={6}
            xs={6}
          >
            {leftButton}
          </Grid>
        )}

        <p className={classes.description}>{description}</p>
      </Grid>
    </div>
  );
}

export function SubHeading({ optionElement, title }) {
  const classes = titleStyles();

  return (
    <Typography className={`${classes.subTitle} sub-heading-h3`} variant="h3">
      {title}{' '}
      <span style={{ position: 'absolute', right: 0, top: 0 }}>
        {optionElement}
      </span>
    </Typography>
  );
}

export function StatusHeading({ status }) {
  const classes = statusText(status)();

  return <span className={classes.status}>{status}</span>;
}

export function FormHeader({ title }) {
  const classes = formHeaderStyles();
  return <h4 className={classes.formHeader}>{title}</h4>;
}

MainHeading.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  option: PropTypes.element
};

SubHeading.propTypes = {
  title: PropTypes.string,
  optionElement: PropTypes.element
};

StatusHeading.propTypes = {
  status: PropTypes.string.isRequired
};
