import { makeStyles } from '@material-ui/styles';
import { fontFamily, color } from '../../constants';

export default makeStyles({
  datePickerContainer: {
    '& .react-datepicker-wrapper, & .MuiTextField-root': {
      width: '100%'
    }
  },
  rangeMessage: {
    padding: '10px 0'
  },
  rangeMessageBold: {
    color: color.red,
    fontSize: 13,
    fontFamily: fontFamily.bold
  }
});
