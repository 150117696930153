import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RiDeleteBinFill } from 'react-icons/ri';
import { HiPlus } from 'react-icons/hi';
import Grid from '@material-ui/core/Grid';

// component
import {
  DeleteButtonGroup,
  SaveButtonGroup
} from '../components/Button/ButtonGroup';
import { ContentHeading, SubHeading } from '../components/common/Title';
import Breadcrumbs from '../components/common/Breadcrumbs';
import Button from '../components/Button/Button';
import OutlineSelect from '../components/Input/OutlineSelect';
import TextField from '../components/Input/TextField';
import withContent from '../components/hoc/withContent';

// etc
import contentStyles from '../styles/common/Content';
import aldertDetailsStyles from '../styles/AlertDetails';
import { buttonStyle } from '../constants';
import { alerts, addAlert } from '../paths';

const linkArray = (isView, { link, alertName }) => [
  {
    link: alerts,
    text: 'Alerts'
  },
  {
    link: isView ? `${alerts}/${link}` : addAlert,
    text: isView ? alertName : 'Add Aldert'
  }
];

const deviceArray = [
  { text: 'All', value: 'All' },
  { text: 'Device 2', value: 'Device2' },
  { text: 'Device 3', value: 'Device3' }
];

const conditionArray = [
  { text: 'Error - Critical', value: 'Error - Critical' },
  { text: 'Condition 2', value: 'Condition2' },
  { text: 'Condition 3', value: 'Condition3' }
];

const notifyArray = [
  { text: 'SMS', value: 'sms' },
  { text: 'Email', value: 'Email' }
];

function AlertDetails() {
  const [condition, setCondition] = useState('');
  const [name, setName] = useState('');
  const [device, setDevice] = useState('');
  const [notify, setNotify] = useState('');
  const [sendTo, setSendTo] = useState('');
  const contentClasses = contentStyles();
  const alertDetailClasses = aldertDetailsStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const handleChange = (value, func) => func(value);
  const isView = 'id' in match.params;
  const title = isView ? 'Edit Alert' : 'Add Alert';

  useEffect(() => {
    if (isView) {
      setCondition('Error - Critical');
      setDevice('All');
      setName('Support Team');
      setNotify('Email');
      setSendTo('123-456-7890');
    }
  }, []);

  return (
    <>
      <ContentHeading
        title={title}
        option={
          <Breadcrumbs
            linkArray={linkArray(isView, {
              link: 'alerttest',
              alertName: 'Alert 1234'
            })}
          />
        }
      />

      {isView && (
        <SaveButtonGroup
          saveOnClick={() => history.push(alerts)}
          cancelOnClick={() => history.push(alerts)}
        />
      )}
      <form>
        <div
          className={`${contentClasses.radiusContainer} ${contentClasses.section}`}
        >
          <SubHeading title="Details" />

          <Grid container spacing={2}>
            <OutlineSelect
              label="Conditions *"
              lg={6}
              md={6}
              onChange={(event) =>
                handleChange(event.target.value, setCondition)
              }
              sm={12}
              value={condition}
              menuItemArray={conditionArray}
            />
            <OutlineSelect
              label="Device *"
              lg={6}
              md={6}
              onChange={(event) => handleChange(event.target.value, setDevice)}
              sm={12}
              value={device}
              menuItemArray={deviceArray}
            />
          </Grid>
        </div>

        <div
          className={`${contentClasses.radiusContainer} ${contentClasses.section}`}
        >
          <SubHeading
            title="Recipients"
            optionElement={
              <Button
                icon={<HiPlus />}
                description="Add New Recipient"
                style={buttonStyle.lineBlue}
              />
            }
          />

          <Grid container spacing={2}>
            <TextField
              label="Name *"
              lg={3}
              md={3}
              onChange={(event) => handleChange(event.target.value, setName)}
              sm={12}
              value={name}
            />
            <OutlineSelect
              label="Notify Via *"
              lg={5}
              md={5}
              onChange={(event) => handleChange(event.target.value, setNotify)}
              sm={12}
              value={notify}
              menuItemArray={notifyArray}
            />
            <TextField
              label="To *"
              lg={3}
              md={3}
              onChange={(event) => handleChange(event.target.value, setSendTo)}
              sm={12}
              value={sendTo}
            />
            {isView && (
              <Grid item lg={1} className={alertDetailClasses.deleteContainer}>
                <RiDeleteBinFill />
              </Grid>
            )}
          </Grid>

          {isView && (
            <Grid container spacing={2}>
              <TextField
                label="Name *"
                lg={3}
                md={3}
                onChange={(event) => handleChange(event.target.value, setName)}
                sm={12}
                value={'Bulb Lead'}
              />
              <OutlineSelect
                label="Notify Via *"
                lg={5}
                md={5}
                onChange={(event) =>
                  handleChange(event.target.value, setNotify)
                }
                sm={12}
                value={'sms'}
                menuItemArray={notifyArray}
              />
              <TextField
                label="To *"
                lg={3}
                md={3}
                onChange={(event) =>
                  handleChange(event.target.value, setSendTo)
                }
                sm={12}
                value={'Bulbguy@cleanslateuv.com'}
              />
              <Grid item lg={1} className={alertDetailClasses.deleteContainer}>
                <RiDeleteBinFill />
              </Grid>
            </Grid>
          )}
        </div>

        {isView ? (
          <DeleteButtonGroup onClick={() => history.push(alerts)} />
        ) : (
          <SaveButtonGroup
            saveOnClick={() => history.push(alerts)}
            cancelOnClick={() => history.push(alerts)}
          />
        )}
      </form>
    </>
  );
}

export default withContent(AlertDetails);
