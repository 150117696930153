import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

// component
import {
  DeleteButtonGroup,
  SaveButtonGroup
} from '../components/Button/ButtonGroup';
import { ContentHeading } from '../components/common/Title';
import Breadcrumbs from '../components/common/Breadcrumbs';
import OutlineSelect from '../components/Input/OutlineSelect';
import TextField from '../components/Input/TextField';
import withContent from '../components/hoc/withContent';

// etc
import { addGroup, groups } from '../paths';
import contentStyles from '../styles/common/Content';

const addLinkArray = [
  {
    link: groups,
    text: 'Group'
  },
  {
    link: addGroup,
    text: 'Add Group'
  }
];

const viewLinkArray = [
  {
    link: groups,
    text: 'Group'
  },
  {
    link: addGroup,
    text: 'Edit Group'
  }
];

const deviceArray = [
  { text: 'Device 1', value: 'device1' },
  { text: 'Device 2', value: 'device2' },
  { text: 'Device 3', value: 'device3' },
  { text: 'DE-123, DE-4456, DE-888', value: 'DE-123, DE-4456, DE-888' }
];

const parentGroupArray = [
  { text: 'Parent Group 1', value: 'Parent Group1' },
  { text: 'Parent Group 2', value: 'Parent Group2' },
  { text: 'Parent Group 3', value: 'Parent Group3' },
  { text: 'N/A', value: 'N/A' }
];

function GroupDetail() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentGroup, setParentGroup] = useState('');
  const [device, setDevice] = useState('');
  const match = useRouteMatch();
  const history = useHistory();
  const contentClasses = contentStyles();
  const handleChange = (value, func) => func(value);
  const isView = 'id' in match.params;
  const title = isView ? 'Edit Group' : 'Add Group';

  useEffect(() => {
    if (isView) {
      setName('First Floor');
      setDescription(
        'All devices on the first or ground floor of this building'
      );
      setParentGroup('N/A');
      setDevice('DE-123, DE-4456, DE-888');
    }
  }, []);

  return (
    <>
      <ContentHeading
        title={title}
        option={
          <Breadcrumbs linkArray={isView ? viewLinkArray : addLinkArray} />
        }
      />

      {isView && (
        <SaveButtonGroup
          saveOnClick={() => history.push(groups)}
          cancelOnClick={() => history.push(groups)}
        />
      )}

      <div
        className={`${contentClasses.radiusContainer} ${contentClasses.section}`}
      >
        <form>
          <Grid container spacing={2}>
            <TextField
              label="Name *"
              lg={6}
              md={6}
              onChange={(event) => handleChange(event.target.value, setName)} //
              sm={12}
              value={name}
            />
            <TextField
              label="Description *"
              lg={6}
              md={6}
              onChange={(event) =>
                handleChange(event.target.value, setDescription)
              }
              sm={12}
              value={description}
            />
            <OutlineSelect
              label="Parent Group *"
              lg={6}
              md={6}
              onChange={(event) =>
                handleChange(event.target.value, setParentGroup)
              }
              sm={12}
              value={parentGroup}
              menuItemArray={parentGroupArray}
            />
            <OutlineSelect
              label="Device *"
              lg={6}
              md={6}
              onChange={(event) => handleChange(event.target.value, setDevice)}
              sm={12}
              value={device}
              menuItemArray={deviceArray}
            />
          </Grid>

          {isView ? (
            <DeleteButtonGroup onClick={() => history.push(groups)} />
          ) : (
            <SaveButtonGroup
              saveOnClick={() => history.push(groups)}
              cancelOnClick={() => history.push(groups)}
            />
          )}
        </form>
      </div>
    </>
  );
}

export default withContent(GroupDetail);
