import { useState } from 'react';
import List from '@material-ui/core/List';
import { useRecoilValue } from 'recoil';

// comp
import MenuItem from './MenuItem';
import MenuItemWithSubMenu from './MenuItemWithSubMenu';

// etc
import menuOptions from './menuOptions';
import menuStyles from '../../styles/common/Menu';
import { userState } from '../../recoil/state';

export default function Menu() {
  const [selectedMenu, setSelectedMenu] = useState(false);
  const classes = menuStyles();
  const user = useRecoilValue(userState);
  const menuArray = menuOptions(user);

  return (
    <List
      classes={{ root: classes.container }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      data-cy="leftMenu"
    >
      {menuArray.map((item, index) => {
        const { hasSubMenu } = item;

        // open sub menu
        if (hasSubMenu) {
          const isSelected = item.subMenuArray
            .map((subMenu) => subMenu.link)
            .some((p) => window.location.pathname.includes(p));
          return (
            <MenuItemWithSubMenu
              isOpen={isSelected}
              item={item}
              key={index}
              onClick={() => setSelectedMenu(!selectedMenu)}
            />
          );
        }

        return <MenuItem key={index} {...item} />;
      })}
    </List>
  );
}
