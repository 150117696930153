import { useState, createContext } from 'react';

const ToastContext = createContext({});

function ToastProvider({ children }) {
  const [toast, setToast] = useState({ message: '', type: '' });

  return (
    <ToastContext.Provider value={{ toast, setToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext };
