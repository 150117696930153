import React from 'react';

// components
import { ContentHeading } from '../../components/common/Title';
import { getPageTitle } from '../../helper/admin/page';
import AdminWithList from '../../components/pages/Admin/AdminWithList';
import IconButton from '../../components/Button/IconButton';
import useCustomerData from '../ManageCustomer/useCustomerData';
import withAdminManage from '../../components/hoc/withAdminManage';
import useTableColumn from '../../components/pages/Admin/hooks/useTableColumn';

import { icons, adminType } from '../../constants';

function Customers({
  // func
  onDelete,
  ...props
}) {
  const { getDeleteReq } = useCustomerData();

  const onRowDelete = (values) => {
    onDelete(getDeleteReq(values.customer_uid));
  };

  // Business logic: depending on user group, should show columns differently
  const pageColumn = useTableColumn(adminType.customer, props.user.userGroup);

  React.useEffect(() => {
    props.getData();
  }, []);

  return (
    <>
      <ContentHeading
        title={props.t(getPageTitle(adminType.customer))}
        leftButton={
          props.hasCreate ? (
            <IconButton icon={icons.addNew} onClick={props.onRowSave} />
          ) : null
        }
      />

      <AdminWithList
        {...props}
        type={adminType.customer}
        onRowDelete={onRowDelete}
        pageColumn={pageColumn}
      />
    </>
  );
}

export default withAdminManage('customer')(Customers);
