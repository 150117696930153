import {
  adminManageCustomer,
  adminManagePartners,
  adminManageUser,
  customers,
  deviceManage,
  devices,
  multipleDeviceManage,
  partners,
  users
} from '../../paths';
import { tierStatus, adminType } from '../../constants';
// partner
// customer
// user
export const getPageTitle = (type) => {
  switch (type) {
    case adminType.customer:
      return 'menuName.customers';

    case adminType.user:
      return 'menuName.users';

    case adminType.device:
      return 'menuName.devices';

    default:
      return 'menuName.partners';
  }
};

export const pageObj = {
  partner: {
    manageURL: '/partner-management',
    deleteURL: '',
    saveURL: adminManagePartners,
    closeURL: partners,
    resMessage: {
      edit: 'toastMessage.partnerEditSuccess',
      delete: 'toastMessage.partnerDeleteSuccess',
      save: 'toastMessage.partnerSaveSuccess'
    }
  },
  customer: {
    manageURL: '/customer-management',
    deleteURL: '',
    saveURL: adminManageCustomer,
    closeURL: customers,
    resMessage: {
      edit: 'toastMessage.customerEditSuccess',
      delete: 'toastMessage.customerDeleteSuccess',
      save: 'toastMessage.customerSaveSuccess'
    }
  },
  user: {
    manageURL: '/user-management',
    deleteURL: '',
    saveURL: adminManageUser,
    closeURL: users,
    resMessage: {
      edit: 'toastMessage.userEditSuccess',
      delete: 'toastMessage.userDeleteSuccess',
      save: 'toastMessage.userSaveSuccess'
    }
  },
  device: {
    manageURL: '/devices',
    deleteURL: '/csuv-item',
    saveURL: multipleDeviceManage,
    editURL: deviceManage,
    closeURL: devices,
    resMessage: {
      edit: 'toastMessage.deviceEditSuccess',
      delete: 'toastMessage.deviceDeleteSuccess',
      save: 'toastMessage.deviceSaveSuccess'
    }
  },
  csuvItem: {
    manageURL: '/csuv-item',
    deleteURL: '',
    saveURL: deviceManage,
    closeURL: devices,
    resMessage: {
      edit: 'toastMessage.deviceEditSuccess',
      delete: 'toastMessage.deviceDeleteSuccess',
      save: 'toastMessage.deviceSaveSuccess'
    }
  }
};

// onSave
// onEdit
// onDelete
// buttons.onSave
// buttons.onEdit
// buttons.onDelete
export const setManageBtnVisibility = ({
  isEdit,
  hasEdit,
  hasDelete,
  type
}) => {
  switch (type) {
    default:
      return {
        onSave: !isEdit,
        onEdit: hasEdit && isEdit,
        goEdit: hasEdit,
        onDelete: hasDelete && isEdit,
        onClose: true
      };
  }
};

export const getManageBtnProps = ({ permissionObj, buttons }) => {
  const props = {};
  Object.entries(permissionObj).forEach(([key, value]) => {
    props[key] = value ? buttons[key] : null;
  });

  return props;
};

const setValueByKey = (data, key) => {
  if (key === 'serial') return data[key]?.toUpperCase();
  if (key === 'username') return data[key]?.toLowerCase();
  if (!data[key]) return '';

  return data[key];
};

export const getRefinedReq = ({ pairs, data }) => {
  const refinedObj = {};

  if (!Object.keys(pairs).length || !Object.keys(data).length) {
    throw new Error('Cannot create req obj');
  }

  Object.entries(pairs).forEach(([key, value]) => {
    refinedObj[value] = setValueByKey(data, key);
  });

  return refinedObj;
};

export const getAssignedEntityFilter = ({ list, name, uid }) => {
  if (!list || !list.length) return [];

  return list.filter((l) => l[name] === uid).map((l) => l.id);
};

export const getTierStatusDescription = (selectedTier) => {
  let key = 'pages.customer.tierStatusDays';
  let days = '';

  switch (selectedTier) {
    case tierStatus.trial30:
      days = '30';
      break;
    case tierStatus.trial60:
      days = '60';
      break;
    case tierStatus.trial90:
      days = '90';
      break;
    default:
      key = 'pages.customer.tierStatus';
      break;
  }
  return { key, days };
};

export const getInputProps = ({ inputProps, errors, values }) => {
  const props = {};

  Object.entries(inputProps).forEach(([key, value]) => {
    props[key] = {
      ...value,
      name: key,
      isInvalid: !!errors?.[key],
      errorMessage: errors?.[key],
      value: values?.[key] || ''
    };
  });

  return props;
};

export const getUidByRole = ({ isPicardAdmin, uidFromUser, existingUid }) => {
  if (existingUid) return existingUid;
  if (isPicardAdmin) return uidFromUser;

  return '';
};

export const getErrorMessageForUser = (message) => {
  if (message.includes('cannot find user')) {
    return 'Cannot find user. Please get in touch with Product team at product@cleanslateuv.com';
  }

  if (message.includes('Customer tier does not exist')) {
    return 'Customer tier does not exist. Please edit the customer tier type and status';
  }

  if (
    message.includes(
      'Error creating item in database table: The conditional request failed'
    )
  ) {
    return 'Device already exists';
  }

  if (message.includes('Error creating user in database:')) {
    return 'User exists in database. Please add different user';
  }

  if (message.includes('User account already exists and is expired.')) {
    return 'User exists in database. Please add different user';
  }

  if (['Cannot send email Email address is not verified'].includes(message)) {
    return `Failed to send welcome email to user ${message}`;
  }

  return message;
};

export const renderPersona = (personaIds) => {
  if (Array.isArray(personaIds)) return personaIds.join('');

  return personaIds;
};

export const getAllGroups = (groupList) => {
  if (Array.isArray(groupList) && groupList.length) {
    return groupList.map((g) => g.id);
  }

  return [];
};

export const getListByDependency = (dependency, list) => {
  return dependency ? list : [];
};
