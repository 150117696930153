import Grid from '@material-ui/core/Grid';
import { iconButtonContainer } from '../../styles/common/Button';

import IconButton from './IconButton';
import { icons } from '../../constants';

function IconButtonWrapper(props) {
  return (
    <Grid item>
      <IconButton {...props} />
    </Grid>
  );
}

export default function IconButtonContainer({
  onSave,
  onEdit,
  onDelete,
  goEdit,
  onClose,
  children
}) {
  const classes = iconButtonContainer();

  return (
    <Grid container className={classes.container}>
      {children || (
        <>
          {onSave ? (
            <IconButtonWrapper icon={icons.save} onClick={onSave} />
          ) : null}
          {onEdit ? (
            <IconButtonWrapper icon={icons.save} onClick={onEdit} />
          ) : null}
          {goEdit ? (
            <IconButtonWrapper icon={icons.edit} onClick={goEdit} />
          ) : null}
          {onDelete ? (
            <IconButtonWrapper icon={icons.delete} onClick={onDelete} />
          ) : null}
          {onClose ? (
            <IconButtonWrapper icon={icons.close} onClick={onClose} />
          ) : null}
        </>
      )}
    </Grid>
  );
}
