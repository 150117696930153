import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

export default makeStyles({
  message: {
    padding: '10px 0',
    color: color.red
  }
});

export const formErrorMessageStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: color.red,

    '& svg': {
      stroke: color.red,
      marginRight: 5
    }
  }
});
