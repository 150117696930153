import { useTranslation } from 'react-i18next';

import Tooltip from '../../../common/Tooltip';
import deviceDetailStyles, {
  deviceTooltipStyles
} from '../../../../styles/DeviceDetail';

export default function Title({
  title,
  hasToolTip,
  onTooltipClick,
  tooltipMessage
}) {
  // helper
  const deviceDetailClasses = deviceDetailStyles();
  const tooltipClasses = deviceTooltipStyles();
  const { t } = useTranslation();

  const renderTooltipProps = () => {
    if (onTooltipClick) {
      return {
        onClick: onTooltipClick
      };
    }

    return {
      message: t(tooltipMessage)
    };
  };

  return (
    <>
      <span className={deviceDetailClasses.deviceInformationTitle}>
        {t(title)}
      </span>

      {hasToolTip && (
        <span className={tooltipClasses.tooltipContainer}>
          <span className={tooltipClasses.tooltipWrapper}>
            <Tooltip
              isImage={true}
              placement="right-start"
              {...renderTooltipProps()}
            />
          </span>
        </span>
      )}
    </>
  );
}
