import { makeStyles } from '@material-ui/styles';
import { color, buttonStyle, fontFamily } from '../../constants';

function getButtonColor(style) {
  const btnColor = {
    normal: color.lightBlue,
    hover: color.darkBlue,
    filled: true,
    label: color.white,
    icon: color.white,
    hoverLabel: color.white
  };

  const {
    filledRed,
    filledGreen,
    filledYellow,
    lineYellow,
    lineBlue,
    lineRed,
    lineGreen,
    filledWhiteGreen,
    filledWhiteDarkBlue,
    filledDarkBlue,
    lineWhite
  } = buttonStyle;

  switch (style) {
    case filledRed:
      return { ...btnColor, normal: color.red, hover: color.darkRed };

    case filledDarkBlue:
      return { ...btnColor, normal: color.darkBlue, hover: '#072030' };

    case filledGreen:
      return { ...btnColor, normal: color.green, hover: color.darkGreen };

    case filledYellow:
      return { ...btnColor, normal: color.yellow, hover: color.darkYellow };

    case filledWhiteGreen:
      return {
        ...btnColor,
        normal: color.white,
        hover: color.lightGrey,
        label: color.green,
        icon: color.green,
        hoverLabel: color.green,
        hoverIcon: color.green
      };

    case filledWhiteDarkBlue:
      return {
        ...btnColor,
        normal: color.white,
        hover: color.darkBlue,
        label: color.darkBlue
      };

    case lineRed:
      return {
        ...btnColor,
        normal: color.red,
        hover: color.darkRed,
        filled: false
      };

    case lineBlue:
      return {
        ...btnColor,
        normal: color.lightBlue,
        hover: color.darkBlue,
        filled: false
      };

    case lineGreen:
      return {
        ...btnColor,
        normal: color.green,
        hover: color.darkGreen,
        filled: false
      };

    case lineYellow:
      return {
        ...btnColor,
        normal: color.yellow,
        hover: color.darkYellow,
        filled: false
      };

    case lineWhite:
      return {
        ...btnColor,
        normal: color.white,
        icon: color.white,
        hover: color.darkBlue,
        filled: false,
        label: color.white,
        hoverLabel: color.darkBlue,
        hoverIcon: color.darkBlue
      };

    default:
      return btnColor;
  }
}

export const buttonStyles = (style, fullWidth, isInitialLabel, size) => {
  const colorObject = getButtonColor(style);
  const { normal, hover, filled, label, hoverLabel, icon, hoverIcon } =
    colorObject;

  return makeStyles({
    label: {
      color: label,
      fontSize: filled ? 'inherit' : 10,
      fontFamily: fontFamily.bold,
      textTransform: isInitialLabel ? 'capitalize' : 'uppercase'
    },
    startIcon: {
      '& svg': {
        fill: icon
      }
    },
    root: {
      width: fullWidth ? '100%' : 'auto',
      minWidth: size === 'small' ? 'auto' : 170,
      backgroundColor: filled ? normal : 'none',
      border: `solid 1px ${normal}`,
      padding: size === 'small' ? '3px 20px' : '5px 20px',

      '&:hover': {
        backgroundColor: filled ? `${hover} !important` : color.white,
        borderColor: hover,
        color: `${hoverLabel} !important`,

        '& $label': {
          ...(hoverLabel
            ? {
                color: `${hoverLabel} !important`
              }
            : {})
        },

        '& $startIcon': {
          '& svg': {
            fill: hoverIcon
          }
        }
      }
    }
  })();
};

export const listButtonStyles = makeStyles({
  listButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20
  }
});

export const largeButtonStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    width: '100%',
    fontFamily: fontFamily.semiBold,
    fontSize: '1rem',
    paddingLeft: 40,
    paddingRight: 40,
    height: 65,

    '&:first-child': {
      backgroundColor: color.darkBlue
    },
    '&:last-child': {
      backgroundColor: color.lightBlue
    }
  },
  descriptionContainer: {
    textAlign: 'left',
    color: color.white
  },
  arrowContainer: {
    textAlign: 'right',
    color: color.white,
    paddingTop: 6,

    '& path': {
      color: color.white
    }
  }
});

export const mobileDownloadButtonStyles = makeStyles((theme) => ({
  mobileDownloadContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '30px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    },

    '& img': {
      width: 130,
      '&:first-child': {
        marginRight: 10
      }
    }
  }
}));

export const iconButtonStyle = makeStyles({
  root: {
    width: 30,
    minWidth: 30,
    backgroundColor: color.lightBlue,
    padding: '5px 20px',

    '&:hover': {
      backgroundColor: color.darkBlue
    }
  },
  startIcon: {
    marginRight: 0,
    '& svg': {
      fill: color.white
    }
  },
  strokeIcon: {
    '& path': {
      stroke: color.white
    }
  }
});

export const iconButtonContainer = makeStyles({
  container: {
    justifyContent: 'flex-end',
    width: '100%',
    position: 'absolute',
    textAlign: 'right',

    '& button': {
      marginLeft: 10
    }
  }
});

export const groupToggleButtonStyles = makeStyles({
  text: {
    paddingLeft: 5,
    textTransform: 'capitalize'
  },
  container: {
    width: '100%',
    marginTop: 20
  },
  active: {
    backgroundColor: color.darkBlue,
    width: '50%',

    '& span': {
      fontFamily: fontFamily.bold,
      color: color.white,
      fontSize: 12
    },

    '&:hover': {
      backgroundColor: '#061f30 !important'
    }
  },
  noActive: {
    backgroundColor: color.white,
    width: '50%',

    '& span': {
      fontSize: 12
    },

    '&:hover': {
      backgroundColor: `${color.lightGrey} !important`
    }
  }
});
