import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Badge from '../../components/common/Badge';
import { alertTypes, color } from '../../constants';
import { viewDevice } from '../../paths';
import { alertStyles } from '../../styles/Dashboard';

export default function AlertNotificationMenu({ type, alerts, currentAlert }) {
  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const [current, setCurrent] = currentAlert;
  const { t } = useTranslation();
  const messageType = type === alertTypes.error ? 'error_name' : 'warning_name';
  const numAlert = type === alertTypes.error ? 'errors' : 'warnings';
  const deviceColor = type === alertTypes.error ? color.red : color.orange;
  const alertClasses = alertStyles();
  const history = useHistory();

  const handlerClick = (event) => {
    setCurrent(type);
    setAlertAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrent('');
    setAlertAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handlerClick}
        className={alertClasses.alertButton}
        disableRipple={false}
        focusVisibleClassName={alertClasses.focusVisible}
      >
        <Badge
          type={type}
          number={alerts?.[numAlert] || 0}
          isOpen={current === type}
        />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={alertAnchorEl}
        displayEmpty
        open={Boolean(alertAnchorEl)}
        onClose={handleClose}
        className={alertClasses.alertMenuContainer}
      >
        {alerts.messages
          .filter((message) => message.alert_type === type)
          .map((item, index) => {
            return (
              <MenuItem
                key={index}
                className={alertClasses.alertMenuItem}
                onClick={() =>
                  history.push({
                    pathname: `${viewDevice}/${item?.serial}`,
                    state: { serial: item?.serial, data: item, deviceList: [] }
                  })
                }
              >
                <div
                  className={alertClasses.alertDevice}
                  style={{ color: deviceColor }}
                >
                  {item.location_name}
                </div>
                <div className={alertClasses.alertSerial}>{item.serial}</div>
                <div className={alertClasses.alertDetail}>
                  {t(`errorMessage.${item?.[messageType]}`)}
                </div>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
