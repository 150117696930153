import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

export const analyticsBoxButtonStyles = makeStyles({
  container: {
    background: color.white,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    cursor: 'pointer',
    height: '100%',

    '&:hover, &.is-selected': {
      background: color.darkBlue,

      '& .title': {
        color: color.white
      },
      '& .view-more-text': {
        color: color.white
      },
      '& .view-more-container': {
        border: `solid 1px ${color.white}`
      },
      '& .view-more': {
        '& path': {
          fill: color.white
        }
      }
    }
  },
  title: {
    fontFamily: fontFamily.bold
  }
});

export const analyticsDetailStyles = makeStyles((theme) => ({
  container: {
    transition: 'all 0.5s ease'
  },
  detailContainer: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    },
    position: 'relative',
    minHeight: 500,
    marginLeft: 30
  },
  title: {
    color: color.darkBlue,
    fontFamily: fontFamily.bold,
    fontSize: 50
  },
  description: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    width: '40%',
    wordWrap: 'break-word',
    marginTop: 30
  },
  buttonContainer: {
    marginTop: 30
  },
  image: {
    width: '50%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  decoBackground: {
    backgroundColor: color.sail,
    width: '100%',
    height: 100,
    position: 'absolute',
    bottom: 0,
    borderRadius: 5
  }
}));

export const averageSanitizationInfoCardStyle = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'flex-end'
  },
  textContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 12,
    color: color.white,
    fontFamily: fontFamily.regular
  },
  dateRange: {
    fontFamily: fontFamily.bold,
    color: color.white
  },
  numberContainer: {
    textAlign: 'center'
  },
  number: {
    fontSize: 30,
    fontFamily: fontFamily.bold,
    color: color.darkBlue,
    textAlign: 'right'
  }
});

export const weekUsageStyles = makeStyles((theme) => {
  return {
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: 25,

      '& svg': {
        fill: color.dimGrey
      }
    },
    title: {
      fontFamily: fontFamily.semiBold,
      color: color.darkBlue
    },

    container: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-around'
    },

    dayPercentContainer: {
      maxWidth: '14.2%',
      alignItems: 'self-start',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    dayTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      width: '100%',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        flexGrow: 1
      }
    },

    // day
    day: {
      fontSize: '0.8em',
      color: color.davyGrey,
      fontFamily: fontFamily.bold,

      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    },

    // percent
    percentContainer: {
      minWidth: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20
    },

    zero: {
      backgroundColor: color.lightBlue, // color.red and alpha 0.3
      '& $percent': {
        color: color.white
      },
      '& svg': {
        color: color.white
      }
    },
    decrease: {
      backgroundColor: 'rgba(234, 66, 56, 0.4)', // color.red and alpha 0.3
      '& $percent': {
        color: color.darkRed
      },
      '& svg': {
        stroke: color.darkRed
      }
    },
    increase: {
      backgroundColor: 'rgba(93, 195, 181, 0.4)', // color.lightBlue and alpha 0.3

      '& $percent': {
        color: color.darkGreen
      },
      '& svg': {
        stroke: color.darkGreen
      }
    },
    percent: {
      fontSize: 12,
      paddingLeft: 5,
      fontFamily: fontFamily.bold
    },

    // current number
    numCurrent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '2em',
      width: '100%',
      fontFamily: fontFamily.bold,
      textAlign: 'center',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center'
      }
    }
  };
});

export const noDataMessageStyles = makeStyles({
  link: {
    color: color.red,
    textDecoration: 'underline'
  }
});
