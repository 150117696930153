import { makeStyles } from '@material-ui/styles';
// import { color } from '../../constants';

export default makeStyles({
  root: {
    padding: 10,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  }
});
