import Grid from '@material-ui/core/Grid';

export default function ArrayContentsRender({ data }) {
  if (Array.isArray(data) && data.length) {
    if (data.length > 5) {
      return (
        <Grid container>
          {data.map((item, key) => (
            <Grid key={key} item lg={12} md={12} sm={12} xs={12}>
              <span className="fs-mask">{item}</span>
            </Grid>
          ))}
        </Grid>
      );
    }
    return Object.values(data).map((item, key) => (
      <div key={key}>
        <span className="fs-mask">{item}</span>
      </div>
    ));
  }

  return '';
}
