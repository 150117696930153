import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

// components

import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { filterState } from '../../recoil/atom/filter';
import { getFilterOptionByKey } from '../../helper/filter';
import { getViewMoreOption } from '../../helper/table';
import { groupState } from '../../recoil/atom/admin';
import { setTableColumns } from '../../helper/tableOptions';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import Button from '../../components/Button/Button';
import Card from '../../components/common/Card';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import Filter from '../../components/Filter/Filter';
import IconButton from '../../components/Button/IconButton';
import Modal from '../../components/common/Modal';
import OutlineSelect from '../../components/Input/OutlineSelect';
import TableContainer from '../../components/common/Table/TableContainer';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';

// etc
import {
  apiActionTypes,
  buttonStyle,
  filterType,
  icons,
  responseKey,
  toastType
} from '../../constants';
import { adminManageGroup } from '../../paths';
import groupStyles from '../../styles/Group';

function Groups() {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [groupList, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const user = useRecoilValue(userState);
  const group = useRecoilValue(groupState);
  const groupClasses = groupStyles();
  let columns =
    groupList?.length > 0 &&
    setTableColumns(Object.keys(groupList[0]), user, t);
  const filterRecoil = useRecoilValue(filterState);

  const { isGroupAdmin, isPicardAdmin } = user.getUserPermission();

  const contentColumns = { lg: 12, md: 12, sm: 12, xs: 12 };

  // Table Row on click event
  const onRowClick = (rowData) => {
    return history.push({
      pathname: adminManageGroup,
      state: { data: rowData }
    });
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      newGroupId: '',
      groupUid: '',
      customerUid: ''
    }
  });

  if (columns) {
    columns =
      columns &&
      columns.filter(
        (column) =>
          column.field !== 'created_at' && column.field !== 'deviceList'
      );
    columns.push({
      field: 'deviceList',
      title: t('common.devices'),
      cellStyle: {
        width: '40%',
        maxWidth: '40%'
      },
      headerStyle: {
        width: '40%',
        maxWidth: '40%'
      }
    });
    columns.push({ field: 'created_at', title: t('table.createdDate') });
    columns.push(getViewMoreOption(onRowClick));
  }

  const onDelete = async (rowData) => {
    setIsOpen(true);
    formik.setFieldValue('groupUid', rowData?.[responseKey.groupUid]);
    formik.setFieldValue('customerUid', rowData?.[responseKey.customerUid]);
  };

  const onModalDelete = async () => {
    try {
      // Delete validation
      if (!formik.values.newGroupId) {
        setShowError(true);
        throw new Error('errorMessage.pleaseSelectOneGroup');
      }

      let tempDeviceList = [];
      let tempUserList = [];

      // re-assign device to new groupUid
      const tempGroupList = groupList
        .map((item) => {
          if (item[responseKey.groupUid] === formik.values.groupUid) {
            tempDeviceList = item.deviceList;
            tempUserList = item.userList;
            return null;
          }
          if (item[responseKey.groupUid] === formik.values.newGroupId) {
            return {
              ...item,
              deviceList: [...new Set([...tempDeviceList, ...item.deviceList])],
              userList: [...new Set([...tempUserList, ...item.userList])]
            };
          }
          return item;
        })
        .filter((item) => item);

      const response = await callAPI(`/group-management`, {
        type: apiActionTypes.DELETE,
        groupUid: formik.values.groupUid,
        newGroupUid: formik.values.newGroupId,
        customerUid: formik.values.customerUid,
        groupUsers: tempUserList,
        csuvSerial: tempDeviceList
      });

      if (response?.status !== 200) {
        throw new Error(t('errorMessage.somethingWentWrong'));
      }

      setGroups(tempGroupList);
      setIsOpen(false);
      setToast({
        message: t('toastMessage.groupDeleteSuccess'),
        type: toastType.success,
        open: true
      });
      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  // Save button on click
  const onSave = () =>
    history.push({ pathname: adminManageGroup, state: 'new' });

  // table option
  const tableProps = {
    columns,
    rows: groupList,
    hasDownload: true,
    tableName: t('menuName.groups'),
    searchQuery: group.filterOptions,
    hasDelete: isGroupAdmin,
    onRowClick,
    onDelete
  };

  const getData = async () => {
    setLoading(true);

    try {
      const response = await callAPI(`/group-management`, {
        type: apiActionTypes.FETCH,
        customerUid: group?.filterOptions?.id
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      if (!Array.isArray(response?.data.group_data)) {
        throw new Error('errorMessage.noDataFound');
      }

      const modifiedGroupList = response.data?.group_data.map((groupItem) => {
        let userList = [];
        const deviceList = response.data?.csuv_data
          .map((groupDevice) => {
            if (
              groupItem?.[responseKey.groupUid] ===
              groupDevice?.[responseKey.groupUid]
            ) {
              return groupDevice.csuv_serial;
            }

            return null;
          })
          .filter((item) => item);

        for (
          let index = 0;
          index < response.data?.user_data.length;
          index += 1
        ) {
          const groupUser = response.data?.user_data[index];
          userList = groupUser[responseKey.groupUids]
            .map((groupInfo) => {
              if (
                groupItem?.[responseKey.groupUid] ===
                groupInfo?.[responseKey.groupUid]
              ) {
                return groupUser.username;
              }

              return null;
            })
            .filter((item) => item);
        }

        return {
          ...groupItem,
          deviceList,
          userList
        };
      });

      setGroups(modifiedGroupList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (group?.filterOptions?.id || isPicardAdmin) {
      getData();
    }

    // customer uid is dependency
  }, [group?.filterOptions?.id]);

  useSegment();

  const filterOptions = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 6, md: 6, sm: 6, xs: 12 },
    hasTitle: false,
    largeHorizontal: true,
    noApply: true
  };

  const headingOptions = {
    title: t('menuName.groups'),
    description: (
      <Trans
        i18nKey="pages.groups.description"
        components={{
          strong: <span className={groupClasses.groupPageDescription} />,
          br: <br />
        }}
        values={{ date: user?.tierRemainDays }}
      />
    ),

    leftButton: group?.permission.edit && (
      <IconButton icon={icons.addNew} onClick={onSave} />
    )
  };

  return (
    <>
      {/* Section Heading */}
      <ContentHeading {...headingOptions} />

      {group?.permission.edit && isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          title={t('errorMessage.pleaseSelectOneGroup')}
        >
          <OutlineSelect
            {...columns}
            errorMessage="errorMessage.selectGroup"
            isInvalid={!formik.values?.newGroupId && showError}
            isRequired={true}
            label="label.group"
            name="newGroupId"
            onChange={formik.handleChange}
            value={formik.values.newGroupId}
            menuItemArray={getFilterOptionByKey({
              filterList: filterRecoil,
              key: filterType.groups,
              excludeId: formik.values?.groupUid
            })}
          />
          <Button
            description={t('label.confirmDelete')}
            onClick={onModalDelete}
            fullWidth={true}
          />
        </Modal>
      )}

      <Grid container spacing={2}>
        {!isPicardAdmin && (
          <Grid item {...contentColumns}>
            <Card style={buttonStyle.filledBlue}>
              <Filter {...filterOptions} listOptions={{ customer: true }} />
            </Card>
          </Grid>
        )}

        <Grid item {...contentColumns}>
          {/* Section Table */}
          {!loading ? (
            <TableContainer tableProps={tableProps} />
          ) : (
            <CardContentLoader />
          )}
        </Grid>
      </Grid>
      {/* 
      <LayoutOverview.Contents
        filterOptions={filterOptions}
        tableProps={tableProps}
        loading={loading}
      /> */}
    </>
  );
}

export default withContent(Groups);
