import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

export default makeStyles({
  deleteContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 23,
    justifyContent: 'center',

    '& svg': {
      fill: color.red
    }
  }
});
