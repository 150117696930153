import React from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../../components/common/Title';
import { manageCustomerSchema } from '../../helper/validation';
import Form from './Form';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import withAdminManage from '../../components/hoc/withAdminManage';

// for customer
import useCustomerData from './useCustomerData';
import useCustomerInputData from './useCustomerInputData';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../helper/admin/page';

function ManageCustomers({
  t,
  location,
  type,
  filterList,
  user,

  // func
  onDelete,
  onEdit,
  onSave,
  onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit
}) {
  const { inheritedData, getDeleteReq, getEditReq, getCreateReq } =
    useCustomerData({
      data: location?.state?.data,
      user
    });
  const inputProps = useCustomerInputData(user);

  const formik = useFormik({
    initialValues: { ...inheritedData },
    validationSchema: manageCustomerSchema(),

    onSubmit: (values) => {
      return isEdit ? onEdit(getEditReq(values)) : onSave(getCreateReq(values));
    }
  });

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      onEdit: formik.handleSubmit,
      onSave: formik.handleSubmit,
      onDelete: () =>
        onDelete(getDeleteReq(location?.state?.data.customer_uid)),
      onClose
    }
  });

  return (
    <>
      <ContentHeading
        title={t('menuName.customers')}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            {...formik}
            isEdit={isEdit}
            filterList={filterList}
            inputPropData={inputProps}
          />
        </Grid>
      </Grid>
    </>
  );
}

// hoc will manage type of value
export default withAdminManage('customer')(ManageCustomers);
