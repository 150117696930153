import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { RecoilRoot } from 'recoil';
import { IntercomProvider } from 'react-use-intercom';

import App from './App';
import ThemeContainer from './styles/ThemeContainer';
import { cognito, region, intercom } from './config';
import { getTemporaryVisible } from './helper/page';
import './locale/index';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/common/Table.css';
import 'core-js/features/string/repeat';
import 'abortcontroller-polyfill';

const authConfig = {
  region,
  secure: true
};

Amplify.configure({
  Auth: { ...authConfig, ...cognito }
});

ReactDOM.render(
  getTemporaryVisible() ? (
    <IntercomProvider appId={intercom} autoBoot>
      <RecoilRoot>
        <ThemeContainer>
          <Suspense fallback={<div>Loading... </div>}>
            <App />
          </Suspense>
        </ThemeContainer>
      </RecoilRoot>
    </IntercomProvider>
  ) : (
    <RecoilRoot>
      <ThemeContainer>
        <Suspense fallback={<div>Loading... </div>}>
          <App />
        </Suspense>
      </ThemeContainer>
    </RecoilRoot>
  ),
  document.getElementById('root')
);
