import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

export default makeStyles({
  title: {
    fontSize: '1em',
    fontFamily: fontFamily.semiBold,
    marginBottom: 20
  },
  value: {
    color: color.lightBlue,
    fontFamily: fontFamily.bold,
    paddingLeft: 5,
    fontSize: '1.5em'
  },

  unitValueContainer: {
    position: 'relative'
  },

  valueContainer: {
    display: 'inline-block',
    // minWidth: 70,
    position: 'relative'
  },

  markLabel: {
    color: color.iconGrey,

    '&[data-index="0"]': {
      transform: 'translateX(0)'
    },

    '&[data-index="1"]': {
      textAlign: 'right',
      width: 100,
      paddingRight: 48
    }
  },

  tooltipContainer: {
    position: 'absolute',
    top: -13,

    '& svg': {
      fill: color.dimGrey
      // position: 'absolute'
      // top: -3,
      // left: 10
    }
  }
});

export const yearPercentStyles = makeStyles({
  year: {
    fontFamily: fontFamily.bold
  },
  value: {
    fontFamily: fontFamily.bold,
    color: color.white,
    paddingLeft: 5,
    fontSize: '1.4em'
  }
});

export const numDeviceStyles = makeStyles({
  num: {
    fontFamily: fontFamily.bold,
    color: color.white,
    fontSize: '3em',
    textAlign: 'center'
  }
});

export const backBtnStyles = makeStyles({
  icon: {
    '& path': {
      color: color.white
    }
  }
});
