import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

// Regular
import csuvRegularEot from '../assets/fonts/EuclidTriangle-Regular.eot';
import csuvRegularSvg from '../assets/fonts/EuclidTriangle-Regular.svg';
import csuvRegularTtf from '../assets/fonts/EuclidTriangle-Regular.ttf';
import csuvRegularWoff from '../assets/fonts/EuclidTriangle-Regular.woff';
import csuvRegularWoff2 from '../assets/fonts/EuclidTriangle-Regular.woff2';

import csuvBoldEot from '../assets/fonts/EuclidTriangle-Bold.eot';
import csuvBoldSvg from '../assets/fonts/EuclidTriangle-Bold.svg';
import csuvBoldTtf from '../assets/fonts/EuclidTriangle-Bold.ttf';
import csuvBoldWoff from '../assets/fonts/EuclidTriangle-Bold.woff';
import csuvBoldWoff2 from '../assets/fonts/EuclidTriangle-Bold.woff2';

import csuvSemiBoldEot from '../assets/fonts/EuclidTriangle-SemiBold.eot';
import csuvSemiBoldSvg from '../assets/fonts/EuclidTriangle-SemiBold.svg';
import csuvSemiBoldTtf from '../assets/fonts/EuclidTriangle-SemiBold.ttf';
import csuvSemiBoldWoff from '../assets/fonts/EuclidTriangle-SemiBold.woff';
import csuvSemiBoldWoff2 from '../assets/fonts/EuclidTriangle-SemiBold.woff2';

const { regular, semiBold, bold } = fontFamily;

// Global style media query - without menu
// [theme.breakpoints.down('xs')]: {
// },
// [theme.breakpoints.up('sm')]: {
// },
// [theme.breakpoints.up('lg')]: {
// }

const csuvRegular = {
  fontFamily: regular,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    url('${csuvRegularEot}?#iefix') format('embedded-opentype'),
    url(${csuvRegularWoff2}) format('woff2'),
    url(${csuvRegularWoff}) format('woff'),
    url(${csuvRegularTtf}) format('truetype'),
    url(${csuvRegularSvg}) format('svg');
  `
};

const csuvBold = {
  fontFamily: bold,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    url('${csuvBoldEot}?#iefix') format('embedded-opentype'),
    url(${csuvBoldWoff2}) format('woff2'),
    url(${csuvBoldWoff}) format('woff'),
    url(${csuvBoldTtf}) format('truetype'),
    url(${csuvBoldSvg}) format('svg');
  `
};

const csuvSemiBold = {
  fontFamily: semiBold,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    url('${csuvSemiBoldEot}?#iefix') format('embedded-opentype'),
    url(${csuvSemiBoldWoff2}) format('woff2'),
    url(${csuvSemiBoldWoff}) format('woff'),
    url(${csuvSemiBoldTtf}) format('truetype'),
    url(${csuvSemiBoldSvg}) format('svg');
  `
};

export const theme = createTheme({
  typography: {
    fontFamily: regular // specifying a new font
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [csuvRegular, csuvBold, csuvSemiBold],
        '*': {
          boxSizing: 'border-box',
          color: color.default,
          transitionProperty: 'background-color, box-shadow',
          transitionDuration: '.2s'
        },
        body: {
          overflowY: 'scroll !important',
          backgroundColor: color.backgroundGrey
        },
        'html, body': {
          height: '100%',
          margin: 0,
          padding: 0,
          minHeight: 600
        },
        'a, a:hover, a:focus, a:visited, a:active': {
          color: 'inherit',
          textDecoration: 'none'
        },
        button: {
          background: 'none',
          border: 'none',
          outline: 'none'
        },
        '#root': {
          minHeight: '100%'
        },
        'ul, li': {
          margin: 0,
          padding: 0,
          listStyle: 'none'
        }
      }
    },
    MuiTypography: {
      h1: {
        fontSize: '2rem',
        fontFamily: bold
      },
      h2: {
        fontSize: '1.7rem',
        fontFamily: bold
      },
      h3: {
        fontSize: '1.4rem',
        fontFamily: semiBold
      },
      h4: {
        fontSize: '1.1rem',
        fontFamily: semiBold
      }
    }
  }
});

export default function ThemeContainer({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
