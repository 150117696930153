import React from 'react';

// components
import { getPageTitle } from '../../../helper/admin/page';
import TableContainer from '../../common/Table/TableContainer';
import useSegment from '../../../hooks/useSegment';
import useTable from './hooks/useTable';

// this component is for simple admin list
function AdminWithList({
  t,
  type,
  user,
  data,
  loading,

  // route
  onRowClick,
  onRowDelete,

  // permission
  hasDelete,

  // page options
  pageColumn,
  actions
}) {
  // third party
  useSegment();

  // hook
  const tableProps = useTable({
    data,
    user,

    // function
    onRowClick,
    onRowDelete,

    // permission
    hasDelete,

    // table utils
    tableName: t(getPageTitle(type)),
    pageColumn,

    actions
  });

  return <TableContainer tableProps={tableProps} loading={loading} />;
}

export default AdminWithList;
