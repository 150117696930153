import { selector } from 'recoil';
import { filterState } from '../atom/filter';
import { userState } from '../state';
import { dashboardState } from '../atom/dashboard';
import { averageState } from '../atom/average';
import { comparisonState } from '../atom/comparison';
import { trendState } from '../atom/trend';
import { useStatisticState } from '../atom/useStatistic';
import { notificationState } from '../atom/notification';
import { roiCalculatorState } from '../atom/roiCalculator';
import { deviceState, viewDeviceState } from '../atom/device';
import {
  partnerState,
  customerState,
  groupState,
  userAdminState
} from '../atom/admin';
import { reportState, scanRecordState } from '../atom/report';

export const resetRecoil = selector({
  key: 'resetRecoil',
  get: () => null,
  set: ({ reset }) => {
    // page state
    reset(dashboardState);
    reset(averageState);
    reset(comparisonState);
    reset(trendState);
    reset(useStatisticState);

    reset(deviceState);
    reset(viewDeviceState);

    reset(partnerState);
    reset(customerState);
    reset(groupState);
    reset(userAdminState);

    reset(reportState);
    reset(scanRecordState);

    reset(notificationState);
    reset(roiCalculatorState);

    // global
    reset(filterState);
    reset(userState);
  }
});
