import React from 'react';

export default function useEstimator({ roiCalculator }) {
  // expansion estimator state
  const [costPerUnit, setCostPerUnit] = React.useState(
    0 + parseFloat(roiCalculator.costPerUnit)
  );
  const [numOfDevice, setNumUnit] = React.useState(1); // not sure if it is new device or sum of current unit +new unit

  const handleCostPerUnit = (value) => {
    setCostPerUnit(value);
  };
  const handleNumUnit = (value) => {
    setNumUnit(value);
  };

  const handleReset = () => {
    setCostPerUnit(0 + parseFloat(roiCalculator.costPerUnit));
    setNumUnit(1);
  };

  return {
    // values
    estimatorData: {
      ...roiCalculator,
      estNumUnit: numOfDevice,
      estNewCostPerUnit: costPerUnit
    },

    costPerUnit,
    numOfDevice: roiCalculator.numOfDevice,

    handleCostPerUnit,
    handleNumUnit,
    handleReset
  };
}
