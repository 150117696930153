export default {
  bulb_change_date: 'table.changeBulbsBy',
  connectivity: 'label.connectivity',
  contact_email: 'label.email',
  contact_name: 'table.contactName',
  contact_phone: 'label.phoneNumber',
  created_at: 'table.createdDate',
  csuv_asset_id: 'pages.device.assetId',
  customer_address: 'pages.device.address',
  customer_name: 'common.customer',
  deployed_image: 'label.deployedImage',
  first_name: 'label.firstName',
  group_name: 'table.groupName',
  is_deleted: '',
  join_date: 'label.joinDate',
  last_login: 'label.lastLogin',
  last_name: 'label.lastName',
  location_name: 'common.location',
  login_count: 'common.loginCount',
  model_type: 'label.modelType',
  permission_level: 'label.permission',
  persona_id: 'label.userPersona',
  phone: 'label.phoneNumber',
  sanitizer_mount_type: 'label.sanitizerMountType',
  serial: 'pages.device.serialNumber',
  signage: 'label.signage',
  size_mount_type: 'label.sizeMountType',
  status: 'table.status',
  tier_status: 'label.tierStatus',
  tier_type: 'label.tier',
  total_uses: 'common.totalUses',
  tz: 'common.timezone',
  username: 'label.email'
};
