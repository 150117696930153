import React from 'react';
import { Formik } from 'formik';
import { format, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// component
import { roiCalculatorSchema } from '../../helper/validation';
import Button from '../../components/Button/Button';
import CustomDatePicker from '../../components/Input/CustomDatePicker';
import TextFieldWithIcon from '../../components/Input/TextFieldWithIcon';

// etc
import { roiCalculatorForm } from '../../styles/ROICalculator';
import { roiTextFieldStyle } from '../../styles/Input/TextField';

export default function CalculatorForm({
  onSubmit,
  isEstimator,
  // val
  roiCalculator
}) {
  const txtClasses = roiTextFieldStyle();
  const formClasses = roiCalculatorForm();
  const { t } = useTranslation();

  const formColumns = !isEstimator ? { md: 3, xs: 6 } : { md: 4, xs: 6 };

  return (
    <Formik
      initialValues={{
        startDate: roiCalculator.startDate, // by default, start date is a week before today
        endDate: roiCalculator.endDate,
        costPerUnit: roiCalculator.costPerUnit,
        costLabor: roiCalculator.costLabor
      }}
      validationSchema={roiCalculatorSchema}
      onSubmit={(values) => onSubmit({ ...values })}
    >
      {(props) => {
        return (
          <form onSubmit={props.handleSubmit} data-testid="calculator-form">
            <Grid container spacing={3}>
              <Grid item md={10} xs={12}>
                <Grid container spacing={2}>
                  <Grid item {...formColumns}>
                    {/* start date */}
                    <CustomDatePicker
                      // start date range must be less than 6 months
                      className="roi-date-picker"
                      endDate={props.values.endDate}
                      label={t('label.startDate')}
                      maxDate={new Date()}
                      minDate={subMonths(new Date(props.values.startDate), 6)}
                      selected={props.values.endDate}
                      selectsEnd
                      startDate={props.values.startDate}
                      testID="roi-startDate"
                      value={`${format(props.values.startDate, 'yyyy-MM-dd')}`}
                      variant={'outlined'}
                      onChange={(date) =>
                        props.setFieldValue('startDate', date)
                      }
                    />
                  </Grid>
                  <Grid item {...formColumns}>
                    {/* end date */}
                    <CustomDatePicker
                      className="roi-date-picker"
                      endDate={props.values.endDate}
                      label={t('label.endDate')}
                      maxDate={new Date()} // today
                      minDate={props.values.startDate}
                      onChange={(date) => props.setFieldValue('endDate', date)}
                      selected={props.values.endDate}
                      selectsEnd
                      startDate={props.values.startDate}
                      testID="roi-endDate"
                      value={`${format(props.values.endDate, 'yyyy-MM-dd')}`}
                      variant={'outlined'}
                    />
                  </Grid>

                  {!isEstimator ? (
                    <Grid item {...formColumns}>
                      <TextFieldWithIcon
                        className={txtClasses.container}
                        adornment="$"
                        label={'Quote per unit'}
                        maskType="amount"
                        name="costPerUnit"
                        onChange={props.handleChange}
                        testID="roi-quote-unit"
                        type="number"
                        value={props.values.costPerUnit}
                      />
                    </Grid>
                  ) : null}
                  <Grid item {...formColumns}>
                    <TextFieldWithIcon
                      className={txtClasses.container}
                      adornment="$"
                      label={'Cost labour per hour'}
                      maskType="amount"
                      name="costLabor"
                      onChange={props.handleChange}
                      testID="roi-cost-labour"
                      type="number"
                      value={props.values.costLabor}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={2} xs={12} className={formClasses.buttonContainer}>
                <Button
                  description="Calculate"
                  fullWidth={true}
                  type="submit"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}
