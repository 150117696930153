import { roiSummaryStyles } from '../../styles/ROICalculator';

export default function Summary({ costLabor }) {
  const classes = roiSummaryStyles();

  return (
    <div>
      <h4 className={classes.title}>
        Calculated by directly comparing the average use of our chemical-free
        solution to standard germicidal wipes.
      </h4>

      <ol className={classes.summaryList}>
        <li>
          <p>
            Average Daily Use and Environmental Impact derived from data
            retrieved
          </p>
        </li>
        <li>
          <p>
            <span>Average of 1.75 devices</span> per CleanSlate cycle
          </p>
        </li>
        <li>
          <p>
            Savings calculated with the following cost factors: Each wipe event
            assumes <span>$0.08 per wipe</span> and{' '}
            <span>$0.02 per pair of gloves</span>.
          </p>
          <p>
            The cost of labour assumes <span>${costLabor}/hr.</span> The average
            staff handling time with chemical wipes is <span>90-s (min)</span>{' '}
            per device
          </p>
        </li>
        <li>
          <p>
            Assumes a <span>$800</span> In yearly bulbs and parts/maintenance
            and <span>${costLabor}/hr</span> for labour/cycle time.
          </p>
        </li>
      </ol>
    </div>
  );
}
