import { makeStyles } from '@material-ui/styles';
import { color, buttonStyle, fontFamily } from '../../constants';

const defaultCardStyle = {
  cardBackgroundColor: color.white,
  cardHeaderColor: color.lightBlue
};

function getCardStyle(style) {
  const {
    filledBlue,
    filledGreen,
    filledRed,
    filledYellow,
    filledDarkBlue,
    filledLightBlue
  } = buttonStyle;
  switch (style) {
    case filledDarkBlue:
      return {
        cardBackgroundColor: color.darkBlue,
        cardHeaderColor: color.white
      };

    case filledLightBlue:
      return {
        cardBackgroundColor: color.lightBlue,
        cardHeaderColor: color.white
      };

    case filledBlue:
      return {
        cardBackgroundColor: color.green,
        cardHeaderColor: color.white
      };

    case filledGreen:
      return {
        cardBackgroundColor: color.green,
        cardHeaderColor: color.white
      };

    case filledRed:
      return { cardBackgroundColor: color.red, cardHeaderColor: color.white };

    case filledYellow:
      return {
        cardBackgroundColor: color.yellow,
        cardHeaderColor: color.darkGreen
      };

    default:
      return defaultCardStyle;
  }
}

export default (props) => {
  const { cardBackgroundColor, cardHeaderColor } = getCardStyle(props);

  return makeStyles({
    cardContainer: {
      borderRadius: 4,
      overFlowX: 'hidden',
      backgroundColor: cardBackgroundColor,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
      overflow: 'auto',
      padding: 16,
      width: '100%',
      // height: '100%',

      '& .sub-heading-h3': {
        color: cardHeaderColor
      }
    },
    cardContent: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,

      '&:last-child': {
        paddingBottom: 0
      }
    }
  })();
};

export const noDataCardStyles = makeStyles({
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center'
  }
});

export const latestDateStyles = makeStyles({
  latestDateContainer: { marginBottom: 5 },
  alignItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  clock: {
    marginRight: 5
  },
  latestTitle: {
    color: color.fontGrey,
    fontFamily: fontFamily.regular,
    fontWeight: 'normal',
    marginRight: 5
  },
  latestDate: {
    fontFamily: fontFamily.bold,
    color: color.dimGrey
  }
});

export const accordionStyles = makeStyles({
  root: {
    margin: 0,
    marginBottom: 16,

    '&.Mui-expanded': {
      minHeight: 0
    }
  },
  summaryButton: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  summaryContainer: {
    display: 'flex'
  },
  summaryRoot: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  content: {
    margin: 0
  },
  expanded: {
    margin: 0
  }
});

export const deviceInfoCardStyles = makeStyles({
  iconContainer: {
    width: 13,

    '& svg': {
      marginLeft: -7
    }
  },
  link: {
    fontSize: 13,
    color: color.white,
    textDecoration: 'underline',

    '&:visited': {
      color: color.white
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10
  },
  expandButtonContainer: {
    justifySelf: 'end',
    flexGrow: 1,
    textAlign: 'end'
  },
  headerContainer: {
    minWidth: 130,
    display: 'flex'
  }
});
