import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

// components
import { ContentHeading } from '../../components/common/Title';
import CustomDateRange from '../../components/common/CustomDateRange';
import AnalyticsBoxButton from './AnalyticsBoxButton';
import Modal from '../../components/common/Modal';
import withContent from '../../components/hoc/withContent';
import useSegment from '../../hooks/useSegment';
import Detail from './Detail';
import { analyticsType } from '../../constants';
import { averages, comparisons, trends, useStatistics } from '../../paths';

const initDate = {
  startDate: new Date(),
  endDate: new Date()
};

function Analytics() {
  const { average, trend, usage, compare } = analyticsType;
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(average);
  const { t } = useTranslation();

  useSegment();

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          title={t('modal.customDateRange.title')}
        >
          <CustomDateRange
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            onClose={() => setIsOpen(false)}
          />
        </Modal>
      )}

      <ContentHeading title={t('menuName.analytics')} />

      <Grid container spacing={3}>
        <Grid item lg={3} md={12} container spacing={3}>
          <AnalyticsBoxButton
            isSelected={currentItem === average}
            link={averages}
            onMouseOver={() => setCurrentItem(average)}
            title="menuName.averages"
          />
          <AnalyticsBoxButton
            isSelected={currentItem === compare}
            link={comparisons}
            onMouseOver={() => setCurrentItem(compare)}
            title="menuName.comparisons"
          />
          <AnalyticsBoxButton
            isSelected={currentItem === trend}
            link={trends}
            onMouseOver={() => setCurrentItem(trend)}
            title="menuName.trends"
          />
          <AnalyticsBoxButton
            isSelected={currentItem === usage}
            link={useStatistics}
            onMouseOver={() => setCurrentItem(usage)}
            title="menuName.useStatistics"
          />
        </Grid>

        <Detail currentItem={currentItem} />
      </Grid>
    </>
  );
}

export default withContent(Analytics);
