export default {
  userState: 'userState',
  analyticsState: 'analyticsState',
  averageState: 'averageState',
  compareState: 'compareState',
  useStatState: 'useStatState',
  selectedTitle: 'selectedTitle',
  filterOptions: 'filterOptions',
  selector: {
    filter: 'filter'
  }
};

export const common = {
  commonState: 'commonState'
};

export const filter = {
  filterState: 'filterState'
};

export const alerts = {
  alertState: 'alertState'
};

export const dashboard = {
  latestActivityState: 'latestActivityState',
  dashboardState: 'dashboardState',
  dashboardAlertState: 'dashboardAlertState'
};

export const device = {
  deviceState: 'deviceState',
  viewDeviceState: 'viewDeviceState'
};

export const average = {
  averageState: 'averageState'
};

export const useStatistic = {
  useStatisticState: 'useStatisticState'
};

export const comparison = {
  comparisonState: 'comparisonState'
};

export const trend = {
  trendState: 'trendState'
};

export const report = {
  reportState: 'reportState',
  scanRecordState: 'scanRecordState',
  scheduledState: 'scheduledState'
};

export const admin = {
  partnerState: 'partnerState',
  customerState: 'customerState',
  groupState: 'groupState',
  userState: 'usersState'
};

export const notification = {
  notificationState: 'notificationState'
};

export const savings = {
  roiCalculator: 'roiCalculator',
  estimator: 'estimator'
};
