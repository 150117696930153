import { IoMdHelpCircleOutline } from 'react-icons/io';
import List from '@material-ui/core/List';

// comp
import MenuItem from './MenuItem';
import MenuItemWithSubMenu from './MenuItemWithSubMenu';

// etc
import menuStyles from '../../styles/common/Menu';
import paths from '../../paths';

const { support, knowledgeBase, contact } = paths;

const menuArray = [
  {
    hasSubMenu: false,
    icon: <IoMdHelpCircleOutline />,
    selectedMenu: true,
    link: support,
    text: 'menuName.support'
  }
];

export default function SettingMenu() {
  const classes = menuStyles();

  return (
    <List
      classes={{
        root: classes.settingMenuContainer
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menuArray.map((item, index) => {
        if (item.hasSubMenu) {
          return (
            <MenuItemWithSubMenu
              isOpen={[knowledgeBase, contact].includes(
                window.location.pathname
              )}
              item={item}
              key={index}
            />
          );
        }

        return <MenuItem key={index} {...item} />;
      })}
    </List>
  );
}
