import React from 'react';
import Grid from '@material-ui/core/Grid';

import {
  renderNoFoundMessage,
  renderSubInformationList
} from '../../helper/roi/roi';

import CompareCostPerSanitization from '../../components/pages/ROICalculator/CompareCostPerSanitization';
import LayoutROIResult from '../../layout/LayoutROIResult';
import NoDataFound from '../../components/common/NoDataFound';
import SubInformation from '../../components/pages/ROICalculator/SubInformation';
import useDateRange from '../../hooks/useDateRange';
import YearBox from './YearBox';

export default function EstimatorResult({
  calculated,
  roiCalculator,
  submitted
}) {
  const noFoundMessage = renderNoFoundMessage({
    submitted,
    ...roiCalculator,
    ...calculated
  });

  const dateRangeObj = useDateRange({
    data: calculated?.csuvCleaning?.yearsPercent,
    startDate: roiCalculator?.startDate,
    calculated,
    roiCalculator
  });

  if (noFoundMessage)
    return (
      <Grid item xs={12}>
        <NoDataFound message={noFoundMessage} fullHeight={true} />
      </Grid>
    );

  return (
    <LayoutROIResult>
      {/* Left */}

      <CompareCostPerSanitization data={dateRangeObj?.compareChartData} />

      <Grid item xs={12}>
        <YearBox data={calculated.savings} />
      </Grid>

      <LayoutROIResult.LeftBottom>
        <SubInformation list={renderSubInformationList(calculated)} />
      </LayoutROIResult.LeftBottom>
    </LayoutROIResult>
  );
}
