import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import { ContentHeading } from '../../components/common/Title';
import { getPageTitle } from '../../helper/admin/page';
import AdminWithList from '../../components/pages/Admin/AdminWithList';
import IconButton from '../../components/Button/IconButton';
import usePartnerData from '../ManagePartner/usePartnerData';
import withAdminManage from '../../components/hoc/withAdminManage';

import { icons, adminType } from '../../constants';
import { home } from '../../paths';

function Partners({
  // func
  onDelete,
  ...props
}) {
  const history = useHistory();
  const { getDeleteReq } = usePartnerData();

  const onRowDelete = (values) => {
    onDelete(getDeleteReq(values.partner_uid));
  };

  React.useEffect(() => {
    if (!props?.user?.getUserPermission().isRiker) {
      return history.push(home);
    }

    props.getData();
    return null;
  }, []);

  return (
    <>
      <ContentHeading
        title={props.t(getPageTitle(adminType.partner))}
        leftButton={
          props.hasCreate ? (
            <IconButton icon={icons.addNew} onClick={props.onRowSave} />
          ) : null
        }
      />

      <AdminWithList
        {...props}
        type={adminType.partner}
        onRowDelete={onRowDelete}
      />
    </>
  );
}

export default withAdminManage('partner')(Partners);
