import { convertFloat } from './csuvCalculator';

// Material cost calculation
export const getDailyCleaningCyclePerDay = ({
  totalCSUVCleanCycle,
  cleanedCycle,
  days
}) => {
  return (totalCSUVCleanCycle * cleanedCycle) / days;
};

export const getMaterialTotalCostPerDay = (
  dailyCleaningCycles,
  avgCostPerCycle
) => {
  return dailyCleaningCycles * avgCostPerCycle;
};

// labour cost calculation
export const getLabourTimePerDay = (dailyCleaningCycles, avgCostPerCycle) => {
  return (dailyCleaningCycles * avgCostPerCycle) / 60 / 60;
};

export const getLabourTotalCostPerDay = (time, costLabour) => {
  return time * costLabour;
};

// get total year of wipe (totalCostPerDay = material + labour)
export const getYearsOfCost = (totalCostPerDay, years) => {
  const wipeTotalYearly = [];
  const wipeOnlyYearly = [];

  // until target years
  for (let index = 1; index <= years; index += 1) {
    wipeOnlyYearly.push(totalCostPerDay * 365);
    wipeTotalYearly.push(totalCostPerDay * (365 * index));
  }

  return { wipeTotalYearly, wipeOnlyYearly };
};

export const getCostPerSanitizationWipe = (costLabor) => {
  return convertFloat(0.1 + (costLabor / 60 / 60) * 90); // https://cleanslateuv.atlassian.net/browse/SXP-1429
};
