import { useState, useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

// components
import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { reportState } from '../../recoil/atom/report';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import Card from '../../components/common/Card';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import Filter from '../../components/Filter/Filter';
import IconButton from '../../components/Button/IconButton';
import TableContainer from '../../components/common/Table/TableContainer';
import withContent from '../../components/hoc/withContent';

import useSegment from '../../hooks/useSegment';
import {
  apiActionTypes,
  responseKey,
  icons,
  toastType,
  buttonStyle
} from '../../constants';
import { manageReport } from '../../paths';

const { FETCH } = apiActionTypes;
const contentColumns = { lg: 12, md: 12, sm: 12, xs: 12 };

function Reports() {
  const [reportList, setReportList] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(userState);
  const reportRecoil = useRecoilValue(reportState);
  const history = useHistory();
  const { t } = useTranslation();
  const { isRiker, isPicardAdmin } = user.getUserPermission();
  const listOptions = { customer: true };
  const { setToast } = useContext(ToastContext);

  const onSave = () => history.push({ pathname: manageReport, state: 'new' });

  const columns = [
    { field: 'report_name', title: t('label.reportName') },
    { field: 'report_type', title: t('table.reportType') },
    { field: 'report_status', title: t('label.reportStatus') },
    { field: 'start_date', title: t('label.startDate') },
    { field: 'end_date', title: t('label.endDate') },
    { field: 'created_at', title: t('table.createdDate') },
    { field: 'report_link', title: t('label.reportLink') }
  ];

  const getData = async () => {
    setLoading(true);

    try {
      const obj = reportRecoil.filterOptions;

      const id = obj?.id;
      const option = id ? { type: FETCH, customerUid: id } : {};
      const response = await callAPI('/report-management', option);

      const filteredData = response?.data?.filter(
        (item) => item.report_type !== 'scanrecord'
      );

      setReportList(filteredData);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (reportRecoil.filterOptions?.id || isPicardAdmin) {
      getData();
    }
  }, [reportRecoil.filterOptions]);

  useSegment();

  // row delete icon click
  const onDelete = async (rowData) => {
    const customerUid =
      reportRecoil.filterOptions?.id || user?.[responseKey.customerUid];

    try {
      const request = {
        type: apiActionTypes.DELETE,
        customerUid,
        reportBucket: rowData?.report_bucket
      };

      const response = await callAPI(`/report-management`, request);

      if (response?.hasError) {
        throw new Error('error');
      }

      setReportList(
        reportList.filter(
          (item) => item.report_bucket !== rowData?.report_bucket
        )
      );
      setToast({
        message: t('toastMessage.reportDeleteSuccess'),
        type: toastType.success,
        open: true
      });
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  // table option
  const tableProps = {
    columns,
    hasFilter: true,
    hasDownload: false,
    hasSearch: true,
    rows: reportList,
    searchQuery: reportRecoil.filterOptions,
    tableName: t('menuName.reports'),
    hasDelete: true,
    onDelete
  };

  const filterOptions = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 4, md: 6, sm: 6, xs: 6 },
    hasTitle: false,
    largeHorizontal: true,
    listOptions,
    pageOnclick: () => getData(),
    noApply: true // since one of request is updating filter automatically, I removed apply button,
  };

  return (
    <>
      <ContentHeading
        title={t('menuName.reportAnalytics')}
        leftButton={
          reportRecoil?.permission?.edit && (
            <IconButton icon={icons.addNew} onClick={onSave} />
          )
        }
      />

      <Grid container spacing={2}>
        {isRiker && (
          <Grid item {...contentColumns}>
            <Card style={buttonStyle.filledBlue}>
              <Filter {...filterOptions} listOptions={{ customer: true }} />
            </Card>
          </Grid>
        )}

        <Grid item {...contentColumns}>
          {/* Section Table */}
          {!loading ? (
            <TableContainer tableProps={tableProps} />
          ) : (
            <CardContentLoader />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default withContent(Reports);
