import { MainHeading } from '../components/common/Title';
import withContent from '../components/hoc/withContent';

function NoMatch() {
  return (
    <>
      <MainHeading title="404" />
    </>
  );
}

export default withContent(NoMatch);
