import Lottie from 'react-lottie';

import Modal from './Modal';
import loadingAnimation from '../../assets/images/loadingAnimation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export default function Loading({ isOpen }) {
  return (
    <Modal isOpen={isOpen}>
      <div>
        <Lottie options={defaultOptions} height={'100%'} width={67} />
      </div>
    </Modal>
  );
}
