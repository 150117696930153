import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  redChip: {
    backgroundColor: color.red,
    '& .MuiChip-label': {
      color: color.white,
      fontFamily: fontFamily.semiBold,
      fontSize: '0.75rem'
    }
  },
  greenChip: {
    backgroundColor: color.green,
    '& .MuiChip-label': {
      color: color.darkBlue,
      fontFamily: fontFamily.semiBold,
      fontSize: '0.75rem'
    }
  },
  blueChip: {
    backgroundColor: color.lightBlue,
    '& .MuiChip-label': {
      color: color.darkBlue,
      fontFamily: fontFamily.semiBold,
      fontSize: '0.75rem'
    }
  },
  yellowChip: {
    backgroundColor: color.mustardYellow,
    '& .MuiChip-label': {
      color: color.darkBlue,
      fontFamily: fontFamily.semiBold,
      fontSize: '0.75rem'
    }
  },
  orangeChip: {
    backgroundColor: color.orange,
    '& .MuiChip-label': {
      color: color.white,
      fontFamily: fontFamily.semiBold,
      fontSize: '0.75rem'
    }
  }
});
