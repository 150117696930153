import { atom } from 'recoil';

import { getDefaultAnalytics } from '../selector/analytics_page';
import { comparison } from '../key';
import { analyticsType } from '../../constants';

export const comparisonState = atom({
  key: comparison.comparisonState,
  default: getDefaultAnalytics(analyticsType.compare)
});
