import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { callAPI } from '../../helper/api';
import {
  setTableColumns,
  getChangeBooleanToString
} from '../../helper/tableOptions';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import { getTemporaryVisible } from '../../helper/page';
import Card from '../../components/common/Card';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import TableContainer from '../../components/common/Table/TableContainer';

import { buttonStyle, toastType } from '../../constants';
import { reports } from '../../paths';

export default function ActivityLog({ serial }) {
  const [loading, setLoading] = useState(true);
  const [activityLog, setActivityLog] = useState([]);
  const history = useHistory();
  const user = useRecoilValue(userState);
  const { setToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const getData = async () => {
    try {
      const latestActivityHistory = await callAPI(`/activity-history`, {
        page: 'deviceInfo',
        name: 'device',
        id: serial
      });

      if (latestActivityHistory?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setActivityLog(latestActivityHistory?.data);
      setLoading(false);
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  const columns =
    activityLog?.length > 0 &&
    setTableColumns(Object.keys(activityLog[0]), user, t);
  const tableProps = {
    columns,
    rows: getChangeBooleanToString(activityLog),
    hasDownload: true,
    hasSearch: true,
    tableName: t('menuName.groups'),
    pageSize: 5,
    hasPage: true
  };

  // When user land dashboard, the filter will not be selected. so it is always empty object.
  useEffect(() => {
    getData();
  }, []);

  return !columns && loading ? (
    <CardContentLoader />
  ) : (
    <Card
      header="pages.device.activityLog"
      button={
        getTemporaryVisible() && {
          description: 'View Full Activity History Report',
          style: buttonStyle.lineGreen,
          onClick: () => history.push(reports)
        }
      }
      cardStyle={{ maxHeight: 600 }}
    >
      {/* Table Section */}
      <TableContainer tableProps={tableProps} />
    </Card>
  );
}
