import { selector } from 'recoil';
import i18n from 'i18next';

import {
  setTableColumns,
  getChangeBooleanToString
} from '../../helper/tableOptions';
import { dashboardState } from '../atom/dashboard';
import { userState } from '../state';
import { alertTypes } from '../../constants';

export const useStatSelector = selector({
  key: 'useStatSelector',
  get: ({ get }) => {
    const dashboard = get(dashboardState);
    const column = { lg: 12, md: 12, sm: 12, xs: 12 };

    if (!dashboard?.useStat) {
      return [];
    }

    return [
      {
        number: dashboard?.useStat?.today || 0,
        date: i18n.t('common.usesToday'),
        columnOptions: column
      },
      {
        number: dashboard?.useStat?.last7 || 0,
        date: i18n.t('common.past7Days'),
        columnOptions: column
      },
      {
        number: dashboard?.useStat?.last30 || 0,
        date: i18n.t('common.past30Days'),
        columnOptions: column
      }
    ];
  }
});

export const fleetHealthSelector = selector({
  key: 'fleetHealthSelector',
  get: ({ get }) => {
    const dashboard = get(dashboardState);
    const user = get(userState);

    if (!dashboard?.alert && !dashboard?.activity) {
      return null;
    }

    let columns =
      dashboard?.activity?.length > 0 &&
      setTableColumns(Object.keys(dashboard?.activity[0]), user);

    if (columns) {
      columns = columns && columns.filter((col) => col.field !== 'timestamp');
      columns.unshift({ field: 'timestamp', title: i18n.t('common.dateTime') });
    }

    // table option
    const tableProps = {
      columns,
      rows: getChangeBooleanToString(dashboard?.activity),
      hasDownload: false,
      hasSearch: false,
      tableName: i18n.t('menuName.customers'),
      hasEdit: false,
      hasDelete: false,
      hasShadow: false
    };

    const list = [
      {
        description: 'common.errors',
        number: dashboard?.alert?.errors || 0,
        type: alertTypes.error
      },
      {
        description: 'common.warnings',
        number: dashboard?.alert?.warnings || 0,
        type: alertTypes.warning
      }
    ];

    return {
      table: tableProps,
      errors: dashboard?.alert?.errors,
      warnings: dashboard?.alert?.warnings,
      messages: dashboard?.alert?.messages,
      list: list.filter((item) => item.number)
    };
  }
});
