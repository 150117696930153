import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';

// component
import TextField from './TextField';

// etc
import passwordStyles from '../../styles/Input/PasswordField';
import { inputType, color } from '../../constants';

export default function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  const passwordClasses = passwordStyles();

  return (
    <div className={passwordClasses.passwordContainer}>
      <TextField
        {...props}
        type={showPassword ? inputType.text : inputType.password}
      />

      <span
        className={passwordClasses.showIcon}
        onClick={() => setShowPassword(!showPassword)}
      >
        <AiFillEye
          style={{ fill: showPassword ? color.darkBlue : color.iconGrey }}
        />
      </span>
    </div>
  );
}
