import PropTypes from 'prop-types';
import MUIButton from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { buttonStyles } from '../../styles/common/Button';
import { buttonStyle as buttonColor } from '../../constants';

export default function Button({
  description,
  icon,
  style,
  onClick,
  type,
  cy,
  fullWidth,
  size,
  isInitialLabel,
  ...others
}) {
  const color = !style ? buttonColor.filledBlue : style;
  const classes = buttonStyles(color, fullWidth, isInitialLabel, size);
  const { t } = useTranslation();

  return (
    <MUIButton
      {...others}
      classes={{
        root: classes.root,
        label: classes.label,
        startIcon: classes.startIcon
      }}
      size={size}
      startIcon={icon}
      onClick={onClick}
      type={type}
      data-cy={cy}
    >
      {t(description)}
    </MUIButton>
  );
}

Button.propTypes = {
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.string
};
