import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  container: {
    width: '100%',

    '& .MuiFormControlLabel-root': {
      flexGrow: 1,
      flexBasis: 0,

      '& .MuiFormControlLabel-label': {
        fontSize: '1em'
      }
    }
  }
});

export const white = makeStyles({
  container: {
    width: '100%',
    '& .MuiSvgIcon-root': {
      fill: color.white
    },

    '& .MuiFormControlLabel-root': {
      flexGrow: 1,
      flexBasis: 0,

      '& .MuiFormControlLabel-label': {
        fontSize: '0.85em',
        color: color.white,
        fontFamily: fontFamily.semiBold
      }
    }
  }
});
