import { makeStyles } from '@material-ui/styles';
// import { fontFamily } from '../../constants';

export default makeStyles({
  noDataFoundGridContainer: {
    height: '100%'
  },
  noDataFoundContainer: {
    textAlign: 'center',
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
