import {
  AiOutlineGroup,
  AiOutlineUser,
  AiOutlineCalculator,
  AiOutlinePieChart
} from 'react-icons/ai';
import { BiChip, BiGitCompare, BiNetworkChart } from 'react-icons/bi';
import { BsGraphUp } from 'react-icons/bs';

import { FaRegChartBar, FaRegHandshake, FaPiggyBank } from 'react-icons/fa';
import { GrUserSettings, GrDocumentCsv } from 'react-icons/gr';
import { RiWindow2Fill, RiBarChartFill, RiScalesLine } from 'react-icons/ri';
import { VscPreview, VscKey } from 'react-icons/vsc';
import { IoNotificationsSharp } from 'react-icons/io5';

import { cognitoGroup, tierGroup } from '../../constants';
import paths from '../../paths';

const { rikerAdmin, rikerUser, picardAdmin, superAdmin } = cognitoGroup;
const {
  // cleanSlates,
  // dataUpload,
  analytics,
  averages,
  comparisons,
  customers,
  dashboard,
  devices,
  expansion,
  groups,
  notifications,
  partners,
  reports,
  roiCalculator,
  scanRecords,
  trends,
  useStatistics,
  users
} = paths;

const dashboardMenu = {
  hasSubMenu: false,
  icon: <RiWindow2Fill />,
  link: dashboard,
  text: 'menuName.dashboard'
};

function getOptionalObject(hasUser, object) {
  return hasUser ? [object] : [];
}

function getAdminMenu(userPermission) {
  const {
    isRiker,
    isPicardAdmin // isSuperRikerAdmin
  } = userPermission;

  return {
    hasSubMenu: true,
    icon: <VscKey />,
    link: '',
    text: 'menuName.admin',
    subMenuArray: [
      ...getOptionalObject(isRiker, {
        icon: <FaRegHandshake />,
        isSubmenu: true,
        link: partners,
        text: 'menuName.partners'
      }),
      {
        icon: <GrUserSettings />,
        isSubmenu: true,
        link: customers,
        text: 'menuName.customers'
      },
      {
        icon: <AiOutlineGroup />,
        isSubmenu: true,
        link: groups,
        text: 'menuName.groups'
      },
      ...getOptionalObject(isRiker || isPicardAdmin, {
        icon: <AiOutlineUser />,
        isSubmenu: true,
        link: users,
        text: 'menuName.users'
      })
      // ...getOptionalObject(isRiker, {
      //   icon: <AiOutlineUser />,
      //   isSubmenu: true,
      //   link: cleanSlates,
      //   text: 'menuName.cleanSlates'
      // }),
      // ...getOptionalObject(isRiker, {
      //   icon: <AiOutlineUser />,
      //   isSubmenu: true,
      //   link: dataUpload,
      //   text: 'menuName.dataUpload'
      // })
    ]
  };
}

const analyticsMenu = (tier) => {
  return {
    hasSubMenu: true,
    icon: <BsGraphUp />,
    link: analytics,
    isPaid: tier,
    text: 'menuName.analytics',
    subMenuArray: [
      {
        icon: <VscPreview />,
        isSubmenu: true,
        link: analytics,
        text: 'menuName.overview'
      },
      {
        icon: <BiNetworkChart />,
        isSubmenu: true,
        link: averages,
        text: 'menuName.averages'
      },
      {
        icon: <BiGitCompare />,
        isSubmenu: true,
        link: comparisons,
        text: 'menuName.comparisons'
      },
      {
        icon: <RiBarChartFill />,
        isSubmenu: true,
        link: trends,
        text: 'menuName.trends'
      },
      {
        icon: <AiOutlinePieChart />,
        isSubmenu: true,
        link: useStatistics,
        text: 'menuName.useStatistics'
      }
    ]
  };
};

// function getDeviceMenu(userPermission) {
function getDeviceMenu() {
  // const { isPicardUser } = userPermission;

  return {
    hasSubMenu: true,
    icon: <BiChip />,
    link: devices,
    text: 'menuName.devices',
    subMenuArray: [
      {
        icon: <VscPreview />,
        isSubmenu: true,
        link: devices,
        text: 'menuName.overview'
      }
      // ...getOptionalObject(!isPicardUser && getTemporaryVisible(), {
      //   icon: <GrHostMaintenance />,
      //   isSubmenu: true,
      //   link: maintenance,
      //   text: 'menuName.maintenance'
      // })
    ]
  };
}

const reportsMenu = (isPaid) => ({
  hasSubMenu: true,
  icon: <FaRegChartBar />,
  link: reports,
  isPaid,
  text: 'menuName.reports',
  subMenuArray: [
    {
      icon: <VscPreview />,
      isSubmenu: true,
      link: reports,
      text: 'menuName.reportAnalytics'
    },
    {
      icon: <GrDocumentCsv />,
      isSubmenu: true,
      link: scanRecords,
      text: 'menuName.scanRecords'
    }
    // ...(getTemporaryVisible()
    //   ? [
    //       {
    //         icon: <MdSchedule />,
    //         isSubmenu: true,
    //         link: scheduled,
    //         text: 'menuName.scheduled'
    //       }
    //     ]
    //   : [])
  ]
});

const notificationMenu = (isPaid) => ({
  hasSubMenu: false,
  isPaid,
  link: notifications,
  icon: <IoNotificationsSharp />,
  text: 'menuName.notifications'
});

const savingMenu = (userPermission) => {
  const { isRiker } = userPermission;

  return {
    hasSubMenu: true,
    isPaid: false,
    link: roiCalculator,
    icon: <FaPiggyBank />,
    text: 'menuName.savings',
    subMenuArray: [
      {
        icon: <AiOutlineCalculator />,
        isSubmenu: true,
        link: roiCalculator,
        text: 'menuName.roiCalculator'
      },
      ...getOptionalObject(isRiker, {
        icon: <RiScalesLine />,
        isSubmenu: true,
        link: expansion,
        text: 'menuName.expansion'
      })
    ]
  };
};

/**
 * @description Returning menu options by user group
 * @param {Object} user user object from recoil
 * @return {Array} user option list
 */
function setUserMenu(user) {
  const { userGroup, tier_type: tier } = user;

  const userPermission = user?.getUserPermission();
  const isPaid = tier !== tierGroup.standard;
  let menu = [
    dashboardMenu,
    analyticsMenu(isPaid),
    getDeviceMenu(userPermission)
  ];

  try {
    switch (userGroup) {
      case picardAdmin:
        menu = [
          dashboardMenu,
          analyticsMenu(isPaid),
          getDeviceMenu(userPermission),
          reportsMenu(isPaid),
          getAdminMenu(userPermission),
          notificationMenu(isPaid),
          savingMenu(userPermission)
        ];
        break;

      case rikerUser:
        menu = [
          dashboardMenu,
          analyticsMenu(isPaid),
          getDeviceMenu(userPermission),
          reportsMenu(isPaid),
          getAdminMenu(userPermission),
          notificationMenu(isPaid),
          savingMenu(userPermission)
        ];
        break;

      case superAdmin:
      case rikerAdmin:
        menu = [
          dashboardMenu,
          analyticsMenu(isPaid),
          getDeviceMenu(userPermission),
          reportsMenu(isPaid),
          getAdminMenu(userPermission),
          notificationMenu(isPaid),
          savingMenu(userPermission) // for test ?
        ];
        break;

      default:
        menu = [
          dashboardMenu,
          analyticsMenu(isPaid),
          getDeviceMenu(userPermission)
        ];
        break;
    }
  } catch (error) {
    console.error('Menu error: ', error);
  }

  return menu;
}

export default setUserMenu;
