/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../../../context/ToastContext';
import { callAPI, getFilterByOption } from '../../../../helper/api';
import { getAllMessage } from '../../../../helper/device';
import { apiActionTypes, toastType } from '../../../../constants';
import { pageObj } from '../../../../helper/admin/page';
import { getSortedFilter } from '../../../../helper/filterOptions';

// API hook
export default function useMultipleDeviceManage(type, [filterList, setFilter]) {
  const history = useHistory();

  // state
  const [data] = React.useState([]);
  const [loading] = React.useState(false);

  // context
  const { setToast } = React.useContext(ToastContext);

  // by type
  const { manageURL, closeURL } = pageObj?.[type];

  // refresh filter but should be refactored
  const refreshFilter = async () => {
    const filter = await getFilterByOption({});
    setFilter(getSortedFilter({ ...filterList, ...filter }));
  };

  const onSave = async (reqData) => {
    try {
      const response = await callAPI(manageURL, {
        ...reqData,
        type: apiActionTypes.CREATE
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      refreshFilter();

      setToast({
        message: getAllMessage({
          created: response.data?.data?.created,
          duplicated: response.data?.data?.rejected,
          serial: response.data?.data?.rejected,
          location: response.data?.data?.rejected
        }),
        type: toastType.success,
        open: true,
        duration: 6000
      });

      return history.push(closeURL);
    } catch (error) {
      setToast({
        message: getAllMessage({
          created: [],
          duplicated: error.response.data?.rejected,
          serial: error.response.data?.rejected,
          location: error.response.data?.rejected
        }),
        type: toastType.error,
        open: true,
        duration: 6000
      });

      return null;
    }
  };

  return {
    // func
    onSave, // CREATE

    // data
    data,
    loading
  };
}
