import { useEffect, useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { callAPI } from '../helper/api';
import { getTierExpiryDate } from '../helper/date';
import { userState } from '../recoil/state';
import { cognito } from '../config';
import { resetRecoil } from '../recoil/selector/common';
import * as authLib from '../lib/auth';
import { apiActionTypes, languageCode as langCode } from '../constants';

const getCognitoUser = () => {
  const userPoolObject = {
    UserPoolId: cognito.userPoolId,
    ClientId: cognito.userPoolWebClientId
  };

  const getUserPool = () => new CognitoUserPool(userPoolObject);
  const pool = getUserPool();

  return pool.getCurrentUser();
};

function getIsSessionValid() {
  const cognitoUser = getCognitoUser();

  if (cognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error, session) => {
        if (error || !session) {
          reject(error);
        }

        if (session) {
          if (!session.isValid()) {
            reject(error);
          }

          resolve({ isValid: session.isValid(), cognitoUser });
        }
      });
    });
  }

  return { isValid: false, cognitoUser: {} };
}

async function getUserInfo() {
  let result = null;

  try {
    const response = await callAPI('/user-info', {
      type: apiActionTypes.FETCH
    });

    if (!response?.data || response?.hasError) {
      throw new Error('Cannot get user information');
    }

    result = response?.data;
  } catch (error) {
    console.warn(error.message);
  }

  return result;
}

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setAuthedUser] = useRecoilState(userState);
  const resetRecoilState = useSetRecoilState(resetRecoil);

  useEffect(async () => {
    try {
      const { isValid, cognitoUser } = await getIsSessionValid();
      const languageCode = localStorage.getItem('lang');
      let userFromDatabase = null;

      if ('signInUserSession' in cognitoUser) {
        const cognitoUserGroups =
          cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];

        // throw error user does not have cognito user group array
        if (
          !cognitoUserGroups ||
          !Array.isArray(cognitoUserGroups) ||
          cognitoUserGroups.length === 0
        ) {
          throw new Error('errorMessage.cannotFindUserGroupFromCognito');
        }

        const userGroup = authLib.getUserGroup(cognitoUserGroups);
        const userTierGroup = authLib.getTierGroup(cognitoUserGroups);

        // throw error if user does not have tier
        if (!userTierGroup) {
          throw new Error('errorMessage.cannotFindUserTierFromCognito');
        }

        if (isValid) {
          userFromDatabase = await getUserInfo();
        } else {
          // throw new Error('errorMessage.sessionExpired');
        }

        if (!userFromDatabase) {
          throw new Error('errorMessage.cannotFindUserFromDatabase');
        }

        const { tierRemainDays, is30DaysLeft } = getTierExpiryDate(
          userFromDatabase,
          cognitoUserGroups
        );

        setAuthedUser({
          ...user,
          ...userFromDatabase,
          email: cognitoUser.username,
          isAuthed: isValid,
          languageCode: localStorage.getItem('lang') || langCode.en,
          tier: userTierGroup,
          timezone:
            user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          user: cognitoUser.signInUserSession,
          userGroup,
          getUserPermission: () => authLib.getUserPermission(userGroup),
          tierRemainDays,
          is30DaysLeft
        });
      }

      if (languageCode) {
        localStorage.setItem('lang', languageCode);
      } else {
        localStorage.setItem('lang', langCode.en);
      }
    } catch (error) {
      window.analytics.reset();
      await authLib.signOut();
      resetRecoilState();
    }

    setIsLoading(true);
  }, []);

  return {
    isLoading
  };
};
