import { atom } from 'recoil';
import { getAnalyticsDefaultDateRange } from '../../helper/date';
import { useStatistic } from '../key';
import { getDefaultAnalytics } from '../selector/analytics_page';
import { analyticsType, dataOptions } from '../../constants';

export const defaultUseStatistics = {
  filterOptions: {
    type: analyticsType.usage,
    id: '',
    name: '',
    ...getAnalyticsDefaultDateRange()
  },
  dateRange: dataOptions.week
};

export const useStatisticState = atom({
  key: useStatistic.useStatisticState,
  default: getDefaultAnalytics(analyticsType.usage)
});
