import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// components
import { ContentHeading } from '../components/common/Title';
import { setTableOptions } from '../helper/tableOptions';
import { userState } from '../recoil/state';
import Breadcrumbs from '../components/common/Breadcrumbs';
import Card from '../components/common/Card';
import Filter from '../components/Filter/Filter';
import Search from '../components/common/Search';
import StatusCard from '../components/common/StatusCard';
import Table from '../components/common/Table';
import withContent from '../components/hoc/withContent';

// etc
import { buttonStyle, tableType } from '../constants';
import { maintenance, reports, scheduled } from '../paths';
import { track } from '../helper/segment';
import segmentMessage from '../segment';
import useSegment from '../hooks/useSegment';

function createData(name, dv, population, size, id) {
  return { name, dv, population, size, id };
}

const rows = [
  createData('First Floor', 5, '', '', 'id1'),
  createData('Breakroom', 2, '', '', 'id1'),
  createData('Second Floor', 10, '', '', 'id1'),
  createData('East Wing', 5, '', '', 'id1'),
  createData('West Wing', 5, '', '', 'id1'),
  createData('DE-123, DE-4456, DE-888', 10, '', '', 'id1')
];

function Maintenance() {
  const { userGroup } = useRecoilValue(userState);
  const history = useHistory();
  const setSortOption = useState('')[1];
  const { t } = useTranslation();
  const columns = setTableOptions(userGroup, tableType.device.recentActivity);
  const bulbColumns = setTableOptions(userGroup, tableType.device.bulbSchedule);
  const listOptions = { partner: true, customer: true, group: true };
  useSegment();

  const linkArray = [
    {
      link: maintenance,
      text: 'Maintenance'
    }
  ];

  const onRecentHistoryReportClick = () => {
    track(segmentMessage.maintenance.viewFullAlertHistory);
    history.push(reports);
  };

  const onBulbScheduleClick = () => {
    track(segmentMessage.maintenance.viewFullBulbSchedule);
    history.push(scheduled);
  };

  return (
    <>
      <ContentHeading
        title={t('menuName.devices')}
        option={<Breadcrumbs linkArray={linkArray} />}
        tooltip="Actionable insights to keep your fleet running smoothly."
        description={`Last updated: ${new Date().toISOString()}`}
      />

      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card style={buttonStyle.filledBlue}>
            <Filter
              listOptions={listOptions}
              column={{ lg: 3, md: 6, sm: 6, xs: 6 }}
              hasTitle={false}
              buttonDescription="Update"
              largeHorizontal={true}
            />
          </Card>
        </Grid>

        <Grid container item lg={6} md={12} sm={12} xs={12}>
          <Grid item lg={12} style={{ marginBottom: 20 }}>
            <Card header="Fleet Needs At-A-Glance">
              <div style={{ display: 'flex' }}>
                <StatusCard number={10} date={t('pages.device.activeErrors')} />
                <StatusCard
                  number={10}
                  date={t('pages.device.activeWarnings')}
                />
                <StatusCard number={10} date={t('pages.device.overdueBulbs')} />
                <StatusCard
                  number={10}
                  date={t('pages.device.bulbDueWithin30')}
                />
              </div>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card
              header="Recent Alerts Activity"
              button={{
                description: 'View Full Alert History Report',
                style: buttonStyle.lineGreen,
                onClick: () => onRecentHistoryReportClick()
              }}
            >
              <Search
                column={{ lg: 7, md: 7, sm: 6, xs: 6 }}
                sortOptions={columns.map((column) => column.label)}
                onOptionChange={setSortOption}
              />
              <Table columns={columns} rows={rows} />
            </Card>
          </Grid>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Card
            header="Bulb Schedule (Within 30 Days)"
            button={{
              description: 'View Full Bulb Schedule Report',
              style: buttonStyle.lineGreen,
              onClick: () => onBulbScheduleClick()
            }}
          >
            <Table columns={bulbColumns} rows={rows} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default withContent(Maintenance);
