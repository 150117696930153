import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';
import device from '../assets/images/stats.png';

export const signInLeft = makeStyles((theme) => ({
  document: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px'
    },
    height: '100vh',
    backgroundColor: color.white,
    top: 0,
    flex: '1 0 auto',
    display: 'flex',
    outline: 0,
    zIndex: 1200,
    position: 'fixed',
    overflowY: 'auto',
    flexShrink: 0,
    flexDirection: 'row'
  },
  leftContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '100px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%'
    }
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  buttonContainer: {
    marginTop: 20,
    textAlign: 'center'
  },
  appLinkContainer: {
    marginTop: 30,
    textAlign: 'center'
  }
}));

export const additionalLinkStyles = makeStyles({
  additionalLinkContainer: {
    marginTop: 20
  },
  forgotPassword: {
    display: 'block',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  forgotPasswordContainer: {
    textAlign: 'right'
  }
});

export const signInRight = makeStyles((theme) => {
  return {
    imageContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.up('lg')]: {
        backgroundImage: `url(${device})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: '150%',
        backgroundColor: '#F1F2F6',
        alignItems: 'start',
        paddingTop: 100
      }
    },
    rightInfoContainer: {
      padding: '0 20%',
      position: 'relative'
    },
    rightLogo: { position: 'absolute', top: -90, right: 0, width: 200 },
    largeInfoText: {
      fontSize: '4em',
      fontFamily: fontFamily.bold,
      marginTop: 20
    },
    smallInfoText: {
      fontSize: '2em',
      fontFamily: fontFamily.regular
    }
  };
});

export const signInError = makeStyles({
  error: {
    color: color.red,
    fontSize: 12
  },
  browserLink: {
    textDecoration: 'underline',
    fontFamily: fontFamily.bold
  }
});
