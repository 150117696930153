import paths from '../paths';

const {
  adminManageCustomer,
  adminManageGroup,
  adminManagePartners,
  analytics,
  averages,
  comparisons,
  customers,
  dashboard,
  devices,
  groups,
  home,
  maintenance,
  notifications,
  notificationsManage,
  partners,
  reports,
  scheduled,
  setting,
  signIn,
  support,
  trends,
  users,
  useStatistics,
  viewDevice
} = paths;

/**
 * @description custom event of fullstory
 * @param {string} message - message to set custom event
 */
export function track(message) {
  if (process.env?.REACT_APP_STAGE === 'prod') {
    window.analytics.track(message);
  } else {
    console.log(message);
  }
}

/**
 * @description this function returning page name
 * @param {string} link - specific url to match title
 * @return {Object} recoil state and pathname
 */
export function getKey(link) {
  const selectedLink = link || window.location.pathname;
  let page = '';

  if (selectedLink.includes(adminManagePartners)) {
    page = 'partnermanage';
    return { page };
  }

  if (selectedLink.includes(adminManageCustomer)) {
    page = 'customermanage';
    return { page };
  }

  if (selectedLink.includes(adminManageGroup)) {
    page = 'groupmanage';
    return { page };
  }

  if (selectedLink.includes(viewDevice)) {
    page = 'DeviceDetail';
    return { page };
  }

  switch (selectedLink) {
    case signIn:
      page = 'SignIn';
      break;

    case dashboard:
      page = 'Dashboard';
      break;

    case home:
      page = 'Dashboard';
      break;

    case analytics:
      page = 'Analytics';
      break;

    case averages:
      page = 'Averages';
      break;

    case comparisons:
      page = 'Comparison';
      break;

    case trends:
      page = 'Trends';
      break;

    case useStatistics:
      page = 'UseStatistics';
      break;

    case devices:
      page = 'Devices';
      break;

    case maintenance:
      page = 'Maintenance';
      break;

    case reports:
      page = 'Reports';
      break;

    case scheduled:
      page = 'Scheduled';
      break;

    case support:
      page = 'Support';
      break;

    case setting:
      page = 'Setting';
      break;

    case groups:
      page = 'Groups';
      break;

    case users:
      page = 'Users';
      break;

    case partners:
      page = 'Partners';
      break;

    case customers:
      page = 'Customers';
      break;

    case notifications:
      page = 'Notifications';
      break;

    case notificationsManage:
      page = 'Notificationmanage';
      break;

    case 'estimator':
      page = 'estimator';
      break;

    case 'roi':
      page = 'roi';
      break;

    default:
      break;
  }

  return { page };
}
