import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import checkboxStyles, { white } from '../../styles/Input/Checkbox';
import { color } from '../../constants';

/**
 * @param {{ row: boolean }} checkboxGroupProps value of the list
 * @param {array} value value of the list
 * @param {{ name: string, id: string }} param0
 * @returns
 */
export default function CSUVCheckbox({
  checkboxGroupProps,
  list = [],
  value = [],
  onChange,
  color: inputColor
}) {
  const renderStyles = () => {
    switch (inputColor) {
      case color.white:
        return white();
      default:
        return checkboxStyles();
    }
  };

  const { t } = useTranslation();
  const classes = renderStyles();

  const handleChange = (props) => {
    if (value.includes(props.target.value)) {
      onChange(value.filter((item) => item !== props.target.value));
    } else {
      onChange([...value, props.target.value]);
    }
  };

  return (
    <FormControl component="fieldset" className={classes.container}>
      <FormGroup {...checkboxGroupProps}>
        {list.map((checkbox) => {
          return (
            <FormControlLabel
              key={checkbox?.id}
              label={t(checkbox?.name)}
              control={
                <Checkbox
                  {...checkbox}
                  value={checkbox?.id}
                  color="primary"
                  checked={value.some((checked) => checked === checkbox?.id)}
                  onChange={handleChange}
                  name={checkbox?.name}
                />
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
