export default {
  estimator: {
    page: 'Expansion Estimator',
    calculated: 'Expansion Estimator - Calculate button Clicked'
  },
  roi: {
    page: 'ROI Calculator',
    calculated: 'ROI Calculator - Calculate button Clicked'
  },
  signin: {
    page: 'Sign in',
    signInClicked: 'Sign In Button Clicked',
    forgotPasswordClicked: 'Forgot Password Button Clicked',
    signInSuccessful: 'User signs in',
    signInFail: 'User signs in failure'
  },
  dashboard: {
    page: 'Dashboard',
    useStatClicked: 'Go to View Use Stats',
    latestActivityClicked: 'Go to Fleet Activity',
    alertDetailItemClicked: 'Go to Device Detail'
  },
  analytics: {
    page: 'Analytics - Overview'
  },
  averages: {
    page: 'Analytics - Averages',
    downloadReport: 'Analytics Average - Download Report',
    scheduledReport: 'Analytics Average - Schedule Report'
  },
  comparison: {
    page: 'Analytics - Comparisons',
    downloadReport: 'Analytics Comparisons - Download Report',
    scheduledReport: 'Analytics Comparisons - Schedule Report'
  },
  trends: {
    page: 'Analytics - Trends',
    downloadReport: 'Analytics Trends - Download Report',
    scheduledReport: 'Analytics Trends - Schedule Report'
  },
  usestatistics: {
    page: 'Analytics - Use Stats',
    downloadReport: 'Analytics Use Stats - Download Report',
    scheduledReport: 'Analytics Use Stats - Schedule Report'
  },
  devices: {
    page: 'Devices - Overview'
  },
  maintenance: {
    page: 'Devices - Maintenance',
    viewFullAlertHistory:
      'Devices - Maintenance - View full alert history report',
    viewFullBulbSchedule:
      'Devices - Maintenance - View full bulb schedule report'
  },
  reports: {
    page: 'Reports - Overview'
  },
  scheduled: {
    page: 'Reports - Scheduled'
  },
  support: {
    page: 'Reports - Scheduled'
  },
  common: {
    signOutClicked: 'Sign Out Button Clicked'
  },
  devicedetail: {
    page: 'Device - Detail'
  },
  setting: {
    page: 'Setting'
  },
  partners: {
    page: 'Partners'
  },
  customers: {
    page: 'Customer'
  },
  groups: {
    page: 'Groups'
  },
  partnermanage: {
    page: 'Partner Management'
  },
  customermanage: {
    page: 'Customer Management'
  },
  groupmanage: {
    page: 'Group Management'
  },
  notifications: {
    page: 'Notifications'
  },
  notificationmanage: {
    page: 'Notification Management'
  }
};
