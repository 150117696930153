import { useState, useEffect } from 'react';
import Container from './Container';
import Header from './Header';
import Detail from './Detail';

export default function Accordion({ type, selectedTitle, list }) {
  const [expanded, setExpanded] = useState(false);
  const onChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(false);
  }, [selectedTitle]);

  return (
    list?.length &&
    list?.map((item, index) => {
      return (
        <Container
          expanded={expanded}
          id={index}
          onChange={onChange}
          type={type}
        >
          <Header title={item.q} />

          <Detail description={item.a} isHtml={item?.html} />
        </Container>
      );
    })
  );
}
