import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';
import { dashboardStyles } from '../../styles/Layout/Dashboard';
import Card from '../../components/common/Card';
import icoHand from '../../assets/images/ic_dashboard_hand.svg';
import icoWave from '../../assets/images/wave.svg';

export default function CleanStatus() {
  const dashboardClasses = dashboardStyles();

  return (
    <Card cardStyle={{ overflow: 'hidden' }}>
      <Grid
        item
        lg={12}
        md={12}
        className={dashboardClasses.goodConditionContainer}
        data-cy="fleetHealthItem"
      >
        <Grid className={dashboardClasses.imageContainer} item lg={4} md={5}>
          <img className={dashboardClasses.handIcon} src={icoHand} />
        </Grid>

        <Grid item lg={8} md={7}>
          <img src={icoWave} className={dashboardClasses.waveIcon} />
          <h2 className={dashboardClasses.cleanStatusMessage}>
            <Trans
              i18nKey="pages.dashboard.happySanitizing"
              components={{ br: <br /> }}
            />
          </h2>
        </Grid>
      </Grid>
    </Card>
  );
}
