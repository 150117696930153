import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

export const roiCalculatorForm = makeStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'stretch'
  }
});

export const roiEstSavingStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },

  paybackContainer: { display: 'flex', alignItems: 'center' },
  paybackIcon: { width: 20, height: 20 },

  savingDollars: {
    border: 'dotted 1px #000',
    width: 20,
    display: 'inline-block',
    marginRight: 10,
    marginLeft: 5
  },

  oneYearSavingContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    display: 'flex',
    justifyContent: 'space-around',

    alignItems: 'center',
    height: '100%',
    paddingTop: '2em',
    paddingBottom: '2em'
  },

  oneYearSavingTitleText: {
    fontFamily: fontFamily.semiBold,
    color: color.white,
    fontSize: '0.9em',
    marginLeft: 10
  },

  oneYearSavingTitle: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '70%'
    },
    flexBasis: '25%', // this is related to oneYearSavingAmount
    display: 'flex',
    alignItems: 'center',
    '& div': {
      textAlign: 'left'
    }
  },
  currencyType: {
    fontFamily: fontFamily.bold,
    color: color.white,
    fontSize: '1.5em',
    display: 'flex',
    alignItems: 'center'
  },
  savingPriceContainer: {
    display: 'inline-flex',
    gap: 8
  },
  oneYearSavingAmount: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.5vw'
    },

    fontFamily: fontFamily.bold,
    color: color.white,
    fontSize: '5vw'
  },
  icon: {
    '& path': {
      stroke: color.white
    }
  },
  month: {
    marginRight: 5,
    marginLeft: 5
  }
}));

export const roiSubStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: color.dimGrey,
    fontSize: '1.05em',

    '& button': {
      marginTop: -3,
      marginLeft: -5
    }
  },
  value: {
    fontFamily: fontFamily.bold,
    color: color.green,
    fontSize: '2.4em',
    paddingLeft: 5,
    paddingRight: 5
  },
  wipe: {
    fontFamily: fontFamily.bold,
    color: color.red,
    fontSize: '1.3em',
    paddingLeft: 5,
    paddingRight: 5
  },
  unit: {
    fontFamily: fontFamily.semiBold,
    color: color.darkBlue,
    fontSize: '0.9em'
  }
});

export const roiSummaryStyles = makeStyles({
  title: {
    color: color.darkBlue
  },
  summaryList: {
    '& li': {
      listStyle: 'decimal',
      marginLeft: -23,
      color: color.davyGrey,
      paddingLeft: 5
    },
    '& li p': {
      color: color.davyGrey,
      marginTop: 5,
      marginBottom: 5,
      fontSize: '0.9em'
    },
    '& li p span': {
      color: color.red,
      fontFamily: fontFamily.semiBold
    }
  }
});

export const dateSelectStyles = makeStyles({
  container: {
    marginTop: 10
  }
});

export const compareBoxStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  title: {
    color: color.white,
    textAlign: 'center'
  },
  amount: {
    color: color.white,
    fontFamily: fontFamily.bold,
    fontSize: '3em',
    textAlign: 'center'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  tooltipContainer: {
    position: 'absolute',
    right: 0
  }
});

export const yearBoxStyles = makeStyles({
  year: {
    color: color.dimGrey
  },
  num: {
    fontFamily: fontFamily.bold,
    fontSize: '1.3em',
    color: color.darkBlue
  }
});

export const legendListStyles = makeStyles({
  legendContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10
  },
  legendColor: {
    marginRight: 5
  }
});

export const estimatorFormStyles = makeStyles({
  resetContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
