import { useTranslation } from 'react-i18next';
import { ContentHeading } from '../components/common/Title';
import withContent from '../components/hoc/withContent';
import useSegment from '../hooks/useSegment';

function Contact() {
  const { t } = useTranslation();
  useSegment();

  return (
    <>
      <ContentHeading title={t('menuName.contactSupport')} />
    </>
  );
}

export default withContent(Contact);
