import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

import { alertState } from '../../recoil/atom/alerts';
import { getWarningMessage } from '../../helper/device';
import Card from '../../components/common/Card';
import deviceDetailStyles from '../../styles/DeviceDetail';

import imgDanger from '../../assets/images/img_danger.svg';
import imgWarning from '../../assets/images/img_warning.svg';
import imgNormal from '../../assets/images/ic_dashboard_hand.svg';
import { deviceStatus } from '../../constants';

const columns = { lg: 7, md: 8, sm: 12, xs: 12 };

export default function Status({ status, serial }) {
  const { t } = useTranslation();
  const deviceDetailClasses = deviceDetailStyles();
  const alert = useRecoilValue(alertState);

  const renderElement = () => {
    let message = t('pages.device.csuvOperational');
    let image = imgNormal;
    let imageClass = deviceDetailClasses.normalImage;

    switch (status?.toLowerCase()) {
      case deviceStatus.error:
        message = t('pages.device.csuvNotOperational');
        image = imgDanger;
        imageClass = deviceDetailClasses.img;
        break;

      case deviceStatus.warning:
        message = (
          <Trans
            i18nKey="pages.device.csuvWarning"
            values={{
              message: getWarningMessage({
                messageList: alert?.messages,
                serial
              })
            }}
            components={{
              span: <span className={deviceDetailClasses.deviceWarning} />
            }}
          />
        );
        image = imgWarning;
        imageClass = deviceDetailClasses.img;
        break;

      default:
        break;
    }

    return { message, image, imageClass };
  };

  const { message, image, imageClass } = renderElement();

  return (
    <Card header="pages.device.status">
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid
          className={deviceDetailClasses.statusImageContainer}
          item
          lg={5}
          md={4}
          sm={12}
          xs={12}
        >
          <img className={imageClass} src={image} />
        </Grid>
        <Grid item {...columns}>
          <div className={deviceDetailClasses.deviceDetailMessage}>
            {message}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}
