import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components
import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { manageAdminSelector } from '../../recoil/selector/admin';
import { manageNotificationSchema } from '../../helper/validation';
import { ToastContext } from '../../context/ToastContext';
import { userState } from '../../recoil/state';
import Card from '../../components/common/Card';
import IconButton from '../../components/Button/IconButton';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import OutlineSelect from '../../components/Input/OutlineSelect';
import Recipient from './RecipientForm';
import RecipientList from './RecipientList';

import withContent from '../../components/hoc/withContent';
import useSegment from '../../hooks/useSegment';

// etc
import {
  icons,
  actionTypes,
  alertTypes,
  responseKey,
  apiActionTypes,
  toastType
  // filterType
} from '../../constants';
import { notifications } from '../../paths';

const { partnerUid, customerUid, alertCategory } = responseKey;

const { email, sms, push } = actionTypes;
const { error, warning, errorWarning, all } = alertTypes;
const { CREATE, DELETE, UPDATE } = apiActionTypes;

function ManageGroups() {
  const showErrorHandler = useState(false);
  const recipientErrorHandler = useState({
    email: false,
    push: false,
    phone: false
  });
  const recipientHandler = useState({ email: [], push: [], phone: [] });
  const [recipients, setRecipients] = recipientHandler;
  const { t } = useTranslation();

  const { setToast } = useContext(ToastContext);
  const user = useRecoilValue(userState);
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };
  const location = useLocation();
  const inheritedData = location?.state?.data; // data from overview page
  const pageTitle = inheritedData
    ? 'menuName.editNotification'
    : 'menuName.addNotification';
  const history = useHistory();
  const setShowError = showErrorHandler[1];
  const setRecipientsError = recipientErrorHandler[1];
  const { isPicardAdmin, isRiker } = user.getUserPermission();
  const selectList = useRecoilValue(manageAdminSelector);

  const isEmail = inheritedData?.action === 'email';
  const isSms = inheritedData?.action === 'phone';
  const isPushNotification = inheritedData?.action === 'push';

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      partnerUid: '',
      customerUid: inheritedData?.[customerUid] || '',
      event: inheritedData?.[alertCategory] || error,
      email: '',
      phone: '',
      push: ''
    },
    validationSchema: manageNotificationSchema
  });

  const { values } = formik;

  const refinedNotificationObject = {
    partnerUid: formik.values.partnerUid?.trim(),
    customerUid: formik.values.customerUid?.trim(),
    alertCategory: formik.values.event
  };

  const recipientObject = {
    formik,
    showErrorHandler,
    recipientErrorHandler,
    recipientHandler
  };

  const validationByUserGroup = () => {
    // Riker admin validation
    if (isRiker && !formik?.isValid) {
      setShowError(true);

      throw new Error('errorMessage.save');
    }

    // Picard admin validation
    if (isPicardAdmin && formik.errors?.event) {
      setShowError(true);

      throw new Error('errorMessage.save');
    }
  };

  const onSave = async () => {
    try {
      const hasItem = Object.keys(recipients).some(
        (item) => recipients[item].length > 0
      );

      if (!hasItem) {
        setShowError(true);
        setRecipientsError({ email: true, push: true, phone: true });
        throw new Error('errorMessage.pleaseAddRecipients');
      }

      validationByUserGroup();

      const response = await callAPI(`/notification-management`, {
        ...refinedNotificationObject,
        ...recipients,
        type: CREATE
      });

      if (response?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t('toastMessage.notificationSaveSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(notifications);
      return null;
    } catch (err) {
      setToast({ message: t(err.message), type: toastType.error, open: true });
    }

    return null;
  };

  // Delete button on click
  const onDelete = async () => {
    try {
      const response = await callAPI(`/notification-management`, {
        type: DELETE,
        partnerUid: formik.values.partnerUid,
        customerUid: formik.values.customerUid,
        alertCategory: inheritedData?.[alertCategory],
        action: inheritedData?.action
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t('toastMessage.notificationDeleteSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(notifications);
      return null;
    } catch (err) {
      setToast({ message: t(err.message), type: toastType.error, open: true });
      return null;
    }
  };

  const onEdit = async () => {
    try {
      const hasItem = Object.keys(recipients).some(
        (item) => recipients[item].length > 0
      );

      if (!formik.isValid || !hasItem) {
        throw new Error('errorMessage.pleaseAddRecipients');
      }

      const response = await callAPI(`/notification-management`, {
        ...refinedNotificationObject,
        [inheritedData.action]: recipients[inheritedData.action],
        type: UPDATE
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setToast({
        message: t('toastMessage.notificationEditSuccess'),
        type: toastType.success,
        open: true
      });
      history.push(notifications);
      return null;
    } catch (err) {
      setToast({ message: t(err.message), type: toastType.error, open: true });
      return null;
    }
  };

  const onClose = () => {
    return history.push(notifications);
  };

  useEffect(() => {
    if (inheritedData && partnerUid in inheritedData) {
      let key = '';

      if (isEmail) key = email;
      if (isSms) key = sms;
      if (isPushNotification) key = push;

      setRecipients({ ...recipients, [key]: inheritedData.recipients });
    }
  }, []);

  const renderAlertMessage = () => {
    switch (formik.values.event) {
      case warning:
        return 'pages.notifications.helpText.warning';

      case errorWarning:
        return 'pages.notifications.helpText.errorWarning';

      default:
        return 'pages.notifications.helpText.error';
    }
  };

  const recipientColumns = (isColumnExist) => {
    if (isColumnExist) return columns;
    return { lg: 4, md: 12, sm: 12, xs: 12 };
  };

  const showList = (targetAction) => {
    return !inheritedData || (inheritedData && targetAction);
  };

  useSegment();

  // initialize customer uid when the select list available
  React.useEffect(() => {
    let cuid = '';
    let puid = '';

    if (isPicardAdmin) {
      puid = user?.partner_uid;
      cuid = user?.customer_uid;
    } else if (selectList?.customers) {
      if (!values.customerUid) {
        cuid = selectList?.customers?.[0].value;
        puid = selectList?.customers?.[0].parent;
      } else {
        cuid = inheritedData?.customer_uid;
        puid = selectList.customers.find(
          (dt) => dt.value === inheritedData?.customer_uid
        )?.parent;
      }
    }

    formik.setFieldValue('customerUid', cuid);
    formik.setFieldValue('partnerUid', puid);
  }, [selectList?.customers]);

  const tempHide = false; // 2022-01-22 since sms and notification feature isn't implemented, temporarily hide.

  const onChange = (_, child) => {
    // set partner and customer uid
    const { parent = '', value = '' } = child.props;

    formik.setFieldValue('customerUid', value);
    formik.setFieldValue('partnerUid', parent);
  };

  const buttonList = [
    { icon: icons.save, fn: !inheritedData ? onSave : onEdit },
    { icon: icons.delete, fn: !inheritedData ? onSave : onDelete },
    { icon: icons.close, fn: !inheritedData ? onSave : onClose }
  ];

  return (
    <>
      <ContentHeading
        title={t(pageTitle)}
        leftButton={
          <IconButtonContainer>
            {buttonList.map((bt) => {
              return <IconButton icon={bt.icon} onClick={bt.fn} />;
            })}
          </IconButtonContainer>
        }
      />

      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card>
            <Grid container spacing={2}>
              {isRiker && (
                <OutlineSelect
                  {...columns}
                  cy="customerOptionField"
                  errorMessage="errorMessage.selectCustomer"
                  disabled={!!inheritedData}
                  isRequired={true}
                  label="label.customer"
                  menuItemArray={selectList?.customers}
                  name="customerUid"
                  placeholder="errorMessage.selectCustomer"
                  value={formik.values.customerUid}
                  onChange={(_, child) => {
                    onChange(_, child);
                  }}
                />
              )}

              <OutlineSelect
                {...columns}
                helperText={renderAlertMessage()}
                disabled={!!inheritedData}
                label={t('label.event')}
                lg={isRiker ? 6 : 12}
                name="event"
                onChange={formik.handleChange}
                value={formik.values.event}
                menuItemArray={[
                  { text: 'common.error', value: error },
                  { text: 'common.warning', value: warning },
                  { text: 'common.errorWarning', value: all }
                ]}
              />
            </Grid>
          </Card>
        </Grid>

        <Grid item {...columns}>
          <Grid container spacing={2}>
            {showList(isEmail) && (
              <Grid item {...recipientColumns(columns)}>
                {/* <Grid item {...recipientColumns(isEmail)}> */}
                <Card header="pages.notifications.sendEmail">
                  <Recipient {...recipientObject} type={email} />
                  <RecipientList {...recipientObject} type={email} />
                </Card>
              </Grid>
            )}

            {showList(isSms) && tempHide && (
              <Grid item {...recipientColumns(isSms)}>
                <Card header="pages.notifications.sendSms">
                  <Recipient {...recipientObject} type={sms} />
                  <RecipientList {...recipientObject} type={sms} />
                </Card>
              </Grid>
            )}

            {showList(isPushNotification) && tempHide && (
              <Grid item {...recipientColumns(isPushNotification)}>
                <Card header="pages.notifications.sendPushNotification">
                  <Recipient {...recipientObject} type={push} />
                  <RecipientList {...recipientObject} type={push} />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withContent(ManageGroups);
