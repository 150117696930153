import Grid from '@material-ui/core/Grid';

import ChartContainer from '../../Charts/ChartContainer';

export default function CompareCostPerSanitization({ data }) {
  if (!data) return null;

  return (
    <Grid item xs={12}>
      <ChartContainer chartOptions={data} />
    </Grid>
  );
}
