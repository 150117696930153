import i18n from 'i18next';

import { setTableColumns, columnRenderFn } from '../tableOptions';
import { getViewMoreOption } from '../table';
import tableColumn from '../tableColumn';

export const getColumns = ({ list, user, onRowClick }) => {
  if (!list?.length) return [];

  let columns = setTableColumns(Object.keys(list[0]), user);

  if (columns) {
    columns =
      columns && columns.filter((column) => column.field !== 'created_at');
    columns.push({ field: 'created_at', title: i18n.t('table.createdDate') });
    columns.push(getViewMoreOption(onRowClick));
  }

  return columns;
};

export const getColumnByKey = (columnsKey, onRowClick) => {
  return columnsKey.map((key) => {
    const formattedColumn = {
      field: key,
      title: i18n.t(tableColumn[key])
    };

    if (['view'].includes(key)) {
      return getViewMoreOption(onRowClick);
    }

    if (['persona_id'].includes(key)) {
      return Object.assign(formattedColumn, {
        render: columnRenderFn[key]
      });
    }

    return formattedColumn;
  });
};
