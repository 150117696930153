import React from 'react';

import {
  roiChartOptions,
  roiCompareChartOptions
} from '../helper/chartOptions';
import { convertFloat } from '../helper/roi/csuvCalculator';
import {
  setPeriodByValue,
  setDataByMonth,
  setZoneBySelectedYr
} from '../helper/roi/roi';

export default function useDateRange({ data, calculated, roiCalculator }) {
  const [chartData, setChartData] = React.useState([]);
  const [savingData, setSavingData] = React.useState([]);
  const [selectedYr, setSelectedYr] = React.useState('5y');

  const setData = (val, yearData) => {
    const month = setPeriodByValue(val, roiCalculator.startDate);

    const arr = setDataByMonth({
      list: yearData,
      duration: month
    });

    return arr;
  };

  const handleDateClick = (val) => {
    // '1y':
    // '3y':
    // '5y':
    const arr = setData(val, calculated?.csuvCleaning?.yearsPercent);
    const savingArr = setData(val, calculated.savings);

    setChartData(arr);
    setSavingData(savingArr);
    setSelectedYr(val);
  };

  if (!data || !data[0][1]) {
    return null;
  }

  const shouldHide =
    calculated?.csuvCleaning?.oneYearCostPerDeviceWithCSUV >
    calculated.wipeCleaning?.costPerSanitization;

  return {
    handleDateClick,
    chartData: roiChartOptions({
      data: !chartData.length
        ? setData('5y', calculated?.csuvCleaning?.yearsPercent)
        : chartData,
      payback: convertFloat(calculated.csuvCleaning.paybackPeriod, 0) - 1,
      customerName: roiCalculator.customerName,
      startDate: roiCalculator.startDate,
      endDate: roiCalculator.endDate,
      selectedYr,
      savings: !savingData.length
        ? setData('5y', calculated.savings)
        : savingData,
      zones: setZoneBySelectedYr({
        yr: selectedYr
      })
    }),
    compareChartData: !shouldHide
      ? roiCompareChartOptions({
          csuv: calculated?.csuvCleaning?.oneYearCostPerDeviceWithCSUV,
          wipe: calculated.wipeCleaning?.costPerSanitization
        })
      : null,
    selectedYear: selectedYr,
    legend: setZoneBySelectedYr({
      yr: selectedYr,
      data: !chartData.length
        ? setData('5y', calculated?.csuvCleaning?.yearsPercent)
        : chartData
    })
  };
}
