import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

export default makeStyles((theme) => ({
  editInput: {
    borderBottom: `solid 1px ${color.white}`,
    fontSize: 15,
    color: '#fff',
    fontFamily: fontFamily.semiBold
  },
  editInputOverride: {
    padding: '0px 0px 5px 0'
  },
  img: {
    height: 60,
    marginRight: 20
  },
  normalImage: {
    height: 80,
    marginRight: 20
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 30
  },
  statusImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceInformation: {
    height: '100%',
    backgroundColor: color.lightBlue,

    '& h2': {
      color: color.white
    }
  },
  deviceInformationContainer: {
    paddingBottom: '30px !important'
  },
  statusTitleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& h2': {
      width: 'auto'
    }
  },
  useStateContainer: {
    marginTop: 20
  },
  useStateText: {
    marginTop: -20,
    paddingLeft: 15
  },
  deviceInformationListContainer: {},
  deviceInformationList: {
    marginBottom: 10,

    '&:last-child': {
      marginBottom: 0
    }
  },
  deviceInformationTitle: {
    fontFamily: fontFamily.semiBold,
    display: 'inline-block',
    fontSize: 12,
    paddingLeft: 13
  },
  deviceDetailContent: {
    marginBottom: 20
  },
  deviceInformationDescription: {
    color: color.white,
    fontFamily: fontFamily.semiBold,
    wordBreak: 'break-word'
  },
  deviceDetailMessage: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    marginBottom: 10
  },
  deviceDetailAlign: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  deviceDetailLink: {
    color: color.white,
    textDecoration: 'underline',
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',

    '&:hover, &:active, &:focus': {
      textDecoration: 'underline',
      color: color.darkGreen,

      '& svg': {
        stroke: color.darkGreen
      }
    }
  },
  deviceDetailIcon: {
    marginLeft: 5
  },
  deviceWarning: {
    fontFamily: fontFamily.bold,
    color: color.lightBlue
  }
}));

export const deviceTooltipStyles = makeStyles({
  tooltipContainer: {
    position: 'relative'
  },
  tooltipWrapper: {
    position: 'absolute',
    top: -10
  }
});

export const deploymentStyles = makeStyles({
  container: {
    marginTop: 20
  }
});

export const selectStyles = makeStyles({
  root: {
    color: color.white,

    '&.MuiInputBase-root:hover': {
      background: color.darkGreenGradient
    },
    '&.MuiFormLabel-root': {
      textTransform: 'capitalize'
    },

    '&.MuiInputBase-input': {
      fontSize: 13
    },

    '&.Mui-focused': {
      color: `${color.white} !important`
    },
    '&.MuiFormLabel-root.Mui-focused': {
      color: color.white
    },
    '&.MuiInput-underline:before': {
      borderBottom: `1px solid ${color.white}`
    },
    '&.MuiInput-underline:after': {
      borderBottom: `2px solid ${color.white}`
    },
    '&.MuiSelect-select:focus': {
      backgroundColor: 'initial !important'
    },
    '& .MuiSelect-icon path': {
      color: color.white
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${color.darkGreen}`
    },
    '& .MuiInputBase-input': {
      fontFamily: fontFamily.bold
    }
  }
});

export const fileUploadStyles = makeStyles({
  image: {
    width: '100%'
  },
  input: {
    display: 'none'
  },
  noImageMessage: {
    fontFamily: fontFamily.semiBold,
    color: color.white
  }
});
