import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

export const notificationManageStyles = makeStyles({
  recipientContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  listContainer: {
    width: '100%',
    maxHeight: 310,
    backgroundColor: color.white
  },
  recipientListContainer: {
    marginTop: 20
  },
  listItemContainer: {
    borderBottom: `solid 1px ${color.lightGrey}`,

    '&:last-child': {
      borderBottom: 'none'
    }
  },
  emptyRecipientContainer: {
    textAlign: 'center'
  }
});
