import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { switchSmallStyles } from '../../styles/Input/Switch';

/**
 *
 * @param {*} props.hasSwitch  depending on value, will show switch ( this value makes easy to test)
 * @param {string} props.checkHandler react useState
 * @param {string} props.label switch label
 * @param {string} props.size switch size
 *
 */
export default function CSUVSwitch({
  hasSwitch,
  checkHandler,
  label,
  size,
  labelPlacement
}) {
  if (!hasSwitch) return null;

  const styles = () => {
    switch (size) {
      default:
        return switchSmallStyles();
    }
  };

  const classes = styles();
  const [checked, setChecked] = checkHandler;

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      classes={{
        label: classes.label,
        labelPlacementStart: classes.switchLabelStart
      }}
      control={
        <Switch
          classes={{
            checked: classes.switchChecked,
            thumb: classes.switchThumb
          }}
          size={size}
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      }
    />
  );
}
