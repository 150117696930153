import Grid from '@material-ui/core/Grid';

import Tooltip from '../../common/Tooltip';
import { roiSubStyles } from '../../../styles/ROICalculator';

import { color } from '../../../constants';

export default function SubInformation({ list }) {
  return list.map((info) => {
    return <SubInformationItem {...info} key={info.title} />;
  });
}

function SubInformationItem({
  color: valueColor,
  testID,
  title,
  tooltip,
  unit,
  value
}) {
  const subBoxStyles = roiSubStyles();
  const customValueStyle = {
    style: {
      color: valueColor
    }
  };

  return (
    <Grid item xs={12} className={subBoxStyles.itemContainer}>
      <div className={subBoxStyles.container}>
        <div className={subBoxStyles.title}>
          {title}:
          {tooltip ? (
            <Tooltip message={tooltip} iconColor={color.dimGrey} />
          ) : null}
        </div>
        <div>
          <span className={subBoxStyles.unit}>{unit}</span>
          <span
            className={subBoxStyles.value}
            data-testid={`${testID}-value`}
            {...customValueStyle}
          >
            {value}
          </span>
        </div>
      </div>
    </Grid>
  );
}
