import React from 'react';
import Slider from '@material-ui/core/Slider';

import styles from '../../styles/Estimator';

export default function CSUVSlider({ onChange, ...others }) {
  const classes = styles();
  const handleChange = (_, value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Slider
      {...others}
      classes={{ markLabel: classes.markLabel }}
      scale={(x) => x ** 10}
      onChange={handleChange}
      name={others['data-testid']}
      aria-labelledby="non-linear-slider"
    />
  );
}
