import { getFilterByOption } from '../../helper/api';
import { getRefinedReq, getUidByRole } from '../../helper/admin/page';

import { apiActionTypes } from '../../constants';

export default function useDeviceData(props) {
  const data = props?.data;
  const user = props?.user;

  const initData = {
    ...data,
    serial: data?.serial || '',
    location_name: data?.location_name || '',
    partner_uid: getUidByRole({
      isPicardAdmin: user?.getUserPermission().isPicardAdmin,
      uidFromUser: user?.partner_uid,
      existingUid: data?.partner_uid
    }),
    customer_uid: getUidByRole({
      isPicardAdmin: user?.getUserPermission().isPicardAdmin,
      uidFromUser: user?.customer_uid,
      existingUid: data?.customer_uid
    }),
    group_uid: data?.group_uid || ''
  };

  // request pairs
  const pairs = {
    serial: 'csuvSerial',
    partner_uid: 'partnerUid',
    customer_uid: 'customerUid',
    group_uid: 'groupUid',
    location_name: 'locationName',
    csuv_asset_id: 'assetId',
    deployed_image: 'deployedImage',
    model_type: 'modelType',
    sanitizer_mount_type: 'sanitizerMountType',
    size_mount_type: 'sizeMountType',
    signage: 'signage'
  };

  const getFetchReq = (uid) => {
    return uid ? { id: uid, name: 'customer' } : {};
  };

  const inheritedData = { ...data, ...initData };

  const getDeleteReq = (serial) => {
    return {
      type: apiActionTypes.DELETE,
      csuvSerial: serial
    };
  };

  /**
   * @param {string} uid selected customer uid from input
   * @returns boolean value
   */
  const comparePrevCustomer = (uid) => {
    if (uid !== inheritedData.customer_uid) return true;
    return false;
  };

  /**
   * when customer changed triggered this function
   * @param {object} values customer request values
   * @returns
   */
  const getEditReq = (values) => {
    // if permission not changed, remove permission
    const reqData = getRefinedReq({ pairs, data: values });

    return comparePrevCustomer(values.customer_uid)
      ? { ...reqData, type: apiActionTypes.MIGRATE }
      : reqData;
  };

  const getCreateReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    return reqData;
  };

  const getGroupByCustomer = async (customerUid) => {
    const response = await getFilterByOption({
      customer: customerUid,
      customerDevices: customerUid
    });

    props.setFilterList({ ...props?.filterList, groups: response?.groups });
  };

  const handleCustomerChange = async ({ partnerUid, customerUid, cb }) => {
    await getGroupByCustomer(customerUid);

    cb('customer_uid', customerUid);
    cb('partner_uid', partnerUid);
    cb('group_uid', ''); // initialize group uid
  };

  return {
    inheritedData,

    // func
    getDeleteReq,
    getEditReq,
    getFetchReq,
    getCreateReq,
    comparePrevCustomer,

    // special user customer handler
    handleCustomerChange,
    getGroupByCustomer
  };
}
