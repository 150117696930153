import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import textFieldStyles from '../../styles/Input/TextField';
import '../../styles/Input/datepicker.css';

export default function CustomDatePicker(props) {
  const textFieldClasses = textFieldStyles();

  return (
    <DatePicker
      {...props}
      wrapperClassName={props.className}
      customInput={
        <TextField
          type={props.type || 'text'}
          id="outlined-basic"
          label={props.label}
          value={props.value}
          variant={props.variant || 'outlined'}
          InputLabelProps={{
            'data-testid': `label-${props.testID}`,
            classes: {
              root: textFieldClasses.root,
              focused: textFieldClasses.focused
            }
          }}
          InputProps={{
            classes: {
              root: textFieldClasses.outlineRoot,
              focused: textFieldClasses.focused,
              notchedOutline: textFieldClasses.notchedOutline
            }
          }}
          inputProps={{
            'data-testid': props.testID
          }}
        />
      }
    />
  );
}
