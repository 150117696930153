import React from 'react';
import Grid from '@material-ui/core/Grid';

import Card from '../../components/common/Card';
import OutlineSelect from '../../components/Input/OutlineSelect';
import TextField from '../../components/Input/TextField';

const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

export default function GroupForm({
  formik,
  showError,
  isEdit,
  selectList,
  user
}) {
  // permission
  const { isRiker } = user?.getUserPermission();

  const onChange = (_, child) => {
    // set partner and customer uid
    const { parent = '', value = '' } = child.props;

    formik.setFieldValue('customerUid', value);
    formik.setFieldValue('partnerUid', parent);
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} data-testid="group-form">
      <Card>
        {isRiker && formik.values.customerUid && (
          <OutlineSelect
            {...columns}
            errorMessage="errorMessage.selectCustomer"
            isInvalid={formik.errors?.customerUid && showError}
            isRequired={true}
            cy="customer-select"
            disabled={isEdit}
            label="label.customer"
            name="customerUid"
            onChange={(_, child) => {
              onChange(_, child);
            }}
            placeholder="errorMessage.selectCustomer"
            value={formik.values.customerUid}
            menuItemArray={selectList?.customers}
          />
        )}

        <TextField
          {...columns}
          errorMessage="errorMessage.group"
          isInvalid={formik.errors?.groupName && showError}
          isRequired={true}
          label="label.group"
          name="groupName"
          onChange={formik.handleChange}
          value={formik.values.groupName}
          cy="groupTextField"
        />
      </Card>
    </Grid>
  );
}
