import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// components
import { callAPI } from '../../helper/api';
import { userState } from '../../recoil/state';
import { ContentHeading } from '../../components/common/Title';
import { timezoneValidation, userInfoSchema } from '../../helper/validation';
import AutocompleteSelect from '../../components/Input/AutocompleteSelect';
import Button from '../../components/Button/Button';
import Card from '../../components/common/Card';
import IconButton from '../../components/Button/IconButton';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import MaskedTextField from '../../components/Input/MaskedTextField';
import TextField from '../../components/Input/TextField';
import timezoneList from '../../timezoneList';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';
import PasswordPopup from './PasswordPopup';

/* hide it for future update */
// import OutlineSelect from '../../components/Input/OutlineSelect';
// import languageList from '../../languageList';

import { icons, responseKey, apiActionTypes } from '../../constants';
import { dashboard } from '../../paths';

function Setting() {
  const [errorMessage, setErrorMessage] = useState('');
  const [timezone, setTimezone] = useState('');
  const [user, setUser] = useRecoilState(userState);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { firstName, lastName, phone, tz } = responseKey;
  const history = useHistory();
  const columns = { lg: 12, md: 12, sm: 12, xs: 12 };
  // const [language, setLanguage] = useState('');

  useSegment();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      firstName: user?.[firstName] || '',
      lastName: user?.[lastName] || '',
      phoneNumber: user?.[phone] || ''

      /* hide it for future update */
      // analytic: user?.preferences?.analytic,
      // alert: user?.preferences?.alert,
      // notification: user?.preferences?.notification,
      // report: user?.preferences?.report
    },
    validationSchema: userInfoSchema
  });

  const refinedCustomerObject = {
    firstName: formik.values.firstName.trim() || null,
    lastName: formik.values.lastName.trim() || null,
    phoneNumber: formik.values.phoneNumber.trim() || null,

    /* hide it for future update */
    // analytic: user?.preferences?.analytic || null,
    // alert: user?.preferences?.alert || null,
    // notification: user?.preferences?.notification || null,
    // report: user?.preferences?.report || null,

    // language: language || null,
    timezone: timezone || null
  };

  const onEdit = async () => {
    /* hide it for future update */
    // const { analytic, alert, notification, report, language: languageValue } = refinedCustomerObject;
    // const preferencesObject = { analytic, alert, notification, report, language: languageValue };

    const updatingUserObject = {
      type: apiActionTypes.UPDATE,
      updatedUserInfo: {
        firstName: refinedCustomerObject.firstName,
        lastName: refinedCustomerObject.lastName,
        phone: refinedCustomerObject.phoneNumber,
        tz: refinedCustomerObject.timezone
        // preferences: preferencesObject
      }
    };

    try {
      const response = await callAPI(`/user-info`, updatingUserObject);

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setUser({
        ...user,
        [`${firstName}`]: refinedCustomerObject.firstName,
        [`${lastName}`]: refinedCustomerObject.lastName,
        phone: refinedCustomerObject.phoneNumber,
        tz: refinedCustomerObject.timezone,
        timezone: refinedCustomerObject.timezone
        // preferences: preferencesObject
      });

      return history.push(dashboard);
    } catch (error) {
      setErrorMessage(error.message);
      return null;
    }
  };

  const onClose = () => {
    history.push(dashboard);
  };

  useEffect(() => {
    setTimezone(user?.[tz]);
  }, []);

  return (
    <>
      {isOpen && (
        <PasswordPopup
          open={[isOpen, setIsOpen]}
          error={[errorMessage, setErrorMessage]}
        />
      )}

      <ContentHeading
        title={t('menuName.settings')}
        leftButton={
          <IconButtonContainer>
            <IconButton icon={icons.edit} onClick={onEdit} />
            <IconButton icon={icons.close} onClick={onClose} />
          </IconButtonContainer>
        }
      />

      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card header="User information">
            <TextField
              {...columns}
              label="label.firstName"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            <TextField
              {...columns}
              label="label.lastName"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />

            <MaskedTextField
              {...columns}
              isInvalid={formik.errors?.phone}
              label="label.phoneNumber"
              name="phoneNumber"
              onChange={formik.handleChange}
              placeholder="placeholder.enterPhone"
              value={formik.values.phoneNumber}
            />

            {/* autocomplete will have different onChange than other text field */}
            <AutocompleteSelect
              {...columns}
              label="common.timezone"
              onChange={(newValue) => setTimezone(newValue)}
              value={timezone}
              list={timezoneList}
              isInvalid={!timezoneValidation(timezone)}
              errorMessage={'errorMessage.timezone'}
            />

            <Grid container justifyContent="center" alignItems="center">
              <Grid item lg={2} md={2} sm={1} xs={1}>
                Password:
              </Grid>
              <Grid item lg={10} md={10}>
                <Button
                  description="label.changePassword"
                  fullWidth={false}
                  onClick={() => setIsOpen(true)}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Section for preferences - the data will come later */}

        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card header="common.preference">
            <OutlineSelect
              {...columns}
              errorMessage="errorMessage.selectCustomer"
              isInvalid={formik.errors?.customerName && showError}
              label="pages.setting.analytic"
              menuItemArray={[]}
              name="customerName"
              onChange={formik.handleChange}
              value={formik.values.analytic}
            />
            <OutlineSelect
              {...columns}
              errorMessage="errorMessage.selectCustomer"
              isInvalid={formik.errors?.customerName && showError}
              label="pages.setting.report"
              menuItemArray={[]}
              name="customerName"
              onChange={formik.handleChange}
              value={formik.values.report}
            />
            <OutlineSelect
              {...columns}
              errorMessage="errorMessage.selectCustomer"
              isInvalid={formik.errors?.customerName && showError}
              label="pages.setting.alert"
              menuItemArray={[]}
              name="customerName"
              onChange={formik.handleChange}
              value={formik.values.alert}
            />

            <AutocompleteSelect
              {...columns}
              label="pages.setting.language"
              onChange={(newValue) => setLanguage(newValue)}
              value={language}
              list={languageList}
              isInvalid={!languageValidation(language)}
              errorMessage={t('errorMessage.timezone')}
            />

            <OutlineSelect
              {...columns}
              errorMessage="errorMessage.selectCustomer"
              isInvalid={formik.errors?.customerName && showError}
              label="pages.setting.notification"
              menuItemArray={[]}
              name="customerName"
              onChange={formik.handleChange}
              value={formik.values.notification}
            />
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
}

export default withContent(Setting);
