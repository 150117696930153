import { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';
import { RiArrowDownSFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

// component
import { callAPI } from '../../helper/api';
import { getTierMessage } from '../../helper/date';
import { ToastContext } from '../../context/ToastContext';
import AlertNotificationMenu from '../../pages/Dashboard/AlertNotificationMenu';
import CustomButton from '../Button/Button';
import LanguageMenu from './LanguageMenu';
import MobileMenu from '../Menu/MobileMenu';
import useBrowserSize from '../../hooks/useBrowserSize';
import { userState } from '../../recoil/state';

// etc
import { alertState } from '../../recoil/atom/alerts';
import { resetRecoil } from '../../recoil/selector/common';
import {
  cognitoGroup,
  alertTypes,
  toastType,
  fontFamily,
  buttonStyle
} from '../../constants';
import { track } from '../../helper/segment';
import * as authLib from '../../lib/auth';
import headerStyles, { tierStyles } from '../../styles/common/Header';
import paths from '../../paths';
import segment from '../../segment';

const initAlert = {
  errors: 0,
  warnings: 0,
  total: 0
};

export default function User() {
  const classes = headerStyles();
  const tierClasses = tierStyles();
  const [loading, setLoading] = useState(true);
  const [userAlert, setUserAlert] = useState(initAlert);
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useRecoilState(userState);
  const [width, height] = useBrowserSize();
  const [name, setUserName] = useState('');
  const history = useHistory();
  const setAlerts = useSetRecoilState(alertState);
  const resetRecoilState = useSetRecoilState(resetRecoil);

  const { setToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const signOut = async () => {
    // Segment tracking
    track(segment.common.signOutClicked);

    await authLib.signOut();
    resetRecoilState();

    history.push(paths.signIn);
  };

  const renderUserType = (currentUserGroup) => {
    const { picardAdmin, rikerAdmin, rikerUser, superAdmin } = cognitoGroup;

    let userGroup = 'Picard User';
    const expired = user?.tierRemainDays < 1 ? 'Expired' : '';

    // in a production environment we won’t show these labels.
    if (['prod', 'staging'].includes(process.env?.REACT_APP_STAGE)) {
      return '';
    }

    switch (currentUserGroup) {
      case picardAdmin:
        userGroup = 'Picard Admin User';
        break;

      case rikerAdmin:
        userGroup = 'Riker Admin';
        break;

      case rikerUser:
        userGroup = 'Riker User';
        break;

      case superAdmin:
        userGroup = 'Super Admin';
        break;

      default:
        userGroup = 'Picard User';
        break;
    }

    return `${userGroup} - ${user.tier} ${user.tier_status} ${expired}`;
  };

  const getAlerts = async () => {
    try {
      const alertsResponse = await callAPI(`/alerts`, { messages: true });

      if (alertsResponse?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setUserAlert(alertsResponse.data);
      setAlerts(alertsResponse.data);
      setLoading(false);
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  useEffect(() => {
    if (width < 600 && height < 650 && isOpen) {
      document.getElementsByTagName('html')[0].style.position = 'fixed';
    } else {
      document.getElementsByTagName('html')[0].style.position = 'relative';
    }
  }, [width, height, isOpen]);

  useEffect(() => {
    getAlerts();
  }, []);

  useEffect(() => {
    setUserName(`${user?.first_name} ${user?.last_name}`);
  }, [user?.first_name, user?.last_name]);

  return (
    <>
      <div className={`${classes.container} ${classes.userContainer}`}>
        <div className={classes.menuContainer}>
          <span className={`${classes.mobile} ${classes.mobileHamburgerMenu}`}>
            <FiMenu
              size={20}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </span>
          <span data-cy="userType" className={classes.userTypeText}>
            {renderUserType(user.userGroup)}
          </span>
          {user?.tierRemainDays ? (
            <span className={tierClasses.tierContainer}>
              <Trans
                i18nKey="common.tierMessage"
                components={{
                  span: (
                    <span
                      style={{ color: '#fff', fontFamily: fontFamily.semiBold }}
                    />
                  ),
                  text: <span className={tierClasses.tierDay} />
                }}
                values={{ date: user?.tierRemainDays }}
              />
              <span style={{ paddingLeft: 20 }}>
                <CustomButton
                  description={'pages.support.contact'}
                  style={buttonStyle.filledWhiteDarkBlue}
                  size="small"
                  onClick={() => {
                    window.location = getTierMessage(user);
                  }}
                />
              </span>
            </span>
          ) : null}
        </div>
        <div>
          <DesktopUserMenu
            signOut={signOut}
            alerts={userAlert}
            name={name}
            loading={loading}
          />
          <MobileUserMenu signOut={signOut} isMenuOpen={true} name={name} />
        </div>
      </div>

      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

function DesktopUserMenu({ signOut, alerts, name, loading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = headerStyles();
  const history = useHistory();
  const onSelectChange = () => {};

  const currentAlert = useState('');
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.desktopUserContainer}>
      <LanguageMenu />

      {loading ? (
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
        </Grid>
      ) : (
        <>
          {alerts?.errors ? (
            <AlertNotificationMenu
              type={alertTypes.error}
              alerts={alerts}
              currentAlert={currentAlert}
            />
          ) : null}
          {alerts?.warnings ? (
            <AlertNotificationMenu
              type={alertTypes.warning}
              alerts={alerts}
              currentAlert={currentAlert}
            />
          ) : null}
        </>
      )}

      <Button onClick={handleClick} className={`${classes.userButton}`}>
        {name} <RiArrowDownSFill size="1em" />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        value={'CleanSlate UV'}
        onChange={onSelectChange}
        displayEmpty
        open={Boolean(anchorEl)}
        onClose={handleClose}
        inputProps={{ 'aria-label': t('label.withoutLabel') }}
      >
        <MenuItem onClick={() => history.push(paths.setting)}>
          {t('menuName.settings')}
        </MenuItem>
        <MenuItem data-cy="signOut" onClick={() => signOut()}>
          {t('menuName.signOut')}
        </MenuItem>
      </Menu>
    </div>
  );
}

function MobileUserMenu({ signOut, name }) {
  const classes = headerStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.mobile}>
      <Button
        className={classes.mobileUserIcon}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={onClick}
      >
        {name} <RiArrowDownSFill size="1em" />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => history.push(paths.setting)}>
          {t('menuName.settings')}
        </MenuItem>
        <MenuItem onClick={signOut}>{t('menuName.signOut')}</MenuItem>
      </Menu>
    </div>
  );
}

DesktopUserMenu.propTypes = {
  signOut: PropTypes.func.isRequired
};

MobileUserMenu.propTypes = {
  signOut: PropTypes.func.isRequired
};
