import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles((theme) => {
  return {
    container: {
      padding: 20
    },
    cardNumberBox: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '100%'
      },
      alignItems: 'center',
      borderRadius: 20,
      boxShadow: `0px 0px 3px 3px ${color.shadow}`,
      color: color.black,
      display: 'flex',
      fontFamily: fontFamily.bold,
      fontSize: 40,
      height: 60,
      justifyContent: 'center',
      marginBottom: 10,
      width: '100%'
    },
    date: {
      fontFamily: fontFamily.semiBold,
      textAlign: 'center',
      color: color.red,
      fontSize: 12
    }
  };
});
