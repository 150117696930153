import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { callAPI } from '../helper/api';
import { ToastContext } from '../context/ToastContext';

import { toastType } from '../constants';
import logo from '../assets/images/logo.svg';
import paths from '../paths';
import systemStyle from '../styles/SystemMaintenance';

export default function Unsubscribe() {
  const classes = systemStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useContext(ToastContext);

  const getUnsubscribe = async (email) => {
    try {
      const response = await callAPI('/unsubscribe-notification', {
        key: email
      });

      if (response?.status !== 200) {
        throw new Error(t('errorMessage.somethingWentWrong'));
      }
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  useEffect(() => {
    if (window.location.href.includes('key=')) {
      let key = window.location.href.split('=')[1];
      key = key.replace(/&(\w+|\d+)$/g, ''); // remove other param e.g. email&param=something

      getUnsubscribe(key);
    } else {
      history.push(paths.signIn);
    }
  }, []);

  const columns = {
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.maintenanceContainer}
    >
      <Grid item container {...columns} className={classes.maintenanceItem}>
        <Grid item {...columns} className={classes.itemWrapper}>
          <img className={classes.logo} src={logo} />
        </Grid>
        <Grid item {...columns}>
          <h1>{t('pages.unsubscribe.title')}</h1>
          <p>{t('pages.unsubscribe.description')}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
