import { callAPI } from '../api';
import { deviceInformation, regex, apiActionTypes } from '../../constants';

// model
export const accList1 = [
  { name: 'label.tableTop', id: deviceInformation.tableTop },
  {
    name: 'label.stationaryStand',
    id: deviceInformation.stationaryStand
  },
  { name: 'label.rollingStand', id: deviceInformation.rollingStand }
];

//  hand sanitizer
export const accList2 = [
  { name: 'label.mounted', id: deviceInformation.mounted },
  { name: 'label.nearby', id: deviceInformation.nearby },
  { name: 'label.missing', id: deviceInformation.missing }
];

export const signageList1 = [
  {
    name: 'label.smallMountedDisplay',
    id: deviceInformation.smallMountedDisplay
  },
  {
    name: 'label.largeMountedDisplay',
    id: deviceInformation.largeMountedDisplay
  }
];

// signage
export const signageList2 = [
  {
    name: 'label.posterNearby',
    id: deviceInformation.posterNearby
  },
  {
    name: 'label.underlidSticker',
    id: deviceInformation.underlidSticker
  },
  { name: 'label.customFacility', id: deviceInformation.customFacility }
];

function getOptionByName(name) {
  switch (name) {
    case 'sizeMountType':
      return signageList1;

    case 'signage':
      return signageList2;

    case 'sanitizerMountType':
      return accList2;
    default:
      return accList1;
  }
}

export const getSelectListWithValue = (list) => {
  const assigned = [];

  list?.forEach((item) => {
    assigned.push({
      list: getOptionByName(item.name),
      value: item.value,
      name: item.name
    });
  });

  return assigned;
};

const getBufferToString = (buffer) => {
  const bytes = new Uint8Array(buffer);
  return bytes.reduce((string, byte) => string + String.fromCharCode(byte), '');
};

/**
 *
 * @param {File} file since s3 bucket requires binary string
 * @returns string value of file
 */
export const getBinaryString = async (file) => {
  if (!file) return null;
  const arrayBuffer = await file.arrayBuffer();

  return getBufferToString(arrayBuffer);
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function getFileExt(mimeType) {
  switch (mimeType) {
    case 'image/gif':
      return '.gif';

    case 'image/bmp':
      return '.bmp';

    case 'image/png':
      return '.png';

    case 'image/jpeg':
      return '.jpg';
    default:
      return '';
  }
}

/**
 *
 * @param {string} base64URL string value of deployed image
 * @returns File object
 */
export const base64ToFile = async (base64URL) => {
  // if base64 url is not available, and not base 64 format , return null
  if (!base64URL || !base64URL.match(regex.base64).length) {
    return null;
  }

  const res = await fetch(base64URL);
  const blob = await res.blob();
  const mimeType = base64URL.match(regex.base64)?.[1];

  return new File([blob], `deployed_image${getFileExt(mimeType)}`, {
    type: mimeType
  });
};

/**
 *
 * @param {boolean} hasImage device item's deployed_image value
 * @param {string} serial csuv serial
 * @returns device object including deployed_image, or deviceItem
 */
export const getDeployedImage = async (hasImage, serial) => {
  // if item does not have deployed image, return item
  if (!hasImage) return null;

  const response = await callAPI('/csuv-item', {
    type: apiActionTypes.DOWNLOAD_DEPLOYMENT_IMAGE,
    fileName: 'deployed_image.txt',
    csuvSerial: serial
  });

  return response?.data?.data;
};

export const setMergeDeployedInfoData = async (deviceItem) => {
  if (!deviceItem?.deployed_image) return deviceItem;

  const base64 = await getDeployedImage(
    deviceItem?.deployed_image,
    deviceItem?.serial
  );
  const deployed_image = await base64ToFile(base64);

  return { ...deviceItem, deployed_image };
};
