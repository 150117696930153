import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/common/Card';
import { renderMaskedText, renderMask } from '../../helper/roi/roi';
import { yearBoxStyles } from '../../styles/ROICalculator';

export default function YearBox({ data }) {
  return (
    <Card cardStyle={{ height: '100%' }}>
      <Grid container justifyContent="space-around">
        {data?.map((item, index) => (
          <YearItem
            key={item[1]}
            num={item[1]}
            yr={item[0]}
            testID={`year${index + 1}`}
          />
        ))}
      </Grid>
    </Card>
  );
}

function YearItem({ num, yr, testID }) {
  const classes = yearBoxStyles();

  return (
    <Grid item>
      <div className={classes.year}>{yr} savings</div>
      <div className={classes.num} data-testid={testID}>
        ${' '}
        {renderMaskedText({
          val: `${parseFloat(num)?.toFixed(2)}`,
          mask: renderMask('amount'),
          type: 'amount'
        })}
      </div>
    </Grid>
  );
}
