/* eslint-disable camelcase */
import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_grouped from 'highcharts-grouped-categories';

import useBrowserSize from '../../hooks/useBrowserSize';
import { analyticsType } from '../../constants';

HC_grouped(Highcharts);

require('highcharts/modules/exporting')(Highcharts);

export default function ChartContainer({ chartOptions, type }) {
  const [width] = useBrowserSize();

  const widthDependency =
    type === analyticsType.average
      ? [width, chartOptions?.series?.data]
      : [width];

  useEffect(() => {
    try {
      const chartContainerWidth =
        document.getElementById('chartContainer')?.offsetWidth;

      if (chartContainerWidth) {
        document.querySelector(
          '.highcharts-root'
        ).style.width = `${chartContainerWidth}px`;
      }
    } catch (err) {
      console.log('Chart Container error: ', err);
    }
  }, widthDependency);

  return (
    <div id="chartContainer">
      {chartOptions ? (
        <HighchartsReact
          id="highChartContainer"
          highcharts={Highcharts}
          options={chartOptions}
          oneToOne={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
