// import { useState, useContext } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { alertState } from '../../recoil/atom/alerts';
import { getBulbChangeMessage, getListAsString } from '../../helper/device';
import {
  getNAValue,
  getTimeToDays,
  getUtcDate,
  formatDate
} from '../../helper/date';
import { manageDeviceSchema } from '../../helper/validation';

import Card from '../../components/common/Card';
import DeviceInfoDetail from './DeviceInfoDetail';
import DeploymentInformation from './DeploymentInformation';
import UnderLineTextField from '../../components/Input/UnderLineTextField';
import useDeviceData from '../ManageDevice/useDeviceData';
import useFetch from '../../hooks/useFetch';

import {
  buttonStyle,
  responseKey,
  dateformat,
  alertTypes,
  apiActionTypes
} from '../../constants';

function useDeviceInformation({ data }) {
  const getAssetId = (value) => {
    if (typeof value === 'string' && value === 'null') return '';

    return !value ? '' : value;
  };

  const initData = {
    ...data,
    location_name: data?.location_name || '',
    csuv_asset_id: getAssetId(data?.csuv_asset_id)
  };

  const refineDeviceInfoReqData = (values) => {
    const entries = Object.entries(values);
    const reqData = {};

    const validKeys = [
      'locationName',
      'assetId',
      'partnerUid',
      'customerUid',
      'groupUid',
      'csuvSerial'
    ];

    entries.forEach(([key, value]) => {
      if (validKeys.includes(key)) {
        reqData[key] = value === 'null' ? '' : value;
      }
    });

    return { ...reqData, type: apiActionTypes.UPDATE };
  };

  return { inheritedData: initData, refineDeviceInfoReqData };
}

export default function DeviceInfo({ data, status, user, setCurrentDevice }) {
  // prevent no data rendering
  if (!Object.keys(data).length) {
    return (
      <Card
        header="pages.device.information"
        style={buttonStyle.filledBlue}
        cardStyle={{ height: 'auto' }}
      />
    );
  }

  const { getEditReq } = useDeviceData({ data, user });

  const { t } = useTranslation();
  const { fetch } = useFetch();
  const alert = useRecoilValue(alertState);

  const {
    bulbHours,
    customerName,
    groupName,
    lastCycle,
    lastMaintenance,
    orderDate,
    partnerName,
    serial
  } = responseKey;

  const { isSuperRikerAdmin, isRiker } = user.getUserPermission();
  const { inheritedData, refineDeviceInfoReqData } = useDeviceInformation({
    data
  });

  const lastEvent =
    lastCycle in data
      ? formatDate(
          getUtcDate({
            date: new Date(data[lastCycle]),
            timezone: user.timezone
          }),
          dateformat.noTime
        )
      : '';
  const columns = { xs: 12 };
  let errorList = [];
  let warningList = [];

  if (Array.isArray(alert?.messages) && alert?.messages?.length > 0) {
    errorList = getBulbChangeMessage({
      alertType: alertTypes.error,
      messageList: alert?.messages
    });
    warningList = getBulbChangeMessage({
      alertType: alertTypes.warning,
      messageList: alert?.messages
    });
  }

  const errorSerialString = `[${t(
    'pages.device.errorStatus'
  )}] %0D%0A ${getListAsString(errorList)}`;
  const warningSerialString = `[${t(
    'pages.device.warningStatus'
  )}] %0D%0A ${getListAsString(warningList)}`;

  const formik = useFormik({
    initialValues: inheritedData,
    validationSchema: manageDeviceSchema,
    onSubmit: async (values, actions) => {
      const req = getEditReq(values);

      const result = await fetch({
        url: '/csuv-item',
        request: refineDeviceInfoReqData(req),
        message: 'toastMessage.deviceEditSuccess'
      });

      if (result) {
        actions.resetForm({ values });
        setCurrentDevice(values);
      }
    }
  });

  const informationCardOption = {
    header: 'pages.device.information',
    style: buttonStyle.filledGreen,
    cardStyle: { height: 'auto' },
    ...(formik.dirty && {
      button: {
        description: 'label.saveChanges',
        icon: <FaRegSave size="0.8em" />,
        onClick: formik.handleSubmit,
        size: 'small',
        style: buttonStyle.lineWhite
      }
    })
  };

  return (
    <Grid container spacing={2}>
      {/* Device information */}
      <Grid item {...columns}>
        <Card {...informationCardOption}>
          {/* Serial Number */}
          <DeviceInfoDetail title="pages.device.serialNumber">
            <DeviceInfoDetail.Description
              description={data[serial]}
              isMasked={true}
            />
          </DeviceInfoDetail>

          {/* Device Location */}
          <DeviceInfoDetail title="common.location">
            <DeviceInfoDetail.DetailWithInput
              isMasked={true}
              description={formik.values.location_name}
              input={UnderLineTextField}
              inputProps={{
                value: formik.values.location_name,
                onChange: formik.handleChange,
                size: 'small',
                name: 'location_name'
              }}
            />
          </DeviceInfoDetail>

          {/* Group */}
          <DeviceInfoDetail title="common.group">
            <DeviceInfoDetail.Description
              description={data[groupName]}
              isMasked={true}
            />
          </DeviceInfoDetail>

          {/* Asset id */}
          <DeviceInfoDetail title="pages.device.assetId">
            <DeviceInfoDetail.DetailWithInput
              isMasked={true}
              description={formik.values.csuv_asset_id}
              input={UnderLineTextField}
              inputProps={{
                value: formik.values.csuv_asset_id,
                onChange: formik.handleChange,
                size: 'small',
                name: 'csuv_asset_id'
              }}
            />
          </DeviceInfoDetail>

          {/* Last Event Received */}
          <DeviceInfoDetail title="pages.device.lastEventReceived">
            <DeviceInfoDetail.Description description={lastEvent} />
          </DeviceInfoDetail>
        </Card>
      </Grid>

      {/* Maintenance */}
      <Grid item {...columns}>
        <Card
          header="pages.device.maintenance"
          style={buttonStyle.filledBlue}
          cardStyle={{ height: 'auto' }}
        >
          {/* Last Maintenance Date */}
          <DeviceInfoDetail
            title="pages.device.lastMaintenanceDate"
            hasToolTip={true}
            tooltipMessage={'pages.device.tooltip.lastMaintenance'}
          >
            <DeviceInfoDetail.Description
              description={getNAValue(
                data[lastMaintenance],
                user.timezone,
                dateformat.textDate
              )}
            />
          </DeviceInfoDetail>

          {/* Remaining Bulb Life */}
          <DeviceInfoDetail
            description={getTimeToDays(data[bulbHours])}
            title="pages.device.bulbHours"
            hasToolTip={true}
            tooltipMessage={'pages.device.tooltip.bulbLife'}
          >
            <DeviceInfoDetail.Description
              description={getTimeToDays(data[bulbHours])}
            />
          </DeviceInfoDetail>

          {/* Order bulb by */}
          <DeviceInfoDetail title="pages.device.upcomingOrderDate">
            <DeviceInfoDetail.Description
              description={getNAValue(
                data[orderDate],
                user.timezone,
                dateformat.textDate
              )}
            />
          </DeviceInfoDetail>

          {/* Change bulb by */}
          <DeviceInfoDetail title="pages.device.upcomingChangeDate">
            <DeviceInfoDetail.Description
              description={getNAValue(
                data?.change_date,
                user.timezone,
                dateformat.textDate
              )}
            />
            <DeviceInfoDetail.OrderBulb
              customerName={`${user?.first_name} ${user?.last_name}`}
              hasOrder={status}
              htmlAlertString={{ errorSerialString, warningSerialString }}
            />
          </DeviceInfoDetail>
        </Card>
      </Grid>

      {/* Partner Customer */}
      {isRiker && (
        <Grid item {...columns}>
          <Card
            header="pages.device.admin"
            style={buttonStyle.filledGreen}
            cardStyle={{ height: 'auto' }}
          >
            <DeviceInfoDetail title="pages.device.customer">
              <DeviceInfoDetail.Description
                description={data[customerName]}
                isMasked={true}
              />
            </DeviceInfoDetail>

            {isSuperRikerAdmin && (
              <DeviceInfoDetail title="pages.device.partner">
                <DeviceInfoDetail.Description
                  description={data[partnerName]}
                  isMasked={true}
                />
              </DeviceInfoDetail>
            )}
          </Card>
        </Grid>
      )}

      <Grid item {...columns}>
        {/* Development information section */}
        <DeploymentInformation
          serial={data?.serial}
          setCurrentDevice={setCurrentDevice}
          data={{
            deployed_image: data?.deployed_image,
            model_type: data?.model_type,
            sanitizer_mount_type: data?.sanitizer_mount_type,
            size_mount_type: data?.size_mount_type,
            signage: data?.signage
          }}
        />
      </Grid>
    </Grid>
  );
}
