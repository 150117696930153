import React from 'react';
import { List } from 'react-content-loader';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { dataOptions, dateformat } from '../../constants';
import { dateRange } from '../../helper/filterOptions';
import { formatDate, setDate } from '../../helper/date';
import { getPageData } from '../../helper/page';
import CustomDateRange from '../common/CustomDateRange';
import Modal from '../common/Modal';

import selectStyles from '../../styles/Input/Select';

function DateSelect({
  changedDateByData,
  data = dateRange,
  defaultValue = dataOptions.week
}) {
  const { t } = useTranslation();
  const { state: pageState } = getPageData(window.location.pathname);

  const [item, setItem] = React.useState(defaultValue);
  const [isOpen, setIsOpen] = React.useState(false);
  const [state, setState] = useRecoilState(pageState);
  const selectClasses = selectStyles();

  // When user change select value, it is stored in Recoil global value
  const onChange = async (event, child) => {
    const selectedValue = child.props.value;

    // if custom days are selected
    if (dataOptions.customDays === selectedValue) {
      setIsOpen(true);
      setItem(selectedValue);

      setState({
        ...state,
        dateRange: selectedValue
      });
      return;
    }

    setItem(selectedValue);
    setState({
      ...state,
      filterOptions: {
        ...state.filterOptions,
        ...setDate(selectedValue)
      },
      dateRange: selectedValue
    });
  };

  const renderValue = (selected) => {
    const selectedObj = data.find((opt) => opt.id === selected);

    if (changedDateByData?.isChanged) {
      const { startDate, endDate } = changedDateByData.changedDate;
      return `${startDate} - ${endDate}`;
    }

    if (selectedObj?.id === dataOptions.customDays) {
      const { startDate, endDate } = state?.filterOptions;

      const formatted = {
        startDate: formatDate(startDate, dateformat.noTime),
        endDate: formatDate(endDate, dateformat.noTime)
      };

      return `${formatted.startDate} - ${formatted.endDate}`;
    }

    return t(selectedObj?.name);
  };

  const onClick = ({ ...props }) => {
    const selectedVal = props.target.value;

    // when selected date is already custom date, selectedVal returns 0
    if (item === dataOptions.customDays && selectedVal === 0) {
      setIsOpen(true);
    }
  };

  React.useEffect(() => {
    if (changedDateByData?.isChanged) {
      setItem(dataOptions.customDays);
    } else {
      setItem(state?.dateRange);
    }
  }, [changedDateByData]);

  if (data?.length) {
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          title={t('modal.customDateRange.title')}
        >
          <CustomDateRange onClose={() => setIsOpen(false)} />
        </Modal>
        <FormControl className={selectClasses.formContainer}>
          {/* Label */}
          <InputLabel
            classes={{
              root: selectClasses.root
            }}
            id="demo-mutiple-name-label"
            shrink={true}
            data-cy="filterLabel"
          >
            {t('label.dateRange')}
          </InputLabel>

          <Select
            classes={{
              root: selectClasses.root
            }}
            renderValue={renderValue}
            displayEmpty
            value={item}
            onChange={onChange}
            onClick={onClick}
            id="demo-mutiple-nme"
            placeholder="Select multiple"
            inputProps={{ 'aria-label': t('label.withoutLabel') }}
            input={
              <InputBase
                className="fs-mask"
                classes={{ root: selectClasses.root }}
              />
            }
          >
            {data.map((opt) => {
              return (
                <MenuItem key={opt.id} value={opt?.id}>
                  {t(opt.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </>
    );
  }

  return <List />;
}

export default React.memo(DateSelect);
