import React from 'react';
import { useTranslation } from 'react-i18next';
import MUITooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { BiPencil } from 'react-icons/bi';
import { RiQuestionLine } from 'react-icons/ri';
import tooltipStyles from '../../styles/common/Tooltip';
import { icons, color } from '../../constants';

function Tooltip({
  message,
  placement,
  type,
  onClick,
  iconColor = color.white
}) {
  const classes = tooltipStyles();
  const { t } = useTranslation();

  const renderIcon = () => {
    let icon = (
      <RiQuestionLine
        color={iconColor}
        fill={iconColor}
        fillOpacity={0.8}
        size="0.9em"
      />
    );
    let tooltipTitle = message;

    switch (type) {
      case icons.edit:
        icon = <BiPencil size="0.8em" fill={iconColor} fillOpacity={0.8} />;
        tooltipTitle = 'label.edit';
        break;

      default:
        break;
    }

    return {
      icon,
      tooltipTitle
    };
  };

  const { icon, tooltipTitle } = renderIcon();

  return (
    <MUITooltip
      title={t(tooltipTitle) || ''}
      placement={placement || 'left-start'}
      className={classes.tooltip}
    >
      <IconButton
        classes={{ root: classes.root }}
        size="medium"
        edge={'end'}
        onClick={onClick}
      >
        {icon}
      </IconButton>
    </MUITooltip>
  );
}

export default React.forwardRef((props, ref) => (
  <Tooltip {...props} ref={ref} />
));
