import { makeStyles } from '@material-ui/styles';
// import { color, fontFamily } from '../../constants';

export default makeStyles({
  passwordContainer: {
    position: 'relative'
  },
  showIcon: {
    position: 'absolute',
    top: 27,
    right: 13,
    fontSize: 30,
    cursor: 'pointer'
  }
});
