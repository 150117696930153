import PropTypes from 'prop-types';
import { BiPlus } from 'react-icons/bi';
import { FaRegSave, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { VscGithubAction } from 'react-icons/vsc';
import { MdEdit } from 'react-icons/md';
import { IoCloseSharp } from 'react-icons/io5';
import MUIButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { iconButtonStyle } from '../../styles/common/Button';
import { icons } from '../../constants';

export default function IconButton({ icon, onClick, cy, type }) {
  const classes = iconButtonStyle();
  const { t } = useTranslation();

  const renderIcon = () => {
    let Icon = null;
    let tooltipMessage = '';

    let dataCy = cy;
    switch (icon) {
      case icons.close:
        tooltipMessage = 'label.close';
        Icon = <IoCloseSharp />;
        dataCy = 'btnClose';
        break;

      case icons.edit:
        tooltipMessage = 'label.edit';
        dataCy = 'btnEdit';
        Icon = <MdEdit />;
        break;

      case icons.addNew:
        tooltipMessage = 'label.addNew';
        dataCy = 'btnAddNew';
        Icon = <BiPlus />;
        break;

      case icons.save:
        tooltipMessage = 'common.save';
        dataCy = 'btnSave';
        Icon = <FaRegSave />;
        break;

      case icons.delete:
        tooltipMessage = 'common.delete';
        dataCy = 'btnDelete';
        Icon = <FaRegTrashAlt />;
        break;

      default:
        tooltipMessage = 'label.actions';
        dataCy = cy;
        Icon = <VscGithubAction />;
        break;
    }

    return { Icon, tooltipMessage, dataCy };
  };

  const { Icon, tooltipMessage, dataCy } = renderIcon();

  return (
    <Grid item>
      <Tooltip title={t(tooltipMessage)}>
        <MUIButton
          classes={{
            root: classes.root,
            label: classes.label,
            startIcon: classes.startIcon
          }}
          size="small"
          data-testid={`btn-${icon}`}
          startIcon={Icon}
          onClick={onClick}
          data-cy={dataCy}
          type={type}
        />
      </Tooltip>
    </Grid>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  cy: PropTypes.string
};
