import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { icons, actionTypes, regex, inputType } from '../../constants';
import { notificationManageStyles } from '../../styles/Notification';
import IconButton from '../../components/Button/IconButton';
import MaskedTextField from '../../components/Input/MaskedTextField';
import TextField from '../../components/Input/TextField';

const columns = { lg: 12, md: 12, sm: 12, xs: 12 };
const { email, push, sms } = actionTypes;

export default function RecipientForm({
  formik,
  showErrorHandler,
  recipientErrorHandler,
  recipientHandler,
  type
}) {
  const [showError, setShowError] = showErrorHandler;
  const [recipientsError, setRecipientsError] = recipientErrorHandler;
  const [recipients, setRecipients] = recipientHandler;
  const { t } = useTranslation();
  const notificationClasses = notificationManageStyles();
  const isEmail = type === email;
  const isPushNotification = type === push;

  // Trigger when user click save button
  const onAddRecipients = async (event) => {
    event.preventDefault();

    let initActionList = [];
    let hasError = false;
    const initError = { email: false, push: false, sms: false };

    switch (type) {
      case email:
        initActionList = [...recipients.email];
        hasError = !formik.values.email.match(regex.email);
        break;

      case sms:
        initActionList = [...recipients.phone];
        hasError = !formik.values.phone.match(regex.phone);
        break;

      default:
        initActionList = [...recipients.push];
        hasError = !formik.values.push.match(regex.email);
        break;
    }

    if (hasError) {
      setShowError(true);
      setRecipientsError({ ...initError, [type]: hasError });

      return null;
    }

    formik.setFieldValue('email', '');
    formik.setFieldValue('phone', '');
    formik.setFieldValue('push', '');
    initActionList.unshift(formik.values[type]);

    setRecipients({ ...recipients, [type]: [...new Set(initActionList)] });
    setRecipientsError(initError);
    hasError = false;
    return null;
  };

  const onChange = (event) => {
    setRecipientsError({ email: false, push: false, phone: false });
    formik.handleChange(event);
  };

  const emailTextFieldProps = {
    columns,
    errorMessage: t('errorMessage.email'),
    isInvalid: recipientsError[type] && showError,
    label: 'label.recipient',
    margin: 'dense',
    onChange: (event) => onChange(event),
    placeholder: 'placeholder.enterEmail',
    shrink: true,
    value: formik.values.email,
    name: 'email'
  };

  const pushTextFieldProps = {
    ...emailTextFieldProps,
    value: formik.values.push,
    name: 'push'
  };

  return (
    <form onSubmit={onAddRecipients}>
      <Grid container spacing={2}>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          {isEmail || isPushNotification ? (
            <TextField
              {...(type === 'email' ? emailTextFieldProps : pushTextFieldProps)}
            />
          ) : (
            <MaskedTextField
              {...columns}
              errorMessage={t('errorMessage.phone')}
              isInvalid={recipientsError[type] && showError}
              label="label.recipient"
              margin="dense"
              name="phone"
              onChange={(event) => onChange(event)}
              placeholder="placeholder.enterPhone"
              shrink={true}
              value={formik.values.phone}
            />
          )}
        </Grid>
        <Grid
          className={notificationClasses.recipientContainer}
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
        >
          <IconButton icon={icons.addNew} type={inputType.submit} />
        </Grid>
      </Grid>
    </form>
  );
}
