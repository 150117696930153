import PropTypes from 'prop-types';
import { FcHighPriority, FcMediumPriority } from 'react-icons/fc';

// etc
import badgeStyles from '../../styles/common/Badge';
import { alertTypes, color, fontFamily } from '../../constants';

export default function Badge({ number, type, onClick, isOpen }) {
  const classes = badgeStyles();

  const renderIcon = () => {
    switch (type) {
      case alertTypes.error:
        return (
          <FcHighPriority
            size="2em"
            onClick={onClick}
            className={classes.clickable}
          />
        );

      case alertTypes.warning:
        return (
          <FcMediumPriority
            size="2em"
            onClick={onClick}
            className={classes.clickable}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <span className={classes.notificationContainer}>
      {renderIcon()}
      <span
        className={classes.notification}
        style={
          isOpen
            ? {
                backgroundColor: color.darkRed,
                fontFamily: fontFamily.bold,
                fontSize: 11
              }
            : {}
        }
      >
        {number}
      </span>
    </span>
  );
}

Badge.propTypes = {
  number: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func
};
