import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

export default function AllOption({ hasAll, handleClick, selectedAll, list }) {
  if (!hasAll || !Array.isArray(list)) return null;

  const getOptionsByAllOpt = () => {
    if (!selectedAll) return list.map((item) => item.id);
    return [];
  };

  return (
    <MenuItem value={'all'} onClick={() => handleClick(getOptionsByAllOpt())}>
      <Checkbox checked={selectedAll} />
      <ListItemText primary={'All'} data-testid={`menu-all`} />
    </MenuItem>
  );
}
