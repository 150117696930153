import Select from '../../../Input/Select';

import { selectStyles as deploymentSelectStyles } from '../../../../styles/DeviceDetail';

export default function DeploymentInfoSelectItem({
  handleChange,
  list,
  name,
  value,
  placeholder
}) {
  const deploymentSelectClasses = deploymentSelectStyles();

  if (!list || !list.length) return null;

  return (
    <Select
      handleChange={handleChange}
      list={list}
      name={name}
      placeholder={placeholder}
      value={value}
      inputBaseStyle={{
        root: deploymentSelectClasses.root
      }}
    />
  );
}
