import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from '../constants';

/**
 * This function is set user's language in entire app
 */
export default function useUserRedirect() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguageCode = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : languageCode.en;

    if (storedLanguageCode) {
      i18n.changeLanguage(storedLanguageCode);
    }
  }, []);
}
