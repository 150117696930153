import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { GrPowerReset } from 'react-icons/gr';

import { FormErrorMessage } from '../../../components/common/ErrorMessage';
import { FormHeader } from '../../../components/common/Title';
import { getInputProps } from '../../../helper/admin/page';
import { singleDeviceSchema } from '../../../helper/validation';
import Button from '../../../components/Button/Button';
import Card from '../../../components/common/Card';
import Checkbox from '../../../components/Input/Checkbox';
import MultipleSelectWithChip from '../../../components/Input/MultipleSelectWithChip';
import Radio from '../../../components/Input/Radio';
import TextField from '../../../components/Input/TextField';
import UploadDeployedImage from '../UploadDeployedImage';

import { manageMultipleDeviceStyles } from '../../../styles/Device';
import { inputType } from '../../../constants';

export default function DeviceForm({
  errors,
  inputPropData,
  isEdit,
  onSubmit,

  handleFileChange,
  acceptTypes,
  handleRemoveSizeMountType
}) {
  const classes = manageMultipleDeviceStyles();
  const inputProps = getInputProps({
    inputProps: inputPropData
  });

  const formik = useFormik({
    initialValues: {
      serial: '',
      location_name: '',
      deployed_image: '',
      model_type: '',
      sanitizer_mount_type: '',
      size_mount_type: '',
      signage: []
    },
    validationSchema: singleDeviceSchema,
    onSubmit
  });

  const deviceErrorMessage = (message, isList) => {
    const isDuplicateMessage = message === 'Serial number is duplicated';

    if (isList && !isDuplicateMessage) return message;
    return isDuplicateMessage ? message : '';
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* device info */}
            <Card header={'Please add a device'}>
              <Grid container spacing={2}>
                <TextField
                  {...inputProps.serial}
                  disabled={isEdit}
                  errorMessage={
                    formik.errors.serial || deviceErrorMessage(errors?.devices)
                  }
                  isInvalid={
                    !!formik.errors.serial ||
                    !!deviceErrorMessage(errors?.devices)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.serial}
                  visible={inputProps.serial.visible || isEdit}
                />
                <TextField
                  {...inputProps.location_name}
                  errorMessage={formik.errors.location_name}
                  isInvalid={!!formik.errors.location_name}
                  onChange={formik.handleChange}
                  value={formik.values.location_name}
                />

                <TextField
                  {...inputProps.csuv_asset_id}
                  errorMessage={formik.errors.csuv_asset_id}
                  isInvalid={!!formik.errors.csuv_asset_id}
                  onChange={formik.handleChange}
                  value={formik.values.csuv_asset_id}
                />
              </Grid>

              <FormErrorMessage
                message={deviceErrorMessage(errors.devices, true)}
              />
            </Card>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            {/* Deployment information */}
            <Grid item md={6} xs={12}>
              <Card
                header="Deployment Information"
                cardStyle={{ height: '100%' }}
              >
                <MultipleSelectWithChip
                  {...inputProps.model_type}
                  value={formik.values.model_type}
                  handleChange={(props) =>
                    formik.setFieldValue('model_type', props.target.value)
                  }
                />
                <MultipleSelectWithChip
                  {...inputProps.sanitizer_mount_type}
                  value={formik.values.sanitizer_mount_type}
                  handleChange={(props) =>
                    formik.setFieldValue(
                      'sanitizer_mount_type',
                      props.target.value
                    )
                  }
                />

                {/* Signage group */}
                <FormHeader title={'Signage'} />

                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Radio
                      radioProps={{
                        onClick: (event) => {
                          handleRemoveSizeMountType(
                            event.target.value,
                            formik.values.size_mount_type,
                            formik.setFieldValue
                          );
                        }
                      }}
                      radioGroupProps={{
                        row: false,
                        'aria-label': 'size_mount_type',
                        name: 'size_mount_type',
                        value: formik.values.size_mount_type,
                        onChange: (props) =>
                          formik.setFieldValue(
                            'size_mount_type',
                            props.target.value
                          )
                      }}
                      list={inputProps.size_mount_type.list}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Checkbox
                      checkboxGroupProps={{ row: false }}
                      list={inputProps.signage.list}
                      onChange={(val) => {
                        formik.setFieldValue('signage', val);
                      }}
                      value={formik.values.signage}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* Deployed device photo */}
            <Grid item md={6} xs={12}>
              <Card
                header="Deployed device photo"
                button={{
                  icon: <GrPowerReset />,
                  size: 'small',
                  description: 'Reset',
                  onClick: () => handleFileChange(null, formik.setFieldValue)
                }}
              >
                <UploadDeployedImage
                  name="deployed_image"
                  url={formik.values.deployed_image}
                  acceptTypes={acceptTypes}
                  onChange={(value) => {
                    return handleFileChange(value, formik.setFieldValue);
                  }}
                />
              </Card>
            </Grid>
          </Grid>

          <Grid item className={classes.container} xs={12}>
            <Button description={'Add'} type={inputType.submit} />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
