import React from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../../components/common/Title';
import { manageDeviceSchema } from '../../helper/validation';
import Form from './Form';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import withAdminManage from '../../components/hoc/withAdminManage';

// for devices
import useDeviceData from './useDeviceData';
import useDeployData from '../DeviceDetail/hook/useDeployData';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../helper/admin/page';
import useDeviceInputData from './useDeviceInputData';
import { adminType } from '../../constants';

function ManageDevice({
  t,
  location,
  type,

  // recoil
  filterList,
  setFilterList,
  user,

  // func
  onDelete,
  onEdit,
  onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit
}) {
  const {
    inheritedData,

    comparePrevCustomer,
    getDeleteReq,
    getEditReq,
    getGroupByCustomer,
    handleCustomerChange
  } = useDeviceData({
    data: location?.state?.data,
    user,
    filterList,
    setFilterList
  });

  const {
    inheritedData: dep_inheritedData,
    uploadImageTypeList,

    getRefinedRequest,
    handleFileChange,
    handleRemoveSizeMountType
  } = useDeployData({
    ...location?.state?.data
  });

  const inputProps = useDeviceInputData(user);

  const formik = useFormik({
    initialValues: { ...inheritedData, ...dep_inheritedData },
    validationSchema: manageDeviceSchema,

    onSubmit: async (values) => {
      const req = getEditReq(values, location?.state?.data.permission_level);
      const refinedReq = await getRefinedRequest(req);

      return onEdit(refinedReq);
    }
  });

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      onEdit: formik.handleSubmit,
      onSave: formik.handleSubmit,
      onDelete: () => onDelete(getDeleteReq(inheritedData?.serial)),
      onClose
    }
  });

  // Device does not require specific customer filter in view page. so it should load specific group filter by customer
  React.useEffect(() => {
    if (inheritedData?.customer_uid) {
      getGroupByCustomer(inheritedData?.customer_uid);
    }
  }, [inheritedData?.customer_uid]);

  return (
    <>
      <ContentHeading
        title={t('menuName.devices')}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            {...formik}
            acceptTypes={uploadImageTypeList?.join(', ')}
            comparePrevCustomer={comparePrevCustomer}
            filterList={filterList}
            handleCustomerChange={handleCustomerChange}
            handleRemoveSizeMountType={handleRemoveSizeMountType}
            handleFileChange={handleFileChange}
            inputPropData={inputProps}
            isEdit={isEdit}
          />
        </Grid>
      </Grid>
    </>
  );
}

// hoc will manage type of value
export default withAdminManage(adminType.csuvItem)(ManageDevice);
