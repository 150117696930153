import { makeStyles } from '@material-ui/styles';

export const containerStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    boxShadow: 'none',
    maxHeight: 500,
    minHeight: 200
  }
});

export const bodyRowStyles = makeStyles({
  root: {
    cursor: 'pointer'
  }
});
