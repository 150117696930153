import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export const switchSmallStyles = makeStyles({
  label: {
    fontSize: '1em',
    color: color.white,
    fontFamily: fontFamily.bold
  },
  switchChecked: {
    '& + .MuiSwitch-track': {
      backgroundColor: `${color.white} !important`
    }
  },
  switchThumb: {
    backgroundColor: `${color.darkBlue} !important`
  },
  switchLabelStart: {
    margin: 0
  }
});
