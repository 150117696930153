import Grid from '@material-ui/core/Grid';

import { getInputProps } from '../../helper/admin/page';
import { sortList } from '../../helper/filterOptions';
import AutocompleteSelect from '../../components/Input/AutocompleteSelect';
import Card from '../../components/common/Card';
import MultipleSelectWithChip from '../../components/Input/MultipleSelectWithChip';
import OutlineSelect from '../../components/Input/OutlineSelect';
import TextField from '../../components/Input/TextField';
import timezoneList from '../../timezoneList';

import { tierGroup, tierStatus, connectivityType } from '../../constants';

export default function Form({
  errors,
  values,
  isEdit,
  handleChange,
  filterList,
  setFieldValue,
  inputPropData
}) {
  const inputProps = getInputProps({
    inputProps: inputPropData,
    errors,
    values
  });

  return (
    <Card>
      <Grid container spacing={2}>
        <TextField {...inputProps.customer_name} onChange={handleChange} />
        <TextField {...inputProps.customer_address} onChange={handleChange} />
        <TextField {...inputProps.contact_name} onChange={handleChange} />
        <TextField {...inputProps.contact_phone} onChange={handleChange} />
        <TextField {...inputProps.contact_email} onChange={handleChange} />

        <Grid item xs={12}>
          <MultipleSelectWithChip
            {...inputProps.partner_uid}
            disabled={isEdit}
            list={sortList(filterList?.partners, 'name')}
            handleChange={(props) => {
              setFieldValue('partner_uid', props.target.value);
            }}
          />
        </Grid>

        <OutlineSelect
          {...inputProps.tier_type}
          onChange={handleChange}
          menuItemArray={[
            { value: tierGroup.standard, text: 'label.standard' },
            { value: tierGroup.premium, text: 'label.premium' },
            { value: tierGroup.enterprise, text: 'label.enterprise' }
          ]}
        />

        <OutlineSelect
          {...inputProps.tier_status}
          onChange={handleChange}
          menuItemArray={[
            { value: tierStatus.trial30, text: 'label.trial30' },
            { value: tierStatus.trial60, text: 'label.trial60' },
            { value: tierStatus.trial90, text: 'label.trial90' },
            { value: tierStatus.permanent, text: 'label.permanent' }
          ]}
        />

        <OutlineSelect
          {...inputProps.connectivity}
          onChange={handleChange}
          menuItemArray={[
            { value: connectivityType.ble, text: 'pages.customer.ble' },
            { value: connectivityType.wifi, text: 'pages.customer.wifi' },
            { value: connectivityType.gsm, text: 'pages.customer.gsm' }
          ]}
        />

        <AutocompleteSelect
          {...inputProps.tz}
          list={timezoneList}
          onChange={(value) => setFieldValue('tz', value)}
        />
      </Grid>
    </Card>
  );
}
