import { List } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { fleetHealthSelector } from '../../recoil/selector/dashboard';
import { getTemporaryVisible } from '../../helper/page';
import AlertDetails from './AlertDetails';
import Card from '../../components/common/Card';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import CleanStatus from './CleanStatus';
import Filter from '../../components/Filter/Filter';
import FleetHealthItem from './FleetHealthItem';
import TableContainer from '../../components/common/Table/TableContainer';
import UseStat from './UseStat';

import { buttonStyle, alertTypes } from '../../constants';
import { reports } from '../../paths';
import { track, getKey } from '../../helper/segment';
import { dashboardStyles } from '../../styles/Layout/Dashboard';
import segmentMessage from '../../segment';

const column = { lg: 12, md: 12, sm: 12, xs: 12 };

export default function ContentsContainer({ latestActivityDate, filterProps }) {
  const { t } = useTranslation();
  const showController = useState('');

  const fleetHealth = useRecoilValue(fleetHealthSelector);
  const history = useHistory();

  const dashboardClasses = dashboardStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const stateColumn = {
    lg: fleetHealth?.list?.length > 1 ? 6 : 12,
    md: fleetHealth?.list?.length > 1 ? 6 : 12,
    sm: 12,
    xs: 12
  };

  const renderStatus = () => {
    const errors = fleetHealth?.errors;
    const warnings = fleetHealth?.warnings;

    return errors || warnings ? (
      fleetHealth?.list.map((item) => {
        return (
          <Grid key={item.description} item {...stateColumn}>
            <FleetHealthItem {...item} showController={showController} />
          </Grid>
        );
      })
    ) : (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CleanStatus />
      </Grid>
    );
  };

  const onLatestActivityClick = () => {
    track(
      `${getKey()?.page} ${segmentMessage.dashboard.latestActivityClicked}`
    );
    history.push(reports);
  };

  return (
    <>
      {/* Alert section */}
      {!fleetHealth ? (
        <Card cardStyle={{ height: 'auto' }}>
          <List />
        </Card>
      ) : (
        <Grid container spacing={2}>
          {renderStatus()}
        </Grid>
      )}

      {/* Alert Detail section */}
      {fleetHealth?.warnings || fleetHealth?.errors ? (
        <Grid {...column} item className={dashboardClasses.detailContainer}>
          {showController[0] ? (
            <AlertDetails
              messages={fleetHealth?.messages}
              type={alertTypes?.[showController[0]]}
            />
          ) : null}
        </Grid>
      ) : (
        <></>
      )}

      {/* Filter UseStat for mobile */}
      {matches && (
        <>
          <Card
            header={t('common.quickFilters')}
            style={buttonStyle.filledBlue}
            cardStyle={{ height: 'auto' }}
          >
            <Filter {...filterProps} />
          </Card>
          <UseStat />
        </>
      )}

      {/* Latest activity section */}
      <Grid
        {...column}
        item
        className={dashboardClasses.latestActivityContainer}
      >
        <Card
          header={'common.latestActivity'}
          latestDate={latestActivityDate}
          button={
            getTemporaryVisible() && {
              onClick: () => onLatestActivityClick(),
              description: 'label.viewActivityHistory',
              style: buttonStyle.lineGreen,
              cy: 'viewActivityHistory'
            }
          }
        >
          {fleetHealth?.table ? (
            <TableContainer tableProps={fleetHealth.table} />
          ) : (
            <CardContentLoader />
          )}
        </Card>
      </Grid>
    </>
  );
}
