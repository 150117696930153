import { Code } from 'react-content-loader';
import { chartPlaceholderStyles } from '../../styles/Layout/ChartView';

export default function CardContentLoader() {
  const chartPlaceholderClasses = chartPlaceholderStyles();

  return (
    <div className={chartPlaceholderClasses.container}>
      <Code />
      <Code />
    </div>
  );
}
