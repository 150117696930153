import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';

import CSUVSlider from '../../Input/CSUVSlider';
import Tooltip from '../../common/Tooltip';
import { renderMask, renderMaskedText } from '../../../helper/roi/roi';

import styles from '../../../styles/Estimator';
import { icons } from '../../../constants';

export default function SliderWithAmount({
  title,
  onChange,

  ...others
}) {
  const classes = styles();

  return (
    <>
      <Grid container>
        {/* title */}
        <Grid item xs={12}>
          <div className={classes.title}>{title}</div>
        </Grid>

        {/* unit value */}
        <Grid item xs={12}>
          <SliderValueWithInput
            onChange={onChange}
            testID={others['data-testid']}
            {...others}
          />
        </Grid>

        {/* slider */}
        <Grid item xs={12}>
          <CSUVSlider
            {...others}
            onChange={onChange}
            aria-labelledby="non-linear-slider"
          />
        </Grid>
      </Grid>
    </>
  );
}

function SliderValueWithInput({ onChange, max, min, testID, unit, value }) {
  const classes = styles();
  const [showInput, setShowInput] = React.useState(false);

  const renderAmount = (u, val) => {
    if (!val) return 0;

    if (!u) {
      return renderMaskedText({
        val: `${val}`,
        mask: renderMask('number'),
        type: 'number'
      });
    }

    return renderMaskedText({
      val: `${val?.toFixed(2)}`,
      mask: renderMask('amount'),
      type: 'amount'
    });
  };

  const handleShowInput = () => {
    setShowInput(!showInput);
  };

  const handleValue = (event) => {
    onChange(Number(event.target.value));
  };

  return (
    <div>
      <span
        className={classes.unitValueContainer}
        data-testid={`label-${testID}`}
      >
        {unit}

        <span>
          <div className={classes.valueContainer}>
            {showInput ? (
              <InputBase
                className={classes.input}
                value={value}
                margin="dense"
                onChange={handleValue}
                onBlur={(ev) => {
                  handleValue(ev);
                  setShowInput(false);
                }}
                inputProps={{
                  step: 10,
                  min,
                  max,
                  type: 'number'
                }}
              />
            ) : (
              <span
                className={classes.value}
                data-testid={`val-${testID}`}
                onClick={handleShowInput}
              >
                {renderAmount(unit, value)}
              </span>
            )}
          </div>

          <span className={classes.tooltipContainer}>
            <Tooltip
              type={icons.edit}
              placement="right-start"
              onClick={handleShowInput}
            />
          </span>
        </span>
      </span>
    </div>
  );
}
