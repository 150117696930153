import { atom, selector } from 'recoil';
import { filter } from '../key';
import { userState } from '../state';
import { callAPI } from '../../helper/api';
import { sortByObjectName, getSortedFilter } from '../../helper/filterOptions';

const getDefaultFilter = selector({
  key: 'getDefaultFilter',
  get: async ({ get }) => {
    const user = get(userState);
    let data = null;

    if (user.isAuthed) {
      data = await callAPI(`/filters`, {})
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          throw new Error('errorMessage.somethingWentWrong');
        });

      if (user?.getUserPermission()?.isRiker && data?.customers) {
        const initList = data?.customers?.sort((prev, next) => {
          return sortByObjectName(prev, next, 'name');
        });

        const secondRes = await callAPI(`/filters`, {
          customer: initList[0].id,
          customerDevices: initList[0].id
        })
          .then((res) => {
            return res.data;
          })
          .catch((error) => {
            console.log(error);
            throw new Error('errorMessage.somethingWentWrong');
          });

        data = { ...data, ...secondRes };
      }
    }

    return getSortedFilter(data);
  }
});

export const filterState = atom({
  key: filter.filterState,

  // set default filter value
  default: getDefaultFilter
});
