import Slide from '@material-ui/core/Slide';
import { GrClose } from 'react-icons/gr';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import CSUVSettingMenu from './SettingMenu';
import CSUVMenu from './Menu';
import Copyright from '../common/Copyright';
import headerStyles from '../../styles/common/Header';

export default function MobileMenu({ isOpen, setIsOpen }) {
  const classes = headerStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return isOpen && matches ? (
    <Slide direction="right" in={isOpen}>
      <div className={classes.mobileMenuList}>
        <div
          className={classes.mobileCloseBtnContainer}
          onClick={() => setIsOpen(false)}
        >
          <GrClose size={20} onClick={() => console.log('hello')} />
        </div>
        <CSUVMenu />
        <CSUVSettingMenu />

        <Copyright />
      </div>
    </Slide>
  ) : (
    <></>
  );
}
