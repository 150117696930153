import PropTypes from 'prop-types';
import { listButtonStyles } from '../../styles/common/Button';

export default function ButtonContainer({ children }) {
  const classes = listButtonStyles();

  return <div className={classes.listButtonContainer}>{children}</div>;
}

ButtonContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};
