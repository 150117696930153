import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  container: {
    backgroundColor: color.backgroundGrey,
    marginTop: 67,
    padding: '30px 30px 50px 30px',
    position: 'relative'
  },
  section: {
    marginTop: 20
  },
  componentContainer: {
    position: 'relative',
    zIndex: 1
  },
  radiusContainer: {
    background: color.white,
    borderRadius: 5,
    boxShadow: `0px 0px 3px 2px ${color.shadow}`,
    padding: 20
  },
  imgC: {
    position: 'absolute',
    bottom: 210,
    right: 0,
    zIndex: 0
  },
  imgS: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 0
  },
  imgU: {
    position: 'absolute',
    zIndex: 0,
    top: 100,
    left: 0
  },
  imgV: {
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
    left: 0
  }
});

export const proBlockStyles = makeStyles((theme) => ({
  proContainer: {
    position: 'fixed',
    backgroundColor: 'rgba(94, 100, 114, 0.75)',
    width: 'calc(100% - 220px)',
    height: '100%',
    zIndex: 9999999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  proTitleContainer: {
    marginBottom: 30
  },
  proTitle: {
    color: color.white,
    fontFamily: fontFamily.bold,
    fontSize: '1.2rem'
  }
}));
