export default function useCustomerInputData(user) {
  const { isRiker, isSuperRikerAdmin } = user?.getUserPermission();

  const inputProps = {
    customer_uid: {
      req: 'customerUid'
    },
    customer_name: {
      label: 'label.facilityName',
      req: 'customerName',
      disabled: !isRiker,
      xs: 12
    },

    // same row
    customer_address: {
      req: 'customerAddress',
      label: 'pages.device.address',
      md: 6,
      xs: 12
    },
    contact_name: {
      req: 'contactName',
      label: 'label.primaryContact',
      md: 6,
      xs: 12
    },

    contact_phone: {
      req: 'contactPhone',
      label: 'label.phoneNumber',
      md: 6,
      xs: 12
    },
    contact_email: {
      req: 'contactEmail',
      label: 'common.email',
      md: 6,
      xs: 12
    },
    partner_uid: {
      label: 'label.distributor',
      multiple: false,
      req: 'partnerUid',
      visible: isSuperRikerAdmin
    },
    tier_type: {
      label: 'label.tier',
      readOnly: !isRiker,
      req: 'tierType',
      visible: isRiker,
      md: 6,
      xs: 12
    },
    tier_status: {
      label: 'label.tierStatus',
      readOnly: !isRiker,
      req: 'tierStatus',
      visible: isRiker,
      md: 6,
      xs: 12
    },
    connectivity: {
      label: 'label.connectivity',
      readOnly: !isRiker,
      req: 'connectivity',
      visible: isRiker,
      md: 6,
      xs: 12
    },
    tz: {
      label: 'common.timezone',
      req: 'timezone',
      md: 6,
      xs: 12
    }
  };

  return inputProps;
}
