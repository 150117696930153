import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useRecoilState } from 'recoil';
import { addDays } from 'date-fns';

import {
  getTotalUsage,
  setRequest,
  currencyToFloat,
  getNameByList,
  getCustomerUidByEntityType,
  getDeviceLengthByEntity
} from '../helper/roi/roi';
import { roiCalculatorState } from '../recoil/atom/roiCalculator';
import { ToastContext } from '../context/ToastContext';
import { validateRoiCalculator } from '../helper/validation';

import { toastType } from '../constants';

const formatUtcDate = (date, isEnd) => {
  const zoneToUtc = zonedTimeToUtc(
    isEnd ? addDays(date, 1) : date,
    'America/Toronto'
  );

  return format(zoneToUtc, 'yyyy-MM-dd');
};

// filterOptions - initialized filter option
export default function useCalculator(filterOptions, filterList) {
  const { setToast } = React.useContext(ToastContext);
  const { t } = useTranslation();

  // recoil
  const [roiCalculator, setRoiCalculator] = useRecoilState(roiCalculatorState);

  const getCustomerName = (id) => {
    if (!filterList?.customers) {
      return '';
    }

    if (Array.isArray(id)) {
      return getNameByList(filterList, id, roiCalculator.filterOptions?.name);
    }

    const { name } = filterList.customers.find((f) => f.id === id);
    return name;
  };

  // submit
  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = async (roiInputValues) => {
    try {
      // cost labor and cost per unit is string so should parse float values
      const values = {
        ...roiInputValues,
        costLabor: currencyToFloat(roiInputValues.costLabor),
        costPerUnit: currencyToFloat(roiInputValues.costPerUnit)
      };

      const errorMessage = validateRoiCalculator(values);

      if (errorMessage) {
        throw new Error(t(errorMessage));
      }

      const startDate = formatUtcDate(values.startDate);
      const endDate = formatUtcDate(values.endDate, true);

      // 2022-07-04 not just customer uid, but also device group will be filtered
      const customerUid = getCustomerUidByEntityType(
        roiCalculator.filterOptions
      );

      // set analytics api req
      const data = await setRequest({
        customerUid,
        endDate,
        id: roiCalculator.filterOptions.id,
        startDate
      });

      const numOfDevice = await getDeviceLengthByEntity(
        roiCalculator.filterOptions
      );
      const sum = getTotalUsage({ data });

      const obj = {
        ...roiCalculator,
        ...values,
        totalCSUVCleanCycle: sum,
        singleCycle: sum / numOfDevice,
        numOfDevice
      };

      setRoiCalculator(obj);
      setSubmitted(true);

      return obj;

      // total cycle should not be num, must be integer
    } catch (error) {
      console.log(error);

      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      return null;
    }
  };

  React.useEffect(() => {
    // set filter options
    setRoiCalculator({ ...roiCalculator, ...filterOptions });
  }, []);

  return {
    handleSubmit,
    roiCalculator,
    submitted,
    customerName: getCustomerName(
      roiCalculator.filterOptions?.id || filterOptions.filterOptions.id
    )
  };
}
