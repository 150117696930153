import { atom } from 'recoil';
import { getAnalyticsDefaultDateRange } from '../../helper/date';
import { trend } from '../key';
import { getDefaultAnalytics } from '../selector/analytics_page';
import { analyticsType, dataOptions } from '../../constants';

export const defaultTrend = {
  filterOptions: {
    type: analyticsType.compare,
    id: '',
    name: '',
    ...getAnalyticsDefaultDateRange()
  },
  selectedFilters: {
    dateRange: dataOptions.week,
    activity: 'scan_cycle_total'
  }
};

export const trendState = atom({
  key: trend.trendState,
  default: getDefaultAnalytics(analyticsType.trend)
});
