import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { RiArrowDropRightLine } from 'react-icons/ri';

import { viewDevice } from '../../paths';
import titleStyles from '../../styles/common/Title';
import breadcrumbStyles from '../../styles/common/Breadcrumbs';

export default function Breadcrumbs({ linkArray }) {
  const classes = titleStyles();
  const breadcrumbClass = breadcrumbStyles();
  const isMasked = new RegExp([viewDevice].join('|'), 'gi').test(
    window.location.pathname
  );

  return (
    <MUIBreadcrumbs
      classes={{ root: classes.breadcrumbs }}
      separator={
        <RiArrowDropRightLine className={classes.separator} fontSize="large" />
      }
      aria-label="breadcrumb"
    >
      {linkArray.length > 1 ? (
        linkArray.map(({ link, text }, index) => {
          return (
            <Link key={index} to={link} className={isMasked ? 'fs-mask' : ''}>
              {text}
            </Link>
          );
        })
      ) : (
        <div className={breadcrumbClass.breadcrumbContainer}>
          <RiArrowDropRightLine
            className={classes.separator}
            fontSize="large"
          />
          {linkArray.map(({ link, text }, index) => {
            return (
              <Link key={index} to={link} className={isMasked ? 'fs-mask' : ''}>
                {text}
              </Link>
            );
          })}
        </div>
      )}
    </MUIBreadcrumbs>
  );
}
