/* eslint-disable no-useless-escape */
module.exports = {
  buttonStyle: {
    filledBlue: 'filledBlue',
    filledDarkBlue: 'filledDarkBlue',
    filledGreen: 'filledGreen',
    filledLightBlue: 'filledLightBlue',
    filledRed: 'filledRed',
    filledWhiteDarkBlue: 'filledWhiteDarkBlue',
    filledWhiteGreen: 'filledWhiteGreen',
    filledYellow: 'filledYellow',
    lineBlue: 'lineBlue',
    lineGreen: 'lineGreen',
    lineRed: 'lineRed',
    lineWhite: 'lineWhite',
    lineYellow: 'lineYellow',
    white: 'white'
  },
  inputType: {
    submit: 'submit',
    password: 'password',
    text: 'text',
    radio: 'radio',
    email: 'email',
    verificationCode: 'verificationCode',
    groups: 'groups',
    dateRange: 'dateRange',
    devices: 'devices',
    customers: 'customers',
    partners: 'partners',
    viewBy: 'viewBy'
  },
  dateformat: {
    noTime: 'yyyy-MM-dd',
    withTime: 'yyyy-MM-dd H:mm',
    textDate: 'PPP',
    textDateWithTime: 'PPPp'
  },
  color: {
    backgroundGrey: '#ECF3F7',
    black: '#1e2933',
    cardShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
    darkBlue: '#003459',
    darkGreen: '#009883',
    darkGreenGradient:
      'linear-gradient(360deg, rgba(32,167,148,1) 0%, rgba(32,167,148,0) 100%)',
    darkRed: '#af0800',
    darkYellow: '#a4b400',
    davyGrey: '#565656',
    default: '#1e2933;',
    dimGrey: 'dimgrey',
    dustyGrey: '#9b9b9b',
    fontGrey: '#a2a2a2',
    green: '#5DC3B5',
    iconGrey: '#C5C5CF',
    lightBlue: '#00ADEE',
    lightGrey: '#f3f3f3',
    mustardYellow: '#ffdd55',
    orange: '#f3722c',
    red: '#EA4238',
    sail: '#ABE1F7',
    shadow: 'rgba(0,0,0,0.03)',
    slideBackground: '#ECF3F7',
    white: '#fff',
    yellow: '#E0F322'
  },
  personaType: {
    actionableReporting: 'AR',
    fleetManagement: 'FM',
    both: 'ARFM'
  },
  icons: {
    addNew: 'addNew',
    close: 'close',
    delete: 'delete',
    edit: 'edit',
    question: 'question',
    save: 'save'
  },
  tableType: {
    scheduled: 'scheduled',
    report: 'report',
    admin: {
      group: 'group',
      user: 'user',
      customer: 'customer',
      partner: 'partner',
      manage: {
        device: 'manageDevice',
        user: 'manageUser',
        group: 'manageGroup',
        customer: 'manageCustomer'
      }
    },
    device: {
      overview: 'overview',
      recentActivity: 'recentActivity',
      bulbSchedule: 'bulbSchedule',
      activityLog: 'activityLog'
    },
    analytics: {
      average: 'average',
      trend: 'trend',
      useStat: 'useStat',
      comparison: 'comparison'
    }
  },
  fontFamily: {
    bold: 'csuv-bold',
    regular: 'csuv-regular',
    semiBold: 'csuv-semibold'
  },
  modalTime: 2000,
  status: {
    red: 'red',
    green: 'green',
    yellow: 'yellow'
  },
  label: {
    email: 'Email',
    password: 'Password'
  },
  signInType: {
    customer: 'customer',
    employee: 'employee'
  },
  tokens: {
    accessToken: 'accessToken',
    idToken: 'idToken',
    refreshToken: 'refreshToken'
  },
  cognitoGroup: {
    picardAdmin: 'picardAdmin',
    picardUser: 'picardUser',
    rikerAdmin: 'rikerAdmin',
    rikerUser: 'rikerUser',
    superAdmin: 'superAdmin'
  },
  tierGroup: {
    standard: 'tierStandard',
    premium: 'tierPremium',
    enterprise: 'tierEnterprise'
  },
  tierStatus: {
    trial30: 'trial30',
    trial60: 'trial60',
    trial90: 'trial90',
    permanent: 'permanent'
  },
  regex: {
    base64: /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
    email: /(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/g,
    password:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/g,
    phone: /[\d][\d][\d][\-][\d][\d][\d][\-][\d][\d][\d][\d]/g, // [\d] number | [\-] hyphen
    phoneNumber: /([\\(]|[\\)]|[\s])/g, // remove bracket, and space
    spaceBetweenText: /[\s]/g, // remove space between text
    verificationCode: /^\d\d\d\d\d\d$/g, // ^ - Start with digit | \d - digit | $ - must end with digit
    csuvSerial: /^CS[\d][\d]-[\d][\d][\d][\d][\d]$/gi
  },
  filterType: {
    group: 'group',
    groups: 'groups',
    dateRange: 'dateRange',
    devices: 'devices',
    device: 'device',
    customers: 'customers',
    customer: 'customer',
    partners: 'partners',
    activities: 'activity',
    viewBy: 'viewBy',
    permission: 'permission',
    customDate: 'customDate'
  },
  languageCode: {
    en: 'en',
    fr: 'fr',
    kr: 'kr',
    in: 'in',
    cn: 'cn'
  },
  dataOptions: {
    fourWeeksToDays: 28,
    alerts: 'alerts',
    all: 'all',
    completed: 'completed',
    csuv: 'csuv',
    customDays: 'customDays',
    customer: 'customer',
    daily: 'daily',
    day: 'day',
    dayOfWeek: 'dayOfWeek',
    devices: 'devices',
    failed: 'failed',
    good: 'good',
    group: 'group',
    inProgress: 'in-progress',
    month: 'month',
    monthly: 'monthly',
    partner: 'partner',
    stopped: 'interrupted',
    success: 'success',
    timeOfDay: 'timeOfDay',
    week: 'week',
    weekly: 'weekly'
  },
  deviceStatus: {
    error: 'error',
    normal: 'normal',
    stopped: 'stopped',
    success: 'success',
    warning: 'warning'
  },
  alertTypes: {
    all: 'all',
    error: 'error',
    warning: 'warning',
    normal: 'normal',
    errorWarning: 'errorWarning',
    info: 'info'
  },
  actionTypes: {
    email: 'email',
    sms: 'phone',
    push: 'push'
  },
  analyticsType: {
    average: 'AVERAGE',
    compare: 'COMPARE',
    trend: 'TREND',
    usage: 'USAGE'
  },
  responseKey: {
    action: 'action',
    activities: 'activities',
    activity: 'activity',
    address: 'address',
    alertCategory: 'alert_category',
    alertType: 'alert_type',
    averageUses: 'average_uses',
    ballastStatus: 'ballast_status',
    bulbChangeDate: 'bulb_change_date',
    bulbHours: 'bulb_hours',
    changeDate: 'change_date',
    contactEmail: 'contact_email',
    contactName: 'contact_name',
    contactPhone: 'contact_phone',
    createdAt: 'created_at',
    csuvAssetId: 'csuv_asset_id',
    csuvHealth: 'csuv_health',
    csuvSerial: 'csuv_serial',
    customerAddress: 'customer_address',
    customerName: 'customer_name',
    customerUid: 'customer_uid',
    date: 'date',
    email: 'email',
    endDate: 'end_date',
    errorName: 'error_name',
    firstName: 'first_name',
    fromDate: 'from_date',
    groupName: 'group_name',
    groupUid: 'group_uid',
    groupUids: 'group_uids',
    joinDate: 'join_date',
    lastCycle: 'last_cycle',
    lastLogin: 'last_login',
    lastMaintenance: 'last_maintenance',
    lastName: 'last_name',
    locationName: 'location_name',
    loginCount: 'login_count',
    orderDate: 'order_date',
    partnerAddress: 'partner_address',
    partnerName: 'partner_name',
    partnerUid: 'partner_uid',
    permission: 'permission_level',
    permissionLevel: 'permission_level',
    personaId: 'persona_id',
    phone: 'phone',
    pk: 'pk',
    push: 'push',
    recipients: 'recipients',
    reportLink: 'report_link',
    reportStatus: 'report_status',
    reportType: 'report_type',
    scanCycle: 'scan_cycle',
    scanCycleState: 'scan_cycle_state',
    serial: 'serial',
    startDate: 'start_date',
    status: 'status',
    timestamp: 'timestamp',
    toDate: 'to_date',
    totalUses: 'total_uses',
    tz: 'tz',
    useCount: 'use_count',
    username: 'username',
    warningName: 'warning_name'
  },
  reportTypes: {
    device: 'DEVICE',
    maintenance: 'MAINTENANCE',
    scanRecord: 'SCANRECORD'
  },
  apiActionTypes: {
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    DOWNLOAD: 'DOWNLOAD',
    FETCH: 'FETCH',
    UPDATE: 'UPDATE',
    MIGRATE: 'MIGRATE',
    DOWNLOAD_DEPLOYMENT_IMAGE: 'DOWNLOAD_DEPLOYMENT_IMAGE',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD'
  },
  errorFromBackend: {
    noDataFound: 'No data found'
  },
  toastType: {
    error: 'error',
    warning: 'warning',
    success: 'success'
  },
  emailAddress: {
    trialEmail: 'product@cleanslateuv.com',
    success: 'success@cleanslateuv.com',
    support: 'support@cleanslateuv.com'
  },
  adminType: {
    partner: 'partner',
    customer: 'customer',
    user: 'user',
    device: 'device',
    csuvItem: 'csuvItem'
  },
  roi: {
    years: {
      y1: {
        id: '1y',
        months: 12
      },
      y2: {
        id: '2y',
        months: 24
      },
      y3: {
        id: '3y',
        months: 36
      },
      y4: {
        id: '4y',
        months: 48
      },
      y5: {
        id: '5y',
        months: 60
      }
    },
    quotePerUnit: {
      min: 1000,
      max: 10000
    },
    labor: {
      min: 1,
      max: 150
    },
    newUnit: {
      min: 1,
      max: 50
    },
    csuv: {
      min: 1500,
      max: 10000
    }
  },
  deviceInformation: {
    // model type
    tableTop: 'tableTop',
    stationaryStand: 'stationaryStand',
    rollingStand: 'rollingStand',

    none: 'none',

    // size
    smallMountedDisplay: 'smallMountedDisplay',
    largeMountedDisplay: 'largeMountedDisplay',

    // signage
    posterNearby: 'posterNearby',
    underlidSticker: 'underlidSticker',
    customFacility: 'customFacility',

    // sanitizer mount type
    mounted: 'mounted',
    nearby: 'nearby',
    missing: 'missing'
  },
  connectivityType: {
    ble: 'ble',
    wifi: 'wifi',
    gsm: 'gsm'
  }
};
