import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { track } from '../../helper/segment';

// etc
import { additionalLinkStyles } from '../../styles/SignIn';
import paths from '../../paths';
import segment from '../../segment';

export default function AdditionalLink({ rememberEmail, setRememberEmail }) {
  const { t } = useTranslation();
  const classes = additionalLinkStyles();
  const columns = { lg: 6, md: 6, sm: 6, xs: 6 };

  return (
    <Grid
      className={classes.additionalLinkContainer}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item {...columns}>
        <input
          data-cy="rememberEmail"
          type="checkbox"
          id="remember"
          defaultChecked={rememberEmail}
        />
        <label
          data-cy="rememberEmailLabel"
          htmlFor="remember"
          onClick={() => setRememberEmail(!rememberEmail)}
        >
          {t('label.rememberEmail')}
        </label>
      </Grid>
      <Grid item {...columns} className={classes.forgotPasswordContainer}>
        <a
          data-cy="forgotPassword"
          className={classes.forgotPassword}
          href={paths.forgotPassword}
          onClick={() => track(segment.signIn.forgotPasswordClicked)}
        >
          {t('label.forgotPassword')}
        </a>
      </Grid>
    </Grid>
  );
}

AdditionalLink.propTypes = {
  rememberEmail: PropTypes.bool.isRequired,
  setRememberEmail: PropTypes.func.isRequired
};
