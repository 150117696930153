import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles({
  notificationContainer: {
    display: 'inline-block',
    position: 'relative',
    marginRight: 20,
    marginTop: 10,
    '& svg': {
      width: 25
    }
  },
  notification: {
    alignItems: 'center',
    backgroundColor: color.red,
    borderRadius: 25,
    boxShadow: 'rgba(0,0,0,0.2)',
    color: color.white,
    display: 'flex',
    fontSize: 10,
    height: 25,
    justifyContent: 'center',
    minWidth: 22,
    position: 'absolute',
    right: -13,
    textAlign: 'center',
    top: -10,
    width: 25
  },
  clickable: { cursor: 'pointer', textTransform: 'none' }
});
