import i18n from 'i18next';
import { analyticsType, dataOptions } from '../../constants';
import {
  lineWeekChartOptions,
  trendOptions,
  compareOptions,
  statisticsOptions
} from '../chartOptions';

/**
 * @description Returning chart option
 * @param {Object} object
 * @param {Object} object.type - chart type
 * @param {Object} object.response - response data from api
 * @param {string} object.activity - type of activity to get data's value
 * @param {Function} t - translation function
 * @return {Object} chart option
 */
export function getChartObject(data) {
  const { type } = data;
  const language = {
    stopped: i18n.t('common.stopped'),
    success: i18n.t('common.success'),
    total: i18n.t('common.total')
  };

  switch (type) {
    case analyticsType.usage:
      return statisticsOptions({
        data: data?.data,
        language,
        showTrendLine: data?.showTrendLine,
        timezone: data?.tz,
        title: '',
        xAxisTitle: i18n.t('pages.analytics.useStatsXAxis'),
        yAxisTitle: i18n.t('pages.analytics.useStatsYAxis')
      });

    case analyticsType.compare:
      return compareOptions({
        timezone: data?.tz,
        title: '',
        data: data?.data,
        activity: 'scan_cycle_total',
        filterList: data?.filterList,
        language
      });

    case analyticsType.trend:
      return trendOptions({
        timezone: data?.tz,
        data: data?.data,
        title: '',
        xAxisTitle: i18n.t('pages.analytics.trendXAxis'),
        yAxisTitle: i18n.t('pages.analytics.trendYAxis'),
        language
      });

    case analyticsType.average:
      return lineWeekChartOptions({
        showValues: data?.showValues,
        currentWeek: data?.currentWeek,
        timezone: data?.tz,
        title: '',
        yAxisTitle: i18n.t('pages.analytics.averageYAxis'),
        xAxisTitle: i18n.t('pages.analytics.averageXAxis'),
        data: data?.data,
        language
      });

    default:
      return null;
  }
}

export function getTranslationLabel(key, customSelectedDate) {
  switch (key) {
    case dataOptions.day:
      return 'common.usesToday';

    case dataOptions.week:
      return 'common.past7Days';

    case dataOptions.month:
      return 'common.past30Days';

    default:
      return customSelectedDate;
  }
}
