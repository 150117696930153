import { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { callAPI } from '../../helper/api';
import { ToastContext } from '../../context/ToastContext';
import Card from '../../components/common/Card';
import StatusCard from '../../components/common/StatusCard';

import { toastType } from '../../constants';

export default function UseStat({ serial }) {
  const { t } = useTranslation();
  const [useStat, setUseStat] = useState({});
  const { setToast } = useContext(ToastContext);

  const getUseStat = async () => {
    try {
      const useStatResponse = await callAPI(`/use-stats`, {
        name: 'device',
        id: serial
      });

      if (useStatResponse?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setUseStat(useStatResponse?.data);
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  const getTranslationLabel = (key) => {
    switch (key) {
      case 'today':
        return 'common.usesToday';

      case 'last7':
        return 'common.past7Days';

      case 'last30':
        return 'common.past30Days';

      default:
        return 'common.allTime';
    }
  };

  useEffect(() => {
    getUseStat();
  }, []);

  return (
    <Card header="pages.device.useStats">
      <Grid container>
        {Object.keys(useStat).length
          ? Object.keys(useStat).map((stat, index) => {
              return (
                <StatusCard
                  key={index}
                  // eslint-disable-next-line radix
                  number={parseInt(useStat[stat])}
                  date={t(getTranslationLabel(stat))}
                  columnOptions={{ lg: 3, md: 3, sm: 6, xs: 6 }}
                />
              );
            })
          : null}
      </Grid>
    </Card>
  );
}
