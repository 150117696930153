import { useRecoilValue } from 'recoil';

import { filterState } from '../../recoil/atom/filter';
import { apiActionTypes } from '../../constants';
import {
  getRefinedReq,
  getAssignedEntityFilter
} from '../../helper/admin/page';

export default function usePartnerData(props) {
  const data = props?.data;
  const filterList = useRecoilValue(filterState);

  const pairs = {
    partner_uid: 'partnerUid',
    partner_name: 'partnerName',
    partner_address: 'partnerAddress',
    assigned_customers: 'assignedCustomers',
    tz: 'timezone'

    // contact_name: 'contactName',
    // contact_phone: 'contactPhone',
    // contact_email: 'contactEmail'
  };

  const inheritedData =
    data && Object.keys(data).length
      ? {
          ...data,
          assigned_customers: getAssignedEntityFilter({
            list: filterList?.customers,
            name: 'parent_partner',
            uid: data.partner_uid
          })
        }
      : {};

  const getDeleteReq = (uid) => {
    return { type: apiActionTypes.DELETE, partnerUid: uid };
  };

  const getEditReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    return reqData;
  };

  const getCreateReq = (values) => {
    const reqData = getRefinedReq({ pairs, data: values });

    delete reqData.partnerUid;
    delete reqData.assignedCustomers;

    return reqData;
  };

  return {
    inheritedData,

    // func
    getDeleteReq,
    getEditReq,
    getCreateReq
  };
}
