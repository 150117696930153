/* eslint-disable no-param-reassign */
import { sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Highcharts from 'highcharts';
import regression from 'regression';

import { color } from '../constants';

const arrayColorList = [
  color.lightBlue,
  color.darkBlue,
  color.red,
  color.darkGreen,
  color.green
];

function sortByDate(list) {
  return list.sort((prev, next) => {
    const prv = prev[0];
    const nxt = next[0];

    if (prv > nxt) return 1;
    if (nxt > prv) return -1;
    return 0;
  });
}
/**
 * @description Returning formatted data
 * @param {Object} object
 * @param {Object} object.data - response date from analytics API
 * @param {string} object.activity - type of activity to get data's value
 * @return {Object} formatted date and value
 */
export function getFormattedDataList({ data, activity }) {
  const isValid = Array.isArray(data) && data.length;

  const filteredData = isValid && data?.filter((item) => item && item?.sk);
  let formattedData = [];

  // If it is group, find duplicated date and sum of activity value when the date is same
  if (filteredData[0]?.pk?.match(/(^g-|^CS\d\d)/gim)) {
    let initMap = new Map();

    filteredData.map((item) => {
      if (!initMap.has(item.sk)) {
        initMap.set(item.sk, item[activity]);
      } else {
        initMap.set(item.sk, item[activity] + initMap.get(item.sk));
      }

      return null;
    });

    initMap = [...initMap];

    // value or point[1] must be float value
    for (let key = 0; key < initMap.length; key += 1) {
      const item = initMap[key];
      formattedData.push([
        Date.parse(new Date(item[0])),
        Math.round(parseFloat(item[1]))
      ]);
    }
  } else {
    // Partner or Customer
    formattedData =
      isValid &&
      data
        ?.filter((item) => item && item?.sk)
        .map((item) => [item.sk, item[activity]]);
    formattedData = formattedData.map((point) => {
      return [Date.parse(new Date(point[0])), Math.round(parseFloat(point[1]))];
    });
  }

  return sortByDate(formattedData);
}

/**
 * @description Returning common options for chart
 * @param {Object} object
 * @param {string} object.timezone - user timezone
 * @param {string} object.title - title of chart
 * @param {string} object.rangeDescription - Range description for accessibility
 * @param {string} object.yAxisTitle - yAxis title
 * @return {Object} option object
 */
export function getCommonOptions({
  timezone,
  title,
  rangeDescription,
  yAxisTitle,
  xAxisTitle
}) {
  return {
    global: {
      useUTC: true
    },
    time: {
      timezone
    },
    title: {
      text: title
    },
    credits: {
      text: 'www.cleanslateuv.com',
      href: 'https://cleanslateuv.com/',
      enabled: true,
      style: {
        fontFamily: 'EuclidTriangle-Regular',
        fontSize: '13px'
      }
    },
    yAxis: {
      title: {
        text: yAxisTitle
      }
    },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription
      },
      labels: {
        formatter() {
          const tz = new Highcharts.Time({
            timezone
          });

          this.value -= tz.getTimezoneOffset();
          return Highcharts.dateFormat('%Y-%m-%d', this.value);
        },
        rotation: -45
      },
      title: {
        text: xAxisTitle
      }
    },
    chart: {
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical'
            }
          }
        }
      ]
    }
  };
}

/**
 * @description get sum of data by activity
 * @param {Object} data - response date from analytics API
 * @param {string} activity - activity name
 * @return {Object} comparison data object
 */
export function getPieChartDataByActivity(data, activity) {
  const object = {};
  const initData = data.filter((option) => option);

  for (let index = 0; index < initData.length; index += 1) {
    const items = initData[index];

    for (
      let itemIndex = 0;
      itemIndex < items[activity].length;
      itemIndex += 1
    ) {
      const item = items[activity][itemIndex];
      const entryItem = Object.entries(item);
      const key = entryItem[0][0];
      const value = entryItem[0][1];

      if (key in object) {
        object[key] += value;
      } else {
        Object.assign(object, { [key]: value });
      }
    }
  }

  return object;
}

/**
 * @description get formatted comparison data
 * @param {Object} data - formatted data by activity
 * @return {Array} comparison data object
 */
export function getFormatPieData(data) {
  return Object.entries(data).map((item, index) => ({
    name: item[0],
    y: item[1],
    color: arrayColorList[index]
  }));
}

/**
 * @description get human readable name by key id (e.g. P-1, C-11)
 * @param {string} name - key name (ID)
 * @return {string} human readable key name
 */
export function getKeyByValue(name) {
  let key = '';

  if (name.match(/^p-/gim)) {
    key = 'partners';
  }

  if (name.match(/^c-/gim)) {
    key = 'customers';
  }

  if (name.match(/^g-/gim)) {
    key = 'groups';
  }

  if (name.match(/^cs[\d][\d]-/gim)) {
    key = 'devices';
  }

  return key;
}

/**
 * @description get renamed key object
 * @param {Object} dataByActivity -  formatted data by activity
 * @param {Object} filterList -  formatted filter list to get name
 * @return {Array} human readable object and sum value
 */
export function renameObject(dataByActivity, filterList) {
  const object = {};
  const entries = Object.entries(dataByActivity);

  for (let index = 0; index < entries.length; index += 1) {
    const data = entries[index];
    const key = getKeyByValue(data[0]);

    for (
      let dataIndex = 0;
      dataIndex < filterList?.[key]?.length;
      dataIndex += 1
    ) {
      const item = filterList?.[key][dataIndex];

      // item.id refers to P-1 or C-1
      if (item?.id === data[0]) {
        const selectedGroupName = item?.location ? item.location : item.name;
        Object.assign(object, { [selectedGroupName]: data[1] });
      }
    }
  }

  return object;
}

export function getHourDataByActivity(data, activity, timezone) {
  const list = data.filter((option) => option).map((item) => item[activity]);
  const initMap = new Map();

  list.map((item) => {
    for (let index = 0; index < item.length; index += 1) {
      const object = item[index];
      const objectEntryArray = Object.entries(object);

      for (
        let entryIndex = 0;
        entryIndex < objectEntryArray.length;
        entryIndex += 1
      ) {
        const arg = objectEntryArray[entryIndex];
        const changedHour = String(arg[0]).padStart(2, '0'); // This line will set string hour but less than 10 will display 01, 02...
        const dummyDateHour = sub(
          utcToZonedTime(`2021-04-01T${changedHour}:00:00.000Z`, timezone),
          { hours: 1 }
        ).getHours(); // To get utc date, it requires actual date.
        const dummyHour = String(dummyDateHour).padStart(2, '0'); // This line will set string hour but less than 10 will display 01, 02...

        // This area returns key: hour  value : data
        if (!initMap.has(dummyHour)) {
          initMap.set(dummyHour, arg[1]);
        } else {
          initMap.set(dummyHour, arg[1] + initMap.get(dummyHour));
        }
      }
    }

    return null;
  });

  const initList = [...initMap.entries()].sort();

  // ie requires "labelrank" key
  // Todo: trend, use stat are not working because of that reason. I should go over more.
  const refinedList = initList.map((item) => {
    return {
      y: item[1],
      name: item[0],
      // eslint-disable-next-line radix
      labelrank: 1
    };
  });

  return refinedList;
}

/**
 * Math: https://www.cuemath.com/geometry/y-mx-b/
 * example: https://codesandbox.io/s/gizzo

 * creating linear trend line for usage,
 * @param {array} data [date (unix), value]
 * @returns [[date (unix), calculated value], [date (unix), calculated value]]
 */
export const calculateRegression = (data) => {
  const { equation } = regression.linear(data, {
    precision: 13
  });

  const [slope, intercept] = equation;

  const xMin = data[0][0]; // min date
  const xMax = data[data.length - 1][0]; // max date

  //  * For a linear fit, the coefficients are `[a, b]` in `y = a * x + b`.
  const calculatedMin = {
    x: xMin,
    y: slope * xMin + intercept
  };

  const calculatedMax = {
    x: xMax,
    y: slope * xMax + intercept
  };

  const regressionData = [
    [calculatedMin.x, calculatedMin.y],
    [calculatedMax.x, calculatedMax.y]
  ];

  return regressionData;
};

export const getTotalRegFormat = (calculatedReg) => {
  const v_start = calculatedReg[0][1];
  const v_end = calculatedReg[1][1];
  const pre = v_start > v_end ? '-' : '+';

  return {
    regData: calculatedReg,
    isPositive: pre === '+',
    pre,
    calculated: Math.round(((v_end - v_start) / v_end) * 100)
  };
};
