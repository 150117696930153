import Grid from '@material-ui/core/Grid';

import Card from '../../components/common/Card';
import MultipleSelectWithChip from '../../components/Input/MultipleSelectWithChip';
import { sortList } from '../../helper/filterOptions';
import { getInputProps } from '../../helper/admin/page';

const inputPropsData = {
  assigned_customers: {
    label: 'label.assignedCustomer',
    readOnly: true,
    multiple: true
  }
};

export default function Form({ errors, filterList, isEdit, values }) {
  if (!isEdit) return null;

  const props = getInputProps({
    inputProps: inputPropsData,
    errors,
    values
  });

  return (
    <Card header={'Additional Information'}>
      <Grid item xs={12}>
        <MultipleSelectWithChip
          {...props.assigned_customers}
          list={sortList(filterList.customers, 'name')}
        />
        Modify in customer manage page
      </Grid>
    </Card>
  );
}
