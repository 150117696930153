import React from 'react';
import { fileToBase64 } from '../../../helper/device/deploymentInformation';
import { getRefinedReq } from '../../../helper/admin/page';
import useFetch from '../../../hooks/useFetch';

import { ToastContext } from '../../../context/ToastContext';
import { toastType, apiActionTypes } from '../../../constants';

export default function useDeployInputData({
  deployed_image,
  model_type,
  sanitizer_mount_type,
  size_mount_type,
  signage
}) {
  const { fetch } = useFetch();

  // context
  const { setToast } = React.useContext(ToastContext);

  const uploadImageTypeList = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  const initData = {
    deployed_image: deployed_image || '',
    model_type: model_type || '',
    sanitizer_mount_type: sanitizer_mount_type || '', // empty value will display placeholder
    size_mount_type: size_mount_type || '',
    signage: signage || [] // empty value will display placeholder,
  };

  const pairs = {
    deployed_image: 'deployedImage',
    model_type: 'modelType',
    sanitizer_mount_type: 'sanitizerMountType',
    size_mount_type: 'sizeMountType',
    signage: 'signage',
    serial: 'csuvSerial'
  };

  const getRefinedRequest = async (reqData) => {
    const refinedReq = {};
    const entries = Object.entries(reqData);

    if (!Object.keys(reqData).length) return null;

    const fileKeyList = ['deployed_image', 'deployedImage'];

    entries.forEach(([key, value]) => {
      if (value) {
        if (fileKeyList.includes(key)) return;
        if (Array.isArray(value) && !value.length) return; // if value is array and empty terminate function
        refinedReq[key] = value;
      }
    });

    if (reqData?.deployedImage || reqData?.deployed_image) {
      const key = reqData?.deployedImage ? 'deployedImage' : 'deployed_image';
      const imageToBase64 = await fileToBase64(reqData[key]);
      refinedReq[key] = imageToBase64;
    }

    return refinedReq;
  };

  const getEditReq = async (values) => {
    let refinedReq = {};

    try {
      const reqData = getRefinedReq({ pairs, data: values });

      refinedReq = await getRefinedRequest(reqData);
    } catch (error) {
      console.log({ error });
    }

    return refinedReq;
  };

  const handleSubmit = async (values, actions, serial) => {
    const req = await getEditReq({ ...values, serial });

    const response = await fetch({
      url: '/csuv-item',
      request: { ...req, type: apiActionTypes.UPDATE },
      message: 'toastMessage.deviceEditSuccess'
    });

    if (response) {
      actions.resetForm({ values });

      return true;
    }

    return false;
  };

  const handleFileChange = (value, changeCallback) => {
    // return true, if cancel clicked
    if (!(value instanceof File)) {
      return true;
    }

    if (value?.size > 1000000) {
      setToast({
        message: 'Image size must be less than 1MB',
        type: toastType.error,
        open: true
      });

      return false;
    }

    changeCallback('deployed_image', value);
    return true;
  };

  const handleRemoveSizeMountType = (eventValue, formikValue, cb) => {
    if (eventValue === formikValue) {
      cb('size_mount_type', '');
    }
  };

  return {
    inheritedData: initData,
    uploadImageTypeList,

    // func
    getEditReq,
    getRefinedRequest,
    handleSubmit,
    handleFileChange,
    handleRemoveSizeMountType
  };
}
