import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles({
  copyrightContainer: {
    fontSize: '0.8rem',
    textAlign: 'center',
    color: color.fontGrey
  },
  version: {
    paddingTop: 20,
    fontSize: '0.8rem',
    textAlign: 'center',
    color: color.darkBlue
  }
});
