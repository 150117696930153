import { useTranslation } from 'react-i18next';
import { BiLogIn } from 'react-icons/bi';
import Button from '../../components/Button/Button';
import TextField from '../../components/Input/TextField';
import loading from '../../assets/images/loading.gif';
import { buttonStyle, inputType } from '../../constants';
import { signInLeft } from '../../styles/SignIn';

export default function Form({
  isLoading,

  // formik
  handleSubmit,
  handleChange,

  // formik obj
  values
}) {
  const formClasses = signInLeft();
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        xs={12}
        name="username"
        onChange={handleChange}
        label={t('label.email')}
        errorMessage={t('errorMessage.email')}
        value={values.username}
      />
      <div className={formClasses.buttonContainer}>
        {isLoading ? (
          <img
            data-cy="forgotPasswordLoading"
            src={loading}
            style={{ width: 20 }}
          />
        ) : (
          <Button
            cy="sendEmailButton"
            fullWidth={true}
            description={t('label.sendVerificationCode')}
            style={buttonStyle.filledBlue}
            type={inputType.submit}
            icon={<BiLogIn />}
          />
        )}
      </div>
    </form>
  );
}
