/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../../../context/ToastContext';
import { callAPI, getFilterByOption } from '../../../../helper/api';
import { refineTableObjectIfNull } from '../../../../helper/table';
import { apiActionTypes, toastType } from '../../../../constants';
import { pageObj, getErrorMessageForUser } from '../../../../helper/admin/page';
import { getSortedFilter } from '../../../../helper/filterOptions';

// API hook
export default function useManage(type, [filterList, setFilter]) {
  // third party
  const { t } = useTranslation();
  const history = useHistory();

  // state
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // context
  const { setToast } = React.useContext(ToastContext);

  // by type
  const { deleteURL, manageURL, resMessage, closeURL } = pageObj?.[type];

  // refresh filter but should be refactored
  const refreshFilter = async () => {
    const filter = await getFilterByOption({});
    setFilter(getSortedFilter({ ...filterList, ...filter }));
  };

  // 1. FETCH
  const getData = async (request = {}) => {
    setLoading(true);

    try {
      const response = await callAPI(manageURL, {
        ...request,
        type: apiActionTypes.FETCH
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      if (!Array.isArray(response?.data)) {
        throw new Error('errorMessage.noDataFound');
      }

      setData(
        Array.isArray(response?.data) &&
          response?.data?.length &&
          refineTableObjectIfNull(response?.data)
      );
      setLoading(false);

      return null;
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
      setLoading(false);
      return null;
    }
  };

  const onEdit = async (reqData) => {
    setLoading(true);

    try {
      const response = await callAPI(manageURL, {
        ...reqData,
        type: reqData?.type || apiActionTypes.UPDATE
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      refreshFilter();

      setToast({
        message: t(resMessage.edit),
        type: toastType.success,
        open: true
      });

      return history.push(closeURL);
    } catch (error) {
      setToast({
        message: error.message,
        type: toastType.error,
        open: true
      });

      return null;
    }
  };

  // Delete button on click
  const onDelete = async (request) => {
    try {
      // device page requires different delete url
      const response = await callAPI(deleteURL || manageURL, request);

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      refreshFilter();

      setToast({
        message: t(resMessage.delete),
        type: toastType.success,
        open: true
      });

      return history.push(closeURL);
    } catch (error) {
      setToast({
        message: error.message,
        type: toastType.error,
        open: true
      });

      return null;
    }
  };

  const onSave = async (reqData) => {
    try {
      const response = await callAPI(manageURL, {
        ...reqData,
        type: apiActionTypes.CREATE
      });

      if (response.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      refreshFilter();

      setToast({
        message: t(resMessage.save),
        type: toastType.success,
        open: true
      });

      return history.push(closeURL);
    } catch (error) {
      setToast({
        message:
          getErrorMessageForUser(error?.response?.data) || error?.message,
        type: toastType.error,
        open: true
      });

      return null;
    }
  };

  return {
    // func
    getData, // FETCH
    onEdit, // UPDATE
    onSave, // CREATE
    onDelete, // DELETE

    // data
    data,
    loading
  };
}
