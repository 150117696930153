import React from 'react';

import { callAPI } from '../../helper/api';
import { setMergeDeployedInfoData } from '../../helper/device/deploymentInformation';

export default function useDeviceInfoData(serial) {
  const [loading, setLoading] = React.useState(true);
  const [deviceInfo, setDeviceInfo] = React.useState({});

  const getDeviceInfo = async () => {
    try {
      const useStatResponse = await callAPI(`/device/${serial}`, {});

      if (useStatResponse?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      return useStatResponse?.data;
    } catch (error) {
      throw error.message;
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const deviceData = await getDeviceInfo();
      const mergedData = await setMergeDeployedInfoData(deviceData);

      setDeviceInfo(mergedData);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (serial) {
      getData();
    }
  }, []);

  return { deviceInfo, loading };
}
