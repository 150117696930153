import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { dashboardStyles } from '../../styles/Layout/Dashboard';
import { dateformat, buttonStyle } from '../../constants';
import { formatDate, getUtcDate, getTierMessage } from '../../helper/date';
import { tierStyles } from '../../styles/common/Header';
import { userState } from '../../recoil/state';
import Button from '../../components/Button/Button';
import Card from '../../components/common/Card';
import ContentsContainer from './ContentsContainer';
import Filter from '../../components/Filter/Filter';
import Loading from '../../components/common/Loading';
import Modal from '../../components/common/Modal';
import useSegment from '../../hooks/useSegment';
import UseStat from './UseStat';
import withContent from '../../components/hoc/withContent';

import { dashboardState } from '../../recoil/atom/dashboard';

function Dashboard() {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useRecoilValue(userState);
  const [dashboard, setDashboard] = useRecoilState(dashboardState);
  const [isOpen, setIsOpen] = useState(location?.isTierModalOpen);
  const [loading, setLoading] = useState(false);
  const [latestActivityDate, setLatestActivityDate] = useState(null);
  const isRiker = user?.getUserPermission()?.isRiker;

  // style
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dashboardClasses = dashboardStyles();
  const tierClasses = tierStyles();

  useSegment();

  const setLatestDate = () => {
    setLatestActivityDate(
      formatDate(
        getUtcDate({ date: new Date().toISOString(), timezone: user.timezone }),
        dateformat.textDateWithTime
      )
    );
  };

  const isTierValid =
    Number.isInteger(user?.tierRemainDays) && user?.tierRemainDays > 0
      ? user?.tierRemainDays
      : 0;

  useEffect(() => {
    if (Number.isInteger(user?.tierRemainDays) && user?.tierRemainDays < 1) {
      setIsOpen(true);
    }
  }, []);

  useEffect(async () => {
    setLoading(true);
    const alert = callAPI(`/alerts`, {
      ...dashboard.filterOptions,
      messages: true
    });

    const activity = callAPI(`/activity-history`, {
      ...dashboard.filterOptions,
      page: 'dashboard'
    });
    const useStat = callAPI(`/use-stats`, dashboard.filterOptions);

    const result = await Promise.all([alert, activity, useStat])
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });

    setLatestDate();
    setDashboard({
      ...dashboard,
      alert: result?.[0].data,
      activity: result?.[1].data,
      useStat: result?.[2].data
    });
    setLoading(false);
  }, [dashboard.filterOptions]);

  return (
    <>
      <Loading isOpen={loading} />

      <div className={dashboardClasses.container}>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={
              isTierValid
                ? t('common.tierModalTitle')
                : t('common.tierExpiredModalTitle')
            }
          >
            <div>
              <Trans
                i18nKey={
                  isTierValid
                    ? 'common.tierModalMessage'
                    : 'common.tierExpiredModalMessage'
                }
                components={{
                  span: <span />,
                  text: <span className={tierClasses.tierDay} />
                }}
                values={{ date: user?.tierRemainDays }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: 20,
                justifyContent: 'center'
              }}
            >
              <Button
                style={buttonStyle.filledBlue}
                description={t('pages.support.contact')}
                isInitialLabel={true}
                onClick={() => {
                  window.location = getTierMessage(user);
                }}
              />
            </div>
          </Modal>
        )}

        {/* Section Info */}
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ContentHeading
              title={t('menuName.dashboard')}
              latestActivityDate={latestActivityDate}
            />
          </Grid>
          {/* Left container */}
          <Grid item lg={8} md={7} sm={12} xs={12} data-cy="contentContainer">
            <ContentsContainer
              latestActivityDate={latestActivityDate}
              isOpen={true}
              filterProps={{ listOptions: { customer: isRiker, group: true } }}
            />
          </Grid>

          {/* Right container */}
          {!matches && (
            <Grid item lg={4} md={5} sm={12} xs={12}>
              <Card
                header={t('common.quickFilters')}
                style={buttonStyle.filledBlue}
                cardStyle={{ height: 'auto' }}
              >
                <Filter listOptions={{ customer: isRiker, group: true }} />
              </Card>
              <UseStat />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default withContent(Dashboard);
