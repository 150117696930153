import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  container: {
    paddingTop: 15,
    marginLeft: 20,
    marginRight: 20
  },
  menuText: {
    color: color.lightBlue,
    fontSize: '0.95rem'
  },
  menuTextRoot: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto'
  },
  menuSubText: {
    color: 'inherit',
    fontSize: '0.7rem',
    fontFamily: (isSelected) =>
      isSelected ? fontFamily.bold : fontFamily.regular
  },
  menuItem: {
    border: (isSelected) =>
      isSelected ? `solid 2px ${color.white}` : `solid 2px ${color.white}`,
    borderRadius: 50,
    fontFamily: (isSelected) =>
      isSelected ? fontFamily.bold : fontFamily.regular,
    marginBottom: 2,
    paddingBottom: 0,
    paddingTop: 0,
    transition: 'all .2s',
    position: 'relative',

    '& > svg': {
      position: 'absolute',
      right: 5
    },

    '&:hover': {
      backgroundColor: color.white,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
      border: `solid 2px ${color.white}`,

      '& $menuText': {
        color: color.darkBlue,
        fontFamily: fontFamily.bold
      },
      '& $menuIcon': {
        '& svg': {
          fill: color.darkBlue
        }
      },
      '& $menuSubText': {
        fontFamily: fontFamily.bold
      }
    }
  },
  menuIcon: {
    minWidth: 25,
    '& svg': {
      fill: color.darkBlue
    }
  },
  settingMenuContainer: {
    borderTop: `solid 1px ${color.lightGrey}`,
    paddingTop: 20,
    marginLeft: 20,
    marginRight: 20,

    '& $menuText': {
      color: color.darkBlue,
      fontFamily: fontFamily.regular
    },
    '& $menuIcon': {
      '& svg': {
        fill: color.fontGrey
      }
    }
  },
  subMenuItem: {
    paddingLeft: 40
  },
  selected: {
    backgroundColor: color.white,
    border: `solid 2px ${color.lightBlue}`,

    '& $menuText': {
      color: color.darkBlue,
      fontFamily: fontFamily.semiBold
    },
    '& $menuIcon': {
      '& svg': {
        fill: color.darkBlue
      }
    }
  },
  proIcon: {
    width: 15,
    marginLeft: 10,
    marginTop: 5
  }
});
