import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

const defaultStyle = {
  selectColor: color.white,
  selectHoverColor: color.darkGreen
};

function getSelectColor(style) {
  switch (style) {
    default:
      return defaultStyle;
  }
}

export default (style) => {
  const { selectColor, selectHoverColor } = getSelectColor(style);

  return makeStyles({
    formContainer: {
      width: '100%'
    },
    noSelectText: {
      color: color.white,
      fontFamily: fontFamily.bold,
      padding: '6px 5px'
    },
    root: {
      color: selectColor,
      padding: '6px 5px',

      '&.MuiInputBase-root:hover': {
        background: color.darkGreenGradient
      },
      '&.MuiFormLabel-root': {
        textTransform: 'capitalize'
      },

      '&.MuiInputBase-input': {
        fontSize: 13
      },

      '&.Mui-focused': {
        color: `${selectColor} !important`
      },
      '&.MuiFormLabel-root.Mui-focused': {
        color: selectColor
      },
      '&.MuiInput-underline:before': {
        borderBottom: `1px solid ${selectColor}`
      },
      '&.MuiInput-underline:after': {
        borderBottom: `2px solid ${selectColor}`
      },
      '&.MuiSelect-select:focus': {
        backgroundColor: 'initial !important'
      },
      '& .MuiSelect-icon path': {
        color: selectColor
      },
      '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${selectHoverColor}`
      },
      '& .MuiInputBase-input': {
        fontFamily: fontFamily.bold
      }
    }
  })();
};

export const groupSelectStyles = makeStyles({
  menuItem: {
    '& span': {
      whiteSpace: 'nowrap',
      width: 340,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14
    }
  }
});
