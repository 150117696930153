import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { SubHeading } from '../../components/common/Title';
import Accordion from '../../components/common/Accordion';

export default function AccordionContainer({
  type,
  selectedTitle,
  searchText,
  list
}) {
  const { t } = useTranslation();
  const notMatch = selectedTitle !== type;

  return list?.length ? (
    <>
      {notMatch ? (
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <SubHeading title={t(`pages.support.${type}`)} />
        </Grid>
      ) : null}

      <Grid
        item
        lg={notMatch ? 10 : 12}
        md={12}
        sm={12}
        xs={12}
        style={{ marginBottom: 30 }}
      >
        <Accordion
          type={type}
          selectedTitle={selectedTitle}
          searchText={searchText}
          list={list}
        />
      </Grid>
    </>
  ) : null;
}
