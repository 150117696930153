import { useContext } from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useTranslation } from 'react-i18next';
import { BiErrorCircle } from 'react-icons/bi';

import { IoClose } from 'react-icons/io5';
import Button from '@material-ui/core/Button';

import toastStyles from '../../styles/common/Toast';
import { ToastContext } from '../../context/ToastContext';
import { color, toastType } from '../../constants';

const { error, success } = toastType;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Toast() {
  const toastClasses = toastStyles();
  const { toast, setToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const renderToastContent = () => {
    let title = '';

    switch (toast.type) {
      case error:
        title = 'common.error';
        break;

      case success:
        title = 'common.success';
        break;

      default:
        title = '';
        break;
    }

    return { title };
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ type: '', message: '', open: false });
  };

  const { title } = renderToastContent();

  if (toast.open) {
    return (
      <MuiSnackbar
        open={toast.open}
        autoHideDuration={toast?.duration || 3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={toast.type}
          className={toastClasses.toastContainer}
          icon={<BiErrorCircle fontSize="1.5rem" fill={color.white} />}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleClose}
              className={toastClasses.toastCloseButton}
            >
              <IoClose fontSize="1.5rem" fill={color.white} />
            </Button>
          }
        >
          <AlertTitle className={toastClasses.toastTitle}>
            {t(title)}
          </AlertTitle>
          <span className={toastClasses.toastContent}>{toast.message}</span>
        </Alert>
      </MuiSnackbar>
    );
  }

  return <></>;
}
