import { makeStyles } from '@material-ui/styles';
import { fontFamily } from '../../constants';

export const dashboardStyles = makeStyles((theme) => ({
  detailContainer: { marginTop: '0.5rem' },
  container: { height: 'auto' },
  statContainer: {
    marginTop: '1rem'
  },
  handIcon: {
    width: 100
  },
  imageContainer: {
    paddingRight: 30,
    textAlign: 'right'
  },
  goodConditionContainer: {
    display: 'flex',
    overflow: 'hidden'
  },
  cleanStatusMessage: {
    marginTop: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: '3vw'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2vw'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3vw'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1vw'
    }
  },
  waveIcon: {
    width: 40,

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  latestActivityContainer: {
    marginTop: '1rem'
  }
}));

export const lastUpdateStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 5
  },
  label: {
    fontFamily: fontFamily.bold,
    display: 'inline-block',
    marginRight: 5
  }
});
