import InputBase from '@material-ui/core/InputBase';
import deviceDetailStyles from '../../styles/DeviceDetail'; // deviceTooltipStyles

/**
 *
 * @param {{ value: string, onChange: function, onBlur: function, size:string, name: string }} props
 * @returns
 */
export default function UnderLineTextField(props) {
  const classes = deviceDetailStyles();

  return (
    <InputBase
      classes={{ input: classes.editInputOverride }}
      className={classes.editInput}
      {...props}
    />
  );
}
