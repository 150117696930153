import PropTypes from 'prop-types';
import { AiOutlineSave } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import { RiDeleteBinLine } from 'react-icons/ri';
import Button from './Button';
import ButtonContainer from './ButtonContainer';
import contentStyles from '../../styles/common/Content';
import { inputType, buttonStyle } from '../../constants';

export function DeleteButtonGroup({ onClick }) {
  const contentClasses = contentStyles();

  return (
    <div className={contentClasses.section}>
      <ButtonContainer>
        <Button
          description="Delete"
          icon={<RiDeleteBinLine />}
          onClick={onClick}
          style={buttonStyle.filledRed}
        />
      </ButtonContainer>
    </div>
  );
}

export function SaveButtonGroup({ saveOnClick, cancelOnClick }) {
  const contentClasses = contentStyles();

  return (
    <div className={contentClasses.section}>
      <ButtonContainer>
        <Button
          description="Save Changes"
          icon={<AiOutlineSave />}
          onClick={saveOnClick}
          type={inputType.submit}
          style={buttonStyle.filledBlue}
        />
        <Button
          description="Cancel"
          icon={<ImCancelCircle />}
          onClick={cancelOnClick}
          style={buttonStyle.filledGreen}
        />
      </ButtonContainer>
    </div>
  );
}

DeleteButtonGroup.propTypes = {
  onClick: PropTypes.func.isRequired
};

SaveButtonGroup.propTypes = {
  saveOnClick: PropTypes.func.isRequired,
  cancelOnClick: PropTypes.func.isRequired
};
