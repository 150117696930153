import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

// components
import Logo from '../common/Logo';
import Menu from '../Menu/Menu';
import SettingMenu from '../Menu/SettingMenu';
import User from '../common/User';
import Copyright from '../common/Copyright';

// etc
import appStyles from '../../styles/App';

export default function withAuth(Component) {
  const classes = appStyles();

  return (props) => (
    <Grid className={classes.container} container>
      <Grid className={classes.sideContainer} item>
        <div className={classes.side}>
          <Logo />
          <div style={{ marginTop: 65 }}>
            <Menu />
            <SettingMenu />
            <div className={classes.bottomMenu}>
              <Copyright />
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.contentContainer} item>
        <User />
        <Component {...props} />
      </Grid>
    </Grid>
  );
}

withAuth.propTypes = {
  Component: PropTypes.element.isRequired
};
