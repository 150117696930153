import { atom, selector } from 'recoil';
import { sub } from 'date-fns';
import { userState } from '../state';

import { report } from '../key';

const getDefaultReport = selector({
  key: 'getDefaultReport',
  get: ({ get }) => {
    const user = get(userState);
    const { isPicardAdmin, isRiker } = user.getUserPermission();
    let id;

    if (isPicardAdmin) {
      id = user.customer_uid;
    }

    return {
      filterOptions: { id },
      permission: {
        read: true,
        edit: isPicardAdmin || isRiker,
        delete: isPicardAdmin || isRiker
      }
    };
  }
});

export const reportState = atom({
  key: report.reportState,
  default: getDefaultReport
});

export const scanRecordState = atom({
  key: report.scanRecordState,
  default: getDefaultReport
});

export const scheduledState = atom({
  key: report.scheduledState,
  default: {
    filterOptions: {
      startDate: sub(new Date(), { months: 1 }),
      endDate: new Date()
    },
    selectedFilters: {
      dateRange: report.month
    }
  }
});
