import { atom } from 'recoil';
import { alerts } from '../key';

export const alertState = atom({
  key: alerts.alertState,
  default: {
    errors: 0,
    warnings: 0,
    total: 0
  }
});
