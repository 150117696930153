import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../constants';

export default makeStyles((theme) => {
  return {
    supportButtonContainer: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 30
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 50
      },

      display: 'flex',
      alignItems: 'center'
    }
  };
});

export const supportMobileStyles = makeStyles(() => ({
  mobileContainer: {
    margin: 30,
    marginTop: 50
  },
  contactContainer: {
    justifyContent: 'flex-end'
  }
}));

export const supportButtonStyles = () => {
  return makeStyles((theme) => {
    return {
      supportItemSelected: {},
      supportBtnContainer: {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        [theme.breakpoints.up('md')]: {
          height: 100,
          position: 'relative'
        },
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 173, 238, 0.3)',
        color: color.darkBlue,
        borderRadius: 10,
        padding: '0 30px',

        '&:hover, &$supportItemSelected': {
          backgroundColor: color.lightBlue,
          color: color.white,

          '& $supportText': {
            color: color.white
          },
          '& $supportViewButton': {
            color: color.white,
            borderColor: color.white
          },
          '& $supportArrow': {
            '& path': {
              fill: color.white
            }
          }
        }
      },
      supportImage: {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
          position: 'absolute',

          height: '90%',
          width: '8vw'
        },
        [theme.breakpoints.up('lg')]: {
          top: -45,
          width: '5vw'
        }
      },
      supportTextContainer: {
        [theme.breakpoints.down('sm')]: {
          padding: '20px 0',
          width: '100%'
        },
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-end'
      },
      supportText: {
        [theme.breakpoints.down('sm')]: {
          fontSize: 20
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '3vw'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 25,
          marginBottom: 3
        },
        transition: 'all 0.2s',
        fontFamily: fontFamily.bold,
        color: color.darkBlue
      },
      supportViewButton: {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        display: 'flex',
        padding: '0 10px',
        alignItems: 'center',
        border: `solid 1px ${color.darkBlue}`,
        height: 23,
        borderRadius: 20,
        color: color.darkBlue,
        width: 100
      },
      supportArrow: {
        color: color.darkBlue,
        marginLeft: 10,

        '& path': {
          fill: color.darkBlue
        }
      },
      supportTextMobile: {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
          display: 'block',
          textAlign: 'center',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between'
        }
      }
    };
  })();
};
