import { makeStyles } from '@material-ui/styles';
import { color } from '../constants';

export default makeStyles({
  reportType: {
    padding: '10px 0',
    color: color.red
  }
});

export const reportTypeStyles = makeStyles({
  reportTypeContainer: {
    display: 'flex',
    position: 'relative'
  },
  tooltipContainer: { marginTop: 15, position: 'absolute', right: 0, top: -50 }
});
