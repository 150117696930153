import {
  accList1,
  accList2,
  signageList1,
  signageList2
} from '../../helper/device/deploymentInformation';

export default function useDeviceInputData(user) {
  const { isRiker } = user?.getUserPermission();

  const inputProps = {
    serial: {
      label: 'pages.device.serialNumber',
      md: 4,
      xs: 12
    },
    location_name: {
      label: 'common.location',
      md: 4,
      xs: 12
    },
    csuv_asset_id: {
      label: 'pages.device.assetId',
      md: 4,
      xs: 12
    },
    customer_uid: {
      label: 'label.assignedCustomer',
      multiple: false,
      visible: isRiker
    },
    group_uid: {
      label: 'label.assignedGroups',
      multiple: false
    },
    deployed_image: {
      multiple: false
    },

    model_type: {
      label: 'label.modelType',
      multiple: false,
      list: accList1
    },

    sanitizer_mount_type: {
      label: 'label.sanitizerMountType',
      multiple: false,
      list: accList2
    },
    size_mount_type: {
      label: 'label.sizeMountType',
      multiple: false,
      list: signageList1
    },
    signage: {
      label: 'label.signage',
      multiple: false,
      list: signageList2
    }
  };

  return inputProps;
}
