import { useState, useEffect } from 'react';
import { BiLogIn } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// component
import { callAPI } from '../../helper/api';
import { getTierExpiryDate } from '../../helper/date';
import { MainHeading } from '../../components/common/Title';
import AdditionalLink from './AdditionalLink';
import browserHelper from '../../helper/browser';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/common/ErrorMessage';
import FloatLogo from '../../components/common/FloatLogo';
import IEErrorMessage from './IEErrorMessage';
import MobileDownloadButton from '../../components/Button/MobileDownloadButton';
import Modal from '../../components/common/Modal';
import PasswordField from '../../components/Input/PasswordField';
import SignInRight from './SignInRight';
import TextField from '../../components/Input/TextField';
import useBrowserSize from '../../hooks/useBrowserSize';
import useLanguage from '../../hooks/useLanguage';

// etc
import {
  buttonStyle,
  inputType,
  modalTime,
  apiActionTypes
} from '../../constants';
import { signInLeft } from '../../styles/SignIn';
import { track } from '../../helper/segment';
import { userState } from '../../recoil/state';
import * as authLib from '../../lib/auth';
import fullStory from '../../segment';
import loading from '../../assets/images/loading.gif';
import paths from '../../paths';
import useSegment from '../../hooks/useSegment';
import validation from '../../helper/validation';

const initUser = {
  email: '',
  password: '',
  valid: {
    email: false,
    password: false
  }
};

const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

export default function SignIn() {
  const [isIE, setIsIE] = useState(false);
  const [authedUser, setAuthedUser] = useRecoilState(userState);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rememberEmail, setRememberEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [user, setUser] = useState(initUser);
  const [width, height] = useBrowserSize();
  const classes = signInLeft();
  const history = useHistory();
  const { t } = useTranslation();

  useLanguage();

  async function getUserInfo() {
    const response = await callAPI('/user-info', {
      type: apiActionTypes.FETCH
    });

    return response?.data;
  }

  if ('isAuthed' in authedUser && authedUser.isAuthed) {
    history.push(paths.home);
  }

  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');

    if (storedEmail) {
      setUser({ ...user, email: storedEmail, valid: { email: true } });
      setRememberEmail(true);
    }
  }, []);

  useEffect(() => {
    if (width > 1280 || height < 500) {
      document.getElementsByTagName('html')[0].style.position = 'fixed';
    } else {
      document.getElementsByTagName('html')[0].style.position = 'relative';
    }
  }, [height, width]);

  const signIn = async (event) => {
    // fullStory track
    track(fullStory.signin.signInClicked);

    event.preventDefault();
    setIsLoading(true);

    if (!user.valid.email || !user.valid.password) {
      setShowError(true);
      setIsLoading(false);
      return;
    }

    try {
      const signedInUser = await authLib.signIn(user);

      if ('newUser' in signedInUser && signedInUser.newUser) {
        setIsLoading(false);
        setIsOpen(true);

        setTimeout(() => {
          history.push({
            pathname: paths.resetPassword,
            state: {
              email: user.email,
              newUser: true,
              sessionToken: signedInUser.session,
              password: user.password
            }
          });
        }, modalTime);

        return;
      }

      const { isAuthenticated, cognitoUser } = signedInUser;

      if (rememberEmail) {
        sessionStorage.setItem(inputType.email, user.email);
      } else {
        sessionStorage.removeItem(inputType.email);
      }

      if (isAuthenticated) {
        const cognitoUserGroups =
          cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];

        // throw error user does not have cognito user group array
        if (
          !cognitoUserGroups ||
          !Array.isArray(cognitoUserGroups) ||
          cognitoUserGroups.length === 0
        ) {
          throw new Error('errorMessage.cannotFindUserGroupFromCognito');
        }

        const userGroup = authLib.getUserGroup(cognitoUserGroups);
        const userTierGroup = authLib.getTierGroup(cognitoUserGroups);

        // throw error if user does not have tier
        if (!userTierGroup) {
          throw new Error('errorMessage.cannotFindUserTierFromCognito');
        }

        const userFromDatabase = await getUserInfo();

        if (!userFromDatabase) {
          throw new Error('errorMessage.cannotFindUserFromDatabase');
        }

        const { tierRemainDays, is30DaysLeft } = getTierExpiryDate(
          userFromDatabase,
          cognitoUserGroups
        );

        callAPI('/gwapi-to-sns', {
          type: 'LOGIN_HISTORY',
          data: cognitoUser.username
        });

        setAuthedUser((currentState) => {
          return {
            ...currentState,
            ...userFromDatabase,
            email: cognitoUser.username,
            isAuthed: isAuthenticated,
            tier: userTierGroup,
            timezone:
              user?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            userGroup,
            username: cognitoUser.username,
            getUserPermission: () => authLib.getUserPermission(userGroup),
            tierRemainDays,
            is30DaysLeft
          };
        });

        // notify segment of logged in user
        window.analytics.identify(cognitoUser.username, {
          name: `${userFromDatabase.first_name} ${userFromDatabase.last_name}`,
          firstName: userFromDatabase.first_name,
          lastName: userFromDatabase.last_name,
          email: cognitoUser.username,
          plan: userTierGroup
        });

        track(fullStory.signin.signInSuccessful);
        history.push({ pathname: paths.home, isTierModalOpen: is30DaysLeft });
        return;
      }

      return;
    } catch (error) {
      console.error('Sign in Failed: ', error);
      setIsLoading(false);
      track(fullStory.signin.signInFail);
      setErrorMessage(t(authLib.cognitoErrorHelper(error)));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    window.analytics.reset();
    localStorage.clear();

    if (browserHelper()) {
      setIsIE(true);
    }
  }, []);

  useSegment();

  const onChange = (type, value) => {
    setErrorMessage('');
    setUser({
      ...user,
      [type]: value.trim(),
      valid: { ...user.valid, [type]: validation(type, value.trim()) }
    });
    setShowError(false);
  };

  return (
    <Grid className={classes.document} container>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          title={t('modal.newUserResetPassword.title')}
          message={t('modal.newUserResetPassword.message')}
        />
      )}

      <FloatLogo />

      <Grid
        className={classes.formContainer}
        container
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
      >
        <div className={classes.leftContainer}>
          <div>
            <MainHeading title={t('menuName.signIn')} />
          </div>

          {isIE && <IEErrorMessage />}

          <form
            onSubmit={(event) => signIn(event)}
            data-cy="signInFormSubmit"
            aria-autocomplete={true}
          >
            <TextField
              {...columns}
              name="email"
              cy="email"
              isInvalid={showError ? !user.valid.email : false}
              label={t('label.email')}
              onChange={(event) =>
                onChange(inputType.email, event.target.value)
              }
              type={inputType.email}
              value={user.email}
              errorMessage={t('errorMessage.email')}
            />

            <PasswordField
              {...columns}
              name="password"
              cy="password"
              isInvalid={showError ? !user.valid.password : false}
              label={t('label.password')}
              onChange={(event) =>
                onChange(inputType.password, event.target.value)
              }
              value={user.password}
              errorMessage={t('errorMessage.password')}
            />

            <div className={classes.buttonContainer}>
              {isLoading ? (
                <img data-cy="loading" src={loading} style={{ width: 20 }} />
              ) : (
                <Button
                  description={t('menuName.signIn')}
                  style={buttonStyle.filledBlue}
                  type={inputType.submit}
                  fullWidth={true}
                  icon={<BiLogIn />}
                  data-testid="btn-submit"
                />
              )}
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
          </form>

          <AdditionalLink
            setRememberEmail={setRememberEmail}
            rememberEmail={rememberEmail}
          />

          <div className={classes.appLinkContainer}>
            <MobileDownloadButton />
          </div>
        </div>
      </Grid>
      <SignInRight />
    </Grid>
  );
}
