import AccordionDetails from '@material-ui/core/AccordionDetails';
import accordionStyles from '../../../styles/common/Accordion';

export default function Detail({ description }) {
  const classes = accordionStyles();

  return (
    <AccordionDetails>
      <div
        className={classes.detail}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </AccordionDetails>
  );
}
