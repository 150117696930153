import React from 'react';
import { MdCalculate } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// components
import { ContentHeading } from '../../components/common/Title';
import { getRoiFilterOptionByUser } from '../../recoil/selector/roiCalculator';
import { userState } from '../../recoil/state';
import Button from '../../components/Button/Button';
import CalculatedResult from './CalculatedResult';
import CalculatorForm from './CalculatorForm';
import Card from '../../components/common/Card';
import Filter from '../../components/Filter/Filter';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import Summary from './Summary';
import useCalculatedResult from '../../hooks/useCalculatedResult';
import useCalculator from '../../hooks/useCalculator';
import withContent from '../../components/hoc/withContent';
import { filterState } from '../../recoil/atom/filter';
import useSegment from '../../hooks/useSegment';
import { track } from '../../helper/segment';
import fullStory from '../../segment';

// etc
import { buttonStyle } from '../../constants';
import { expansion } from '../../paths';

function ROICalculator() {
  const { t } = useTranslation();
  const initFilterOption = useRecoilValue(getRoiFilterOptionByUser);
  const filterList = useRecoilValue(filterState);
  const history = useHistory();
  const { callSNSLog } = useSegment('roi');

  // user
  const user = useRecoilValue(userState);
  const { isRiker } = user.getUserPermission();

  // business

  const {
    // func
    handleSubmit: calculatorSubmit,

    // val
    roiCalculator,
    submitted,
    customerName
  } = useCalculator(initFilterOption, filterList);

  const roiCalculated = useCalculatedResult({
    roiCalculator,
    numOfDevice: roiCalculator.numOfDevice,
    costPerUnit: roiCalculator.costPerUnit
  });

  const filterCompOpt = {
    buttonDescription: 'label.applyFilter',
    column: { lg: 4, md: 6, sm: 6, xs: 6 },
    hasTitle: false,
    largeHorizontal: true
  };

  React.useEffect(() => {
    callSNSLog({
      type: 'WIPES_SAVINGS',
      data: user?.email
    });
  }, []);

  const handleSubmit = async (values) => {
    await calculatorSubmit(values);

    // track calculate
    track(fullStory.roi.calculated);
  };

  const headingOpt = {
    title: t('menuName.roiCalculator'),
    description:
      'This calculator helps estimate the return on your CleanSlate investment using your actual usage data and comparing with wipes.',
    leftButton: isRiker && (
      <IconButtonContainer>
        <Button
          description="pages.savings.btnExpansion"
          icon={<MdCalculate />}
          onClick={() => history.push(expansion)}
        />
      </IconButtonContainer>
    )
  };

  return (
    <>
      <ContentHeading {...headingOpt} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isRiker && (
            <Card style={buttonStyle.filledBlue} cardStyle={{ height: '100%' }}>
              <Filter {...filterCompOpt} listOptions={{ customer: true }} />
            </Card>
          )}
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CalculatorForm
              onSubmit={handleSubmit}
              roiCalculator={roiCalculator}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <CalculatedResult
            calculated={roiCalculated}
            submitted={submitted}
            roiCalculator={{ ...roiCalculator, customerName }}
          />
        </Grid>
      </Grid>

      <Summary costLabor={roiCalculator.costLabor} />

      {process.env?.REACT_APP_STAGE === 'dev' ? (
        <div>
          ***Test purpose <br />
          Production does not show this JSON text
          <br />
          {JSON.stringify(roiCalculator)}
        </div>
      ) : null}
    </>
  );
}

export default withContent(ROICalculator);
