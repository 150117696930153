import FormHelperText from '@material-ui/core/FormHelperText';
import { FiAlertCircle } from 'react-icons/fi';

import errorMessageStyles, {
  formErrorMessageStyles
} from '../../styles/ErrorMessage';

export default function ErrorMessage({ message }) {
  const classes = errorMessageStyles();

  if (!message) return null;

  return (
    <div className={classes.message} data-cy="errorMessage">
      {message}
    </div>
  );
}

export function FormErrorMessage({ message }) {
  const classes = formErrorMessageStyles();

  if (!message) return null;
  return (
    <FormHelperText className={classes.container}>
      <FiAlertCircle />
      {message}
    </FormHelperText>
  );
}
