import { format, sub, differenceInDays } from 'date-fns';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import i18next from 'i18next';

import * as authLib from '../lib/auth';
import {
  dataOptions,
  tierGroup,
  cognitoGroup,
  emailAddress,
  tierStatus
} from '../constants';

/**
 * @description Returning string format Date
 * @param {Date} date - new Date
 * @return {string} string formatted Date
 */
export function formatDate(date, condition) {
  let formattedDate = new Date();

  let formatText = '';
  if (condition === 'noTime') {
    formatText = 'yyyy-MM-dd';
  } else if (condition) {
    formatText = condition;
  } else {
    formatText = 'yyyy-MM-dd H:mm';
  }

  try {
    formattedDate = format(date, formatText);
  } catch (error) {
    console.error('formatDate error:', error);
  }

  return formattedDate;
}

/**
 * @description Returning timezone Date
 * @param {Object} object
 * @param {Date} object.date - new Date form
 * @param {string} object.timezone - user's timezone
 * @return {Date} timezone Date
 */
export function getUtcDate({ date, timezone }) {
  try {
    return utcToZonedTime(toDate(date), timezone);
  } catch (error) {
    console.error('getUtcDate error:', date, timezone);
    return null;
  } //
}

/**
 * @description Returning start and end date depends on static date option
 * @param {string} value - date option
 * @return {Object} transformed start and end date value object
 */
export function setDate(value) {
  let startDate;
  const endDate = new Date();

  switch (value) {
    case dataOptions.day:
      startDate = new Date();
      break;

    case dataOptions.week:
      startDate = sub(endDate, { weeks: 1 });
      break;

    // Custom date range | month select
    default:
      startDate = sub(endDate, { months: 1 });
      break;
  }

  return { startDate, endDate };
}

/**
 * @description Returning formatted date by bulb hours
 * @param {number} bulbHours bulb hours
 * @return {string} transformed date by translation
 */
export function getTimeToDays(bulbHours) {
  const days = Math.floor(bulbHours / 24);

  let convertedTime = bulbHours;
  let translationKey = 'pages.device.deviceHours';

  if (bulbHours > 24 && days < 30) {
    convertedTime = days;
    translationKey = 'pages.device.deviceDays';
  } else if (days > 30) {
    convertedTime = Math.floor(days / 30);
    translationKey = 'pages.device.deviceMonths';
  }

  return `${i18next.t(translationKey, { convertedTime })}`;
}

/**
 * @description if the date is not valid, return N/A value
 * @param {string} value date value
 * @param {string} timezone timezone value from user setting
 * @return {string} formatted date
 */
export function getNAValue(value, timezone, type) {
  if (!value || value === 'NULL') {
    return 'N/A';
  }

  return formatDate(utcToZonedTime(toDate(value), timezone), type);
}

/**
 * @description set integer number different between start and end date
 * @param {Date} object date object
 * @param {Date} object.start start date
 * @param {Date} object.end start date
 * @return {Number} returning number of days between two date.
 */
export function diffDays({ start, end }) {
  return differenceInDays(new Date(start), new Date(end));
}

/**
 * @description set default analytics date range
 * @return {Object} returning start and end date.
 */
export function getAnalyticsDefaultDateRange(dateUnit = 'weeks') {
  const endDate = new Date();
  const startDate = sub(endDate, { [dateUnit]: 1 });

  return { endDate, startDate };
}

function getTierRemainDaysByOption(trialDays, tierRemainDays) {
  if (tierRemainDays > trialDays) return null;
  if (tierRemainDays < 1) return 0;

  return tierRemainDays;
}

/**
 * @description calculate user's tier date
 * @param {Object} userFromDatabase user's data from user-info api
 * @return {Object} returning calculated tier date
 */
export function getTierExpiryDate(userFromDatabase, cognitoUserGroups) {
  let tierRemainDays = 0;

  const userGroup = authLib.getUserGroup(cognitoUserGroups);
  const userTierGroup = authLib.getTierGroup(cognitoUserGroups);
  const tierStatusFromDB = userFromDatabase?.tier_status;
  const isPicardMember = [
    cognitoGroup.picardUser,
    cognitoGroup.picardAdmin
  ].includes(userGroup);

  if (
    !isPicardMember ||
    (isPicardMember && tierStatusFromDB === tierStatus.permanent)
  ) {
    return { tierRemainDays: null, is30DaysLeft: null };
  }

  // Make trial day dynamic - days from database
  // eslint-disable-next-line radix
  const trialDays = tierStatusFromDB
    ? parseInt(tierStatusFromDB.replace('trial', ''))
    : 0;

  if (userTierGroup !== tierGroup.standard) {
    tierRemainDays = differenceInDays(
      new Date(userFromDatabase?.created_at),
      new Date()
    ); // diff days returns negative value
    tierRemainDays = trialDays + tierRemainDays;
  }

  return {
    tierRemainDays: getTierRemainDaysByOption(trialDays, tierRemainDays),
    is30DaysLeft: tierRemainDays <= 30
  };
}

export function getTierMessage(user) {
  const isTierValid =
    Number.isInteger(user?.tierRemainDays) && user?.tierRemainDays > 0
      ? user?.tierRemainDays
      : 0;
  const tierDays = user?.tier_status
    ? user?.tier_status.replace('trial', '')
    : 0; // by default it is set 90
  const tierMessage = i18next.t(
    isTierValid ? 'common.tierEmail.willExpire' : 'common.tierEmail.expired',
    { day: tierDays }
  );

  return `mailto:${emailAddress.trialEmail}?subject=${i18next.t(
    'common.tierEmail.title'
  )}&body=${tierMessage}`;
}
