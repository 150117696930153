import FilePicture from '../../components/Input/FilePicture';

import { manageFileStyles } from '../../styles/Device';

export default function UploadDeployedImage(props) {
  const classes = manageFileStyles();

  return (
    <div className={classes.fileImageContainer}>
      <FilePicture {...props} />
    </div>
  );
}
