const config = {
  // dev: {
  //   // region: 'us-east-2',
  //   // cognito: {
  //   //   userPoolId: 'us-east-2_2FlojZpfQ',
  //   //   userPoolWebClientId: '6ibs7ap5ejrm9dj815ikevdbk6'
  //   // },
  //   // api: 'https://8zngu2ulwg.execute-api.us-east-2.amazonaws.com/prod'
  //   region: 'us-east-2',
  //   cognito: {
  //     userPoolId: 'us-east-2_2FlojZpfQ',
  //     userPoolWebClientId: '6ibs7ap5ejrm9dj815ikevdbk6'
  //   },
  //   api: 'https://8zngu2ulwg.execute-api.us-east-2.amazonaws.com/prod',
  //   intercom: 'kvoclnxg'
  // },
  dev: {
    // region: 'us-east-2',
    // cognito: {
    //   userPoolId: 'us-east-2_2FlojZpfQ',
    //   userPoolWebClientId: '6ibs7ap5ejrm9dj815ikevdbk6'
    // },
    // api: 'https://8zngu2ulwg.execute-api.us-east-2.amazonaws.com/prod'
    region: 'us-east-2',
    cognito: {
      userPoolId: 'us-east-2_0uArxVNmA',
      userPoolWebClientId: '13cc4j2m61m50bjb5uqmr8segj'
    },
    api: 'https://cc0c6875zi.execute-api.us-east-2.amazonaws.com/dev',
    intercom: 'b1bows3x'
  },
  // ** previous dev env
  // dev: {
  //   region: 'us-east-1',
  //   cognito: {
  //     userPoolId: 'us-east-2_2L13jWI1v',
  //     userPoolWebClientId: '6iqd1h8vp36o2odelroqt7la19'
  //   },
  //   api: 'https://8l9vismahk.execute-api.us-east-1.amazonaws.com/dev',
  //   intercom: 'b1bows3x'
  // },
  staging: {
    region: 'us-east-2',
    cognito: {
      userPoolId: 'us-east-2_2FlojZpfQ',
      userPoolWebClientId: '6ibs7ap5ejrm9dj815ikevdbk6'
    },
    api: 'https://8zngu2ulwg.execute-api.us-east-2.amazonaws.com/prod',
    intercom: 'kvoclnxg'
    // region: 'us-east-1',
    // cognito: {
    //   userPoolId: 'us-east-1_aPEhnKxe8',
    //   userPoolWebClientId: '6gvpliga5cifaislh55016h04l'
    // },
    // api: 'https://634kxlcpvg.execute-api.us-east-1.amazonaws.com/stage',
    // intercom: 'kvoclnxg'
  },
  prod: {
    region: 'us-east-2',
    cognito: {
      userPoolId: 'us-east-2_2FlojZpfQ',
      userPoolWebClientId: '6ibs7ap5ejrm9dj815ikevdbk6'
    },
    api: 'https://8zngu2ulwg.execute-api.us-east-2.amazonaws.com/prod',
    intercom: 'kvoclnxg'
  }
};

module.exports = config[process.env?.REACT_APP_STAGE];
