import { callAPI } from '../../helper/api';
import { filterType } from '../../constants';

export const renderFilterType = ({ type }) => {
  const { devices, groups, device, group, customer } = filterType;
  switch (type) {
    case devices:
      return { name: device };

    case groups:
      return { name: group };

    default:
      return { name: customer };
  }
};

// group device multiple value control
export const setMultipleValue = ({ selectedValue, eventValue, data }) => {
  if (!eventValue?.length) {
    return [];
  }

  let value;
  const allOptions = data.map((dt) => dt.id);
  const hasAll = allOptions.every((opt) => {
    if (opt === 'all' || opt === 'PARTNER') return true;
    return eventValue.includes(opt);
  });

  // case 1. if selected all
  if (selectedValue === 'all' || selectedValue === 'PARTNER') {
    if (eventValue.includes('all') || eventValue.includes('PARTNER')) {
      value = allOptions;
    }
    // de select all
    else {
      value = [];
    }
  }
  // case 2. select one other item
  else if (selectedValue !== 'all' && selectedValue !== 'PARTNER') {
    if (hasAll) {
      value = allOptions;
    } else {
      value = eventValue.filter((val) => val !== 'all' && val !== 'PARTNER');
    }
  }

  return value;
};

// when search select called
export const updateFilter = async ({ name, value }) => {
  const response = await callAPI(`/filters`, {
    [name]: value,
    [`${name}Devices`]: value
  })
    .then((res) => res)
    .catch((error) => console.log(error));
  return response.data;
};

export const initEntityValues = ({ id, parent }) => {
  if (id === 'PARTNER') {
    return 'all';
  }

  if (Array.isArray(id)) {
    return parent;
  }

  if (id && !Array.isArray(id)) {
    return id;
  }

  return 'all';
};

export const setGroupLabelValue = ({ data, selected, type }) => {
  const labelList = [];

  const originalIds = data?.map((val) => val.id);

  if (!data.length) {
    return 'label.pleaseSelectCustomer';
  }

  if (originalIds?.length === selected?.length) {
    return 'All';
  }

  if (!selected.length) {
    return 'errorMessage.pleaseSelectOptions';
  }

  if (!selected.includes('all') && !selected.includes('PARTNER')) {
    data.forEach((opt) => {
      if (selected.includes(opt.id) && opt.id !== 'all') {
        let label = opt.name;

        if (type === filterType.devices) {
          label = `${opt.name} - ${opt.location}`;
        }

        labelList.push(label);
      }
    });

    return labelList.join(', ');
  }

  return '';
};

export const findParentName = ({ ids, data }) => {
  return data.find((dt) => ids.includes(dt.id)).name || '';
};

// should change it to selector
export const setEntityLabelValue = ({ data, id, name, parent }) => {
  let label = 'errorMessage.pleaseSelectOptions';

  try {
    if (!data) {
      throw new Error('data is mandatory');
    }

    if (!id && !parent && !name) {
      return 'label.pleaseSelectCustomer';
    }

    if (/^c/g.test(id) || parent) {
      label = findParentName({ ids: [id, parent], data });
    } else if (
      id === 'PARTNER' ||
      /^p-/g.test(id) ||
      (!id && name === 'partner')
    ) {
      // label = 'All';
      label = 'label.pleaseSelectCustomer';
    }
  } catch (error) {
    console.log(error.message);
    return '';
  }

  return label;
};
