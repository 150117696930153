import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '../common/Tooltip';

import outlineSelectStyles from '../../styles/common/OutlineSelect';
import textFieldStyles from '../../styles/Input/TextField';
import tooltipStyles from '../../styles/common/Tooltip';

export default function OutlineSelect({
  errorMessage,
  helperText,
  disabled,
  isInvalid,
  readOnly,
  isRequired,
  label,
  lg,
  margin,
  md,
  menuItemArray,
  name,
  onChange,
  placeholder,
  sm,
  tooltip,
  value,
  xs,
  cy,
  visible = true
}) {
  const { t } = useTranslation();
  const classes = outlineSelectStyles();
  const textFieldClasses = textFieldStyles();
  const tooltipClasses = tooltipStyles();
  const muiHelperText = isInvalid ? errorMessage : helperText;

  if (!visible) return null;

  return (
    <Grid item lg={lg} md={md} sm={sm} xs={xs} className={classes.container}>
      {tooltip && (
        <div className={tooltipClasses.tooltipContainer}>
          <Tooltip message={t(tooltip)} />
        </div>
      )}
      <FormControl
        classes={{ root: classes.selectContainer }}
        variant="outlined"
        margin="normal"
        disabled={disabled}
      >
        <InputLabel
          classes={{ root: classes.label, focused: classes.focused }}
          className={`${isInvalid ? classes.errorMessage : ''}`}
          margin={margin}
          data-testid={`outline-select-input-label`}
        >
          {`${t(label)}${isRequired ? ' *' : ''}`}
        </InputLabel>
        <Select
          classes={{ select: classes.selectRoot }}
          className="fs-mask"
          margin={margin}
          name={name}
          error={isInvalid}
          onChange={onChange}
          placeholder={t(placeholder)}
          helpertext={muiHelperText}
          value={value}
          input={
            <OutlinedInput
              classes={{
                root: textFieldClasses.outlineRoot,
                focused: textFieldClasses.focused,
                notchedOutline: textFieldClasses.notchedOutline
              }}
            />
          }
          inputProps={{ readOnly, 'data-testid': `outline-select-${name}` }}
          data-cy={cy}
        >
          {menuItemArray.map((item) => {
            return (
              <MenuItem
                key={item.text}
                selected={item.value}
                value={item.value}
                parent={item?.parent}
                data-testid={`outline-select-menu-${item.value}`}
              >
                {t(item.text)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText className={isInvalid ? classes.errorMessage : ''}>
          {t(muiHelperText)}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}

OutlineSelect.propTypes = {
  label: PropTypes.string.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  menuItemArray: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sm: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
