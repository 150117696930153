import React from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../../components/common/Title';
import { managePartnerSchema } from '../../helper/validation';
import AdditionalSection from './AdditionalSection';
import Form from './Form';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import withAdminManage from '../../components/hoc/withAdminManage';

// for partner
import usePartnerData from './usePartnerData';
import usePartnerInputData from './usePartnerInputData';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../helper/admin/page';

function ManagePartners({
  t,
  location,
  type,
  filterList,

  // func
  onDelete,
  onEdit,
  onSave,
  onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit
}) {
  const { inheritedData, getDeleteReq, getEditReq, getCreateReq } =
    usePartnerData({
      data: location?.state?.data
    });

  const inputProps = usePartnerInputData();

  const formik = useFormik({
    initialValues: { ...inheritedData },
    validationSchema: managePartnerSchema,

    onSubmit: (values) => {
      return isEdit ? onEdit(getEditReq(values)) : onSave(getCreateReq(values));
    }
  });

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      onEdit: formik.handleSubmit,
      onSave: formik.handleSubmit,
      onDelete: () => onDelete(getDeleteReq(location?.state?.data.partner_uid)),
      onClose
    }
  });

  return (
    <>
      <ContentHeading
        title={t('menuName.partners')}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form {...formik} inputPropData={inputProps} />
        </Grid>
        <Grid item xs={12}>
          <AdditionalSection
            {...formik}
            isEdit={isEdit}
            filterList={filterList}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withAdminManage('partner')(ManagePartners);
