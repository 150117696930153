import React from 'react';
import MUICard from '@material-ui/core/Card';
import { useTranslation, Trans } from 'react-i18next';
import { ImClock } from 'react-icons/im';

import { SubHeading } from '../Title';
import Tooltip from '../Tooltip';
import Button from '../../Button/Button';
import cardStyles, { latestDateStyles } from '../../../styles/common/Card';

import { color } from '../../../constants';

function CardContent({ children, className, cardStyle }) {
  return (
    <MUICard className={className} style={cardStyle}>
      {children}
    </MUICard>
  );
}

export default function Card({
  children,
  header,
  style = 'white',
  button,
  tooltip,
  cardStyle,
  latestDate,
  headerComponent,
  containerComponent
}) {
  const Container = containerComponent || CardContent;
  const { t } = useTranslation();
  const cardColor = !style ? 'white' : style;
  const cardClasses = cardStyles(cardColor);
  const latestClasses = latestDateStyles();

  const subHeadingElement = () => {
    if (button) return <Button {...button} />;
    if (tooltip)
      return (
        <span style={{ top: -20, right: -15, position: 'absolute', zIndex: 3 }}>
          <Tooltip message={t(tooltip)} />
        </span>
      );

    return null;
  };

  return (
    <Container
      className={cardClasses.cardContainer}
      elevation={1}
      cardStyle={cardStyle}
    >
      {headerComponent || (
        <div>
          {header ? (
            <SubHeading
              title={typeof header === 'string' ? t(header) : header}
              optionElement={subHeadingElement()}
            />
          ) : null}
        </div>
      )}

      {latestDate && (
        <div
          className={`${latestClasses.alignItemContainer} ${latestClasses.latestDateContainer}`}
        >
          <div>
            <span className={`${latestClasses.alignItemContainer}`}>
              <ImClock className={latestClasses.clock} fill={color.dimGrey} />
            </span>
          </div>
          <div>
            <Trans
              i18nKey="pages.dashboard.updatedDate"
              values={{ date: latestDate }}
              components={{
                strong: <strong className={latestClasses.latestTitle} />,
                span: <span className={latestClasses.latestDate} />
              }}
            />
          </div>
        </div>
      )}

      {children}
    </Container>
  );
}
