import { FiMoreHorizontal } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import MUIIconButton from '@material-ui/core/IconButton';
import MUITable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import { userState } from '../../recoil/state';
import { getUtcDate, formatDate } from '../../helper/date';
import { alertDetailStyle } from '../../styles/Dashboard';
import { track } from '../../helper/segment';
import segment from '../../segment';

import { alertTypes, responseKey } from '../../constants';
import paths from '../../paths';

export default function AlertDetails({ messages, type }) {
  const { t } = useTranslation();
  const {
    groupName,
    locationName,
    customerName,
    serial,
    alertType,
    errorName,
    warningName
  } = responseKey;

  const user = useRecoilValue(userState);
  const history = useHistory();
  const alertClasses = alertDetailStyle();
  const { isRiker } = user.getUserPermission();
  const alertName = type === alertTypes.error ? errorName : warningName;

  const renderAlertMessage = (msg) => {
    if (msg === `errorMessage.dimmerVoltageWarning`) {
      return `${t(`errorMessage.dimmerVoltageWarning`)}. ${t(
        'errorMessage.pleaseContact'
      )}`;
    }

    return t(msg);
  };

  const headerList = [
    'pages.device.serialNumber',
    ...(isRiker ? ['common.customer'] : []),
    'common.group',
    'common.location',
    'common.dateTime',
    'label.alertDescription'
  ];

  const renderStyle = () => {
    if (alertTypes.warning === type) {
      return {
        headerClass: alertClasses.warningHeader,
        hover: alertClasses.warningHover
      };
    }

    return {
      headerClass: alertClasses.errorHeader,
      hover: alertClasses.errorHover
    };
  };

  const { headerClass } = renderStyle();

  return (
    <div data-cy="alertTable">
      <TableContainer component={Paper}>
        <MUITable size="small" aria-label="a dense table">
          <TableHead className={headerClass}>
            <TableRow>
              {headerList.map((title, index) => (
                <TableCell key={index}>{t(title)}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {messages?.length &&
              messages
                .filter((message) => message[alertType] === type)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    hover={true}
                    onClick={() => {
                      history.push({
                        pathname: `${paths.viewDevice}/${row.serial}`,
                        state: {
                          serial: row?.serial,
                          data: row,
                          deviceList: []
                        }
                      });
                    }}
                    data-cy="alertRow"
                    className={`${alertClasses.row} ${type}`}
                  >
                    <TableCell className="fs-mask">{row[serial]}</TableCell>
                    {isRiker && (
                      <TableCell className="fs-mask">
                        {row[customerName]}
                      </TableCell>
                    )}
                    <TableCell className="fs-mask">{row[groupName]}</TableCell>
                    <TableCell className="fs-mask">
                      {row[locationName]}
                    </TableCell>
                    <TableCell>
                      {formatDate(
                        getUtcDate({
                          date: new Date(row.timestamp),
                          timezone: user.timezone
                        })
                      )}
                    </TableCell>
                    <TableCell>
                      {renderAlertMessage(`errorMessage.${row[alertName]}`)}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={t('common.moreDetails')}>
                        <MUIIconButton
                          onClick={() =>
                            track(segment.dashboard.alertDetailItemClicked)
                          }
                        >
                          <FiMoreHorizontal />
                        </MUIIconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </MUITable>
      </TableContainer>
    </div>
  );
}
