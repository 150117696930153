import { averageState } from '../recoil/atom/average';
import { deviceState, viewDeviceState } from '../recoil/atom/device';
import {
  reportState,
  scanRecordState,
  scheduledState
} from '../recoil/atom/report';

import { useStatisticState } from '../recoil/atom/useStatistic';
import { comparisonState } from '../recoil/atom/comparison';
import { trendState } from '../recoil/atom/trend';
import { dashboardState } from '../recoil/atom/dashboard';
import {
  groupState,
  customerState,
  partnerState,
  userAdminState
} from '../recoil/atom/admin';
import { roiCalculatorState } from '../recoil/atom/roiCalculator';
import { notificationState } from '../recoil/atom/notification';
import paths from '../paths';

/**
 * @description This function is returning recoil state
 * @param {string} url - current url pathname (window.location.pathname)
 * @return {Object} recoil state and pathname
 */
export function getPageData(url) {
  const {
    adminManageCustomer,
    adminManageGroup,
    adminManagePartners,
    adminManageUser,
    averages,
    comparisons,
    customers,
    dashboard,
    deviceManage,
    devices,
    expansion,
    groups,
    home,
    multipleDeviceManage,
    notifications,
    partners,
    reports,
    roiCalculator,
    scanRecords,
    scheduled,
    trends,
    users,
    useStatistics,
    viewDevice
  } = paths;

  if (url.includes(viewDevice)) {
    return { state: viewDeviceState };
  }

  switch (url) {
    case dashboard:
      return { state: dashboardState };

    case home:
      return { state: dashboardState };

    case averages:
      return {
        state: averageState
      };

    case trends:
      return {
        state: trendState
      };

    case comparisons:
      return {
        state: comparisonState
      };

    case useStatistics:
      return {
        state: useStatisticState,
        page: useStatistics
      };

    case multipleDeviceManage:
    case deviceManage:
    case viewDevice:
    case devices:
      return { state: deviceState };

    case reports:
      return { state: reportState };

    case scanRecords:
      return { state: scanRecordState };

    case scheduled:
      return { state: scheduledState };

    case adminManageGroup:
    case groups:
      return { state: groupState };

    case adminManageCustomer:
    case customers:
      return { state: customerState };

    case notifications:
      return { state: notificationState };

    case adminManagePartners:
    case partners:
      return { state: partnerState };

    case roiCalculator:
      return { state: roiCalculatorState };

    case expansion:
      return { state: roiCalculatorState };

    case adminManageUser:
    case users:
      return { state: userAdminState };

    default:
      return { state: null };
  }
}

export function getTemporaryVisible() {
  // If true -> visible , false -> invisible
  switch (process.env.REACT_APP_STAGE) {
    case 'prod':
      return false;

    case 'staging':
      return false;

    default:
      return true;
  }
}
