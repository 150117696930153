// import { useState } from 'react';
import React from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import Tooltip from '../../components/common/Tooltip';
import Title from '../../components/pages/Device/DeviceInfo/Title';
import deviceDetailStyles, {
  deviceTooltipStyles
} from '../../styles/DeviceDetail';
import { icons, emailAddress } from '../../constants';

/**
 *
 * @param {{ title: string, hasTooltip: boolean, children: React.ReactNode}} object
 * @returns
 */
export default function DeviceInformationDetail({
  title,
  hasToolTip,
  onTooltipClick,
  children,
  tooltipMessage,
  columns = { title: { xs: 5 }, contents: { xs: 7 } }
}) {
  const deviceDetailClasses = deviceDetailStyles();

  return (
    <Grid
      container
      spacing={1}
      className={deviceDetailClasses.deviceInformationList}
    >
      <Grid item {...columns?.title}>
        <Title
          title={title}
          hasToolTip={hasToolTip}
          onTooltipClick={onTooltipClick}
          tooltipMessage={tooltipMessage}
        />
      </Grid>

      <Grid item {...columns?.contents}>
        {children}
      </Grid>
    </Grid>
  );
}

function Description({ description, isMasked }) {
  const deviceDetailClasses = deviceDetailStyles();

  return (
    <span
      className={`${deviceDetailClasses.deviceInformationDescription} ${
        isMasked && 'fs-mask'
      }`}
    >
      {description}
    </span>
  );
}

function OrderBulb({ hasOrder, customerName, htmlAlertString }) {
  const deviceDetailClasses = deviceDetailStyles();
  const { t } = useTranslation();

  if (!hasOrder || !customerName || !htmlAlertString) return null;

  return (
    <div>
      <a
        href="#"
        target="_blank"
        onClick={(event) => {
          event.preventDefault();
          window.location = `mailto:${
            emailAddress.support
          }?subject=${customerName}: ${t('pages.device.email.title')}
                  &body=${t('pages.device.email.hi')}, %0D%0A${t(
            'pages.device.email.body'
          )}%0D%0A ${htmlAlertString?.errorSerialString} ${
            htmlAlertString?.warningSerialString
          }`;
        }}
        className={deviceDetailClasses.deviceDetailLink}
      >
        {t('pages.device.orderBulb')}{' '}
        <HiOutlineArrowNarrowRight
          fill="#fff"
          stroke="#fff"
          className={deviceDetailClasses.deviceDetailIcon}
        />
      </a>
    </div>
  );
}

/**
 * @description render text value and input
 * @param {string} description value of the information
 * @param {JSX.Element} input Input element
 * @param {object} inputProps props for the Input from parent
 *
 */
function DetailWithInput({
  isEdit = false,
  description,
  input: Input,
  inputProps,
  isMasked,
  element
}) {
  const { t } = useTranslation();

  const tooltipClasses = deviceTooltipStyles();
  const [showEditInput, setShowEditInput] = React.useState(isEdit);

  const handleBlur = () => setShowEditInput(false);

  /**
   *
   * one of description or element must be existed
   */
  const renderElement = () => {
    if (description) {
      return <Description description={t(description)} isMasked={isMasked} />;
    }

    if (element) {
      return element;
    }

    return null;
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        {showEditInput ? (
          <Input {...inputProps} onBlur={handleBlur} />
        ) : (
          renderElement()
        )}
      </Grid>
      <Grid item xs={2}>
        <span className={tooltipClasses.tooltipContainer}>
          <span className={tooltipClasses.tooltipWrapper}>
            <Tooltip
              type={icons.edit}
              placement="right-start"
              onClick={() => setShowEditInput(!showEditInput)}
            />
          </span>
        </span>
      </Grid>
    </Grid>
  );
}

/**
 * @description for flexibility, created only edit wrapper component
 * @param {function} onEditClick edit button event
 * @param {JSX.Element} element
 * @param {object} inputProps props for the Input from parent
 *
 */
function EditWrapper({ onEditClick, element }) {
  const tooltipClasses = deviceTooltipStyles();

  return (
    <Grid container>
      <Grid item xs={10}>
        {element}
      </Grid>
      <Grid item xs={2}>
        {onEditClick ? (
          <span className={tooltipClasses.tooltipContainer}>
            <span className={tooltipClasses.tooltipWrapper}>
              <Tooltip
                type={icons.edit}
                placement="right-start"
                onClick={onEditClick}
              />
            </span>
          </span>
        ) : null}
      </Grid>
    </Grid>
  );
}
DeviceInformationDetail.Description = Description;
DeviceInformationDetail.OrderBulb = OrderBulb;
DeviceInformationDetail.DetailWithInput = DetailWithInput;
DeviceInformationDetail.EditWrapper = EditWrapper;
