import { makeStyles } from '@material-ui/styles';
// import { color } from '../constants';

export const manageMultipleDeviceStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export const manageFileStyles = makeStyles({
  fileImageContainer: {
    '& img': {
      width: 'auto',
      height: '100%'
    }
  }
});
