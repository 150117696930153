import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { List } from 'react-content-loader';
import { useStatSelector } from '../../recoil/selector/dashboard';
import Button from '../../components/Button/Button';
import Card from '../../components/common/Card';
import StatusCard from '../../components/common/StatusCard';

import { buttonStyle } from '../../constants';
import { dashboardStyles } from '../../styles/Layout/Dashboard';
import { useStatistics } from '../../paths';
import { track, getKey } from '../../helper/segment';
import segmentMessage from '../../segment';

export default function UseStat() {
  const { t } = useTranslation();
  const useStat = useRecoilValue(useStatSelector);
  const history = useHistory();
  const dashboardClasses = dashboardStyles();

  const onUserStatClick = () => {
    track(`${getKey()?.page} - ${segmentMessage.dashboard.useStatClicked}`);
    history.push(useStatistics);
  };

  return (
    <div className={dashboardClasses.statContainer}>
      <Card header={t('common.useStats')}>
        {useStat?.length ? (
          <Grid container justifyContent="center" alignItems="baseline">
            {useStat.map((item) => {
              return <StatusCard key={item.date} {...item} />;
            })}
          </Grid>
        ) : (
          <List />
        )}
        <Button
          description={t('label.viewUseStatistics')}
          style={buttonStyle.filledBlue}
          fullWidth={true}
          onClick={onUserStatClick}
        />
      </Card>
    </div>
  );
}
