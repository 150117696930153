import i18n from 'i18next';

import { alertTypes } from '../constants';
import ballastStatus from '../ballastStatus';

/**
 * @enum {string} invalid type
 */
const invalidType = {
  serial: 'serial',
  location: 'location',
  duplicated: 'duplicated'
};

const errorBallastList = [
  1111110101, 1111110110, 1111110111, 1111111000, 1100101000
];
const warningBallastList = [
  1100011111, 1100101111, 1100111111, 1101001111, 1101101111, 1101111111
];

export function getBulbChangeMessage({ messageList, alertType }) {
  const alertCodeList =
    alertType === alertTypes.warning ? warningBallastList : errorBallastList;
  let alertList = [];

  if (Array.isArray(messageList) && messageList.length > 0) {
    alertList = messageList?.filter((item) => {
      return (
        item?.alert_type === alertType && alertCodeList.includes(item.ballast)
      );
    });
  }

  return alertList;
}

export function getWarningMessage({ serial, messageList }) {
  const warningDevice = messageList?.filter(
    (item) =>
      item?.serial === serial && item?.alert_type === alertTypes?.warning
  )?.[0];
  const message = i18n.t(
    `errorMessage.${ballastStatus[warningDevice?.ballast]}`
  );

  return message.toLowerCase();
}

export function getListAsString(list) {
  let string = '';

  for (let index = 0; index < list.length; index += 1) {
    string += `${list[index]?.serial}%0D%0A`;
  }

  return string;
}

export function getResponseKey(title) {
  switch (title) {
    case 'common.location':
      return 'locationName';

    case 'pages.device.address':
      return 'address';

    default:
      return '';
  }
}

/**
 *
 * @param {*} list
 * @param {*} errorType success - undefined and input types
 * @returns Array
 */
export const getSerialList = (list, errorType) => {
  if (!Array.isArray(list)) {
    return [];
  }

  // success return device serial only
  if (!errorType) return list.map((device) => device.serial);

  // error message return type
  return list
    .filter((device) => device.type === errorType)
    .map((device) => device.serial);
};

export const createMessageByArray = (list, invalidTypeValue) => {
  const getMessagePrefix = (serialList) => {
    if (!serialList.length) return '';

    switch (invalidTypeValue) {
      case invalidType.duplicated:
        return `Duplicated: `;

      case invalidType.serial:
        return `Invalid Serial: `;

      case invalidType.location:
        return `Invalid Location: `;

      default:
        return 'Created: ';
    }
  };

  if (!list || !list.length) return '';
  return getMessagePrefix(list) + list.join(', ');
};

export const mergeAllMessage = (messageList) => {
  if (!Array.isArray(messageList)) return '';
  if (!messageList.length) return '';

  return messageList.filter((str) => str).join('\n');
};

export const getAllMessage = ({ created, duplicated, serial, location }) => {
  try {
    const createdList = getSerialList(created);
    const duplicatedList = getSerialList(duplicated, invalidType.duplicated);
    const serialList = getSerialList(serial, invalidType.serial);
    const locationList = getSerialList(location, invalidType.location);

    const createdMsg = createMessageByArray(createdList);
    const duplicatedMsg = createMessageByArray(
      duplicatedList,
      invalidType.duplicated
    );
    const serialMsg = createMessageByArray(serialList, invalidType.serial);
    const locationMsg = createMessageByArray(
      locationList,
      invalidType.location
    );

    return mergeAllMessage([createdMsg, duplicatedMsg, serialMsg, locationMsg]);
  } catch (error) {
    console.log(error.message);

    return '';
  }
};
