import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles((theme) => ({
  chartViewChartContainer: {
    [theme.breakpoints.down('md')]: {
      // flexDirection: 'column-reverse'
    },
    marginBottom: 20
  }
}));

export const chartPlaceholderStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: color.white,
    padding: '2rem'
  }
});

export const chartDateMessageStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12
  }
});
