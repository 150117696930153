import { useTranslation } from 'react-i18next';
import { HiOutlineTrash, HiOutlineMail, HiOutlineBell } from 'react-icons/hi';
import { RiMessage2Line } from 'react-icons/ri';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MUIIconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { notificationManageStyles } from '../../styles/Notification';
import { actionTypes } from '../../constants';

const { email, sms } = actionTypes;
const columns = { lg: 12, md: 12, sm: 12, xs: 12 };

export default function RecipientList({ recipientHandler, type }) {
  const [recipients, setRecipients] = recipientHandler;
  const { t } = useTranslation();
  const notificationClasses = notificationManageStyles();

  const renderIcon = () => {
    switch (type) {
      case email:
        return <HiOutlineMail />;

      case sms:
        return <RiMessage2Line />;

      default:
        return <HiOutlineBell />;
    }
  };

  const onRecipientDelete = (item) => {
    const initList = [...recipients[type]];
    const filteredList = initList.filter((recipient) => recipient !== item);
    setRecipients({ ...recipients, [type]: filteredList });
  };

  return (
    <div className={notificationClasses.recipientListContainer}>
      <List className={notificationClasses.listContainer} subheader={<li />}>
        {recipients[type]?.length ? (
          recipients[type].map((sectionId) => (
            <li
              key={`section-${sectionId}`}
              className={notificationClasses.listItemContainer}
            >
              <ul>
                <ListItem>
                  <ListItemIcon>{renderIcon()}</ListItemIcon>
                  <ListItemText primary={sectionId} />

                  <ListItemSecondaryAction>
                    <MUIIconButton edge="end">
                      <HiOutlineTrash
                        size="0.7em"
                        onClick={() => onRecipientDelete(sectionId)}
                      />
                    </MUIIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </ul>
            </li>
          ))
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              {...columns}
              className={notificationClasses.emptyRecipientContainer}
            >
              {t('pages.notifications.pleaseAddRecipients')}
            </Grid>
          </Grid>
        )}
      </List>
    </div>
  );
}
