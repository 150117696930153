import Contents from './Contents';
import { supportMobileStyles } from '../../styles/Support';

function SupportMobile() {
  const classes = supportMobileStyles();
  return (
    <div className={classes.mobileContainer}>
      <Contents />
    </div>
  );
}
export default SupportMobile;
