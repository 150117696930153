import { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// component
import { callAPI } from '../../helper/api';
import { ContentHeading } from '../../components/common/Title';
import { setMergeDeployedInfoData } from '../../helper/device/deploymentInformation';

import {
  getManageBtnProps,
  setManageBtnVisibility
} from '../../helper/admin/page';

import { ToastContext } from '../../context/ToastContext';
import ActivityLog from './ActivityLog';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import deviceDetailStyles from '../../styles/DeviceDetail';
import DeviceInfo from './DeviceInfo';
import IconButtonContainer from '../../components/Button/IconButtonContainer';
import Status from './Status';
import UseStat from './UseStat';
import withAdminManage from '../../components/hoc/withAdminManage';
import useDeviceInfoData from './useDeviceInfoData';

// etc
import { toastType, adminType } from '../../constants';
import { devices, deviceManage } from '../../paths';
import useURLParam from './useURLParam';

function DeviceDetail({
  t,
  location,
  type,

  // func
  // onRowClick,
  // onEdit,
  // onSave,
  // onClose,

  // permission
  hasDelete,
  hasEdit,
  isEdit,
  ...props
}) {
  // third party
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  // styles
  const deviceDetailClasses = deviceDetailStyles();
  const [currentDevice, setCurrentDevice] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const { setToast } = useContext(ToastContext);

  // custom hook for devices
  const serial = useURLParam(location);
  const { deviceInfo, loading } = useDeviceInfoData(serial);

  const getData = async (options) => {
    try {
      const deviceResponse = await callAPI(`/devices`, options);

      if (deviceResponse?.status !== 200) {
        throw new Error('errorMessage.somethingWentWrong');
      }

      setDeviceList(deviceResponse.data);
    } catch (error) {
      setToast({
        message: t(error.message),
        type: toastType.error,
        open: true
      });
    }
  };

  const onClose = () => {
    const url = location?.state?.from ? location?.state?.from : devices;
    return history.push(url);
  };

  const linkArray = [
    {
      link: devices,
      text: serial
    }
  ];

  useEffect(() => {
    if (!location?.state?.deviceList) {
      getData();
    } else {
      setDeviceList(location?.state.deviceList);
    }
  }, []);

  const onRowEdit = async (data) => {
    try {
      history.push({
        pathname: deviceManage,
        state: {
          data: await setMergeDeployedInfoData(data)
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  // button props
  const headerButtonProps = getManageBtnProps({
    permissionObj: setManageBtnVisibility({
      isEdit,
      hasEdit,
      hasDelete,
      type
    }),
    buttons: {
      goEdit: () => {
        onRowEdit({ ...location?.state?.data, ...currentDevice });
      },
      onClose
    }
  });

  const renderDeviceComponent = () => (
    <DeviceInfo
      data={deviceInfo}
      status={deviceInfo.status !== 'Normal'}
      serialNum={serial}
      deviceList={deviceList}
      user={props.user}
      loading={loading}
      setCurrentDevice={setCurrentDevice}
    />
  );

  return (
    <>
      <ContentHeading
        title={t('menuName.devices')}
        option={<Breadcrumbs linkArray={linkArray} />}
        leftButton={<IconButtonContainer {...headerButtonProps} />}
      />

      <Grid container spacing={2}>
        {/* Left information */}
        <Grid item md={6} xs={12}>
          <div>
            {/* Status */}
            <Grid
              item
              xs={12}
              className={deviceDetailClasses.deviceDetailContent}
            >
              {!Object.keys(deviceInfo) ? (
                <CardContentLoader />
              ) : (
                <Status
                  status={deviceInfo.status}
                  serial={deviceInfo?.serial}
                />
              )}
            </Grid>

            {/* mobile size */}
            {matches && (
              <Grid
                item
                xs={12}
                className={deviceDetailClasses.deviceDetailContent}
              >
                {renderDeviceComponent()}
              </Grid>
            )}

            {/* Use Stats */}
            <Grid
              item
              xs={12}
              className={deviceDetailClasses.deviceDetailContent}
            >
              <UseStat serial={serial} />
            </Grid>

            {/* Activity Log */}
            <Grid
              item
              xs={12}
              className={deviceDetailClasses.deviceDetailContent}
            >
              <ActivityLog serial={serial} />
            </Grid>
          </div>
        </Grid>

        {/* Desktop Right information */}
        {!matches && (
          <Grid item md={6} xs={12}>
            {/* Device Info */}
            {renderDeviceComponent()}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default withAdminManage(adminType.csuvItem)(DeviceDetail);
