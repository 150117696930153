import { FaSearch } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import searchStyles from '../../styles/common/Search';

export default function Search({ searchText, onChange }) {
  const classes = searchStyles();
  const { t } = useTranslation();
  const columnOptions = { lg: 12, md: 12, sm: 12, xs: 12 };

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item {...columnOptions} className={classes.searchContainer}>
        <button>
          <FaSearch />
        </button>
        <input
          className={`${classes.input} fs-mask`}
          placeholder={t('common.search')}
          value={searchText}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
}
