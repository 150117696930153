import { selector } from 'recoil';

import { sortByObjectName } from '../../helper/filterOptions';
import { userState } from '../state';
import { filterState } from '../atom/filter';

export const getRoiFilterOptionByUser = selector({
  key: 'getRoiFilterOptionByUser',
  get: ({ get }) => {
    const user = get(userState);
    const filter = get(filterState);
    const { isSuperRikerAdmin, isRikerUser } = user.getUserPermission();
    let name = '';
    let id = '';
    let customerUid = '';
    if (isSuperRikerAdmin) {
      const initList = [...filter.customers].sort((prev, next) => {
        return sortByObjectName(prev, next, 'name');
      });
      name = 'customer';
      id = initList[0].id;
    } else if (isRikerUser) {
      name = 'customer';
      const initList = [...filter.customers].sort((prev, next) => {
        return sortByObjectName(prev, next, 'name');
      });
      id = initList[0].id;
      customerUid = initList[0].id;
    } else {
      name = 'customer';
      id = user?.customer_uid;
      customerUid = user?.customer_uid;
    }
    return {
      filterOptions: { name, id, customerUid }
    };
  }
});
