import { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { MdEmail } from 'react-icons/md';

import { ContentHeading } from '../../components/common/Title';
import { track } from '../../helper/segment';
import AccordionContainer from './AccordionContainer';
import Button from '../../components/Button/Button';
import hardwareData from './hardware';
import NoDataFound from '../../components/common/NoDataFound';
import Search from '../../components/common/Search';
import softwareData from './software';
import supportStyles, {
  supportButtonStyles,
  supportMobileStyles
} from '../../styles/Support';
import useSegment from '../../hooks/useSegment';

import browse from '../../assets/images/img_browse.svg';
import hardware from '../../assets/images/img_chip.svg';
import software from '../../assets/images/img_software.svg';
import { buttonStyle, color, emailAddress } from '../../constants';

function Content() {
  const classes = supportStyles();
  const mobileClasses = supportMobileStyles();
  const { t } = useTranslation();
  const [selectedTitle, setSelectedTitle] = useState('all');
  const [searchText, setSearchText] = useState('');

  const onClick = (title) => {
    track(`Support - Selected ${title}`);
    setSelectedTitle(title);
  };
  const column = { lg: 4, md: 6, sm: 6, xs: 6 };
  useSegment();

  const setDataByType = (list) => {
    const targetList = list.filter((item) => {
      const filteredSearchText = searchText.replace(
        /([.?*+^$[\]\\(){}|-])/g,
        ''
      ); // prevent invalid regex
      const regex = new RegExp(filteredSearchText, 'gi');

      return regex.test(item.q) || regex.test(item.a);
    });

    return targetList;
  };

  const filteredHardware = setDataByType(hardwareData);
  const filteredSoftware = setDataByType(softwareData);

  const showHardware =
    ['all', 'hardware'].includes(selectedTitle) && filteredHardware?.length;
  const showSoftware =
    ['all', 'software'].includes(selectedTitle) && filteredSoftware.length;

  const onContactClick = (event) => {
    event.preventDefault();
    window.location = `mailto:${emailAddress.success}`;
  };

  return (
    <>
      <Grid container>
        <Grid item lg={10} md={10} sm={6} xs={7}>
          <ContentHeading title={t('menuName.support')} />
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={6}
          xs={5}
          className={mobileClasses.contactContainer}
        >
          <Button
            description={t('pages.support.contact')}
            icon={<MdEmail fill={color.green} />}
            onClick={onContactClick}
            style={buttonStyle.filledGreen}
            fullWidth={true}
          />
        </Grid>
      </Grid>

      <Search
        onChange={(event) => setSearchText(event.target.value)}
        searchText={searchText}
      />

      <Grid container spacing={2} className={classes.supportButtonContainer}>
        <SupportButtonContainer
          title="pages.support.browseAll"
          style={buttonStyle.filledGreen}
          image={browse}
          column={column}
          selected={selectedTitle === 'all'}
          onClick={() => onClick('all')}
        />

        <SupportButtonContainer
          title="pages.support.hardware"
          style={buttonStyle.filledYellow}
          image={hardware}
          column={column}
          selected={selectedTitle === 'hardware'}
          onClick={() => onClick('hardware')}
        />

        <SupportButtonContainer
          title="pages.support.software"
          style={buttonStyle.filledRed}
          image={software}
          column={column}
          selected={selectedTitle === 'software'}
          onClick={() => onClick('software')}
        />
      </Grid>

      <Grid container item lg={12} style={{ marginTop: 30 }}>
        {showHardware || showSoftware ? (
          <>
            {showHardware ? (
              <AccordionContainer
                selectedTitle={selectedTitle}
                type="hardware"
                searchText={searchText}
                list={filteredHardware}
              />
            ) : null}
            {showSoftware ? (
              <AccordionContainer
                selectedTitle={selectedTitle}
                type="software"
                searchText={searchText}
                list={filteredSoftware}
              />
            ) : null}
          </>
        ) : (
          <NoDataFound />
        )}
      </Grid>
    </>
  );
}

export default Content;

function SupportButtonContainer({
  title,
  style,
  image,
  column,
  selected,
  onClick
}) {
  const classes = supportButtonStyles(style);
  const { t } = useTranslation();

  return (
    <Grid item {...column} onClick={() => onClick(t(title))}>
      <div
        className={`${classes.supportBtnContainer} ${
          selected && classes.supportItemSelected
        }`}
      >
        <img src={image} className={classes.supportImage} />
        <div className={classes.supportTextContainer}>
          <div className={classes.supportTextMobile}>
            <div className={classes.supportText}>{t(title)}</div>
            {!selected && (
              <span className={classes.supportViewButton}>
                view <BsArrowRight className={classes.supportArrow} size={20} />
              </span>
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
}
