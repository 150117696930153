import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';
import csuvLogo from '../../assets/images/logo_.svg';

// etc
import { signInRight } from '../../styles/SignIn';

export default function SignInRight() {
  const classes = signInRight();

  return (
    <Grid
      className={classes.imageContainer}
      item
      lg={6}
      md={12}
      sm={12}
      xs={12}
      justifyContent="center"
      direction="row"
      container
    >
      <div className={classes.rightInfoContainer}>
        <img src={csuvLogo} className={classes.rightLogo} />
        <Trans
          i18nKey="pages.signIn.description"
          components={{
            span: <span className={classes.smallInfoText} />,
            p: <p className={classes.largeInfoText} />
          }}
        />
      </div>
    </Grid>
  );
}
