import { useEffect, useState } from 'react';
import { BiLogIn } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components
import { callAPI } from '../helper/api';
import { MainHeading } from '../components/common/Title';
import { getTierExpiryDate } from '../helper/date';
import Button from '../components/Button/Button';
import ErrorMessage from '../components/common/ErrorMessage';
import FloatLogo from '../components/common/FloatLogo';
import Modal from '../components/common/Modal';
import PasswordField from '../components/Input/PasswordField';
import PasswordRequirement from '../components/Input/PasswordRequirement';
import useLanguage from '../hooks/useLanguage';
import fullStory from '../segment';

// etc
import * as authLib from '../lib/auth';
import { signInLeft } from '../styles/SignIn';
import { userState } from '../recoil/state';
import {
  buttonStyle,
  inputType,
  modalTime,
  apiActionTypes
} from '../constants';
import { track } from '../helper/segment';
import forgotPasswordStyles from '../styles/ForgotPassword';
import loading from '../assets/images/loading.gif';
import paths from '../paths';
import validation from '../helper/validation';

const initNewPassword = {
  newPassword: '',
  newPasswordConfirm: '',
  valid: {
    newPassword: false,
    newPasswordConfirm: false
  }
};

export default function ResetPassword() {
  const formClasses = signInLeft();
  const history = useHistory();
  const classes = forgotPasswordStyles();
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const setAuthedUser = useSetRecoilState(userState);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState('');
  const [password, setNewPassword] = useState(initNewPassword);

  useLanguage();

  async function getUserInfo() {
    const response = await callAPI('/user-info', {
      type: apiActionTypes.FETCH
    });

    return response?.data;
  }

  const onChange = (stateName, value, type) => {
    setNewPassword({
      ...password,
      [stateName]: value.trim(),
      valid: { ...password.valid, [stateName]: validation(type, value.trim()) }
    });
    setShowError(false);
  };

  async function onResetPassword(event) {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    if (!password.valid.newPassword || !password.newPasswordConfirm) {
      setShowError(true);
      setIsLoading(false);
      return;
    }

    if (password.newPassword !== password.newPasswordConfirm) {
      setShowError(true);
      setIsLoading(false);
      setErrorMessage(t('errorMessage.passwordNotMatch'));
      return;
    }

    try {
      const { isAuthenticated, cognitoUser } =
        await authLib.initializeNewPassword(user, password.newPassword);
      const cognitoUserGroups =
        cognitoUser?.signInUserSession?.accessToken?.payload['cognito:groups'];

      // throw error user does not have cognito user group array
      if (
        !cognitoUserGroups ||
        !Array.isArray(cognitoUserGroups) ||
        cognitoUserGroups.length === 0
      ) {
        throw new Error('errorMessage.cannotFindUserGroupFromCognito');
      }

      const userGroup = authLib.getUserGroup(cognitoUserGroups);
      const userTierGroup = authLib.getTierGroup(cognitoUserGroups);

      // throw error if user does not have tier
      if (!userTierGroup) {
        throw new Error('errorMessage.cannotFindUserTierFromCognito');
      }

      // throw error if user does not have data from database
      const userFromDatabase = await getUserInfo();

      if (!userFromDatabase) {
        throw new Error('errorMessage.cannotFindUserFromDatabase');
      }

      const { tierRemainDays, is30DaysLeft } = getTierExpiryDate(
        userFromDatabase,
        cognitoUserGroups
      );

      setIsOpen(true);

      callAPI('/gwapi-to-sns', {
        type: 'LOGIN_HISTORY',
        data: cognitoUser.username
      });

      setTimeout(() => {
        setAuthedUser((currentState) => {
          return {
            ...currentState,
            ...userFromDatabase,
            email: cognitoUser.username,
            isAuthed: isAuthenticated,
            tier: userTierGroup,
            timezone:
              user?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            userGroup,
            username: cognitoUser.username,
            getUserPermission: () => authLib.getUserPermission(userGroup),
            tierRemainDays,
            is30DaysLeft
          };
        });

        // notify segment of logged in user
        window.analytics.identify(cognitoUser.username, {
          name: `${userFromDatabase.first_name} ${userFromDatabase.last_name}`,
          firstName: userFromDatabase.first_name,
          lastName: userFromDatabase.last_name,
          email: cognitoUser.username,
          plan: userTierGroup
        });

        track(fullStory.signin.signInSuccessful);
        history.push({ pathname: paths.home, isTierModalOpen: is30DaysLeft });
      }, modalTime);
    } catch (error) {
      console.error('Reset  Failed: ', error);
      track(fullStory.signin.signInFail);
      setErrorMessage(t(authLib.cognitoErrorHelper(error)));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (
      history.location.state &&
      'newUser' in history.location.state &&
      history.location.state.newUser
    ) {
      setUser({
        ...user,
        email: history.location.state.email,
        sessionToken: history.location.state.sessionToken,
        password: history.location.state.password
      });
    } else {
      history.push(paths.signIn);
    }

    return null;
  }, []);

  return (
    <Grid
      className={classes.container}
      container
      justifyContent="center"
      alignItems="center"
      lg={12}
    >
      <Grid className={classes.formContainer}>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            title="Your new password has successfully been set."
            message="You will now be redirected to your Dashboard."
          />
        )}
        <FloatLogo />
        <MainHeading title="menuName.resetPassword" />

        <form onSubmit={(event) => onResetPassword(event)}>
          <PasswordField
            autoComplete="new-password"
            cy="newPassword"
            helperText=""
            label="label.newPassword"
            onChange={(event) =>
              onChange('newPassword', event.target.value, inputType.password)
            }
            isInvalid={showError ? !password.valid.newPassword : false}
            value={password.newPassword}
            errorMessage={t('errorMessage.password')}
          />

          <PasswordRequirement />

          <PasswordField
            autoComplete="new-password"
            cy="newpasswordConfirm"
            helperText=""
            label="label.newPasswordConfirm"
            isInvalid={showError ? !password.valid.newPasswordConfirm : false}
            onChange={(event) =>
              onChange(
                'newPasswordConfirm',
                event.target.value,
                inputType.password
              )
            }
            value={password.newPasswordConfirm}
            errorMessage={t('errorMessage.password')}
          />

          <div className={formClasses.buttonContainer}>
            {isLoading ? (
              <img src={loading} style={{ width: 20 }} />
            ) : (
              <Button
                description="label.sendResetPassword"
                fullWidth={true}
                style={buttonStyle.filledBlue}
                type={inputType.submit}
                icon={<BiLogIn />}
              />
            )}
          </div>
        </form>

        {errorMessage && <ErrorMessage message={errorMessage} />}
      </Grid>
    </Grid>
  );
}
