import { Trans } from 'react-i18next';

export default function PasswordRequirement() {
  return (
    <span style={{ fontSize: 12 }}>
      <Trans i18nKey="pages.forgotPassword.passwordRestriction">
        Password must contain <strong>at least 8 characters</strong>, an{' '}
        <strong>uppercase</strong>
        <br /> and{' '}
        <strong>lowercase letter, a number, and a special character.</strong>
      </Trans>
    </span>
  );
}
