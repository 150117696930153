export default {
  1111111111: 'normal',
  1100001111: 'coolingRequired',
  1100011111: 'bulbChange1W',
  1100101111: 'bulbChange2W',
  1100111111: 'bulbChange3W',
  1101001111: 'bulbChange4W',
  1101011111: 'trackingSystemFailed',
  1101101111: 'bulbChange2D',
  1101111111: 'bulbChange1D',
  1110011111: 'displayWarning',
  1110001111: 'dimmerWarning',
  1111110000: 'error101M',
  1111110010: 'error103',
  1111110011: 'error105',
  1111110101: 'error106',
  1111110110: 'error107',
  1111110111: 'error108',
  1111111000: 'error109',
  1111111001: 'error101R',
  1111110001: 'error101S',
  1111111010: 'error101F',
  1100101000: 'dimmerVoltageWarning'
};
