import { makeStyles } from '@material-ui/styles';
import { color, fontFamily } from '../../constants';

export default makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    boxShadow: color.cardShadow
  },
  searchContainer: {
    display: 'flex',
    background: color.white,
    borderRadius: 5,
    padding: '10px',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    border: 'none',
    padding: 3,
    fontFamily: fontFamily.regular,
    color: color.darkBlue,
    marginLeft: 30,
    width: '100%',
    '&::placeholder': {
      color: color.iconGrey
    },
    '&:active, &:focus': {
      outline: 'none'
    }
  },
  sortByContainer: {
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  sortByTitle: {
    fontFamily: fontFamily.semiBold,

    minWidth: 80
  },
  sortBy: {
    backgroundColor: '#E0F322',
    color: '#003459',
    border: 'none',
    height: '100%',
    borderRadius: 5,
    fontFamily: fontFamily.regular,
    padding: 5,
    width: '100%'
  }
});
