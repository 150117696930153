import { makeStyles } from '@material-ui/styles';
import { color } from '../../constants';

export default makeStyles({
  toastContainer: {
    minWidth: 300
  },
  toastTitle: {
    color: color.white
  },
  toastContent: {
    color: color.white
  },
  toastCloseButton: {
    borderRadius: 30
  }
});
