import logoStyles from '../../styles/common/Logo';
import logo from '../../assets/images/logo.svg';
import paths from '../../paths';

export default function FloatLogo() {
  const classes = logoStyles();

  return (
    <div className={classes.logo}>
      <a href={paths.home}>
        <img src={logo} />
      </a>
    </div>
  );
}
