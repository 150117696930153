import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import textFieldStyles from '../../../styles/Input/TextField';

/**
 * @description when select does not have list, render this component instead for ui/ux
 * @param {string} props.inputLabel input label text
 * @param {string} props.message input value placeholder
 * @param {string} props.name input name for testing
 * @returns
 */
export default function NoList({ inputLabel, message, name }) {
  const textFieldClasses = textFieldStyles();
  const InputLabelProps = {
    classes: {
      root: textFieldClasses.root,
      focused: textFieldClasses.focused
    }
  };

  const InputProps = {
    classes: {
      root: textFieldClasses.outlineRoot,
      focused: textFieldClasses.focused,
      notchedOutline: textFieldClasses.notchedOutline
    },
    readOnly: true
  };

  return (
    <Grid item xs={12}>
      <TextField
        disabled={true}
        fullWidth={true}
        inputProps={{ 'data-testid': `input-${name}` }}
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        label={inputLabel}
        margin="normal"
        value={message}
        variant="outlined"
      />
    </Grid>
  );
}
