import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Grid from '@material-ui/core/Grid';

// components
import { averageSanitizationInfoCardStyle } from '../../styles/Analytics';
import { ContentHeading } from '../../components/common/Title';
import { setTableColumns, getFormattedList } from '../../helper/tableOptions';
import { useChartDateChange } from '../../components/pages/Analytics/hooks/useChartDateChange';
import { userState } from '../../recoil/state';
import { useStatisticState } from '../../recoil/atom/useStatistic';
import CardContentLoader from '../../components/Loader/CardContentLoader';
import FilterChartContainer from '../../components/Charts/FilterChartContainer';
import Loading from '../../components/common/Loading';
import SanitizationInfoCard from '../Averages/SanitizationInfoCard';
import Switch from '../../components/Input/Switch';
import TableContainer from '../../components/common/Table/TableContainer';
import useAnalytics from '../../hooks/useAnalytics';
import useSegment from '../../hooks/useSegment';
import withContent from '../../components/hoc/withContent';

// etc
import { analyticsType } from '../../constants';

function UseStatistics() {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const classes = averageSanitizationInfoCardStyle();
  const usageRecoil = useRecoilValue(useStatisticState);

  // analytics react hook
  const { resData, loading } = useAnalytics(analyticsType.usage);

  // 2022-08-15 chart data's date is not same because of loading latest data
  const changed = useChartDateChange(resData.chart, {
    startDate: usageRecoil.filterOptions.startDate,
    endDate: usageRecoil.filterOptions.endDate
  });

  const modifiedList = getFormattedList(resData?.table);
  const columns =
    modifiedList?.length > 0 &&
    setTableColumns(Object.keys(modifiedList[0]), user, t);

  let totalUsage = 0;

  for (let index = 0; index < resData.chart.data?.length; index += 1) {
    totalUsage += resData.chart.data[index]?.scan_cycle_total;
  }

  const tableProps = {
    columns,
    rows: modifiedList,
    hasDownload: true,
    hasSearch: true,
    tableName: t('menuName.useStatistics')
  };

  // to control trend line
  const [checked, setChecked] = React.useState(true);

  // filter chart props
  const filterChartProps = {
    changed,
    chartData: resData.chart,
    listOptions: { customer: true, group: true },
    loading,
    type: analyticsType.usage,
    filterProps: {
      switch: {
        value: checked,
        element: (
          <Grid item xs={12}>
            <Switch
              checkHandler={[checked, setChecked]}
              hasSwitch={true}
              label="Show trend line"
              labelPlacement="end"
              size="small"
            />
          </Grid>
        )
      }
    }
  };

  useSegment();

  return (
    <>
      <Loading isOpen={loading} />

      <Grid container spacing={2}>
        <Grid item lg={9} xs={12}>
          <ContentHeading
            title={t('menuName.useStatistics')}
            description={t('pages.analytics.useStatsDescription')}
          />
        </Grid>
        <Grid item lg={3} xs={12} className={classes.titleContainer}>
          <Grid container spacing={1}>
            <SanitizationInfoCard
              title="pages.analytics.averageTotal"
              number={totalUsage}
              changedDateByData={changed}
              filterOptions={usageRecoil.filterOptions}
            />
          </Grid>
        </Grid>
      </Grid>

      <FilterChartContainer {...filterChartProps} />

      {/* Section Table */}
      {!loading ? (
        <TableContainer tableProps={tableProps} />
      ) : (
        <CardContentLoader />
      )}
    </>
  );
}

export default withContent(UseStatistics);
