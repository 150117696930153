/* eslint-disable no-template-curly-in-string */
import { format } from 'date-fns';
import i18n from 'i18next';
import { convertFloat } from './roi/csuvCalculator';
import {
  getChartXData,
  setXAxisPositionByYr,
  getFormattedXAxisCategories,
  getCustomerChartName
} from './roi/roi';
import {
  calculateRegression,
  getCommonOptions,
  getFormatPieData,
  getFormattedDataList,
  getPieChartDataByActivity,
  getTotalRegFormat,
  renameObject
} from './chart';
import { setByHour, createChartList, sortByHours } from './trend';
import { color } from '../constants';

/**
 * @description line chart options
 * @param {Object} object
 * @param {string} object.timezone - user timezone
 * @param {string} object.title - title of chart
 * @param {Object} object.data - response date from analytics API
 * @return {Object} chart options
 */
export const lineWeekChartOptions = ({
  showValues,
  data = [],
  yAxisTitle,
  xAxisTitle,
  currentWeek
}) => {
  if (!data.length) return null;

  const [showBarValues, compareUsages] = showValues;

  const xAxisCategories = [];
  const seriesData = [];
  const currentWeekData = [];

  // previous data series
  data.forEach((item) => {
    const { total, day } = item[1];

    const barColor = [
      'pages.analytics.days.sun',
      'pages.analytics.days.sat'
    ].includes(day)
      ? color.fontGrey
      : color.lightBlue;

    const label = i18n.t(day);
    xAxisCategories.push(label);

    seriesData.push({
      name: `Previous weeks usage<br/>${label}`,
      color: barColor,
      y: total,
      dataLabels: {
        enabled: showBarValues,
        format: '{point.y:.1f}'
      }
    });
  });

  currentWeek.forEach((item) => {
    const { day, current } = item;
    const label = i18n.t(day);

    currentWeekData.push({
      name: `This week usage<br/>${label}`,
      color: color.darkBlue,
      y: current
    });
  });

  const currentWeekLineGraph = {
    type: 'line',
    // dashStyle: 'shortdot',
    showInLegend: false, // hide legend
    lineWidth: 0, // remove line
    data: currentWeekData,
    color: color.darkBlue,
    states: { hover: { enabled: false } },

    tooltip: {
      formatter() {
        return `The value for <b>${this.x}</b> is <b>${this.y}</b>`;
      }
    }
  };

  const previousData = { data: seriesData, tooltip: { enabled: false } };

  if (!seriesData.length || !xAxisCategories.length) return null;

  const commonOption = getCommonOptions({
    timezone: 'America/Toronto',
    title: '',
    yAxisTitle,
    xAxisTitle
  });

  return {
    ...commonOption,

    tooltip: {
      formatter() {
        return `${this.key}: ${this.y?.toFixed(1)}`;
      }
    },

    xAxis: {
      categories: xAxisCategories,
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'EuclidTriangle-Regular'
        },
        rotation: -45
      },
      title: {
        text: xAxisTitle
      }
    },

    chart: {
      type: 'column',
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },

    legend: {
      enabled: false
    },

    plotOptions: {
      column: {
        tooltip: {
          enabled: false
        }
      }
    },

    series: compareUsages ? [previousData, currentWeekLineGraph] : previousData
  };
};

/**
 * @description line chart options
 * @description stack column chart options
 * @param {Object} object
 * @param {string} object.timezone - user timezone
 * @param {string} object.title - title of chart
 * @param {Object} object.data - response date from analytics API
 * @return {Object} chart options
 */
export const statisticsOptions = ({
  data,
  language,
  showTrendLine,
  timezone,
  title,
  xAxisTitle,
  yAxisTitle
}) => {
  const successList = getFormattedDataList({
    data,
    activity: 'scan_cycle_success'
  });
  const failedList = getFormattedDataList({
    data,
    activity: 'scan_cycle_fail'
  });

  const totalList = getFormattedDataList({
    data,
    activity: 'scan_cycle_total'
  });

  const successData = {
    name: language.success,
    data: successList,
    color: color.lightBlue
  };
  const failedData = {
    name: language.stopped,
    data: failedList,
    color: color.mustardYellow
  };

  const { regData, isPositive, pre, calculated } = getTotalRegFormat(
    calculateRegression(totalList)
  );

  const totalRegData = {
    type: 'spline',
    dashStyle: 'shortdot',
    showInLegend: false, // hide legend
    data: regData,
    color: color.darkBlue,
    tooltip: {
      headerFormat: '',
      pointFormatter() {
        return `Change: <b style="color: ${
          isPositive ? color.green : color.red
        }"> ${pre}${Math.abs(calculated)} %</b>`;
      }
    }
  };

  const seriesArray = showTrendLine
    ? [successData, failedData, totalRegData]
    : [successData, failedData];

  return {
    ...getCommonOptions({ timezone, title, yAxisTitle, xAxisTitle }),
    chart: {
      type: 'column',
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },
    legend: {
      reversed: true
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        label: {
          connectorAllowed: false
        },
        pointInterval: 24 * 3600 * 1000 // one day
      }
    },
    series: seriesArray
  };
};

/**
 * @description pie chart options
 * @param {Object} object
 * @param {string} object.timezone - user timezone
 * @param {string} object.title - title of chart
 * @param {Object} object.data - response date from analytics API
 * @param {string} object.activity - selected activity name
 * @param {Object} object.filterList - formatted filter list
 * @return {Object} chart options
 */
export const compareOptions = ({
  timezone,
  title,
  data,
  activity,
  filterList
}) => {
  let dataByActivity = getPieChartDataByActivity(data, activity);
  dataByActivity = renameObject(dataByActivity, filterList);
  const formattedPieDataByActivity = getFormatPieData(dataByActivity);

  if (!formattedPieDataByActivity) return null;

  return {
    time: {
      timezone
    },
    credits: {
      text: 'www.cleanslateuv.com',
      href: 'https://cleanslateuv.com/',
      enabled: true,
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },
    title: {
      text: title
    },
    tooltip: {
      formatter() {
        return `<span class="fs-mask">${this.key}</span>`;
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format:
            '<b class="fs-mask">{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [
      {
        colorByPoint: true,
        data: formattedPieDataByActivity
      }
    ]
  };
};

export const trendOptions = ({
  timezone,
  title,
  data,
  xAxisTitle,
  language,
  yAxisTitle
}) => {
  let failMap = new Map();
  let successMap = new Map();

  const date = data[0].sk.split('T')[0];

  data.forEach((item) => {
    successMap = setByHour({ map: successMap, list: item.scan_cycle_success });
    failMap = setByHour({ map: failMap, list: item.scan_cycle_fail });
  });

  let successList = createChartList({ map: successMap, date, tz: timezone });
  let failedList = createChartList({ map: failMap, date, tz: timezone });

  successList = sortByHours(successList);
  failedList = sortByHours(failedList);

  const yAxisTime = successList.map((time) => time.name);

  const successData = {
    name: language.success,
    data: successList,
    color: color.lightBlue
  };
  const failedData = {
    name: language.stopped,
    data: failedList,
    color: color.mustardYellow
  };

  return {
    ...getCommonOptions({ timezone, title }),
    chart: {
      type: 'bar',
      style: {
        fontFamily: 'EuclidTriangle-Regular'
      }
    },
    title: {
      text: title
    },
    xAxis: {
      categories: yAxisTime,
      title: {
        text: yAxisTitle
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: xAxisTitle
      }
    },
    legend: {
      reversed: true
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    series: [successData, failedData]
  };
};

export const roiChartOptions = ({
  customerName,
  data,
  payback,
  startDate,
  endDate,
  selectedYr,
  zones,
  savings
}) => {
  if ([data].every((item) => item.length))
    return {
      chart: {
        type: 'area',
        spacingLeft: 0,
        spacingRight: 0
      },
      title: {
        text: `Cleanslate UV ROI${getCustomerChartName(customerName)}`
      },
      legend: {
        enabled: false
      },

      xAxis: [
        {
          tickPositions: setXAxisPositionByYr(selectedYr), // specific xAxis label position by index
          categories: getFormattedXAxisCategories(data, startDate),
          allowDecimals: false,
          minPadding: 0,
          maxPadding: 0,
          title: {
            text: 'Time'
          }
        }
      ],
      point: {},

      yAxis: [
        {
          title: {
            text: 'Savings on Dollars'
          },
          labels: {
            formatter() {
              return `$ ${this.value}`;
            }
          }
        },
        {
          title: {
            text: 'Percentage of Return on investment'
          },
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          },
          opposite: true
        }
      ],
      series: [
        {
          name: 'Savings in Dollars',
          type: 'spline',

          data: savings?.map((d) => d[1]),
          color: color.black,
          marker: {
            enabled: false
          },
          dashStyle: 'shortdot',
          tooltip: {
            headerFormat: '',
            pointFormatter() {
              const yr = Math.floor(this.x / 12 + 1);
              const month = (this.index % 12) + 1;

              return `M${month}, Y${yr} - $ ${convertFloat(this.y, 2)}</b>`;
            }
          }
        },
        {
          type: 'area',
          data: getChartXData(data, payback),
          zoneAxis: 'x',
          zones,
          yAxis: 1,
          tooltip: {
            headerFormat: '',
            pointFormatter() {
              const yr = Math.floor(this.x / 12 + 1);
              const month = (this.index % 12) + 1;

              return `M${month}, Y${yr} - ${convertFloat(this.y, 2)}%</b>`;
            }
          }
        }
      ],
      credits: {
        text: 'www.cleanslateuv.com',
        href: 'https://cleanslateuv.com/',
        enabled: true,
        style: {
          fontFamily: 'EuclidTriangle-Regular'
        }
      },
      exporting: {
        filename: `${customerName}_${`${format(
          startDate,
          'yyyy-MM-dd'
        )}-${format(endDate, 'yyyy-MM-dd')}`}`,
        buttons: {
          contextButton: {
            menuItems: [
              // 'printChart',
              // 'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG'
            ]
          }
        }
      }
    };

  return null;
};

export const roiCompareChartOptions = ({ csuv, wipe }) => {
  if (!csuv || !wipe) return null;

  return {
    chart: {
      type: 'bar',
      height: 200
    },
    title: {
      text: 'Cost per sanitization'
    },

    xAxis: {
      categories: ['Cleanslate UV', 'Wipe'],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Cost ($USD)',
        align: 'high'
      },
      labels: {
        overflow: 'justify',
        format: '${text}',
        align: 'right'
      }
    },
    tooltip: {
      valuePrefix: 'USD $'
    },
    plotOptions: {
      bar: {
        colors: [color.lightBlue, color.red],
        dataLabels: {
          align: 'center',
          enabled: true,
          format: `$ {y}`,
          position: 'center'
        }
      }
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      shadow: true
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: 'Cleanslate UV',
        data: [{ y: parseFloat(csuv), color: color.lightBlue }, null]
      },
      {
        name: 'Wipe',
        data: [null, { y: parseFloat(wipe), color: color.red }]
      }
    ],
    exporting: {
      enabled: false
    }
  };
};
