import PropTypes from 'prop-types';
import { mobileDownloadButtonStyles } from '../../styles/common/Button';
import imgAppleStore from '../../assets/images/img_appstore.svg';
import imgGooglePlay from '../../assets/images/img_android.svg';

export default function MobileDownloadButton() {
  const classes = mobileDownloadButtonStyles();

  return (
    <>
      <div className={classes.mobileDownloadContainer}>
        <AppDownload
          link="https://play.google.com/store/apps/details?id=com.cleanslateuv.csuv"
          img={imgGooglePlay}
        />
        <AppDownload
          link="https://apps.apple.com/ca/app/cleanslate-uv/id1522084469"
          img={imgAppleStore}
        />
      </div>
    </>
  );
}

function AppDownload({ link, img }) {
  return (
    <a href={link} target="_blank">
      <img src={img} />
    </a>
  );
}

AppDownload.propTypes = {
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
};
