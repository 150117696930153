import { deviceStatus, dataOptions } from '../constants';
import ballastStatusObject from '../ballastStatus';

export function getLabelByValue(value) {
  let label = '';
  let modifiedValue = value;

  if (Number.isInteger(value)) {
    modifiedValue = ballastStatusObject[`${value}`];
  }

  switch (modifiedValue?.toLowerCase()) {
    case dataOptions.inProgress:
      label = 'common.inProgress';
      break;

    case dataOptions.failed:
      label = 'common.failed';
      break;

    case dataOptions.all:
      label = 'common.errorWarning';
      break;

    case dataOptions.completed:
    case dataOptions.success:
      label = 'common.success';
      break;

    case dataOptions.stopped:
      label = 'common.stopped';
      break;

    case deviceStatus.normal:
      label = 'common.normal';
      break;

    case deviceStatus.warning:
      label = 'common.warning';
      break;

    case deviceStatus.error:
      label = 'common.error';
      break;

    case 'good':
      label = 'common.good';
      break;

    default:
      label = value;
      break;
  }

  return { label };
}

/**
 * @description get formatted select options by filter's array value
 * @param {Array} list array value from filter options
 * @return {Object} formatted array list
 */
export function getFilterToSelectOption(list) {
  if (list?.length && list?.every((item) => item.name && item.name)) {
    return list.map((item) => {
      return {
        value: item?.id,
        text: item?.name
      };
    });
  }

  return null;
}

/**
 * @description get default select value by id
 * @param {Array} list array value from filter options
 * @return {string} selected value or default value
 */
export function getDefaultValueById(list, id) {
  const defaultValue = list.find((item) => item?.value === id);
  return defaultValue?.value || list[0]?.value;
}
