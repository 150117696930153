import { useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { VscGithubAction } from 'react-icons/vsc';
import Grid from '@material-ui/core/Grid';

import { ContentHeading } from '../components/common/Title';
import { devices, scheduledManage, scheduled } from '../paths';
import { setTableOptions } from '../helper/tableOptions';
import { userState } from '../recoil/state';
import Breadcrumbs from '../components/common/Breadcrumbs';
import Button from '../components/Button/Button';
import ButtonMenu from '../components/Menu/ButtonMenu';
import Card from '../components/common/Card';
import CustomDateRange from '../components/common/CustomDateRange';
import Filter from '../components/Filter/Filter';
import Modal from '../components/common/Modal';
import OutlineSelect from '../components/Input/OutlineSelect';
import ReportType from '../components/Reports/ReportType';
import Search from '../components/common/Search';
import Table from '../components/common/Table';
import withContent from '../components/hoc/withContent';
import useSegment from '../hooks/useSegment';
import scheduledReportStyles from '../styles/ScheduledReport';
import { buttonStyle, tableType } from '../constants';

const initOpen = {
  filter: false,
  table: false
};

function createData(reportType, partner, customer, size, id) {
  return { reportType, partner, customer, size, id };
}

const rows = [
  createData('First Floor', 5, '', '', 'id1'),
  createData('Breakroom', 2, '', '', 'id1'),
  createData('Second Floor', 10, '', '', 'id1'),
  createData('East Wing', 5, '', '', 'id1'),
  createData('West Wing', 5, '', '', 'id1'),
  createData('DE-123, DE-4456, DE-888', 10, '', '', 'id1')
];

const initDate = {
  startDate: new Date(),
  endDate: new Date()
};

const initModalOpen = {
  dateRange: false,
  scheduledDate: false
};

const linkArray = [
  {
    link: devices,
    text: 'Manage'
  }
];

function ScheduledReport() {
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [isOpen, setIsOpen] = useState(initModalOpen);
  const [device, setDevice] = useState('');
  const [open, setOpen] = useState(initOpen);
  const setSortOption = useState('')[1];
  const { userGroup } = useRecoilValue(userState);
  const { t } = useTranslation();
  const classes = scheduledReportStyles();
  const history = useHistory();

  const deviceArray = [
    { text: 'All', value: 'All' },
    { text: 'Device 2', value: 'Device2' },
    { text: 'Device 3', value: 'Device3' }
  ];

  const isManagePage = window.location.pathname.includes(scheduledManage);
  const columns = setTableOptions(userGroup, tableType.scheduled);

  const listOptions = {
    partner: true,
    customer: true,
    group: true,
    device: true,
    scheduled: () => setIsOpen({ ...isOpen, scheduledDate: true }),
    dateRange: () => setIsOpen({ ...isOpen, dateRange: true })
  };

  // menu list for top - right buttons
  const menuList = [
    { description: 'Add New', callback: () => history.push(scheduledManage) }
  ];
  const manageMenuList = [
    { description: 'Save', callback: () => history.push(scheduledManage) },
    { description: 'Cancel', callback: () => history.push(scheduled) }
  ];
  useSegment();

  return (
    <>
      {isOpen.dateRange && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen({ ...isOpen, dateRange: false })}
          title={t('modal.customDateRange.title')}
        >
          <CustomDateRange
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            onClose={() => setIsOpen(false)}
          />
        </Modal>
      )}

      {isOpen.scheduledDate && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen({ ...isOpen, scheduledDate: false })}
          title={t('modal.scheduledDate.title')}
        >
          <CustomDateRange
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            onClose={() => setIsOpen(false)}
            scheduled={true}
          />
        </Modal>
      )}

      <ContentHeading
        title={t('menuName.scheduledReport')}
        tooltip={
          !isManagePage
            ? 'pages.reports.tooltips.scheduledReports'
            : 'pages.reports.tooltips.scheduledReportsManage'
        }
        option={isManagePage && <Breadcrumbs linkArray={linkArray} />}
        leftButton={
          <ButtonMenu
            menuList={isManagePage ? manageMenuList : menuList}
            button={
              <Button description="label.actions" icon={<VscGithubAction />} />
            }
          />
        }
      />

      <Grid container spacing={2}>
        <Grid container item lg={7} md={12} sm={12} xs={12} alignItems="center">
          <Grid item lg={9} md={8} sm={7} xs={7}>
            <ReportType
              selectedItem={device}
              setItem={setDevice}
              itemArray={deviceArray}
              toolTipMessage="Here is the Report type help tooltip"
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={5}
            xs={5}
            style={{ paddingTop: 5, paddingLeft: 10 }}
          >
            <Button
              description="Filter"
              icon={<BiFilterAlt />}
              style={buttonStyle.filledRed}
              fullWidth={true}
              onClick={() => setOpen({ ...open, filter: !open.filter })}
            />
          </Grid>
        </Grid>

        {open.filter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card style={buttonStyle.filledBlue}>
              <Filter
                listOptions={listOptions}
                column={{ lg: 4, md: 6, sm: 6, xs: 6 }}
                hasTitle={false}
                buttonDescription="label.applyFilter"
                onClick={() => setOpen({ ...open, table: !open.table })}
              />
            </Card>
          </Grid>
        )}

        {!isManagePage && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.scheduledReportSearch}
          >
            <Search
              sortOptions={columns.map((column) => column.label)}
              onOptionChange={setSortOption}
            />
          </Grid>
        )}

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card>
            {isManagePage ? (
              <Grid container spacing={2}>
                <OutlineSelect
                  label="Receipients:"
                  lg={4}
                  md={4}
                  onChange={() => console.log('')}
                  sm={12}
                  value={'condition'}
                  menuItemArray={[]}
                />
                <OutlineSelect
                  label="Frequency:"
                  lg={4}
                  md={4}
                  onChange={() => console.log('')}
                  sm={12}
                  value={'condition'}
                  menuItemArray={[]}
                />
                <OutlineSelect
                  label="Time:"
                  lg={4}
                  md={4}
                  onChange={() => console.log('')}
                  sm={12}
                  value={'condition'}
                  menuItemArray={[]}
                />
              </Grid>
            ) : (
              <Table
                columns={columns}
                link={scheduledManage}
                rows={rows}
                hasEdit={true}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default withContent(ScheduledReport);
