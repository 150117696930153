import { atom } from 'recoil';
import { getAnalyticsDefaultDateRange } from '../../helper/date';
import { average } from '../key';
import { analyticsType, dataOptions } from '../../constants';
import { getDefaultAnalytics } from '../selector/analytics_page';

export const defaultAverage = {
  filterOptions: {
    type: analyticsType.average,
    id: '',
    name: '',
    ...getAnalyticsDefaultDateRange('months')
  },
  dateRange: dataOptions.month
};

export const averageState = atom({
  key: average.averageState,
  default: getDefaultAnalytics(analyticsType.average)
});
